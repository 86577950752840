import React, { FC, useState, useRef, useContext, useMemo } from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import { Button } from '@sumup/circuit-ui';
import Tagger from '@elbwalker/tagger';

import { MenuItem as MenuItemType } from '../../interfaces';
import {
  DATA_SELECTOR,
  LINK_SELECTOR,
  LIST_ITEM_SELECTOR,
  SHOP_CATALOG_PAGES_ZONE,
} from '../../constants';
import LanguageMenu from '../LanguageMenu';
import MenuItem from '../MenuItem';
import GroupsMenu from '../GroupsMenu';
import ShoppingCart from '../ShoppingCart';

import { shouldShowStickyCta } from './DesktopMenuService';

import dataSelector from '~/shared/util/data-selector';
import isTouchDevice from '~/shared/util/isTouchDevice';
import SiteContext from '~/shared/providers/SiteContext';
import { PageContext } from '~/shared/providers/PageContext';
import { NAVIGATION_EVENTS } from '~/shared/services/optimizely/navigation-revamp-experiment/constants';
import { ACTIONS, ENTITIES, TRIGGERS } from '~/shared/constants/tracking';
import Link from '~/shared/components/Link';
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';

export const SHOW_DELAY_TIME = 100;
export const CLOSE_DELAY_TIME = 500;

const navStyles = ({ theme }: { theme: Theme }) => css`
  display: none;

  ${theme.mq.mega} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: min(${theme.spacings.tera}, 1vw);
  }
`;

const Nav = styled('nav')(navStyles);

const listStyles = ({ theme }: { theme: Theme }) => css`
  list-style: none;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: auto;
  gap: min(${theme.spacings.giga}, 1vw);
  align-items: center;

  ${theme.mq.tera} {
    gap: ${theme.spacings.giga};
  }
`;

const StyledList = styled('ul')(listStyles);

const listItemStyles = ({ theme }: { theme: Theme }) => css`
  height: ${theme.spacings.nav.mobile};
  display: flex;
  align-items: center;

  ${theme.mq.tera} {
    height: ${theme.spacings.nav.desktop};
  }
`;
const StyledListItem = styled('li')(listItemStyles);

const StickyContainerStyles = ({ theme }: { theme: Theme }) => css`
  display: flex;
  gap: ${theme.spacings.kilo};
  align-items: center;
`;

const StickyContainer = styled('div')(StickyContainerStyles);

const StickyShoppingCartWrapperStyles = ({ theme }: { theme: Theme }) => css`
  margin-right: ${theme.spacings.kilo};
`;
const StickyShoppingCartWrapper = styled('div')(
  StickyShoppingCartWrapperStyles,
);

export interface DesktopMenuProps {
  items: MenuItemType[];
  isSticky: boolean;
  staticLinks: Record<string, MenuItemType>;
  stickyLinks: Record<string, MenuItemType>;
  shopIntegration?: boolean;
}

export const DesktopMenu: FC<DesktopMenuProps> = ({
  items = [],
  isSticky = false,
  staticLinks,
  stickyLinks,
  shopIntegration = false,
}) => {
  const { experiments } = useOptimizelyData();
  const { shop, help, login } = staticLinks;
  const { primaryStickyCta, secondaryStickyCta } = stickyLinks;
  const site = useContext(SiteContext);
  const page = useContext(PageContext);
  const [openIndex, setOpenIndex] = useState(-1);

  const hoverInTimer = useRef<ReturnType<typeof setTimeout>>(null);
  const hoverOutTimer = useRef<ReturnType<typeof setTimeout>>(null);
  const hasTouch = isTouchDevice();
  const tagger = useMemo(() => {
    const taggerInfo = {
      action: ACTIONS.CLICK,
      entity: ENTITIES.NAV,
      trigger: TRIGGERS.CLICK,
    };
    const { action, entity, trigger } = taggerInfo;
    const t = Tagger();

    return {
      ...t.entity(entity),
      ...t.action(trigger, action),
    };
  }, []);

  const isOnShopCatalogPage = page?.route?.zoneId === SHOP_CATALOG_PAGES_ZONE;
  const hasPrimaryStickyCta = shouldShowStickyCta(
    primaryStickyCta,
    page?.route,
  );
  const hasSecondaryStickyCta = shouldShowStickyCta(
    secondaryStickyCta,
    page?.route,
  );
  const showSticky =
    isSticky &&
    (shopIntegration || hasPrimaryStickyCta || hasSecondaryStickyCta);

  const handleMenuItemHover =
    (isHovered: boolean, hoverIndex: number) => () => {
      if (hasTouch) {
        return;
      }

      if (isHovered) {
        clearTimeout(hoverOutTimer.current);
        hoverInTimer.current = setTimeout(() => {
          setOpenIndex(hoverIndex);
        }, SHOW_DELAY_TIME);
      } else {
        clearTimeout(hoverInTimer.current);
        hoverOutTimer.current = setTimeout(() => {
          setOpenIndex(-1);
        }, CLOSE_DELAY_TIME);
      }
    };
  const handleMenuItemClick = (clickIndex: number) => () => {
    if (clickIndex === openIndex) {
      setOpenIndex(-1);
    } else {
      setOpenIndex(clickIndex);
    }
  };
  const handleGroupItemClick = () => {
    setOpenIndex(-1);
  };

  return (
    <Nav>
      <StyledList data-selector={dataSelector('section', DATA_SELECTOR)}>
        {!isOnShopCatalogPage &&
          items?.map((originalItem, itemIndex) => {
            const item = getVariationComponent(
              originalItem,
              experiments,
            ) as MenuItemType;
            if (!item || !Object.keys(item).length) {
              return null;
            }
            const hasGroups = !!item.menuItemGroups?.length;
            const isOpen = openIndex === itemIndex;
            return (
              <StyledListItem
                key={`${item.label}-${itemIndex}`}
                data-selector={dataSelector(LIST_ITEM_SELECTOR, DATA_SELECTOR)}
                onMouseEnter={handleMenuItemHover(true, itemIndex)}
                onMouseLeave={handleMenuItemHover(false, itemIndex)}
                onFocus={handleMenuItemHover(true, itemIndex)}
                onBlur={handleMenuItemHover(false, itemIndex)}
                onTouchStart={handleMenuItemHover(true, itemIndex)}
              >
                <MenuItem
                  {...item}
                  data-selector={dataSelector(
                    LINK_SELECTOR,
                    LIST_ITEM_SELECTOR,
                    DATA_SELECTOR,
                  )}
                  showArrowIcon={hasGroups}
                  rotateArrowIcon={isOpen}
                  isCurrent={hasGroups && isOpen}
                  onClick={handleMenuItemClick(itemIndex)}
                  aria-haspopup={hasGroups}
                  aria-expanded={isOpen}
                  shouldTrack={!hasGroups}
                  optimizelyFullStackClickEvents={[
                    {
                      eventName: NAVIGATION_EVENTS.FIRST_LEVEL,
                      eventTags: { label: item.label },
                    },
                  ]}
                  data-elbcontext={`component:${DATA_SELECTOR}`}
                  data-elb="nav"
                  data-elb-nav={`description:${item.label}@${LIST_ITEM_SELECTOR}@${DATA_SELECTOR}`}
                  {...tagger}
                />
                {hasGroups && (
                  <GroupsMenu
                    isOpen={isOpen}
                    label={item.label}
                    image={item.image}
                    description={item.description}
                    groups={item.menuItemGroups}
                    onGroupItemClick={handleGroupItemClick}
                  />
                )}
              </StyledListItem>
            );
          })}
      </StyledList>

      {!isSticky && (
        <StyledList>
          {shopIntegration && (
            <li>
              <ShoppingCart addRightSpace={isOnShopCatalogPage} />
            </li>
          )}

          {!isOnShopCatalogPage &&
            shop?.label &&
            shop?.destination?.url &&
            !shopIntegration && (
              <li>
                <MenuItem
                  {...shop}
                  data-selector={dataSelector(LINK_SELECTOR, DATA_SELECTOR)}
                  aria-haspopup="false"
                  optimizelyFullStackClickEvents={[
                    {
                      eventName: NAVIGATION_EVENTS.SHOP,
                      eventTags: { label: shop.label },
                    },
                  ]}
                  data-elbcontext={`component:${DATA_SELECTOR}`}
                  data-elb="nav"
                  data-elb-nav={`description:${shop.label}@${DATA_SELECTOR}`}
                  {...tagger}
                />
              </li>
            )}
          {!isOnShopCatalogPage && help?.label && help?.destination?.url && (
            <li>
              <MenuItem
                {...help}
                data-selector={dataSelector(LINK_SELECTOR, DATA_SELECTOR)}
                aria-haspopup="false"
                optimizelyFullStackClickEvents={[
                  {
                    eventName: NAVIGATION_EVENTS.FIRST_LEVEL,
                    eventTags: { label: help.label },
                  },
                ]}
                data-elbcontext={`component:${DATA_SELECTOR}`}
                data-elb="nav"
                data-elb-nav={`description:${help.label}@${DATA_SELECTOR}`}
                {...tagger}
              />
            </li>
          )}
          {!isOnShopCatalogPage && login?.label && login?.destination?.url && (
            <li>
              <MenuItem
                {...login}
                data-selector={dataSelector(LINK_SELECTOR, DATA_SELECTOR)}
                showPrefixIcon
                aria-haspopup="false"
                optimizelyFullStackClickEvents={[
                  {
                    eventName: NAVIGATION_EVENTS.LOGIN,
                  },
                ]}
                data-elbcontext={`component:${DATA_SELECTOR}`}
                data-elb="nav"
                data-elb-nav={`description:${login.label}@${DATA_SELECTOR}`}
                {...tagger}
              />
            </li>
          )}
          {!isOnShopCatalogPage && site?.country?.multilingual && (
            <li>
              <LanguageMenu />
            </li>
          )}
        </StyledList>
      )}

      {showSticky && (
        <StickyContainer>
          {shopIntegration && (
            <StickyShoppingCartWrapper>
              <ShoppingCart addRightSpace={!hasPrimaryStickyCta} />
            </StickyShoppingCartWrapper>
          )}

          {hasSecondaryStickyCta && (
            <Link
              href={secondaryStickyCta.destination?.url}
              trackingId=""
              trackingContentEntry={{}}
              data-selector={dataSelector('cta', DATA_SELECTOR)}
              optimizelyFullStackClickEvents={[
                {
                  eventName: NAVIGATION_EVENTS.SECONDARY_STICKY_CTA,
                  eventTags: { labels: secondaryStickyCta.label },
                },
              ]}
            >
              <Button
                variant="secondary"
                data-elbcontext={`component:${DATA_SELECTOR}`}
                data-elb="nav"
                data-elb-nav={`description:${secondaryStickyCta.label}@${DATA_SELECTOR}`}
                {...tagger}
              >
                {secondaryStickyCta.label}
              </Button>
            </Link>
          )}

          {hasPrimaryStickyCta && (
            <Link
              href={primaryStickyCta.destination?.url}
              trackingId=""
              trackingContentEntry={{}}
              data-selector={dataSelector('cta', DATA_SELECTOR)}
              optimizelyFullStackClickEvents={[
                {
                  eventName: NAVIGATION_EVENTS.PRIMARY_STICKY_CTA,
                  eventTags: { labels: primaryStickyCta.label },
                },
              ]}
            >
              <Button
                variant="primary"
                data-elbcontext={`component:${DATA_SELECTOR}`}
                data-elb="nav"
                data-elb-nav={`description:${primaryStickyCta.label}@${DATA_SELECTOR}`}
                {...tagger}
              >
                {primaryStickyCta.label}
              </Button>
            </Link>
          )}
        </StickyContainer>
      )}
    </Nav>
  );
};
