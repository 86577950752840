import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button } from '@sumup/circuit-ui';

import CircuitButtonGroup from '../CircuitButtonGroup';
import Link from '../Link';

import { trackingContentEntryPropType } from '~/shared/util/shared-prop-types';

const baseStyles = ({ theme }) => css`
  margin-top: ${theme.spacings.giga};
  overflow: visible;
  ${theme.mq.untilKilo} {
    > * {
      text-align: center;
    }
  }
`;

const StyledButtonGroup = styled(CircuitButtonGroup)(baseStyles);

const LegacyButtonGroup = ({
  buttonLabel = '',
  buttonUrl = '',
  buttonTrackingId = '',
  buttonOptimizelyFullStackClickEvents = [],
  buttonDataSelector = 'primary@button-group',
  secondaryButtonLabel = '',
  secondaryButtonUrl = '',
  secondaryButtonTrackingId = '',
  secondaryButtonOptimizelyFullStackClickEvents = [],
  secondaryButtonDataSelector = 'secondary@button-group',
  size,
  trackingContentEntry,
  ...rest
}) => {
  const hasPrimaryButton = buttonUrl && buttonLabel;
  const hasSecondaryButton = secondaryButtonUrl && secondaryButtonLabel;
  const hasButtons = hasPrimaryButton || hasSecondaryButton;

  if (!hasButtons) {
    return null;
  }

  return (
    <StyledButtonGroup {...rest}>
      {hasPrimaryButton && (
        <Link
          href={buttonUrl}
          trackingId={buttonTrackingId}
          optimizelyFullStackClickEvents={buttonOptimizelyFullStackClickEvents}
          trackingContentEntry={trackingContentEntry}
        >
          <Button
            size={size}
            variant="primary"
            data-selector={buttonDataSelector}
          >
            {buttonLabel}
          </Button>
        </Link>
      )}
      {hasSecondaryButton && (
        <Link
          href={secondaryButtonUrl}
          trackingId={secondaryButtonTrackingId}
          optimizelyFullStackClickEvents={
            secondaryButtonOptimizelyFullStackClickEvents
          }
          trackingContentEntry={trackingContentEntry}
        >
          <Button size={size} data-selector={secondaryButtonDataSelector}>
            {secondaryButtonLabel}
          </Button>
        </Link>
      )}
    </StyledButtonGroup>
  );
};

LegacyButtonGroup.KILO = 'kilo';
LegacyButtonGroup.GIGA = 'giga';

LegacyButtonGroup.LEFT = 'left';
LegacyButtonGroup.CENTER = 'center';
LegacyButtonGroup.RIGHT = 'right';

LegacyButtonGroup.propTypes = {
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonTrackingId: PropTypes.string,
  buttonOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  buttonDataSelector: PropTypes.string,
  size: PropTypes.oneOf([LegacyButtonGroup.KILO, LegacyButtonGroup.GIGA]),
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonUrl: PropTypes.string,
  secondaryButtonTrackingId: PropTypes.string,
  secondaryButtonOptimizelyFullStackClickEvents: PropTypes.arrayOf(
    PropTypes.string,
  ),
  secondaryButtonDataSelector: PropTypes.string,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
};

/**
 * @component
 */
export default LegacyButtonGroup;
