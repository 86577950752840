import { useEffect, useState } from 'react';

import useCookies from './use-cookies';

import * as OneTrust from '~/shared/services/onetrust';
import isServer from '~/shared/util/is-server';

export default function useActiveCookieCategories() {
  const [getCookies] = useCookies();
  const cookies = getCookies() as unknown as OneTrust.CookieObjectType;
  const cookieActiveCategories =
    OneTrust.getActiveCookieCategoriesFromCookies(cookies);
  const [activeCategories, setActiveCategories] = useState(
    cookieActiveCategories,
  );

  useEffect(() => {
    if (isServer) {
      return;
    }

    OneTrust.onConsentChange((changedActiveCategories) => {
      setActiveCategories(changedActiveCategories);
    });
  }, []);

  return activeCategories;
}
