import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@emotion/react';
import Router from 'next/router';
import { RouterContext } from 'next/dist/shared/lib/router-context.shared-runtime';

import {
  sitePropType,
  feesPropType,
  productPropTypes,
  richTextPropType,
  requestPropType,
} from '~/shared/util/shared-prop-types';
import RichText from '~/shared/components/RichText';
import * as inline from '~/shared/components/RichText/configs/inline';
import ProductsContext from '~/shared/providers/ProductsContext';
import FeesContext from '~/shared/providers/FeesContext';
import SiteContext from '~/shared/providers/SiteContext';
import RequestContext from '~/shared/providers/RequestContext';

const FAQSchemaQuestion = ({
  content = {},
  site = {},
  products = {},
  fees = {},
  request = {},
  theme = {},
}) => (
  <ThemeProvider theme={theme}>
    <RouterContext.Provider value={Router}>
      <RequestContext.Provider value={request}>
        <SiteContext.Provider value={site}>
          <ProductsContext.Provider value={{ products }}>
            <FeesContext.Provider value={fees}>
              <RichText
                richText={content}
                renderNode={inline.createRenderNode()}
                renderMark={inline.createRenderMark()}
              />
            </FeesContext.Provider>
          </ProductsContext.Provider>
        </SiteContext.Provider>
      </RequestContext.Provider>
    </RouterContext.Provider>
  </ThemeProvider>
);

FAQSchemaQuestion.propTypes = {
  request: requestPropType,
  site: sitePropType,
  fees: feesPropType,
  products: PropTypes.objectOf(productPropTypes),
  content: richTextPropType,
  theme: PropTypes.object,
};

/**
 * @component
 */
export default FAQSchemaQuestion;
