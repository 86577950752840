import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import NavAnchor from '../../../NavAnchor';

import LoginIcon from './svg/login.svg';

import { trackingContentEntryPropType } from '~/shared/util/shared-prop-types';
import Link from '~/shared/components/Link';
import { NAVIGATION_EVENTS } from '~/shared/services/optimizely/navigation-revamp-experiment/constants';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';

const navAnchorStyles = ({ theme }) => css`
  display: block;

  ${theme.mq.nav} {
    padding-right: 0;
  }

  svg {
    transition: fill 0.1s ease-in-out;
    margin-right: ${theme.spacings.byte};
    fill: currentColor;
  }

  &:hover,
  &:focus {
    svg {
      fill: currentColor;
    }
  }
`;

const StyledNavAnchor = styled(NavAnchor)(navAnchorStyles);

/**
 * Login Button
 */
function LoginButton({
  loginLabel = '',
  loginUrl = '',
  loginTrackingId = '',
  loginOptimizelyFullStackClickEvents = [],
  onClick = () => {},
  trackingContentEntry = {},
  ...rest
}) {
  if (!loginUrl || !loginLabel) {
    return null;
  }

  const handleLoginClick = () => {
    OptimizelyFullStack.trackEvent({
      eventName: NAVIGATION_EVENTS.LOGIN,
    });

    onClick();
  };

  return (
    <Link
      href={loginUrl}
      onClick={handleLoginClick}
      trackingId={loginTrackingId}
      optimizelyFullStackClickEvents={loginOptimizelyFullStackClickEvents}
      trackingContentEntry={trackingContentEntry}
    >
      <StyledNavAnchor {...rest}>
        <LoginIcon aria-hidden="true" role="presentation" />
        {loginLabel}
      </StyledNavAnchor>
    </Link>
  );
}

LoginButton.propTypes = {
  loginUrl: PropTypes.string,
  loginLabel: PropTypes.string,
  loginTrackingId: PropTypes.string,
  loginOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
};

/**
 * @component
 */
export default LoginButton;
