import React from 'react';
import styled from '@emotion/styled';
import isEmpty from 'lodash/isEmpty';

// TODO: Refactor Menu and remove cycle dependency with the MenuItem
// eslint-disable-next-line import/no-cycle
import MenuItem from '../MenuItem';
import variants from '../../SideBarNavigation.variants';
import { LEVELS, LevelsType, VariantsType } from '../../constants';
import { NavigationLink } from '../../types';

interface Props {
  items: NavigationLink[];
  level?: LevelsType;
  variant: VariantsType;
}
const StyledMenu = styled.ul<Pick<Props, 'level' | 'variant'>>(
  {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  ({ level, theme, variant }) => ({
    ...variants({ theme, level })[variant]?.components.menu,
    marginBottom: level === LEVELS.TWO ? theme.spacings.byte : 0,
  }),
);

const Menu = ({ items, level = LEVELS.ONE, variant }: Props) => {
  if (isEmpty(items)) {
    return null;
  }

  return (
    <StyledMenu variant={variant} level={level}>
      {items.map(
        ({
          contentType,
          id,
          label,
          links,
          name,
          openLinkInNewWindow,
          trackingId,
          url,
          isActive,
        }) => (
          <MenuItem
            contentType={contentType}
            id={id}
            key={id}
            label={label}
            level={level}
            name={name}
            openLinkInNewWindow={openLinkInNewWindow}
            subMenu={links}
            trackingId={trackingId}
            url={url}
            variant={variant}
            isActive={isActive}
          >
            {label}
          </MenuItem>
        ),
      )}
    </StyledMenu>
  );
};

export default Menu;
