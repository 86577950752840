import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import NavList from '../NavList';

import useMobileScreens from './hooks/use-mobile-screens';

const containerStyles = () => css`
  width: 100%;
  height: 100%;
`;

const Container = styled('div')(containerStyles);

function MobileMenu({
  links,
  actionAreaHeight,
  closeMenu,
  defaultLinksGroupLabel,
  backButtonLabel,
  navigationTrackingData,
}) {
  // fixes a bug where if user lands on a sublink the
  // navigation screen would slide from the side when
  // opening
  const [mounted, setMounted] = React.useState(false);
  const {
    currentScreen,
    screens,
    allScreens,
    linksToScreensMap,
    handleScreenChange,
  } = useMobileScreens(links, defaultLinksGroupLabel);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  if (!currentScreen) {
    return null;
  }

  const { nesting } = currentScreen;

  return (
    <Container>
      {screens.map((screen, idx) => (
        <NavList
          screen={screen}
          idx={idx}
          key={screen.id}
          nesting={nesting}
          linksToScreensMap={linksToScreensMap}
          screens={screens}
          allScreens={allScreens}
          handleScreenChange={handleScreenChange}
          closeMenu={closeMenu}
          backButtonLabel={backButtonLabel}
          mounted={mounted}
          actionAreaHeight={actionAreaHeight}
          navigationTrackingData={navigationTrackingData}
        />
      ))}
    </Container>
  );
}

MobileMenu.propTypes = {
  links: PropTypes.array,
  actionAreaHeight: PropTypes.string,
  closeMenu: PropTypes.func,
  defaultLinksGroupLabel: PropTypes.string,
  backButtonLabel: PropTypes.string,
  navigationTrackingData: PropTypes.object,
};

export default React.memo(MobileMenu);
