import assignWith from 'lodash/fp/assignWith';
import identity from 'lodash/fp/identity';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import join from 'lodash/fp/join';
import flow from 'lodash/fp/flow';
import omit from 'lodash/fp/omit';
import omitBy from 'lodash/fp/omitBy';
import pickBy from 'lodash/fp/pickBy';
import includes from 'lodash/fp/includes';
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/fp/get';
import getConfig from 'next/config';
import { set as setCookie } from 'es-cookie';
import { elb } from '@elbwalker/walker.js';

import { isPhoneOrEmail, addLeadingSlash } from './url';
import { findLocale } from './one-domain';

import {
  USER_LOGGED_IN_COOKIE_MAX_AGE,
  USER_LOGGED_IN_COOKIE_NAME,
  USER_VISITED_STORE,
  USER_VISITED_STORE_MAX_AGE,
} from '~/shared/services/optimizely/constants';
import * as Url from '~/shared/services/url';
import * as Analytics from '~/shared/services/analytics';
import * as Metrics from '~/shared/services/metrics';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';
import {
  SUMUP_DOMAINS,
  DUAL_TLD_DOMAINS,
  DASHBOARD_HOSTNAME,
  DASHBOARD_TARGET,
  PRODUCT_SELECTION,
  STANDALONE_SHOP,
  NEW_STANDALONE_SHOP,
  SUMUP_VERCEL_SUFFIX,
} from '~/shared/constants/websites';
// eslint-disable-next-line max-len
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';
import { VERCEL_CDN_CACHE_PARAMS } from '~/shared/constants';
import { ONE_DOMAIN_HOSTS } from '~/shared/constants/one-domain';
import { WEBSITE_HOSTNAMES_WITH_LOCALES } from '~/shared/constants/hostname-locales';
import {
  isSamsungPartnerLink,
  generateDynamicSamsungPartnerLink,
} from '~/shared/services/samsung-partner-link';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';
import { isNonLocalizedPathname } from '~/shared/services/pathnames';

const { publicRuntimeConfig = {} } = getConfig();
const { environment } = publicRuntimeConfig;
const isDev = environment === 'dev';
const isTest = environment === 'test';

const PRC_SEPARATOR = '-s-';

const PROMO_PARAMS = ['prc', 'fcam_rc'];
const CAREERS_FILTER_PARAMS = ['search', 'department', 'city'];
export const HIDDEN_VERCEL_PARAMS = [
  'slug',
  'pageLocale',
  'jobLocation',
  'jobDepartment',
  'jobTitle',
  'jobId',
  ...Object.values(VERCEL_CDN_CACHE_PARAMS),
];

const EXCLUDED_PARAMS = ['payleven', ...HIDDEN_VERCEL_PARAMS];
const REQUEST_URL_EXCLUDED_PARAMS = ['utm_', '_ga'];

export function removeExcludedQueryParams(query = {}, excluded = []) {
  return omitBy(
    (value, key) => excluded.some((bl) => includes(bl, key)),
    query,
  );
}

export function isNotSumUpHostname(url = {}) {
  const { hostname } = url;

  if (!hostname) {
    return false;
  }

  if (hostname.indexOf(SUMUP_VERCEL_SUFFIX) !== -1) {
    return false;
  }

  if ((isDev || isTest) && hostname === 'localhost') {
    return false;
  }

  const parts = hostname.split('.');
  const isDualTldDomain = DUAL_TLD_DOMAINS.some((dualTldDomain) =>
    hostname.includes(dualTldDomain),
  );
  const indexFromEnd = isDualTldDomain ? 3 : 2;
  const domain = parts[parts.length - indexFromEnd];
  return !SUMUP_DOMAINS.some((sd) => includes(sd, domain));
}

export function isExternal(url = {}) {
  return isNotSumUpHostname(url) || isPhoneOrEmail(url);
}

// https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/881656272/Vouchers+Promotions
export function getProductsPromoParams(products = []) {
  const prcFromProducts = flow(
    filter(
      (product) =>
        !!product && product.hasPromo && product.promoCode !== 'sumup', // HACK: to allow for empty prc
    ),
    map((product) => product.promoCode),
    join(PRC_SEPARATOR),
  )(products);

  return {
    prc: prcFromProducts,
  };
}

export function getDomainLocale(url) {
  const hostWithoutWww = url.host?.replace('www.', '');
  return WEBSITE_HOSTNAMES_WITH_LOCALES[hostWithoutWww];
}

export function formatURLToOneDomain(url, request, locale) {
  // invalid requests mocks that are missing the host
  // or request mocks with multi-domain values
  // cannot be subject of one domain formatter
  const isOneDomainRequestHost = Object.values(ONE_DOMAIN_HOSTS).some(
    (oneDomainHost) => request?.host?.includes(oneDomainHost),
  );
  const isVercelOrLocalHost =
    request?.host?.includes(SUMUP_VERCEL_SUFFIX) ||
    request?.host?.includes('localhost');
  if (!(isOneDomainRequestHost || isVercelOrLocalHost)) {
    return url;
  }

  const shouldEnsureLocale = locale && isSameWebsiteLink(url, request);
  if (shouldEnsureLocale) {
    // eslint-disable-next-line no-param-reassign
    url.pathname = ensureLocaleOnPath(locale, url.pathname);
    return url;
  }

  const domainLocale = getDomainLocale(url);
  if (domainLocale) {
    // eslint-disable-next-line no-param-reassign
    url.protocol = request.protocol;
    // eslint-disable-next-line no-param-reassign
    url.host = request.host;
    // eslint-disable-next-line no-param-reassign
    url.pathname = ensureLocaleOnPath(domainLocale, url.pathname);
    return url;
  }

  return url;
}

// complete documentation on query params sources, order of precedences and filtering can be found here -
// https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/22027534499/Website+Links+Query+Params+Order+of+precedence+filter+rules
// !!! IMPORTANT: please update the documentation when you are doing any query params changes in code !!!
export function determineQuery(
  url = {},
  request = {},
  productsConfig = {},
  fees = {},
  fullPage = {},
  universalQueryParams = {},
  localStorageRequestParams = {},
) {
  // for SEO reasons we want to make sure website internal links
  // do not have query params except for the ones declared on the link itself
  // (example of internal links - link from sumup.com/foo to sumup.com/bar)
  // https://sumupteam.atlassian.net/browse/SDM-6
  const shouldUseUrlQueryOnly =
    isSameWebsiteLink(url, request) || !!getDomainLocale(url, request);
  if (shouldUseUrlQueryOnly) {
    const cleanUrlQuery = removeExcludedQueryParams(url.query, EXCLUDED_PARAMS);

    return cleanUrlQuery;
  }

  const requestParams = {
    ...localStorageRequestParams,
    ...request.query,
  };
  const isPartnerPage = fullPage.contentType === 'partnerPage';
  const combinedParams = isPartnerPage
    ? determineQueryOnPartnerPages({
        url,
        requestParams,
        productsConfig,
        fullPage,
      })
    : determineQueryOnPages({
        url,
        requestParams,
        productsConfig,
        fees,
        universalQueryParams,
      });

  const isExternalUrl = isExternal(url);
  const paramsToExcludeFromCombined = isExternalUrl
    ? [...EXCLUDED_PARAMS, ...PROMO_PARAMS, ...CAREERS_FILTER_PARAMS]
    : EXCLUDED_PARAMS;
  const cleanCombinedParams = removeExcludedQueryParams(
    combinedParams,
    paramsToExcludeFromCombined,
  );

  return pickBy(identity, cleanCombinedParams);
}

export function determineQueryOnPartnerPages({
  url = {},
  requestParams = {},
  productsConfig = {},
  fullPage = {},
}) {
  const { query: linkHrefParams = {} } = url;

  const paramsToExcludeFromRequestUrl = [
    ...PROMO_PARAMS,
    ...REQUEST_URL_EXCLUDED_PARAMS,
  ];
  const cleanRequestParams = removeExcludedQueryParams(
    requestParams,
    paramsToExcludeFromRequestUrl,
  );
  // partner pages should be allowed to pass the utm_campaign param from query
  const utmCampaign = requestParams.utm_campaign;
  if (utmCampaign) {
    cleanRequestParams.utm_campaign = utmCampaign;
  }

  const { queryParams: partnerPageParams = {} } = fullPage;

  // partner pages can make use only of product overrides
  const { pageProducts } = productsConfig;
  const productsPromoParams = getProductsPromoParams(pageProducts);

  const prioritizedParams = {
    locale: linkHrefParams.locale || cleanRequestParams.locale,
    hostname: linkHrefParams.hostname || cleanRequestParams.hostname,
    prc:
      linkHrefParams.prc ||
      partnerPageParams.prc ||
      cleanRequestParams.prc ||
      productsPromoParams.prc,
  };

  // HINT: the lower destructured object is in the list, the higher it's priority
  const combinedParams = {
    ...partnerPageParams,
    ...linkHrefParams,
    ...cleanRequestParams,
    ...prioritizedParams,
  };

  return combinedParams;
}

export function determineQueryOnPages({
  url = {},
  requestParams = {},
  productsConfig = {},
  fees = {},
  universalQueryParams = {},
}) {
  const { query: linkHrefParams = {} } = url;

  const cleanRequestParams = removeExcludedQueryParams(
    requestParams,
    REQUEST_URL_EXCLUDED_PARAMS,
  );

  const { products: mergedSiteAndPageProducts } = productsConfig;
  const productsPromoParams = getProductsPromoParams(mergedSiteAndPageProducts);

  // https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/1345061102/Fee+Campaign
  const feeCampaignParams = {
    fcam_rc: fees.campaignTokens,
  };

  const prioritizedParams = {
    locale: linkHrefParams.locale || cleanRequestParams.locale,
    hostname: linkHrefParams.hostname || cleanRequestParams.hostname,
    prc:
      linkHrefParams.prc || cleanRequestParams.prc || productsPromoParams.prc,
  };

  // HINT: the lower destructured object is in the list, the higher it's priority
  const combinedParams = {
    ...universalQueryParams,
    ...feeCampaignParams,
    ...linkHrefParams,
    ...cleanRequestParams,
    ...prioritizedParams,
  };

  return combinedParams;
}

export function formatHref(url, query, request = {}) {
  const isExternalUrl = isExternal(url);
  const { host, protocol } = request;
  const [hostname, port] = host ? host.split(':') : [];

  if (isExternalUrl) {
    return Url.format(url, false);
  }

  const hash = getHash(request, url);

  if (hash) {
    const parsedRequestWithoutHash = omit(['search', 'query', 'hash'], request);
    return Url.format({ ...parsedRequestWithoutHash, query, hash });
  }

  const parsedUrlWithoutParams = omit(['search', 'query'], url);

  const urlObj = assignWith(
    (objValue, srcValue) => objValue || srcValue,
    parsedUrlWithoutParams,
    { protocol, hostname, port, query, hash },
  );

  return Url.format(urlObj);
}

export const hasLocaleOnPath = (pathname) => {
  const [, firstChunk] = pathname?.split('/') || [];
  return findLocale(firstChunk);
};

export const ensureLocaleOnPath = (pageLocale, pathname = '') => {
  if (isNonLocalizedPathname(pathname)) {
    return pathname;
  }

  const [, firstChunk, ...restPathnameChunks] = pathname?.split('/') || [];
  const isFirstChunkSupportedLocale = findLocale(firstChunk);

  if (isFirstChunkSupportedLocale) {
    const pathnameWithoutLocale = restPathnameChunks.join('/');
    return `/${firstChunk.toLowerCase()}${addLeadingSlash(
      pathnameWithoutLocale,
    )}`;
  }

  if (pageLocale) {
    return `/${pageLocale.toLowerCase()}${addLeadingSlash(pathname)}`;
  }

  return pathname;
};

export function getHash(request, url = {}) {
  const current = Url.isCurrent(url, request);
  return current && url.hash;
}

export function getDashboardOrShopTarget(url = {}) {
  const { hostname, href } = url;
  const isDashboardOrShopHost =
    hostname === DASHBOARD_HOSTNAME ||
    hostname === STANDALONE_SHOP ||
    hostname === NEW_STANDALONE_SHOP;

  if (isDashboardOrShopHost) {
    const isLogin = includes('/login', href);
    const isProductSelection = includes('/product-selection', href);

    let target;
    if (isLogin) {
      target = DASHBOARD_TARGET.LOGIN;
    } else if (isProductSelection) {
      target = PRODUCT_SELECTION;
    } else {
      target = DASHBOARD_TARGET.SIGNUP;
    }
    return {
      isDashboardOrShopUrl: true,
      target,
    };
  }

  return {
    isDashboardOrShopUrl: false,
    target: undefined,
  };
}

export function isHostnameInsideLocale({ url = {}, websites = [] }) {
  const { hostname } = url;

  if (!hostname || isEmpty(websites)) {
    return false;
  }

  const isOneDomainHost = Object.values(ONE_DOMAIN_HOSTS).find(
    (oneDomainHost) => oneDomainHost === hostname,
  );
  const isWebsiteHost = websites
    .map((website) => get('domain.hostname', website))
    .some((websiteHostname) => websiteHostname === hostname);

  return isOneDomainHost || isWebsiteHost;
}

export function getDestinationCategory({ url = {}, websites = [] }) {
  const { isDashboardOrShopUrl, target } = getDashboardOrShopTarget(url);
  if (isDashboardOrShopUrl) {
    return target;
  }

  if (includes('/downloads', url.href)) {
    return Analytics.destinationCategory.DOWNLOAD;
  }

  if (includes('help.sumup', url.hostname)) {
    return Analytics.destinationCategory.SUPPORT_CENTER;
  }

  if (!url.hostname || isHostnameInsideLocale({ url, websites })) {
    // Internal event and dashboard event should be mutually exclusive
    return Analytics.destinationCategory.INTERNAL;
  }

  if (isExternal(url)) {
    return Analytics.destinationCategory.EXTERNAL;
  }

  return Analytics.destinationCategory.UNCATEGORIZED;
}

export function getClickHandler(onClick, context = {}) {
  const {
    trackingId,
    trackingLabel,
    optimizelyFullStackClickEvents,
    locale,
    url = {},
    shouldTrack,
    trackingContentEntry: {
      contentType,
      contentEntryName,
      contentEntryId,
    } = {},
    websites = [],
    router,
    href = '',
    disableSPANavigation,
  } = context;
  const { isDashboardOrShopUrl, target } = getDashboardOrShopTarget(url);
  const destinationCategory = getDestinationCategory({
    url,
    websites,
  });

  return async (event) => {
    if (shouldTrack) {
      Analytics.sendEvent({
        'event': 'interaction',
        'target': 'Mkt_Web',
        'action': trackingId,
        'target-properties': trackingLabel,
        contentType,
        contentEntryName,
        contentEntryId,
        destinationCategory,
        'destinationUrl': Url.format(url, false),
      });

      // track clicks in Google Analytics 4
      if (trackingId) {
        let buttonDescription = trackingId;
        if (contentType && contentEntryId) {
          buttonDescription += `@${contentType}@${contentEntryId}`;
        }
        elb('button clicked', {
          button_description: buttonDescription,
          destination: destinationCategory,
        });
      }

      sendNinetailedEvent(trackingId);

      OptimizelyFullStack.trackEvents(optimizelyFullStackClickEvents);

      if (isDashboardOrShopUrl) {
        if (target === DASHBOARD_TARGET.LOGIN) {
          OptimizelyFullStack.trackLogin();
          setCookie(USER_LOGGED_IN_COOKIE_NAME, 'true', {
            expires: USER_LOGGED_IN_COOKIE_MAX_AGE,
          });
        } else if (target === DASHBOARD_TARGET.SIGNUP) {
          setCookie(USER_VISITED_STORE, 'true', {
            expires: USER_VISITED_STORE_MAX_AGE,
          });
          OptimizelyFullStack.trackSignup();
        }

        OptimizelyFullStack.trackLoginAndSignup();

        Metrics.sendEvent({
          msg: 'Dashboard link click',
          tags: { locale },
          counterMetrics: [
            {
              name: 'dashboard_link_click',
              labels: { target, locale },
            },
          ],
        });
      }
    }

    if (isSamsungPartnerLink(href)) {
      event.preventDefault();
      const samsungPartnerLink = generateDynamicSamsungPartnerLink();
      // eslint-disable-next-line no-console
      console.log('DEBUG: samsung partner link', samsungPartnerLink);
      window.location.assign(samsungPartnerLink);
      return undefined;
    }

    // use SSR full reload navigation when user tries to open Convertflow reverse-proxy page
    // https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/3213755512/Convertflow+Integration
    const convertflowPathRegex = /^(\/[a-z][a-z]-[a-z][a-z])?\/start\/.*/;
    const isNavigatingToConvertflowPage = convertflowPathRegex.test(
      url.pathname,
    );

    const hasHref = !!href.length;

    const shouldUseSPANavigation =
      !disableSPANavigation &&
      router &&
      hasHref &&
      !isNavigatingToConvertflowPage;

    if (shouldUseSPANavigation) {
      event.preventDefault();
      router.push(href);
    }

    if (onClick) {
      onClick(event);
    }

    // Convertflow should have the click behaviour of external links (so not SPA), but hide the query params for SEO as if they were internal because they're in our domain. Here we're ensuring query params are preserved in destination. See https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/3213755512/Website+Convertflow+Integration
    if (isNavigatingToConvertflowPage && hasHref) {
      event.preventDefault();
      window.location.assign(href);
    }

    return undefined;
  };
}

export function getUniversalParams(
  siteParams,
  originalPageParams,
  optimizelyExperiments = [],
) {
  const pageParams =
    getVariationComponent(originalPageParams, optimizelyExperiments) || {};

  return {
    ...siteParams,
    ...pageParams.configuration,
  };
}

export function isSameWebsiteLink(url = {}, request = {}) {
  return !url?.host || url?.host === request?.host;
}

export function switchStorefrontToWebsiteCatalog(url = {}) {
  const result = getDashboardOrShopTarget(url);
  const shouldSwitch =
    result?.isDashboardOrShopUrl && result?.target === PRODUCT_SELECTION;
  const replaceCatalog = (urlPart) => {
    const websiteCatalog = '/website/product-selection';
    const posCatalog = '/point-of-sale/product-selection';
    const signupCatalog = '/product-selection';
    if (urlPart.includes(websiteCatalog)) {
      return urlPart;
    }

    if (urlPart.includes(posCatalog)) {
      return urlPart.replace(posCatalog, websiteCatalog);
    }

    return urlPart.replace(signupCatalog, websiteCatalog);
  };

  if (shouldSwitch) {
    if (url?.href) {
      // eslint-disable-next-line no-param-reassign
      url.href = replaceCatalog(url.href);
    }
    if (url?.pathname) {
      // eslint-disable-next-line no-param-reassign
      url.pathname = replaceCatalog(url.pathname);
    }
  }

  return url;
}
