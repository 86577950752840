function getLoggingFunction(consoleName) {
  if (typeof __PRODUCTION__ === 'undefined' || __TEST__) {
    return () => {};
  }
  // TODO: Log to Kibana once we've integrated Pythia.
  return __PRODUCTION__ ? () => {} : console[consoleName]; // eslint-disable-line no-console
}

export function critical(...args) {
  getLoggingFunction('error', 'critical')(...args);
}
export function error(...args) {
  getLoggingFunction('error', 'error')(...args);
}
export function warn(...args) {
  getLoggingFunction('warn', 'warning')(...args);
}
export function info(...args) {
  getLoggingFunction('info', 'info')(...args);
}
export function debug(...args) {
  getLoggingFunction('log', 'debug')(...args);
}
