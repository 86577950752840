import { FC, useContext, useEffect, useState } from 'react';
import { elb } from '@elbwalker/walker.js';
import { Body, clsx, Hr } from '@sumup/circuit-ui';
import { Document } from '@contentful/rich-text-types';

import styles from './InfoBanner.module.scss';
import { getTimerValues, Timer } from './InfoBannerService';

import * as inline from '~/shared/components/RichText/configs/inline';
import RichText from '~/shared/components/RichText';
import dataSelector from '~/shared/util/data-selector';
import * as Analytics from '~/shared/services/analytics';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';
import SiteContext from '~/shared/providers/SiteContext';

const DATA_SELECTOR = 'info_banner';

const DEFAULT_BACKGROUND = 'normal';
export interface InfoBannerProps {
  id: string;
  name: string;
  contentType: string;
  text?: Document;
  secondaryText?: Document;
  isClickable?: boolean;
  clickableLink?: string;
  background?: string;
  backgroundIsStrongVariant?: boolean;
  trackingId?: string;
  optimizelyFullStackClickEvents?: string[];
  countdownEndDate?: string;
}

export const InfoBanner: FC<InfoBannerProps> = ({
  id,
  name,
  contentType,
  text,
  secondaryText,
  isClickable,
  clickableLink,
  background = DEFAULT_BACKGROUND,
  backgroundIsStrongVariant = true,
  trackingId,
  optimizelyFullStackClickEvents,
  countdownEndDate,
}) => {
  const { accessibilityLabels = {} } = useContext(SiteContext);

  const hasText = !!text?.content.length;
  const hasSecondaryText = !!secondaryText?.content.length;
  const [textToDisplay, setTextToDisplay] = useState(text);

  const endDate = countdownEndDate && new Date(countdownEndDate);
  const hasCountdown = endDate > new Date();
  const [timer, setTimer] = useState<Timer | undefined>(
    getTimerValues(endDate),
  );

  useEffect(() => {
    let textIntervalId: ReturnType<typeof setInterval>;
    let countdownIntervalId: ReturnType<typeof setInterval>;

    if (hasText && hasSecondaryText && !textIntervalId) {
      textIntervalId = setInterval(() => {
        setTextToDisplay((currentText) =>
          currentText === text ? secondaryText : text,
        );
      }, 3000);
    }

    if (hasCountdown && !countdownIntervalId) {
      countdownIntervalId = setInterval(() => {
        setTimer(getTimerValues(endDate));
      }, 1000);
    }

    return () => {
      if (textIntervalId) {
        clearInterval(textIntervalId);
      }

      if (countdownIntervalId) {
        clearInterval(countdownIntervalId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!hasText) {
    return null;
  }

  const trackingContentEntry = {
    contentType,
    contentEntryName: name,
    contentEntryId: id,
  };

  const onClick = () => {
    if (isClickable) {
      Analytics.sendEvent({
        event: 'interaction',
        target: 'Mkt_Web',
        action: trackingId,
        destinationCategory: undefined,
        destinationUrl: clickableLink,
        ...trackingContentEntry,
      });
      sendNinetailedEvent(trackingId);

      elb('button clicked', {
        button_description: trackingId,
      });

      OptimizelyFullStack.trackEvents(optimizelyFullStackClickEvents);
      window.location.assign(clickableLink);
    }
  };

  const getBackgroundClassname = () => {
    if (backgroundIsStrongVariant) {
      return `${background}-strong`;
    }
    return background;
  };

  const backgroundClassname = getBackgroundClassname();

  return (
    <div
      className={clsx(
        styles.base,
        isClickable && styles.clickable,
        styles[backgroundClassname],
      )}
      data-selector={dataSelector('wrapper', DATA_SELECTOR)}
      onClick={onClick}
    >
      <div className={clsx(styles.container, hasCountdown && styles.countdown)}>
        <div
          className={clsx(
            styles.text,
            hasSecondaryText && styles['text-switch'],
          )}
        >
          <RichText
            renderNode={inline.createRenderNode(
              inline.ONE,
              trackingContentEntry,
            )}
            renderMark={inline.createRenderMark(inline.ONE)}
            richText={textToDisplay}
          />
        </div>

        {hasCountdown && (
          <>
            <Hr />
            <div className={styles['countdown-timer']}>
              {Object.keys(timer).map((key) => (
                <div key={`countdonw-${key}`} className={styles['timer-item']}>
                  <Body variant="highlight" size="two" suppressHydrationWarning>
                    {timer[key]}
                  </Body>
                  <Body size="two">{accessibilityLabels[key]}</Body>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};
