import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BRAND_HEIGHT_DESKTOP } from '../../constants';

import Link from '~/shared/components/Link';
import {
  secondaryLogoPropType,
  trackingContentEntryPropType,
} from '~/shared/util/shared-prop-types';
import dataSelector from '~/shared/util/data-selector';

/**
 * Secondary logo
 */

const DATA_SELECTOR = 'secondary_logo';

const secondaryLogoWrapperStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  height: 100%;
  margin-left: ${theme.spacings.kilo};
  padding-left: ${theme.spacings.kilo};
  border-left: 1px solid var(--cui-border-normal);
`;

const SecondaryLogoWrapper = styled('span')(secondaryLogoWrapperStyles);

const secondaryLogoImgStyles = css`
  width: auto;
  height: 100%;
`;

const SecondaryLogoImg = styled('img')(secondaryLogoImgStyles);

const SecondaryLogo = ({
  logo = {},
  url = '',
  onClick = () => {},
  trackingContentEntry = {},
  ...rest
}) => {
  if (!logo.url) {
    return null;
  }

  const src = `${logo.url}?h=${BRAND_HEIGHT_DESKTOP}`;
  const srcSet = `${src}, ${logo.url}?h=${BRAND_HEIGHT_DESKTOP * 2} 2x`;

  if (!url) {
    return (
      <SecondaryLogoWrapper data-selector={dataSelector(DATA_SELECTOR)}>
        <SecondaryLogoImg src={src} srcSet={srcSet} alt={logo.alt} />
      </SecondaryLogoWrapper>
    );
  }

  return (
    <Link
      href={url}
      onClick={onClick}
      trackingContentEntry={trackingContentEntry}
      data-selector={dataSelector(DATA_SELECTOR)}
    >
      <SecondaryLogoWrapper
        as="a"
        aria-label={logo.alt || 'Secondary logo link'}
        {...rest}
      >
        <SecondaryLogoImg src={src} srcSet={srcSet} alt={logo.alt} />
      </SecondaryLogoWrapper>
    </Link>
  );
};

SecondaryLogo.propTypes = {
  ...secondaryLogoPropType,
  onClick: PropTypes.func,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
};

/**
 * @component
 */
export default SecondaryLogo;
