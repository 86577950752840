import React from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import { VIEWPORTS } from '~/shared/constants';
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';

const TARGET_CONTENT_TYPE = 'fullMedia';
const MEDIA_TYPES = {
  BACKGROUND_VIDEO: 'backgroundVideo',
};

interface BackgroundMediaData {
  webm?: string;
  url?: string;
  placeholder?: string;
}

interface ViewportContent {
  backgroundMedia: {
    data: BackgroundMediaData;
  };
}

export interface Section {
  contentType?: string;
  contentMobile?: ViewportContent;
  contentDesktop?: ViewportContent;
  contentTablet?: ViewportContent;
}

interface Experiment {
  experimentKey: string;
  variationKey: string;
}

interface PreloadMediaProps {
  sections?: Section[];
  viewportName?: string;
  experiments?: Experiment[];
}

const PreloadMedia = ({
  sections = [],
  viewportName,
  experiments = [],
}: PreloadMediaProps) => {
  if (isEmpty(sections)) {
    return null;
  }

  const [firstSection] = sections;

  if (firstSection.contentType !== TARGET_CONTENT_TYPE) {
    return null;
  }

  const viewports = {
    [VIEWPORTS.MOBILE]: getVariationComponent(
      firstSection.contentMobile,
      experiments,
    ),
    [VIEWPORTS.DESKTOP]: getVariationComponent(
      firstSection.contentDesktop,
      experiments,
    ),
    [VIEWPORTS.TABLET]: getVariationComponent(
      firstSection.contentTablet,
      experiments,
    ),
  };
  const viewportContent = viewports[viewportName];

  if (isEmpty(viewportContent)) {
    return null;
  }

  const mediaContentType = viewportContent.backgroundMedia?.contentType;
  const { webm } = viewportContent.backgroundMedia?.data || {};

  const hasVideoMedia =
    mediaContentType === MEDIA_TYPES.BACKGROUND_VIDEO && webm;

  if (hasVideoMedia) {
    return <link rel="preload" as="video" href={webm} type="video/webm" />;
  }

  return null;
};

/**
 * @component
 */
export default PreloadMedia;
