import React, { Children, ReactElement, ReactNode } from 'react';
import styled from '@emotion/styled';
import { ClassNames, css } from '@emotion/react';

const wrapperBaseStyles = () => css`
  display: block;
  position: relative;
  overflow: hidden;
  height: auto;
  width: 100%;
`;

const wrapperAspectRatioStyles = ({ aspectRatio }) =>
  aspectRatio &&
  css`
    height: 0;
    width: 100%;
    padding-top: ${Math.round((1 / aspectRatio) * 100)}%;
  `;

const Wrapper = styled('div')(wrapperBaseStyles, wrapperAspectRatioStyles);

const childBaseStyles = (cssClassName) => cssClassName`
  display: block;
  height: auto;
  max-height: 100%;
  width: 100%;
`;

const childAspectRatioStyles = (cssClassName, { aspectRatio }) =>
  aspectRatio &&
  cssClassName`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    max-height: none;
    object-fit: cover;
    z-index: 2;
  `;

type Props = {
  children?: ReactNode;
  aspectRatio?: number;
  className?: string;
};
const AspectRatio = React.forwardRef<HTMLDivElement, Props>(
  ({ aspectRatio, children, className }, ref) => {
    if (!children) {
      return null;
    }

    const [child, ...restChildren] = Children.toArray(children);

    return (
      <Wrapper
        ref={ref}
        aspectRatio={aspectRatio}
        className={className}
        data-elbcontext="component:aspect_ratio"
      >
        <ClassNames>
          {({ css: cssClassName, cx }) =>
            React.cloneElement(child as ReactElement, {
              className: cx(
                childBaseStyles(cssClassName),
                childAspectRatioStyles(cssClassName, { aspectRatio }),
              ),
            })
          }
        </ClassNames>
        {restChildren}
      </Wrapper>
    );
  },
);

AspectRatio.displayName = 'AspectRatio';

/**
 * @component
 */
export default AspectRatio;
