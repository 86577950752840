import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import FooterLink from '../FooterLink';

import { trackingContentEntryPropType } from '~/shared/util/shared-prop-types';

const wrapperStyles = ({ theme }) => css`
  grid-area: links;

  ${theme.mq.kiloToMega} {
    margin-top: ${theme.spacings.tera};
  }
  ${theme.mq.untilKilo} {
    margin-bottom: ${theme.spacings.giga};
  }
`;
const customMqByte = '@media (min-width: 320px)';

const listStyles = ({ theme, colNumber, linksCount }) => css`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(${linksCount}, auto);
  grid-auto-flow: column;
  list-style: none;

  ${customMqByte} {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(${Math.ceil(linksCount / 2)}, auto);
  }
  ${theme.mq.kilo} {
    grid-template-columns: repeat(${colNumber}, 1fr);
    grid-template-rows: repeat(${Math.ceil(linksCount / colNumber)}, auto);
  }
`;

const itemStyles = ({ theme }) => css`
  color: var(--cui-fg-subtle);
  margin-bottom: ${theme.spacings.byte};

  ${theme.typography.body.one};
`;
const Wrapper = styled('nav')(wrapperStyles);
const List = styled('ul')(listStyles);
const Item = styled('li')(itemStyles);

function PrimaryMenu({
  links = [],
  colNumber = 3,
  trackingContentEntry = {},
  label = 'Footer primary navigation',
}) {
  if (!links.length) {
    return null;
  }

  return (
    <Wrapper aria-label={label}>
      <List colNumber={colNumber} linksCount={links.length}>
        {links.map((link, j) => (
          <Item key={j}>
            <FooterLink
              link={link}
              selectorPrefix="link"
              trackingContentEntry={trackingContentEntry}
            />
          </Item>
        ))}
      </List>
    </Wrapper>
  );
}

PrimaryMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      trackingId: PropTypes.string,
    }),
  ),
  colNumber: PropTypes.number,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
  /**
   * Accessibility label for primary menu.
   */
  label: PropTypes.string,
};

export default PrimaryMenu;
