export const CONTENTFUL_SUPPORTED_PARAMS = [
  'fm',
  'q',
  'fl',
  'w',
  'h',
  'fit',
  'f',
  'r',
  'bg',
];

export const CONTENTFUL_DEFAULT_IMAGE_QUALITY = 85;
export const CONTENTFUL_LIGHT_IMAGE_QUALITY = 70;
