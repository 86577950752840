import React from 'react';
import PropTypes from 'prop-types';

import SumUpIcon from './svg/sumup.svg';
import SumUpShortIcon from './svg/sumup-short.svg';

/**
 * The SumUp logo.
 */
function SumUpLogo({ isShort = false, ...rest }) {
  const iconProps = { fill: 'var(--cui-fg-normal)', ...rest };
  return isShort ? (
    <SumUpShortIcon {...iconProps} />
  ) : (
    <SumUpIcon {...iconProps} />
  );
}

SumUpLogo.propTypes = {
  isShort: PropTypes.bool,
};

/**
 * @component
 */
export default SumUpLogo;
