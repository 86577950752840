import React, { useContext, useLayoutEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';
import { Headline } from '@sumup/circuit-ui';

import Menu from './components/Menu';
import variants, { VariantsProps } from './SideBarNavigation.variants';
import { VARIANTS, SIZES, VariantsType } from './constants';
import { markActiveLinkRecursively } from './SideBarNavigationService';
import { SideBarNavigationProps } from './types';

import ExpandIcon from '~/shared/components/icons/ExpandIcon';
import RequestContext from '~/shared/providers/RequestContext';
import SiteContext from '~/shared/providers/SiteContext';

interface StyledHeadlineProps extends VariantsProps {
  parentVariant: SideBarNavigationProps['variant'];
}

const StyledHeadline = styled(Headline)<StyledHeadlineProps>(
  ({ theme, parentVariant, active }) =>
    variants({ theme, active })[parentVariant],
);

const ScrollableContainer = styled.div(
  ({
    theme,
    distanceFromTop,
    parentVariant,
  }: {
    theme?: Theme;
    distanceFromTop: number;
    parentVariant: VariantsType;
  }) => {
    const variant = variants({ theme })[parentVariant];
    return css`
      position: relative;
      overflow-y: scroll;
      height: calc(100vh - ${distanceFromTop}px - ${variant.marginBottom});
    `;
  },
);

const SideBarNavigation = ({
  navigationLinks,
  title,
  variant = VARIANTS.PRIMARY,
  className,
}: SideBarNavigationProps) => {
  const request = useContext(RequestContext);
  const site = useContext(SiteContext);
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const [distanceFromTop, setDistanceFromTop] = useState<number>(0);
  const size = variant === VARIANTS.PRIMARY ? SIZES.THREE : SIZES.FOUR;
  const isCollapsable = variant === VARIANTS.COLLAPSABLE;
  const ref = useRef<HTMLHeadingElement>(null);

  useLayoutEffect(() => {
    if (ref.current) {
      const distance =
        ref.current.clientHeight + ref.current.getBoundingClientRect().top;
      setDistanceFromTop(distance);
    }
  }, []);

  markActiveLinkRecursively(request, site, navigationLinks);

  return (
    <div data-selector="sidebar-navigation" className={className}>
      {title && (
        <StyledHeadline
          as="h4"
          active={expanded}
          size={size}
          parentVariant={variant}
          onClick={() => isCollapsable && setExpanded((v) => !v)}
          ref={ref}
        >
          {isCollapsable && <ExpandIcon expanded={expanded} />}
          {title}
        </StyledHeadline>
      )}
      {(!isCollapsable || expanded) && (
        <ScrollableContainer
          distanceFromTop={distanceFromTop}
          parentVariant={variant}
        >
          <Menu variant={variant} items={navigationLinks} />
        </ScrollableContainer>
      )}
    </div>
  );
};

export default SideBarNavigation;
