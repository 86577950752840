import { useEffect, useState, useRef } from 'react';

import { UP, DOWN } from '~/shared/constants/directions';
import isServer from '~/shared/util/is-server';

type ScrollKey = 'scrollY' | 'scrollX';
type ScrollDirection = 'up' | 'down';

// the keys that are used in IE11 for getting the offset
const scrollOffsetKeys = {
  scrollY: 'pageYOffset',
  scrollX: 'pageXOffset',
};

function createUseScroll(key: ScrollKey) {
  return () => {
    const [scrollDistance, setScrollDistance] = useState(
      getScrollDistance(key),
    );

    useEffect(() => {
      if (isServer) {
        return undefined;
      }

      let ticking = null;

      function handleScroll() {
        if (ticking === null) {
          ticking = window.requestAnimationFrame(() => {
            setScrollDistance(getScrollDistance(key));
            ticking = null;
          });
        }
      }

      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return scrollDistance;
  };
}

export function useScrolledPastThreshold(threshold = 0) {
  const key = 'scrollY';
  const [scrolledPastThreshold, setScrolledPastThreshold] = useState(false);

  useEffect(() => {
    if (isServer) {
      return undefined;
    }

    setScrolledPastThreshold(getScrollDistance(key) > threshold);

    let ticking = null;

    function handleScroll() {
      if (ticking === null) {
        ticking = window.requestAnimationFrame(() => {
          setScrolledPastThreshold(getScrollDistance(key) > threshold);
          ticking = null;
        });
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [threshold]);

  return scrolledPastThreshold;
}

function getScrollDistance(key: ScrollKey): number {
  if (isServer) {
    return 0;
  }

  const ie11Key = scrollOffsetKeys[key];

  if (typeof window[key] === 'undefined') {
    return window[ie11Key] as number;
  }

  return window[key];
}

export const useScrollY = createUseScroll('scrollY');
export const useScrollX = createUseScroll('scrollX');

export function useScrollDirection(scrollDistance: number) {
  const prevScrollDistance = useRef(0);
  const prevScrollDirection = useRef<ScrollDirection | undefined>();

  const currentPrevScrollDistance = prevScrollDistance.current;
  const currentPrevScrollDirection = prevScrollDirection.current;

  const scrollDirection = getScrollDirection(
    currentPrevScrollDistance,
    scrollDistance,
    currentPrevScrollDirection,
  );

  useEffect(() => {
    prevScrollDistance.current = scrollDistance;
    prevScrollDirection.current = scrollDirection;
  });

  return scrollDirection;
}

function getScrollDirection(
  prevScrollDistance: number,
  scrollDistance: number,
  prevScrollDirection?: ScrollDirection,
): ScrollDirection {
  if (!prevScrollDistance || prevScrollDistance === scrollDistance) {
    return prevScrollDirection;
  }
  return scrollDistance < prevScrollDistance ? UP : DOWN;
}
