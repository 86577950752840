/*
 * Optimizely hook used in React components to get active experiments on a page for rendering.
 */

import { useContext } from 'react';
import Router from 'next/router';

import { getOptimizelyData } from './optimizely-data';

import RequestContext from '~/shared/providers/RequestContext';

export default function useOptimizelyData() {
  const request = useContext(RequestContext);
  const requestQuery = request.query || {};
  const routerQuery = Router?.router?.query || {};
  const query = !Object.keys(requestQuery).length ? routerQuery : requestQuery;

  return getOptimizelyData(query);
}
