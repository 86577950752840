export function getIconSrcSet(src, width) {
  return [
    {
      src,
      width,
      height: width,
      size: '1x',
    },
    {
      src,
      width: width * 2,
      height: width * 2,
      size: '2x',
    },
  ];
}
