import React from 'react';
import styled from '@emotion/styled';
import { CSSObject } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';

import { NavigationLink } from '../../types';
import variants, { VariantsProps } from '../../SideBarNavigation.variants';
import { LEVELS, LevelsType, VariantsType } from '../../constants';
// TODO: Refactor MenuItem and remove cycle dependency with the Menu
// eslint-disable-next-line import/no-cycle
import Menu from '../Menu';

import { DOWN, UP } from '~/shared/constants/directions';
import Link from '~/shared/components/Link';
import ChevronIcon from '~/shared/components/icons/ChevronIcon';

interface Props extends NavigationLink {
  children: React.ReactNode;
  subMenu?: NavigationLink[];
  level?: LevelsType;
  variant: VariantsType;
}

interface StyledMenuItemProps extends VariantsProps {
  parentVariant: Props['variant'];
}

const StyledMenuItem = styled(Body)<StyledMenuItemProps>(
  {
    transition: 'all 0.2s ease',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  ({ level, theme, active, parentVariant }) =>
    ({
      fontWeight: theme.fontWeight.regular,
      marginBottom: theme.spacings.mega,
      ...(active && {
        fontWeight: theme.fontWeight.bold,
      }),
      ...variants({ theme, level, active })[parentVariant]?.components.menuItem,
    }) as CSSObject,
);

const MenuItem = ({
  children,
  subMenu,
  level,
  url,
  openLinkInNewWindow,
  variant,
  contentType,
  name,
  id,
  trackingId,
  isActive,
}: Props) => {
  const [expanded, setExpanded] = React.useState(isActive);
  const hasSubMenu = subMenu?.length > 0;

  return (
    <>
      <Link
        href={!hasSubMenu ? url : undefined}
        onClick={() => hasSubMenu && setExpanded((v) => !v)}
        target={openLinkInNewWindow ? '_blank' : undefined}
        trackingId={trackingId}
        trackingContentEntry={{
          contentType,
          contentEntryName: name,
          contentEntryId: id,
        }}
      >
        <StyledMenuItem
          size="one"
          as="li"
          level={level}
          active={isActive}
          parentVariant={variant}
        >
          {children}
          {hasSubMenu && <ChevronIcon direction={expanded ? UP : DOWN} />}
        </StyledMenuItem>
      </Link>
      {hasSubMenu && expanded && (
        <Menu variant={variant} items={subMenu} level={LEVELS.TWO} />
      )}
    </>
  );
};

export default MenuItem;
