import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Link from '../../../Link';
import Logo from '../../../icons/Logo';

import dataSelector from '~/shared/util/data-selector';
import { trackingContentEntryPropType } from '~/shared/util/shared-prop-types';

const DATA_SELECTOR = 'footer';

const wrapperStyles = ({ theme }) => css`
  grid-area: logo;
  padding-bottom: ${theme.spacings.tera};

  ${theme.mq.kiloToMega} {
    padding-bottom: 0;
  }
`;
const Wrapper = styled('div')(wrapperStyles);

const logoStyles = () => css`
  height: 36px;
  width: auto;
`;

const StyledLogo = styled(Logo)(logoStyles);

function FooterLogo({ trackingContentEntry }) {
  return (
    <Wrapper data-selector={dataSelector('logo', DATA_SELECTOR)}>
      <Link
        href="/"
        trackingContentEntry={trackingContentEntry}
        trackingId="footer_clicked"
        trackingLabel="sumup_logo"
      >
        <a>
          <StyledLogo />
        </a>
      </Link>
    </Wrapper>
  );
}

FooterLogo.propTypes = {
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
};

export default FooterLogo;
