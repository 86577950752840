/* eslint-disable no-continue */
/* eslint-disable no-await-in-loop */
import { Dispatch } from 'react';
import { produce } from 'immer';

import {
  getAttributionParams,
  getTrackingParams,
} from '../../services/attribution';

import {
  FETCH_ORDER,
  FETCH_ORDER_ERROR,
  FETCH_ORDER_SUCCESS,
  ADDING_LINE_ITEM,
  LINE_ITEM_ADDED,
  LINE_ITEM_ERROR,
  REMOVING_LINE_ITEM,
  LINE_ITEM_REMOVED,
  UPDATING_QUANTITY,
  UPDATE_QUANTITY_SUCCESS,
  UPDATE_QUANTITY_ERROR,
  CLAYER_ORDER_ID,
  CLAYER_ORDER_LOCALE,
  SUMUP_ONE_SKU,
  getMaxQuantity,
  SUMUP_ONE_MAX_QUANTITY,
  LINE_ITEM_LIMITED_QUANTITY,
  LINE_ITEM_REDUCED_QUANTITY,
} from './constants';
import { ReducerAction, CartState, Order, ProductInfo } from './types';
import { findProductInOrder } from './utils';
import {
  trackAddToCart,
  trackAddToCartFail,
  trackOrderCreate,
  trackOrderCreateFail,
  trackRemoveFromCart,
  trackRemoveFromCartFail,
  trackUpdateQuantity,
  trackUpdateQuantityFail,
} from './tracking';

import { PurchaseClientType } from '~/domains/shop/services/purchasable-client';
import { formatCurrency } from '~/shared/services/currency';
import { WebsiteProduct } from '~/shared/api-controllers/shared/transformers/products';
import { Plan } from '~/shared/api-controllers/shared/transformers/plans';

export const retrieveOrder = async ({
  dispatch,
  orderId,
  client,
  siteProducts,
  sitePlans,
}: {
  dispatch: Dispatch<ReducerAction>;
  orderId: string;
  client: PurchaseClientType;
  siteProducts: WebsiteProduct[];
  sitePlans: Plan[];
}) => {
  dispatch({ type: FETCH_ORDER });
  try {
    const orderRes = (await client?.orders.get(orderId)) as Order;

    if (!orderRes) {
      const err = new Error('Order not found');
      dispatch({ type: FETCH_ORDER_ERROR, data: err });
      return Promise.reject(err);
    }

    const order = remapOrder(orderRes, siteProducts, sitePlans);

    dispatch({
      type: FETCH_ORDER_SUCCESS,
      data: remapOrder(order, siteProducts),
    });
    return order;
  } catch (error: unknown) {
    dispatch({ type: FETCH_ORDER_ERROR, data: error });
    return Promise.reject(error);
  }
};

export const addToOrder = async ({
  productInfoList,
  cartState,
  client,
  locale,
  dispatch,
  siteProducts,
  sitePlans,
}: {
  productInfoList: ProductInfo[];
  cartState: CartState;
  client: PurchaseClientType;
  locale: string;
  dispatch: Dispatch<ReducerAction>;
  siteProducts: WebsiteProduct[];
  sitePlans: Plan[];
}) => {
  dispatch({ type: ADDING_LINE_ITEM });
  let action: 'add' | 'update' | 'create' | null = null;
  const addedSkuCodes: string[] = [];
  try {
    let order: Order = produce(cartState.order, () => {});

    if (!order) {
      action = 'create';
      order = (await client?.orders.create({
        salesChannel: 'website',
        languageCode: locale,
        attributionParams: getAttributionParams(),
        trackingParams: getTrackingParams(),
      })) as Order;
      window.localStorage.setItem(CLAYER_ORDER_ID, order.id);
      window.localStorage.setItem(CLAYER_ORDER_LOCALE, locale?.toLowerCase());
      trackOrderCreate({ locale });
    }

    const hasAddedSumUpOne = !!findProductInOrder(order, SUMUP_ONE_SKU);
    const isAddingSumUpOne = productInfoList.find(
      (product) => product.skuCode === SUMUP_ONE_SKU,
    );

    for (const productInfo of productInfoList) {
      const existingLineItem = findProductInOrder(order, productInfo.skuCode);
      const ctfProduct = siteProducts.find(
        (product) => product.skuCode === productInfo?.skuCode,
      );

      if (existingLineItem) {
        const shouldLimitQuantity =
          existingLineItem.quantity >= getMaxQuantity(ctfProduct?.hasPromo) ||
          hasAddedSumUpOne ||
          isAddingSumUpOne;
        if (shouldLimitQuantity) {
          dispatch({
            type: LINE_ITEM_LIMITED_QUANTITY,
            data: order,
          });
          continue;
        }

        action = 'update';
        await client?.lineItems.update({
          id: existingLineItem.id,
          quantity: existingLineItem.quantity + (productInfo?.quantity || 1),
        });
      } else {
        action = 'add';
        await client?.lineItems.create({
          ...(ctfProduct?.isBundle
            ? { bundleCode: productInfo.skuCode }
            : { skuCode: productInfo.skuCode }),
          quantity: productInfo?.quantity || 1,
          orderId: order.id,
        });

        if (ctfProduct) {
          const isExpired =
            new Date().getTime() >
            new Date(ctfProduct.original.expirationDate).getTime();

          if (ctfProduct.hasPromo && !isExpired) {
            try {
              await client?.orders.update({
                id: order.id,
                couponCode: ctfProduct.promoCode,
              });
            } catch (err) {
              // tbd gracefully handle expired coupons
              // https://sumupteam.atlassian.net/browse/WPS-546
            }
          }
        }
      }

      const orderRes = (await client?.orders.get(order.id)) as Order;
      order = remapOrder(orderRes, siteProducts, sitePlans);
      dispatch({
        type: LINE_ITEM_ADDED,
        data: order,
      });

      const lineItem = findProductInOrder(order, productInfo.skuCode);
      if (action === 'add') {
        trackAddToCart({ lineItem, locale });
      }
      if (action === 'update') {
        trackUpdateQuantity({ locale });
      }

      addedSkuCodes.push(productInfo.skuCode);
    }

    // SumUp One subscription strictly forces quantity to 1 card reader
    if (hasAddedSumUpOne || isAddingSumUpOne) {
      for (const lineItem of order.lineItems) {
        if (lineItem.quantity > SUMUP_ONE_MAX_QUANTITY) {
          await client?.lineItems.update({
            id: lineItem.id,
            quantity: SUMUP_ONE_MAX_QUANTITY,
          });
          const orderRes = (await client?.orders.get(order.id)) as Order;
          order = remapOrder(orderRes, siteProducts, sitePlans);
          dispatch({
            type: LINE_ITEM_REDUCED_QUANTITY,
            data: order,
          });
        }
      }
    }

    return addedSkuCodes;
  } catch (error: unknown) {
    dispatch({ type: LINE_ITEM_ERROR, data: error });
    if (action === 'create') {
      trackOrderCreateFail({ locale });
    }

    if (action === 'add') {
      trackAddToCartFail({ locale });
    }

    if (action === 'update') {
      trackUpdateQuantityFail({ locale });
    }
    return Promise.reject(error);
  }
};

export const removeFromOrder = async ({
  dispatch,
  id,
  client,
  cartState,
  siteProducts,
  locale,
  sitePlans,
}: {
  dispatch: Dispatch<ReducerAction>;
  id: string;
  client: PurchaseClientType;
  siteProducts: WebsiteProduct[];
  cartState: CartState;
  locale: string;
  sitePlans: Plan[];
}) => {
  dispatch({ type: REMOVING_LINE_ITEM });

  try {
    await client?.lineItems.delete(id);
    const orderRes = (await client?.orders.get(cartState.order.id)) as Order;
    const order = remapOrder(orderRes, siteProducts, sitePlans);

    dispatch({
      type: LINE_ITEM_REMOVED,
      data: order,
    });
    trackRemoveFromCart({ locale });

    return order;
  } catch (error: unknown) {
    dispatch({ type: LINE_ITEM_ERROR, data: error });
    trackRemoveFromCartFail({ locale });
    return Promise.reject(error);
  }
};

export const updateLineItemQuantity = async ({
  client,
  id,
  quantity,
  cartState,
  dispatch,
  siteProducts,
  locale,
  sitePlans,
}: {
  dispatch: Dispatch<ReducerAction>;
  id: string;
  quantity: number;
  cartState: CartState;
  client: PurchaseClientType;
  siteProducts: WebsiteProduct[];
  locale: string;
  sitePlans: Plan[];
}) => {
  dispatch({ type: UPDATING_QUANTITY });
  try {
    await client?.lineItems.update({
      id,
      quantity,
    });
    const orderRes = (await client?.orders.get(cartState.order.id)) as Order;
    const order = remapOrder(orderRes, siteProducts, sitePlans);
    dispatch({
      type: UPDATE_QUANTITY_SUCCESS,
      data: order,
    });
    trackUpdateQuantity({ locale });
    return order;
  } catch (error: unknown) {
    dispatch({ type: UPDATE_QUANTITY_ERROR, data: error });
    trackUpdateQuantityFail({ locale });
    return Promise.reject(error);
  }
};

/* eslint-disable no-param-reassign */
export function remapOrder(
  order?: Order,
  products: WebsiteProduct[] = [],
  sitePlans: Plan[] = [],
): Order | null {
  if (!order) {
    return null;
  }

  const { currencyCode } = products[0];
  const result = produce(order, (draft) => {
    draft.lineItems.forEach((lineItem) => {
      const ctflProduct = products.find(
        (product) =>
          product.skuCode === lineItem.skuCode ||
          product.skuCode === lineItem.bundleCode,
      );
      if (ctflProduct) {
        lineItem.productId = ctflProduct.productId;
        lineItem.name = ctflProduct.productName;
        lineItem.price = formatCurrency(
          lineItem.totalAmountCents / 100,
          currencyCode,
        ).toString();
        lineItem.image = ctflProduct.image;
        lineItem.vatPrice = formatCurrency(
          lineItem.taxAmountCents / 100,
          currencyCode,
        ).toString();
        lineItem.fullPrice = formatCurrency(
          (lineItem.compareAtAmountCents * lineItem.quantity) / 100,
          currencyCode,
        ).toString();
      }

      const ctflPlan = sitePlans.find(
        (plan) => plan.skuCode === lineItem.skuCode,
      );

      if (ctflPlan) {
        lineItem.image = ctflPlan.image;
        lineItem.monthlyCost = ctflPlan.monthlyCost;
      }
    });

    draft.totalPrice = formatCurrency(
      draft.totalAmountWithTaxesCents / 100,
      currencyCode,
    ).toString();

    // Delivery is always free!
    draft.shippingPrice = formatCurrency(0, currencyCode).toString();
  });

  return result;
}
/* eslint-enable no-param-reassign */
