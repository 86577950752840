import { createContext, Context } from 'react';

import { Plan } from '~/shared/api-controllers/shared/transformers/plans';
import { LiveWebsite } from '~/shared/api-controllers/site/transformers/config';

export interface SiteContextType {
  locale: string;
  country: {
    code: string;
    locales: Array<string>;
    multilingual: false;
    name: string;
  };
  websites: LiveWebsite[];
  support: {
    email: string;
    phone: string;
    openingHours: {};
  };
  accessibilityLabels: {
    closeButton: string;
    closeHamburgerMenu: string;
    nextPage: string;
    openHamburgerMenu: string;
    pagination: string;
    previousPage: string;
    progressBar: string;
    thresholdRadio: string;
    cardPlanSelect: string;
    instalmentSelect: string;
    days: string;
    hours: string;
    minutes: string;
    seconds: string;
  };
  cartLabels: {
    yourCart: string;
    emptyCart: string;
    viewCart: string;
    goToCart: string;
    checkout: string;
    closeCart: string;
    vat: string;
    vatIncluded: string;
    vatIncludedAbbreviated: string;
    quantity: string;
    removeItem: string;
    itemDeleted: string;
    itemAdded: string;
    delivery: string;
    totalCost: string;
    loadingLabel: string;
    plusQuantity: string;
    minusQuantity: string;
    monthlyCost: string;
    freeLabel: string;
    discountLabel: string;
  };
  meta: {};

  universalQueryParams?: Record<string, unknown>;
  seoPages?: { id: string; label: string; pathname: string }[];

  environmentId?: string;
  spaceId?: string;
  plans: Plan[];
}

/**
 * Passes down site data that is used across pages
 */
const SiteContext: Context<SiteContextType> = createContext({
  locale: '',
  country: {
    code: '',
    locales: [],
    multilingual: false,
    name: '',
  },
  websites: [],
  support: {
    email: '',
    phone: '',
    openingHours: {},
  },
  loadOptimizely: false,
  accessibilityLabels: {
    closeButton: 'Close',
    closeHamburgerMenu: 'Close menu',
    nextPage: 'Next page',
    openHamburgerMenu: 'Open menu',
    pagination: 'Pagination',
    previousPage: 'Previous page',
    progressBar: 'Progress bar',
    thresholdRadio: 'Threshold revenue selector',
    cardPlanSelect: 'Select card plan',
    instalmentSelect: 'Select instalment',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
  },
  cartLabels: {
    yourCart: 'Your cart',
    emptyCart: 'Your cart is empty',
    viewCart: 'View cart',
    goToCart: 'Go to cart',
    checkout: 'Continue to checkout',
    closeCart: 'Close cart',
    vat: 'VAT',
    vatIncluded: 'VAT included',
    vatIncludedAbbreviated: 'incl.VAT',
    quantity: 'Quantity',
    removeItem: 'Remove item',
    itemDeleted: 'Your item has been deleted',
    itemAdded: 'Added to cart',
    delivery: 'Delivery',
    totalCost: 'Total cost',
    loadingLabel: 'Loading...',
    plusQuantity: 'Add one',
    minusQuantity: 'Remove one',
    monthlyCost: 'Monthly cost',
    freeLabel: 'Free',
    discountLabel: 'OFF',
  },
  meta: {},
  plans: [],
});

export default SiteContext;
