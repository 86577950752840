import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { FeeBadge, PricingBadge, TextBadge } from '~/shared/components/Badges';
import { TYPES } from '~/shared/constants/sections';
import { BADGE_TYPES } from '~/shared/constants';

const roundedCornerByHeroType = {
  [TYPES.HERO]: ({ theme }) => css`
    ${theme.mq.untilKilo} {
      border-bottom-right-radius: ${theme.borderRadius.exa};
      border-top-right-radius: ${theme.borderRadius.exa};
    }
    ${theme.mq.kilo} {
      border-top-left-radius: ${theme.borderRadius.byte};
      border-top-right-radius: ${theme.borderRadius.byte};
    }
  `,
  [TYPES.HERO_NEW]: ({ theme }) => css`
    border-radius: ${theme.borderRadius.byte} 0 ${theme.borderRadius.byte} 0;

    ${theme.mq.kilo} {
      border-radius: ${theme.borderRadius.byte};
    }
    ${theme.mq.mega} {
      border-radius: ${theme.borderRadius.byte};
    }
  `,
};
const roundedCornerStyles = ({ parentContentType, theme }) => css`
  ${roundedCornerByHeroType[parentContentType]({ theme })}
`;

const positionByHeroType = {
  [TYPES.HERO]: ({ theme }) => css`
    ${theme.mq.untilKilo} {
      top: 5%;
      left: 0;
    }
    ${theme.mq.kilo} {
      top: auto;
      right: 15%;
      bottom: 0;
      left: auto;
    }
  `,
  [TYPES.HERO_NEW]: ({ theme }) => css`
    top: 0;

    ${theme.mq.kilo} {
      top: ${theme.spacings.mega};
      right: auto;
      bottom: auto;
      left: ${theme.spacings.mega};
    }
    ${theme.mq.mega} {
      top: ${theme.spacings.mega};
      right: auto;
      bottom: auto;
      left: ${theme.spacings.mega};
    }
  `,
};
const positionStyles = ({ parentContentType, theme }) => css`
  ${positionByHeroType[parentContentType]({ theme })}
`;

const baseStyles = ({ theme }) => css`
  position: absolute;
  z-index: ${theme.zIndex.badge};
`;

/**
 * Styled components: Badge in Hero
 * to highlight a price, fee or other promotion.
 */
const StyledPricingBadge = styled(PricingBadge)(
  baseStyles,
  roundedCornerStyles,
  positionStyles,
);

const StyledFeeBadge = styled(FeeBadge)(
  baseStyles,
  roundedCornerStyles,
  positionStyles,
);

const StyledTextBadge = styled(TextBadge)(
  baseStyles,
  roundedCornerStyles,
  positionStyles,
);

function Badge({ badge = {}, parentContentType }) {
  if (badge.contentType === BADGE_TYPES.PRICING_BADGE) {
    return (
      <StyledPricingBadge parentContentType={parentContentType} {...badge} />
    );
  }

  if (badge.contentType === BADGE_TYPES.FEE_BADGE) {
    return <StyledFeeBadge parentContentType={parentContentType} {...badge} />;
  }

  if (badge.contentType === BADGE_TYPES.TEXT_BADGE) {
    return <StyledTextBadge parentContentType={parentContentType} {...badge} />;
  }

  return null;
}

Badge.propTypes = {
  badge: PropTypes.object,
  parentContentType: PropTypes.oneOf([TYPES.HERO, TYPES.HERO_NEW]),
};

/**
 * @component
 */
export default Badge;
