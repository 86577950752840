import React, { useContext, useEffect, useState } from 'react';
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';
import { elb } from '@elbwalker/walker.js';

import FooterSelect from '../FooterSelect';

import SiteContext from '~/shared/providers/SiteContext';
import RequestContext from '~/shared/providers/RequestContext';
import formatSelector from '~/shared/util/data-selector';
import { DIRECTIONS } from '~/shared/components/Dropdown/constants';
import {
  WB_REPLACEMENT_SITE_FEATURE_TOGGLE,
  WB_REPLACEMENT_PAGE_CAREERS_FEATURE_TOGGLE,
} from '~/shared/services/optimizely/constants';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import { SeoPage } from '~/shared/api-controllers/seo-pages/transformers/seo-page';
import { SeoPagesResponse } from '~/shared/api-controllers/seo-pages';
import { CAREERS_PATH } from '~/shared/constants/pathnames';

export interface SeoPagesSelectProps {
  id?: string;
  label?: string;
  className?: string;
}

export interface SelectItem {
  id?: string;
  href: {
    pathname?: string;
  };
  label?: string;
}

function SeoPagesSelect({
  id = 'select-page',
  label = '',
  className,
}: SeoPagesSelectProps) {
  const { featureToggles } = useOptimizelyData();
  const site = useContext(SiteContext);
  const request = useContext(RequestContext);
  const isWBSiteReplacementEnabled =
    !!featureToggles[WB_REPLACEMENT_SITE_FEATURE_TOGGLE];
  const isWBCareersPageReplacementEnabled =
    !!featureToggles[WB_REPLACEMENT_PAGE_CAREERS_FEATURE_TOGGLE] &&
    request?.pathname?.startsWith(CAREERS_PATH);
  const [fetchedSeoPages, setFetchedSeoPages] = useState<SeoPage[]>([]);
  const shouldFetchSeoPages =
    isWBSiteReplacementEnabled || isWBCareersPageReplacementEnabled;
  const fetchSeoPages = async () => {
    if (!shouldFetchSeoPages) {
      return;
    }

    if (!site?.locale) {
      return;
    }

    try {
      const res = await fetch(`/api/seo-pages/${site.locale.toLowerCase()}/`);
      const { seoPages = [] } = (await res.json()) as SeoPagesResponse;

      setFetchedSeoPages(seoPages);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  useEffect(() => {
    fetchSeoPages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { pathname } = request;
  const seoPages =
    (shouldFetchSeoPages ? fetchedSeoPages : site.seoPages) || [];

  if (isEmpty(seoPages)) {
    return null;
  }

  const items: SelectItem[] = seoPages.map((seoPage) => ({
    label: seoPage.label,
    href: { pathname: seoPage.pathname },
    id: seoPage.id,
  }));
  const pathnameWithoutSlashes = pathname.replace(/\//g, '');
  const selectedItem = find(
    ({ href }) => href.pathname === pathnameWithoutSlashes,
    items,
  );
  const handlePageChange = (item: SelectItem) => {
    elb('article selected', {
      button_description: `select-article-${item.id}@footer`,
      article_id: item.id,
      article_name: item.label,
      business_flow: 'top_articles',
    });
  };

  return (
    <FooterSelect
      id={id}
      items={items}
      initialItem={selectedItem}
      placeholder={label}
      direction={DIRECTIONS.UP}
      onChange={handlePageChange}
      data-selector={formatSelector('select_page', 'footer')}
      shouldWrapLabelText
      aria-label={label}
      className={className}
    />
  );
}

export default SeoPagesSelect;
