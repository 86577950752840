import { useEffect, useContext } from 'react';

import isServer from '~/shared/util/is-server';
import * as Metrics from '~/shared/services/metrics';
import SiteContext from '~/shared/providers/SiteContext';
import RequestContext from '~/shared/providers/RequestContext';
import getPerformanceLabels from '~/shared/util/get-performance-labels';
import { VERCEL_CDN_CACHE_PARAMS } from '~/shared/constants';

export default function usePageMetrics(code = 200) {
  const site = useContext(SiteContext);
  const request = useContext(RequestContext);

  useEffect(() => {
    if (isServer) {
      return;
    }

    const perfLabels = getPerformanceLabels();

    Metrics.sendEvent(
      {
        msg: 'Page view',
        tags: {
          locale: site.locale,
          pathname: request.pathname,
        },
        counterMetrics: [
          {
            name: 'page_http_responses',
            labels: {
              code: code ? code.toString() : undefined,
              locale: site.locale,
              geo_country:
                request?.query?.[VERCEL_CDN_CACHE_PARAMS.GEO_COUNTRY],
              connection_type: perfLabels.connectionType,
              device_type: perfLabels.deviceType,
            },
          },
        ],
      },
      true,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}
