export function doesURLWithSegmentsMatch(
  currentUrl: string,
  urlToCheck: string,
): boolean {
  if (!currentUrl || !urlToCheck) {
    return false;
  }

  const pathnameWithLocale = currentUrl.split('?')[0];
  const pathname = pathnameWithLocale.split('/').slice(1).slice(1).join('/');

  if (pathname === '/' || !pathname) {
    return false;
  }

  const pathnameSegments = pathname.split('/').filter((v) => !!v);
  const urlToCheckSegments = urlToCheck
    .split('/')
    .filter((v) => !!v)
    .slice(2);

  return (
    urlToCheck.endsWith(pathname) ||
    urlToCheckSegments.every((segment) => pathnameSegments.includes(segment))
  );
}
