import React from 'react';
import styled from '@emotion/styled';

import ChevronIcon from '~/shared/components/icons/ChevronIcon';
import { UP, DOWN } from '~/shared/constants/directions';

interface Props {
  expanded: boolean;
  fill?: string;
}

const Wrapper = styled.div<Props>`
  position: relative;
  width: 20px;
  height: 100%;
  margin-right: 4px;
  ${ChevronIcon} {
    position: absolute;
    top: 50%;
    left: 50%;
    transition: all 0.2s ease;
    transform: translate(-50%) rotate(90deg);
    transform-origin: center;
    margin-top: -5px;

    ${({ expanded }) => `
      :nth-child(1) {
        ${expanded ? 'transform: translate(-50%) rotate(0deg);' : ''}
        margin-left: ${expanded ? '-7px' : '-5px'};
      }
      :nth-child(2) {
        ${expanded ? 'transform: translate(-50%) rotate(180deg);' : ''}
        margin-left: ${expanded ? '-2px' : '-5px'};
      }
    `}
  }
`;
const ExpandIcon = ({ expanded, ...props }: Props) => (
  <Wrapper expanded={expanded}>
    <ChevronIcon direction={DOWN} {...props} />
    <ChevronIcon direction={UP} {...props} />
  </Wrapper>
);

export default ExpandIcon;
