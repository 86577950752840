import { Theme, css, useTheme } from '@emotion/react';
import styled from '@emotion/styled';
import { Body, Headline, useMedia } from '@sumup/circuit-ui';
import { FC } from 'react';

import { DATA_SELECTOR } from '../../constants';

import GroupsMenuItem from './components/GroupsMenuItem';

import {
  Image as ImageType,
  MenuItemGroup as MenuItemGroupType,
} from '~/shared/components/HeaderNavigation/interfaces';
import Image from '~/shared/components/Image';
import dataSelector from '~/shared/util/data-selector';

const groupsMenuWrapperStyles = ({ theme }: { theme: Theme }) => css`
  padding-top: 20px;

  ${theme.mq.mega} {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100vw;
    padding-left: ${theme.spacings.kilo};
    padding-right: ${theme.spacings.kilo};
    min-height: 420px;
    background-color: var(--cui-bg-normal);
    overflow: hidden;
    z-index: 1;
    border-top: 1px solid var(--cui-border-subtle);
    padding-bottom: 20px;
    box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  }

  ${theme.mq.tera} {
    left: calc((100vw - ${theme.maxWidth}) / 2 * -1);
  }
`;
const groupsMenuWrapperOpenStyles = ({
  theme,
  isOpen,
}: {
  theme?: Theme;
  isOpen: boolean;
}) =>
  isOpen &&
  css`
    ${theme.mq.mega} {
      visibility: visible;
      opacity: 1;
      pointer-events: auto;
    }
  `;
const GroupsMenuWrapper = styled('div')(
  groupsMenuWrapperStyles,
  groupsMenuWrapperOpenStyles,
);

const groupsMenuLayoutStyles = ({ theme }: { theme?: Theme }) => css`
  display: flex;
  width: 100%;
  margin: 0 auto;
  flex-direction: column;

  ${theme.mq.mega} {
    flex-direction: row;
  }

  ${theme.mq.tera} {
    max-width: ${theme.maxWidth};
  }
`;
const GroupsMenuLayout = styled('div')(groupsMenuLayoutStyles);

const imageStyles = ({ theme }: { theme: Theme }) => css`
  border-radius: ${theme.borderRadius.mega};
  margin-top: ${theme.spacings.kilo};
  height: 184px;
  width: 100%;
  picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
    }
  }
  &:first-child {
    margin-top: 0;
  }
`;
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-expect-error
const StyledImage = styled(Image)(imageStyles);

const menuInfoStyles = ({ theme }: { theme: Theme }) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;

  ${theme.mq.mega} {
    width: 328px;
    margin-right: ${theme.spacings.exa};
  }
`;
const MenuInfo = styled('div')(menuInfoStyles);

const descriptionTextStyles = ({ theme }: { theme: Theme }) => css`
  margin-top: ${theme.spacings.giga};
  &:first-child {
    margin-top: 0;
  }
`;
const DescriptionText = styled(Body)(descriptionTextStyles);

const menuInfoTextStyles = ({ theme }: { theme: Theme }) => css`
  color: var(--cui-fg-subtle);

  ${theme.mq.mega} {
    color: var(--cui-fg-normal);
    padding: ${theme.spacings.giga};
    background-color: var(--cui-bg-subtle);
    border-radius: ${theme.borderRadius.mega};
  }
`;
const MenuInfoText = styled('div')(menuInfoTextStyles);

const groupsStyles = ({ theme }: { theme: Theme }) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacings.mega} 0;

  ${theme.mq.mega} {
    gap: 64px;
    flex-direction: row;
    border: none;
    padding: 0;
  }
`;
const Groups = styled('div')(groupsStyles);

export interface GroupsMenuProps {
  label?: string;
  description?: string;
  image?: ImageType;
  groups?: MenuItemGroupType[];
  isOpen?: boolean;
  isMobile?: boolean;
  onGroupItemClick?: () => void;
}

export const GroupsMenu: FC<GroupsMenuProps> = ({
  isOpen = false,
  label,
  description,
  image,
  groups = [],
  isMobile = false,
  onGroupItemClick = () => {},
}) => {
  const theme = useTheme();
  const isMegaBreakpoint = useMedia(theme.breakpoints.mega);

  if (!groups?.length) {
    return null;
  }

  return (
    <GroupsMenuWrapper
      isOpen={isOpen}
      aria-hidden={!isOpen}
      hidden={!isOpen}
      data-selector={dataSelector('sub_menu', DATA_SELECTOR)}
    >
      <GroupsMenuLayout>
        <MenuInfo>
          {(label || description) && (
            <MenuInfoText>
              {label && (
                <Headline as="h2" size="one">
                  {label}
                </Headline>
              )}
              {description && (
                <DescriptionText size="one">{description}</DescriptionText>
              )}
            </MenuInfoText>
          )}

          {isMegaBreakpoint && (
            <StyledImage
              lazyLoad
              src={image?.url}
              alt={image?.alt}
              width={image?.width}
              srcSet={[
                {
                  src: image?.url,
                  width: image?.width,
                  size: '2x',
                },
              ]}
            />
          )}
        </MenuInfo>
        <Groups>
          {groups.map((group, groupIndex) => (
            <GroupsMenuItem
              group={group}
              key={`${group.label}-${groupIndex}`}
              onGroupItemClick={onGroupItemClick}
              isMobile={isMobile}
              isOpen={isOpen}
            />
          ))}
        </Groups>
      </GroupsMenuLayout>
    </GroupsMenuWrapper>
  );
};
