import React, { useState, useEffect } from 'react';
import { css } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';

import NavAnchor from '../../../NavAnchor';
import ArrowIcon from '../MenuSection/svg/arrow.svg';
import { LinkBadgeLabel, LinkBadgeContainer } from '../MenuSection/MenuSection';
import useHighlightedMenuLinks from '../../hooks/use-highlighted-menu-links';

import { hasScreenNesting, hasPageUrlInLinkScreen } from './NavListService';

import * as Analytics from '~/shared/services/analytics';
import RequestContext from '~/shared/providers/RequestContext';
import SiteContext from '~/shared/providers/SiteContext';
import formatSelector from '~/shared/util/data-selector';
import Link from '~/shared/components/Link';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';
import { getMobileTrackingEvent } from '~/shared/services/optimizely/navigation-revamp-experiment';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';

export const ARROW_CLICK_TRACKING_EVENT = 'arrow_click@horizontal_navigation';

const containerStyles = ({ slideIn, index, theme }) => css`
  width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  will-change: transform;
  z-index: ${50 + index};
  transform: translateX(${slideIn ? '0' : '100vw'});
  transition: transform ${theme.transitions.slow};
  background: var(--cui-bg-normal);
`;

const Container = styled('div')(containerStyles);

const listStyles = ({ actionAreaHeight }) => css`
  list-style: none;
  padding-bottom: ${actionAreaHeight};
`;

const StyledList = styled('ul')(listStyles);

const listItemStyles = ({ theme }) => css`
  margin: 0 ${theme.spacings.giga};
  width: calc(100% - 2 * ${theme.spacings.giga});
  border-bottom: 1px solid var(--cui-border-normal);
  position: relative;
`;

const ListItem = styled('li')(listItemStyles);

const iconStyles = ({ deg }) => css`
  transition:
    transform 0.1s ease-in-out,
    color 0.1s ease-in-out;
  transform: rotate(${deg}deg);
`;

const iconSpaceStyles = ({ spaced }) =>
  spaced &&
  css`
    margin-right: 8px;
  `;

const StyledArrowIcon = styled(ArrowIcon, { shouldForwardProp: isPropValid })(
  iconStyles,
  iconSpaceStyles,
);

const buttonStyles = ({ theme }) => css`
  position: absolute;
  right: 0;
  top: 0;
  background: none;
  border: 0;
  outline: none;
  text-align: right;
  z-index: 2;
  padding: ${theme.spacings.giga} ${theme.spacings.byte};

  &:hover,
  &:focus {
    cursor: pointer;
  }
`;

const Button = styled('button')(buttonStyles);

const backLinkStyles = () => css`
  background: var(--cui-bg-highlight);
  display: flex;
  padding-left: 8px;
  align-items: center;
`;

const BackLink = styled('div')(backLinkStyles);

const groupTitleContainerStyles = ({ theme }) => css`
  margin: 0 ${theme.spacings.giga};
`;

const GroupTitleContainer = styled('div')(groupTitleContainerStyles);

const groupTitleStyles = ({ theme }) => css`
  font-weight: bold;
  font-size: ${theme.typography.subHeadline.fontSize};
  line-height: ${theme.typography.subHeadline.lineHeight};
  text-transform: uppercase;
  color: var(--cui-fg-placeholder);
  margin: ${theme.spacings.giga} 0 ${theme.spacings.byte};
`;

const GroupTitle = styled('p')(groupTitleStyles);

const SLIDE_OUT_ANIMATION_DURATION = 450;

function NavList({
  idx,
  screen = {},
  nesting,
  handleScreenChange = () => {},
  linksToScreensMap,
  screens,
  allScreens,
  backButtonLabel = '',
  closeMenu,
  mounted,
  actionAreaHeight,
  navigationTrackingData = {},
}) {
  const { saveLinkToHighlight, isLinkToHighlight } = useHighlightedMenuLinks();
  const site = React.useContext(SiteContext);
  const request = React.useContext(RequestContext);
  const [slideIn, setSlideIn] = useState(
    () => screen.nesting < nesting || screen.nesting === 0 || !mounted,
  );

  useEffect(() => {
    setSlideIn(true);
  }, []);

  if (!screen.links) {
    return null;
  }

  const previousScreen = screens[screen.nesting - 1];
  const parentLink = previousScreen
    ? find(
        ({ toScreenId }) => toScreenId === screen.id,
        Object.values(linksToScreensMap),
      )
    : undefined;

  const createScreenHandleClick = (link) => () => {
    if (link.slideOut) {
      setSlideIn(false);
      setTimeout(() => {
        handleScreenChange(link);
      }, SLIDE_OUT_ANIMATION_DURATION);
    } else {
      saveLinkToHighlight(link.id, screen.nesting);
      handleScreenChange(link);
    }
  };

  const createHrefHandleClick = (link) => (e) => {
    if (link.url) {
      saveLinkToHighlight(link.id, screen.nesting, true);
      closeMenu(e);

      OptimizelyFullStack.trackEvent({
        eventName: getMobileTrackingEvent(screen.nesting),
        eventTags: { label: link.label },
      });
    }
  };

  const handleArrowClick = () => {
    Analytics.sendEvent({
      event: 'interaction',
      target: 'Mkt_Web',
      action: ARROW_CLICK_TRACKING_EVENT,
      destinationCategory: Analytics.destinationCategory.UI_OPERATION,
      destinationUrl: undefined,
      ...navigationTrackingData,
    });

    sendNinetailedEvent(ARROW_CLICK_TRACKING_EVENT);
  };

  return (
    <Container
      data-selector={formatSelector('screen', 'mobile_navigation')}
      index={idx}
      slideIn={slideIn}
    >
      {parentLink ? (
        <BackLink
          data-selector={formatSelector('back', 'mobile_navigation')}
          onClick={createScreenHandleClick({
            id: parentLink.id,
            forward: false,
            slideOut: true,
          })}
        >
          <StyledArrowIcon deg={90} spaced />
          <NavAnchor>
            {backButtonLabel} {previousScreen.linksGroupLabel || ''}
          </NavAnchor>
        </BackLink>
      ) : null}
      {screen.linksGroupLabel && screen.nesting !== 0 ? (
        <GroupTitleContainer>
          <GroupTitle>{screen.linksGroupLabel}</GroupTitle>
        </GroupTitleContainer>
      ) : null}
      <StyledList actionAreaHeight={actionAreaHeight}>
        {screen.links.map(
          ({
            url,
            label,
            contentType,
            name,
            id,
            openLinkInNewWindow,
            extraLevelNavigation,
            linksGroupLabel,
            linkBadgeLabel,
            ...rest
          }) => {
            const linkHasScreenNesting = hasScreenNesting(
              id,
              allScreens,
              screen,
            );
            const pageUrlIsInNestedScreens = hasPageUrlInLinkScreen(
              id,
              request,
              site,
              allScreens,
            );
            const shouldHightlightLink =
              linkHasScreenNesting &&
              pageUrlIsInNestedScreens &&
              isLinkToHighlight(id);

            return (
              <ListItem
                data-selector={formatSelector('linkItem', 'mobile_navigation')}
                key={label}
                onClick={createScreenHandleClick({ id })}
              >
                <Link
                  onClick={createHrefHandleClick({ id, url, label })}
                  href={url}
                  target={openLinkInNewWindow ? '_blank' : undefined}
                  trackingContentEntry={{
                    contentType,
                    contentEntryName: name,
                    contentEntryId: id,
                  }}
                  {...rest}
                >
                  <NavAnchor
                    inline
                    isCurrent={shouldHightlightLink}
                    data-selector="link@menu-section"
                  >
                    {label}
                  </NavAnchor>
                </Link>
                {!isEmpty(linkBadgeLabel) && (
                  <LinkBadgeContainer>
                    <LinkBadgeLabel>{linkBadgeLabel}</LinkBadgeLabel>
                  </LinkBadgeContainer>
                )}
                {linkHasScreenNesting ? (
                  <Button
                    data-selector="button@menu-section"
                    onClick={handleArrowClick}
                  >
                    <StyledArrowIcon deg={-90} />
                  </Button>
                ) : null}
              </ListItem>
            );
          },
        )}
      </StyledList>
    </Container>
  );
}

NavList.propTypes = {
  idx: PropTypes.number,
  screen: PropTypes.object,
  nesting: PropTypes.number,
  handleScreenChange: PropTypes.func,
  closeMenu: PropTypes.func,
  linksToScreensMap: PropTypes.object,
  screens: PropTypes.array,
  allScreens: PropTypes.object,
  backButtonLabel: PropTypes.string,
  mounted: PropTypes.bool,
  actionAreaHeight: PropTypes.string,
  navigationTrackingData: PropTypes.object,
};

export default NavList;
