import { FC, useContext, useMemo } from 'react';
import Tagger from '@elbwalker/tagger';
import { Anchor } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css, Theme } from '@emotion/react';

import { ExternalLink } from '../../interfaces';

import { markHighlightedLinks } from './OfferSwitcherService';

import { ACTIONS, ENTITIES, TRIGGERS } from '~/shared/constants/tracking';
import Link from '~/shared/components/Link';
import dataSelector from '~/shared/util/data-selector';
import SiteContext from '~/shared/providers/SiteContext';
import RequestContext from '~/shared/providers/RequestContext';

const DATA_SELECTOR = 'offer-switcher';

const Wrapper = styled('ul')(
  ({ theme }: { theme: Theme }) => css`
    max-width: ${theme.maxWidth};
    display: flex;
    gap: var(--cui-spacings-mega);
    padding: var(--cui-spacings-mega) var(--cui-spacings-giga);
    padding-bottom: var(--cui-spacings-bit);
    list-style: none;

    ${theme.mq.mega} {
      padding: 0;
      margin: 0 auto;
    }
  `,
);

const StyledAnchor = styled(Anchor)(
  ({ variant }: { variant?: string }) => css`
    text-decoration: none;
    span {
      border-bottom: 1px solid transparent;
    }
    ${variant === 'highlight' &&
    `
      span {
        border-color: var(--cui-fg-normal);
      }
      `}
    &:hover {
      span {
        border-color: var(--cui-fg-accent-hovered);
      }
    }
    &:focus {
      span {
        border-color: var(--cui-fg-accent);
      }
    }
    &:active {
      span {
        border-color: var(--cui-fg-accent-pressed);
      }
    }
  `,
);

export interface OfferSwitcherProps {
  items?: ExternalLink[];
}
export const OfferSwitcher: FC<OfferSwitcherProps> = ({ items = [] }) => {
  const request = useContext(RequestContext);
  const site = useContext(SiteContext);

  const tagger = useMemo(() => {
    const taggerInfo = {
      action: ACTIONS.CLICK,
      entity: ENTITIES.NAV,
      trigger: TRIGGERS.CLICK,
    };
    const { action, entity, trigger } = taggerInfo;
    const t = Tagger();

    return {
      ...t.entity(entity),
      ...t.action(trigger, action),
    };
  }, []);

  if (!items.length) {
    return null;
  }

  const itemsWithHighlight = markHighlightedLinks(items, request, site.locale);

  return (
    <Wrapper data-selector={dataSelector('wrapper', DATA_SELECTOR)}>
      {itemsWithHighlight.map((item, index) => {
        const { label, url, contentType, id, name, forceNewTab } = item;

        if (!label || !url) {
          return null;
        }

        const newTabProps = forceNewTab
          ? {
              target: '_blank',
              rel: 'noopener noreferrer',
              disableSPANavigation: true,
            }
          : {};

        return (
          <li key={`${DATA_SELECTOR}-${index}`}>
            <Link
              href={url}
              trackingId=""
              trackingContentEntry={{
                contentType,
                contentEntryName: name,
                contentEntryId: id,
              }}
              {...newTabProps}
            >
              <StyledAnchor
                data-selector={dataSelector('link', DATA_SELECTOR)}
                data-elbcontext={`component:${DATA_SELECTOR}`}
                data-elb="nav"
                data-elb-nav={`description:${item.label}@$list_item@${DATA_SELECTOR}`}
                size="one"
                variant={item.isHighlighted ? 'highlight' : null}
                {...tagger}
              >
                <span>{label}</span>
              </StyledAnchor>
            </Link>
          </li>
        );
      })}
    </Wrapper>
  );
};
