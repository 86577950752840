import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { More } from '@sumup/icons';
import get from 'lodash/fp/get';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';

import CtaButton from '../ActionArea/components/CtaButton/CtaButton';
import { getIconSrcSet } from '../Menu/components/SubMenu/SubMenuService';

import NavItem from './components/NavItem';

import dataSelector from '~/shared/util/data-selector';
import Image from '~/shared/components/Image';
import * as Analytics from '~/shared/services/analytics';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';

export const DATA_SELECTOR = 'tertiary_menu';
export const ICON_WIDTH = 24;
export const MAX_WIDTH_LEFT_CONTAINER = 300;

const wrapperStyles = ({ theme }) => css`
  display: none;

  ${theme.mq.nav} {
    background-color: var(--cui-bg-strong);
    height: ${theme.spacings.tertiaryNav.desktop};
    display: block;
  }
`;

const Wrapper = styled('div')(wrapperStyles);

const innerWrapperStyles = ({ theme }) => css`
  background-color: var(--cui-bg-strong);
  height: ${theme.spacings.tertiaryNav.desktop};
  display: flex;
  justify-content: space-between;

  ${theme.mq.tera} {
    padding: 0;
    margin: 0 auto;
    max-width: ${theme.maxWidth};
  }
`;

const InnerWrapper = styled('div')(innerWrapperStyles);

const leftContainerStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  padding-left: ${theme.spacings.giga};
  max-width: 20%;
  color: var(--cui-fg-on-strong);
`;

const LeftContainer = styled('div')(leftContainerStyles);

const iconStyles = ({ theme, width }) => css`
  width: ${width}px;
  margin-right: ${theme.spacings.mega};
`;

const Icon = styled(Image)(iconStyles);

const labelStyles = ({ theme }) => css`
  ${theme.typography.body.one};
  font-weight: ${theme.fontWeight.bold};
`;

const Label = styled('div')(labelStyles);

const centerListStyles = () => css`
  display: flex;
  align-items: center;
  color: var(--cui-fg-placeholder);
`;

const CenterList = styled('ul')(centerListStyles);

const rightContainerStyles = ({ theme }) => css`
  padding-right: ${theme.spacings.mega};
  line-height: ${theme.spacings.tertiaryNav.desktop};
`;

const RightContainer = styled('div')(rightContainerStyles);

const collapsibleContainerStyles = () => css`
  position: absolute;
  right: -50%;
  top: 100%;
  background: var(--cui-bg-strong);
  display: none;
  flex-direction: column;
  box-shadow:
    0px -2px 5px rgba(12, 15, 20, 0.08),
    0px 4px 4px rgba(12, 15, 20, 0.06),
    0px 8px 8px rgba(12, 15, 20, 0.06);
  border: 1px solid var(--cui-border-strong);
  border-radius: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  transition: opacity 0.05s ease-in-out;
  visibility: hidden;
  transition: visibility 0.2s ease-in-out 0.4s;
`;

const collapsibleContainerOpenStyles = ({ theme, isCollapsibleOpen }) =>
  isCollapsibleOpen &&
  css`
    visibility: visible;
    transition-delay: 0s;
    display: block;
    z-index: ${theme.zIndex.popover};
  `;

const CollapsibleContainer = styled('ul')(
  collapsibleContainerStyles,
  collapsibleContainerOpenStyles,
);

const listItemStyles = ({ theme }) => css`
  display: none;

  ${theme.mq.mainNavCollapsibleMenu} {
    display: inline-flex;
    position: relative;
    padding: ${theme.spacings.byte};
    text-align: center;
    margin: 0 ${theme.spacings.bit};
    width: auto;
    cursor: pointer;
  }

  ${theme.mq.mega} {
    margin: 0 ${theme.spacings.byte};
  }
`;
const ListItem = styled('li')(listItemStyles);

const moreIconStyles = () => css`
  color: var(--cui-fg-on-strong);
  transform: translateY(25%) rotate(90deg);
`;

const StyledMoreIcon = styled(More)(moreIconStyles);

/**
 * A TertiaryMenu component for third level navigation.
 * This component use only on the desktop version of the website.
 */
const TertiaryMenu = (props) => {
  const {
    links = [],
    ctaButtonLabel,
    ctaButtonTrackingId,
    ctaButtonUrl,
    label,
    icon = {},
    ctaButtonOptimizelyFullStackClickEvents,
    canBeHidden,
    soloDesign = {},
  } = props;
  const { experiments } = useOptimizelyData();
  const [isCollapsibleOpen, setCollapsibleOpen] = useState(false);

  if (isEmpty(links)) {
    return null;
  }

  const iconURL = get('file.url', icon);
  const collapsibleLinks = filter({ isCollapsible: true }, links);

  const triggerCollapsible = (param) => () => {
    setCollapsibleOpen(param);

    const trackingId = param
      ? `open@collapsible_${DATA_SELECTOR}`
      : `close@collapsible_${DATA_SELECTOR}`;
    Analytics.sendEvent({
      event: 'interaction',
      target: 'Mkt_Web',
      action: trackingId,
      destinationCategory: Analytics.destinationCategory.UI_OPERATION,
      destinationUrl: undefined,
    });

    sendNinetailedEvent(trackingId);
  };

  return (
    <Wrapper data-selector={dataSelector('wrapper', DATA_SELECTOR)}>
      <InnerWrapper>
        <LeftContainer>
          {iconURL && (
            <Icon
              src={iconURL}
              alt={icon.title}
              lazyLoad={false}
              width={ICON_WIDTH}
              height={ICON_WIDTH}
              srcSet={getIconSrcSet(iconURL, ICON_WIDTH)}
              data-selector={dataSelector('icon', DATA_SELECTOR)}
            />
          )}
          {label && (
            <Label data-selector={dataSelector('group_label', DATA_SELECTOR)}>
              {label}
            </Label>
          )}
        </LeftContainer>
        <CenterList>
          {links.map((link, i) => (
            <NavItem
              key={i}
              applyCollapsibleStyles={!!link.isCollapsible}
              {...getVariationComponent(link, experiments)}
            />
          ))}
          {!isEmpty(collapsibleLinks) && (
            <ListItem
              onMouseEnter={triggerCollapsible(true)}
              onMouseLeave={triggerCollapsible(false)}
              onFocus={triggerCollapsible(true)}
              onBlur={triggerCollapsible(false)}
              onTouchStart={triggerCollapsible(true)}
            >
              <StyledMoreIcon
                data-selector={dataSelector('more_icon', DATA_SELECTOR)}
              />
              {canBeHidden ? (
                <CollapsibleContainer isCollapsibleOpen={isCollapsibleOpen}>
                  {collapsibleLinks.map((link, i) => (
                    <NavItem
                      key={i}
                      isVertical={true}
                      {...getVariationComponent(link, experiments)}
                    />
                  ))}
                </CollapsibleContainer>
              ) : null}
            </ListItem>
          )}
        </CenterList>
        <RightContainer>
          <CtaButton
            ctaButtonUrl={ctaButtonUrl}
            ctaButtonLabel={ctaButtonLabel}
            ctaButtonTrackingId={ctaButtonTrackingId}
            ctaButtonOptimizelyFullStackClickEvents={
              ctaButtonOptimizelyFullStackClickEvents
            }
            data-selector={dataSelector('cta', DATA_SELECTOR)}
            size={'kilo'}
            soloDesign={soloDesign}
            variant="secondary"
          />
        </RightContainer>
      </InnerWrapper>
    </Wrapper>
  );
};

TertiaryMenu.propTypes = {
  links: PropTypes.array,
  contentType: PropTypes.string,
  ctaButtonLabel: PropTypes.string,
  ctaButtonTrackingId: PropTypes.string,
  ctaButtonUrl: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.object,
  isTertiaryMenuShowing: PropTypes.bool,
  canBeHidden: PropTypes.bool,
  isZeroWidth: PropTypes.bool,
  ctaButtonOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),

  /*
   * TEMPORARY EXPERIMENTAL SOLO STYLES EXTENSIONS!
   * added via https://sumupteam.atlassian.net/browse/SA-32606
   * TBD: will be removed or converted to proper features
   */
  soloDesign: PropTypes.object,
};

/**
 * @component
 */
export default TertiaryMenu;
