import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { BACKGROUND_MAP } from '~/shared/components/Sections/SectionsService';
import { BACKGROUNDS } from '~/shared/constants/sections';
import { BADGE_BACKGROUNDS } from '~/shared/constants';
import { childrenPropType } from '~/shared/util/shared-prop-types';

const wrapperStyles = () => css`
  padding: 10px 17px;
  display: flex;
  align-items: center;
`;

const backgroundStyles = ({ background }) => {
  const backgroundColor = BACKGROUND_MAP[background] || BACKGROUNDS.ORANGE;
  const backgroundMap = {
    [BACKGROUNDS.ORANGE]: css`
      background-color: var(--cui-bg-normal);
      color: var(--cui-fg-normal);
    `,
    [BACKGROUNDS.PURPLE]: css`
      background-color: var(--cui-bg-normal);
      color: var(--cui-fg-normal);
    `,
  };

  return backgroundMap[backgroundColor] || null;
};

const Wrapper = styled('div')(wrapperStyles, backgroundStyles);

/**
 * Badge style container with background
 */
function BadgeContainer({
  background,
  className,
  'data-selector': dataSelector,
  children,
}) {
  return (
    <Wrapper
      className={className}
      background={background}
      data-selector={dataSelector}
    >
      {children}
    </Wrapper>
  );
}

BadgeContainer.propTypes = {
  /**
   * selected background from Contentful
   */
  'background': PropTypes.oneOf(BADGE_BACKGROUNDS),
  'className': PropTypes.string,
  'data-selector': PropTypes.string,
  'children': childrenPropType,
};

/**
 * @component
 */
export default BadgeContainer;
