import React from 'react';
import PropTypes from 'prop-types';
import Downshift from 'downshift';
import isEmpty from 'lodash/fp/isEmpty';
import dynamic from 'next/dynamic';

import Container from './components/Container';
import Toggle from './components/Toggle';
import Item from './components/Item';
import Label from './components/Label';
import { DIRECTIONS } from './constants';
import { dropdownItemPropType } from './shared';
import * as DropdownService from './DropdownService';

import { trackingContentEntryPropType } from '~/shared/util/shared-prop-types';
import formatSelector from '~/shared/util/data-selector';

const Menu = dynamic(
  () =>
    import(
      /* webpackChunkName: 'component-dropdown-menu' */ './components/Menu'
    ),
);

function Span() {
  return <span />;
}

const DropdownWithoutMemo = ({
  id = '',
  items = [],
  initialItem = {},
  placeholder = 'Select an item',
  direction = DIRECTIONS.DOWN,
  // HACK: fix for https://sumupteam.atlassian.net/browse/SA-13867
  renderLabelPrefix = Span,
  shouldWrapLabelText,
  onChange = () => {},
  'aria-label': ariaLabel,
  'aria-labelledby': ariaLabelledby,
  trackingContentEntry = {},
  hasPrefix = false,
  toggleTabIndex = '0',
  disableToggleHoverStyles = false,
  disableToggleFocusStyles = false,
  ...props
}) => {
  if (isEmpty(items) || !id) {
    return null;
  }
  const ariaLabelProps = {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledby,
  };
  const getRenderPrefixProp = (item) =>
    renderLabelPrefix && {
      renderPrefix: (rest) => renderLabelPrefix({ item, ...rest }),
    };

  const itemToString = DropdownService.getItemToString(initialItem);
  const stateReducer = DropdownService.getStateReducer();

  return (
    <Downshift
      id={id}
      initialSelectedItem={initialItem}
      onChange={onChange}
      itemToString={itemToString}
      stateReducer={stateReducer}
      {...props}
    >
      {({
        isOpen,
        selectedItem,
        highlightedIndex,
        getRootProps,
        getToggleButtonProps,
        getMenuProps,
        getItemProps,
      }) => {
        const rootProps = getRootProps({
          refKey: 'ref',
          direction,
          role: undefined,
          ...ariaLabelProps,
        });
        const toggleProps = getToggleButtonProps();

        return (
          <Container {...rootProps} {...props}>
            <Toggle
              {...toggleProps}
              direction={direction}
              tabIndex={toggleTabIndex}
              isOpen={isOpen}
              data-selector={formatSelector('menu_toggle', 'dropdown')}
              disableToggleHoverStyles={disableToggleHoverStyles}
              disableToggleFocusStyles={disableToggleFocusStyles}
            >
              <Label
                aria-haspopup="listbox"
                text={
                  selectedItem.label ? selectedItem.label.trim() : placeholder
                }
                hasPrefix={hasPrefix}
                {...getRenderPrefixProp(selectedItem)}
                {...ariaLabelProps}
              />
            </Toggle>
            <Menu
              {...getMenuProps({
                refKey: 'ref',
                ...ariaLabelProps,
              })}
              isOpen={isOpen}
              direction={direction}
              data-selector={formatSelector('menu', 'dropdown')}
            >
              {items.map((item, index) => {
                const itemProps = getItemProps({ item, index });

                return (
                  <Item
                    {...itemProps}
                    key={index}
                    item={item}
                    data-selector={formatSelector('menu_item', 'dropdown')}
                    trackingContentEntry={trackingContentEntry}
                    disableSPANavigation={props.disableSPANavigation}
                  >
                    <Label
                      text={item.label}
                      isActive={highlightedIndex === index}
                      shouldWrapText={shouldWrapLabelText}
                      hasPrefix={hasPrefix}
                      {...getRenderPrefixProp(item)}
                    />
                  </Item>
                );
              })}
            </Menu>
          </Container>
        );
      }}
    </Downshift>
  );
};

DropdownWithoutMemo.DIRECTIONS = DIRECTIONS;

DropdownWithoutMemo.propTypes = {
  /**
   * Component id string ensures proper SSR rendering
   * https://github.com/downshift-js/downshift/issues/602#issuecomment-429663734
   */
  'id': PropTypes.string.isRequired,
  /**
   * List of items to be displayed in dropdown menu.
   */
  'items': PropTypes.arrayOf(dropdownItemPropType),
  /*
   * Initially selected item from the list.
   */
  'initialItem': dropdownItemPropType,
  /**
   * Render prop that should render a left-aligned overlay icon or element.
   * Receives a `className` prop.
   */
  'renderLabelPrefix': PropTypes.func,
  /**
   * Specify if label text should suppress line breaks
   */
  'shouldWrapLabelText': PropTypes.bool,
  /**
   * String to show when no selection is made.
   */
  'placeholder': PropTypes.string,
  /**
   * Opening slide direction of dropdown menu.
   */
  'direction': PropTypes.oneOf([DIRECTIONS.UP, DIRECTIONS.DOWN]),
  /**
   * onChange handler is called when the selection changes.
   */
  'onChange': PropTypes.func,
  /**
   * String that labels dropdown.
   * Required for accessibility when `aria-labelledby` is not provided.
   */
  'aria-label': PropTypes.string,
  /**
   * Name of the element that labels dropdown.
   * Required for accessibility when `aria-label` is not provided.
   */
  'aria-labelledby': PropTypes.string,
  /**
   * Information for analytics tracking event.
   */
  'trackingContentEntry': trackingContentEntryPropType,
  /*
   * The selected element of the items provided to Downshift.
   */
  'selectedItem': dropdownItemPropType,
  /*
   * Need to be set to true when you want your prefix to be rendered.
   */
  'hasPrefix': PropTypes.bool,
  /*
   * Sets the tabIndex for the toggle container.
   */
  'toggleTabIndex': PropTypes.string,
  /*
   * Disables the coloring of the border when hovering over the dropdown.
   */
  'disableToggleHoverStyles': PropTypes.bool,
  /*
   * Disables the coloring of the border when focusing the dropdown.
   */
  'disableToggleFocusStyles': PropTypes.bool,

  /*
   * Disables the SPA navigation when selecting an item.
   */
  'disableSPANavigation': PropTypes.bool,
};

const Dropdown = React.memo(DropdownWithoutMemo);
Dropdown.propTypes = DropdownWithoutMemo.propTypes;
Dropdown.DIRECTIONS = DropdownWithoutMemo.DIRECTIONS;

export default Dropdown;
