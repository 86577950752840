import find from 'lodash/fp/find';
import includes from 'lodash/fp/includes';

export default function imageFormatExtensions(src, opts = {}) {
  const params = {};
  const supportedSrcFormat = getSupportedSrcFormat(src);

  if (supportedSrcFormat === '.jpg' || opts.forceProgressive) {
    params.fm = 'jpg';
    params.fl = 'progressive';
  }

  return params;
}

export function imageParametersForFormat(src, finalFormat) {
  const params = {};
  const supportedSrcFormat = getSupportedSrcFormat(src);

  if (supportedSrcFormat) {
    params.fm = finalFormat;
  }

  if (finalFormat === 'jpg') {
    params.fl = 'progressive';
  } else {
    params.fl = undefined;
  }

  return params;
}

// only .jpg, .jpeg and .png images are elligible for webp and avif transformation
export function getSupportedSrcFormat(src) {
  return find((ext) => includes(ext, src), ['.jpg', '.png', '.jpeg']);
}
