import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';

import PrimaryLogo from './components/PrimaryLogo';
import SubSiteLogo from './components/SubSiteLogo';
import SecondaryLogo from './components/SecondaryLogo';

import {
  partnerPropType,
  subSitePropType,
  secondaryLogoPropType,
  trackingContentEntryPropType,
} from '~/shared/util/shared-prop-types';

const wrapperStyles = ({ theme }) => css`
  display: flex;
  align-items: center;
  max-width: 100%;
  z-index: 3;
  height: ${theme.spacings.tera};
`;

const Wrapper = styled('div')(wrapperStyles);

/**
 * An area to display our and our partners' brand.
 */
const LogoBranded = ({
  subSite = {},
  partner = {},
  secondaryLogo = {},
  isShortLogo = false,
  primaryLogoLinksToHomepage = false,
  className = '',
  id = '',
  onClick = () => {},
  trackingContentEntry = {},
  ...rest
}) => {
  const secondaryLogoProps = !isEmpty(partner) ? partner : secondaryLogo;

  return (
    <Wrapper className={className} id={id}>
      <PrimaryLogo
        {...rest}
        logoLinksToHomepage={primaryLogoLinksToHomepage}
        isShort={isShortLogo}
        onClick={onClick}
        id={id}
        trackingContentEntry={trackingContentEntry}
      />
      {!isShortLogo && (
        <Fragment>
          <SubSiteLogo
            {...subSite}
            onClick={onClick}
            trackingContentEntry={trackingContentEntry}
          />
          <SecondaryLogo
            {...secondaryLogoProps}
            onClick={onClick}
            trackingContentEntry={trackingContentEntry}
          />
        </Fragment>
      )}
    </Wrapper>
  );
};

LogoBranded.propTypes = {
  /**
   * SubSite information
   */
  subSite: PropTypes.shape(subSitePropType),
  /**
   * Partner information
   */
  partner: PropTypes.shape(partnerPropType),
  /**
   * Additional logo for cobranding
   */
  secondaryLogo: PropTypes.shape(secondaryLogoPropType),
  /**
   * Only show SumUp icon as logo
   */
  isShortLogo: PropTypes.bool,
  /**
   * Additional class name
   */
  className: PropTypes.string,
  /**
   * Optional anchor id
   */
  id: PropTypes.string,
  /**
   * On click
   */
  onClick: PropTypes.func,
  /**
   * Primary logo links to homepage
   */
  primaryLogoLinksToHomepage: PropTypes.bool,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
};

/**
 * @component
 */
export default LogoBranded;
