import { LanguageLink } from './interfaces';

import { LiveWebsite } from '~/shared/api-controllers/site/transformers/config';
import { LocalizedUrls } from '~/shared/api-controllers/route/transformers/localized-urls';

export function getLanguageLinks(
  currentLocale: string,
  countryLocales: string[] = [],
  allWebsites: LiveWebsite[] = [],
  allLocalizedUrls: LocalizedUrls = {},
): {
  activeLink: LanguageLink;
  dropdownLinks: LanguageLink[];
  allLinks: LanguageLink[];
} {
  const defaultResult = {
    activeLink: null,
    dropdownLinks: [],
    allLinks: [],
  };

  return countryLocales.reduce((result, locale) => {
    const targetWebsite = allWebsites.find(
      (website) => website.siteLocale === locale,
    );

    if (targetWebsite) {
      const url = allLocalizedUrls[locale] || targetWebsite.domain;
      const label = locale.slice(0, 2)?.toUpperCase();

      const link = {
        url,
        label,
        locale,
      };

      result.allLinks.push(link);

      if (currentLocale === locale) {
        // eslint-disable-next-line no-param-reassign
        result.activeLink = link;
      } else {
        result.dropdownLinks.push(link);
      }
    }

    return result;
  }, defaultResult);
}
