import md5 from 'blueimp-md5/js/md5.min';

export const PARTNER_ID = 'SumUp';
export const PARTNER_NAME = 'SumUp';

export const SAMSUNG_PARTNER_LINK_REGEX =
  /^https:\/\/shop\.samsung\.com\/[a-z][a-z]\/multistore\/[a-z][a-z]epp\/partner/;
export const SAMSUNG_PARTNER_URL =
  'https://shop.samsung.com/it/multistore/itepp/partner_pro/login/rewardspartner';
export const SAMSUNG_HASH_KEY = 'rLLn3XsHmBg2XMFHdxadCJc4ScVfbwj7';
export const SAMSUNG_PARAMS = {
  AFFILIATE_ID: 'affiliateid',
  AFFILIATE_NAME: 'affiliatename',
  CLICKTIMESTAMP: 'clicktimestamp',
  HASH: 'hash',
};

export function createTimestamp() {
  // using consistent locale for predictable parsing
  const now = new Date().toLocaleString('en-GB'); // -> '03/02/1982, 01:00:00'
  const [datePart, timePart] = now.split(', '); // ->['03/02/1982', '01:00:00']
  const [day, month, year] = datePart.split('/'); // -> ['03', '02', '1982']
  const result = `${year}${month}${day}${timePart.replace(/:/g, '')}`; // -> '19820203010000'

  return result;
}

export function isSamsungPartnerLink(href: string): boolean {
  return SAMSUNG_PARTNER_LINK_REGEX.test(href);
}

export function generateDynamicSamsungPartnerLink(): string {
  const clickTimestamp = createTimestamp();
  const hash = md5(`${PARTNER_ID}${clickTimestamp}${SAMSUNG_HASH_KEY}`);

  const url = new URL(SAMSUNG_PARTNER_URL);

  url.searchParams.set(SAMSUNG_PARAMS.AFFILIATE_ID, PARTNER_ID);
  url.searchParams.set(SAMSUNG_PARAMS.AFFILIATE_NAME, PARTNER_NAME);
  url.searchParams.set(SAMSUNG_PARAMS.CLICKTIMESTAMP, String(clickTimestamp));
  url.searchParams.set(SAMSUNG_PARAMS.HASH, hash);

  return url.toString();
}
