import { SUMUP_VERCEL_SUFFIX } from '~/shared/constants/websites';
import { ONE_DOMAIN_HOSTS } from '~/shared/constants/one-domain';
import * as ENVIRONMENTS from '~/shared/constants/environments';
import { AVAILABLE_LOCALES_ONE_DOMAIN } from '~/shared/constants/locales';

export function isOneDomainHost(host: string, queryHostname?: string): boolean {
  const isVercelOrLocalHost =
    host.includes(SUMUP_VERCEL_SUFFIX) || host.includes('localhost');

  const hostname = isVercelOrLocalHost
    ? queryHostname || ONE_DOMAIN_HOSTS[ENVIRONMENTS.PRODUCTION]
    : host;

  return !!Object.values(ONE_DOMAIN_HOSTS).find(
    (defaultHostname) => defaultHostname === hostname,
  );
}

export const findLocale = (locale) =>
  AVAILABLE_LOCALES_ONE_DOMAIN.some(
    (_locale) => _locale.toLowerCase() === locale?.toLowerCase(),
  );
