import { useCallback, useState, useEffect } from 'react';

function getSize(el, { width = 0, height = 0 } = {}) {
  if (!el) {
    return {
      width,
      height,
    };
  }

  return {
    width: el.offsetWidth,
    height: el.offsetHeight,
  };
}

export default function useComponentSize(ref = {}, opts = {}) {
  const [componentSize, setComponentSize] = useState(
    getSize(ref.current, opts.defaultValues),
  );

  const handleResize = useCallback(() => {
    if (ref.current) {
      setComponentSize(getSize(ref.current));
    }
  }, [ref]);

  useEffect(() => {
    const node = ref.current;

    if (!node) {
      return;
    }

    handleResize();

    if (opts.noResize) {
      return;
    }

    if (typeof ResizeObserver === 'function') {
      let resizeObserver = new ResizeObserver(() => handleResize());
      resizeObserver.observe(node);

      // eslint-disable-next-line consistent-return
      return function cleanup() {
        resizeObserver.disconnect(node);
        resizeObserver = null;
      };
    }

    window.addEventListener('resize', handleResize);

    // eslint-disable-next-line consistent-return
    return function cleanup() {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref, handleResize, opts.noResize]);

  return componentSize;
}
