// eslint-disable-next-line import/prefer-default-export
export const VARIABLES = {
  PRICE: 'Price',
  ORIGINAL_PRICE: 'Original price',
  EXPIRY_DATE: 'Promo expiry date',
  CREDIT_CARD_FEE: 'Credit card fee',
  DEBIT_CARD_FEE: 'Debit card fee',
  UNIVERSAL_FEE: 'Universal fee',
  MONTHLY_FEE: 'Monthly fee',
  SUPPORT_EMAIL: 'Support email',
  SUPPORT_PHONE: 'Support phone',
  SUPPORT_HOURS: 'Support hours',
  PAYOUT_TIME: 'Payout time',
  GLOBAL_VARIABLE: 'Global variable',
  QUERY_PARAMETER: 'Query parameter',
  ONETRUST_COOKIE_LIST: 'OneTrust cookie list',
  PUBLISHED_AT: 'Published At',
  UPDATED_AT: 'Updated At',
};

export const TEXT_FORMATS = {
  BOLD: 'bold',
  ITALIC: 'italic',
  STRIKETHROUGH: 'strikethrough',
};

export const TEXT_SIZES = {
  HEADLINE_ONE: 'Headline One',
  HEADLINE_TWO: 'Headline Two',
  HEADLINE_THREE: 'Headline Three',
  HEADLINE_FOUR: 'Headline Four',
  BODY_ONE: 'Body One',
  BODY_TWO: 'Body Two',
};
