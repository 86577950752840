import startsWith from 'lodash/startsWith';

import {
  isCurrent,
  parse as parseUrl,
  URLLikeObject,
} from '~/shared/services/url';
import { formatURLToOneDomain } from '~/shared/services/LinkService';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

export interface SiteData {
  locale: string;
}

export function isPageUrlInMenuLink(
  request: RequestType,
  site: SiteData,
  link: { url?: string } = {},
): boolean {
  const { url } = link;

  if (!url || !request || !site) {
    return false;
  }

  const isOnlyHash = startsWith('#', url);
  const parsedUrl = parseUrl(url, isOnlyHash ? request.href : request.origin);
  const formattedUrl = formatURLToOneDomain(
    parsedUrl,
    request,
    site?.locale,
  ) as URLLikeObject;
  const isCurrentUrl = isCurrent(formattedUrl, request);

  return isCurrentUrl;
}

export function isPageUrlInMenuLinks(
  request: RequestType,
  site: SiteData,
  links: { url?: string }[] = [],
): boolean {
  return links.some((link) => isPageUrlInMenuLink(request, site, link));
}
