import { useState, useEffect, useCallback } from 'react';

import {
  saveClickedLinkIdToHighlight,
  populateClickedLinkIdsToHighlight,
  getStoredClickedLinkIdsToHighlight,
} from '../MenuHighlightService';

export default function useHighlightedMenuLinks() {
  const [linkIdsToHighlight, setLinkIdsToHighlight] = useState([]);

  useEffect(() => {
    populateClickedLinkIdsToHighlight();
    setLinkIdsToHighlight(getStoredClickedLinkIdsToHighlight());
  }, []);

  const saveLinkToHighlight = (
    linkId: string,
    navigationLevel: number,
    saveToStorage?: boolean,
  ): void => {
    saveClickedLinkIdToHighlight(linkId, navigationLevel, saveToStorage);

    if (saveToStorage) {
      setLinkIdsToHighlight(getStoredClickedLinkIdsToHighlight());
    }
  };
  const isLinkToHighlight = useCallback(
    (linkId: string): boolean => linkIdsToHighlight.includes(linkId),
    [linkIdsToHighlight],
  );

  return {
    linkIdsToHighlight,
    saveLinkToHighlight,
    isLinkToHighlight,
  };
}
