import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import FooterLink from '../FooterLink';
import SocialIcons from '../SocialIcons';

import { trackingContentEntryPropType } from '~/shared/util/shared-prop-types';

const wrapperStyles = ({ theme, noLinks }) => css`
  margin-top: ${theme.spacings.giga};
  ${noLinks
    ? css`
        display: flex;
        justify-content: center;
      `
    : ''}

  ${theme.mq.untilKilo} {
    margin-top: ${theme.spacings.mega};
  }
`;
const listStyles = ({ theme }) => css`
  list-style: none;

  ${theme.mq.kilo} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
  }
`;
const listItemStyles = ({ theme }) => css`
  ${theme.typography.body.two};
  margin-bottom: ${theme.spacings.byte};

  ${theme.mq.kilo} {
    display: inline;
    margin-right: ${theme.spacings.tera};
  }

  ${theme.mq.mega} {
    ${theme.typography.body.one};
  }
`;
const innerListStyles = () => css`
  list-style: none;
`;
const Wrapper = styled('nav')(wrapperStyles);
const List = styled('ul')(listStyles);
const ListItem = styled('li')(listItemStyles);
const InnerList = styled('ul')(innerListStyles);

function SecondaryMenu({
  links = [],
  trackingContentEntry = {},
  label = 'Footer secondary navigation',
}) {
  const noLinks = !links.length;

  return (
    <Wrapper noLinks={noLinks} aria-label={label}>
      <List>
        {!noLinks && (
          <li>
            <InnerList>
              {links.map((link, i) => (
                <ListItem key={i}>
                  <FooterLink
                    link={link}
                    selectorPrefix="link_secondary"
                    trackingContentEntry={trackingContentEntry}
                  />
                </ListItem>
              ))}
            </InnerList>
          </li>
        )}

        <li>
          <SocialIcons trackingContentEntry={trackingContentEntry} />
        </li>
      </List>
    </Wrapper>
  );
}

SecondaryMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string.isRequired,
      trackingId: PropTypes.string,
    }),
  ),
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
  /**
   * Accessibility label for secondary menu.
   */
  label: PropTypes.string,
};

export default SecondaryMenu;
