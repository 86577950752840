import React from 'react';
import PropTypes from 'prop-types';
import merge from 'lodash/fp/merge';

import * as ActionTypes from './actionTypes';

const StoreContext = React.createContext([]);

const initialState = {
  positions: [],
  careersSearch: {
    city: '',
    department: '',
    search: '',
  },
  sessionQuery: {},
};

export function updateNamespace(store, namespace, payload) {
  return { ...store, [namespace]: { ...store[namespace], ...payload } };
}

export function reducer(store, action) {
  switch (action.type) {
    case ActionTypes.SET_CAREERS_SEARCH:
      return updateNamespace(store, 'careersSearch', action.payload);
    case ActionTypes.SET_POSITIONS:
      return { ...store, positions: [...action.payload] };
    case ActionTypes.SET_SESSION_QUERY:
      return {
        ...store,
        sessionQuery: {
          ...store.sessionQuery,
          ...action.payload,
        },
      };
    default:
      return store;
  }
}

function StoreProvider({ children, positions, sessionQuery }) {
  const [store, dispatch] = React.useReducer(
    reducer,
    merge(initialState, { positions, sessionQuery }),
  );

  React.useEffect(() => {
    if (positions) {
      dispatch({ type: ActionTypes.SET_POSITIONS, payload: positions });
    }
  }, [positions, dispatch]);

  const value = [store, dispatch];

  return (
    <StoreContext.Provider value={value}>{children}</StoreContext.Provider>
  );
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
  positions: PropTypes.array,
  sessionQuery: PropTypes.object,
};

export { StoreContext, StoreProvider };
