import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

import { getStatusCodeFromError } from '~/shared/services/errors';

const { publicRuntimeConfig = {} } = getConfig();

function checkExceptionFilter(statusCode, err) {
  if (statusCode === 404) {
    return true;
  }

  if (statusCode === 410) {
    return true;
  }

  if (err instanceof URIError) {
    return true;
  }

  return false;
}

function captureException(err = {}, context = {}) {
  if (__DEV__ || __TEST__ || !publicRuntimeConfig.sentry) {
    return false;
  }

  const statusCode = getStatusCodeFromError(err, context.res);
  const isFiltered = checkExceptionFilter(statusCode, err);

  if (isFiltered) {
    return false;
  }

  Sentry.configureScope((scope) => {
    if (err.message) {
      // de-duplication currently doesn't work correctly for ssr/browser errors
      // so we force deduplication by error message if it is present
      scope.setFingerprint([err.message]);
      scope.setExtra('errorMessage', err.message);
    }

    if (statusCode) {
      scope.setExtra('statusCode', statusCode);
    }

    if (err.data) {
      scope.setExtra('errorData', err.data);
    }

    if (err.request) {
      scope.setExtra('requestData', {
        code: err.code,
        syscall: err.syscall,
        hostname: err.hostname,
        host: err.host,
        port: err.port,
      });
    }

    if (context) {
      const { req, errorInfo, query, asPath } = context;
      if (process.browser) {
        scope.setTag('ssr', false);
        scope.setExtra('query', query);
        scope.setExtra('pathname', asPath);
        scope.setExtra('userAgent', navigator.userAgent);
      } else {
        scope.setTag('ssr', true);
        scope.setExtra('url', req.url);
        scope.setExtra('method', req.method);
        scope.setExtra('headers', req.headers);
        scope.setExtra('params', req.params);
        scope.setExtra('query', req.query);
        scope.setExtra('userAgent', req.headers['user-agent']);
      }

      if (errorInfo) {
        Object.keys(errorInfo).forEach((key) =>
          scope.setExtra(key, errorInfo[key]),
        );
      }
    }
  });

  return Sentry.captureException(err);
}

export default captureException;
