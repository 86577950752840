import { elb } from '@elbwalker/walker.js';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Theme } from '@sumup/design-tokens';
import isEmpty from 'lodash/fp/isEmpty';
import React from 'react';

import { COOKIE_SETTINGS_LINK_TYPE } from '../../constants';

import Link from '~/shared/components/Link';
import useActiveCookieCategories from '~/shared/hooks/use-active-cookie-categories';
import * as Analytics from '~/shared/services/analytics';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';
import * as OneTrust from '~/shared/services/onetrust';
import { TrackingContentEntryType } from '~/shared/types/shared';
import formatSelector from '~/shared/util/data-selector';

const linkStyles = ({ theme }: { theme?: Theme }) => css`
  border-bottom: ${theme.borderWidth.kilo} solid;
  border-bottom-color: transparent;
  transition: color border-bottom-color ${theme.transitions.default};
  &:hover,
  &:focus {
    color: var(--cui-fg-accent-hovered);
    border-bottom-color: var(--cui-bg-accent-strong-hovered);
  }
`;
const StyledLink = styled('a')(linkStyles);

const buttonStyles = () => css`
  background-color: transparent;
  border-color: transparent;
  color: inherit;
  font-size: inherit;
  padding: 0;
  cursor: pointer;
`;
const StyledButton = styled('button')(buttonStyles, linkStyles);

type Props = {
  link?:
    | {
        url: string;
        label: string;
        trackingId: string;
        /**
         * Allows to open links in new window by specifying '_blank'
         */
        target: string;
        /**
         * Type of link which should be rendered differently.
         * Supported values: 'onetrust-cookies-settings'
         */
        type: string;
      }
    | {};
  selectorPrefix: string;
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry?: TrackingContentEntryType | {};
  className?: string;
};
function FooterLink({
  link = {},
  selectorPrefix = '',
  trackingContentEntry = {},
  className,
}: Props) {
  const activeCookieCategories = useActiveCookieCategories();

  if (isEmpty(link) || !selectorPrefix) {
    return null;
  }

  const { url, label, trackingId, target, type } = link;

  if (type === COOKIE_SETTINGS_LINK_TYPE) {
    // do not render link if OneTrust is disabled
    if (isEmpty(activeCookieCategories)) {
      return null;
    }

    const handleClick = () => {
      OneTrust.openCookieSettingsModal();

      elb('button clicked', {
        button_description: 'open onetrust cookie preferences',
      });
      Analytics.sendEvent({
        action: trackingId,
        event: 'interaction',
        target: 'Mkt_Web',
        destinationCategory: Analytics.destinationCategory.UI_OPERATION,
        destinationUrl: undefined,
        ...trackingContentEntry,
      });

      sendNinetailedEvent(trackingId);
    };

    return <StyledButton onClick={handleClick}>{label}</StyledButton>;
  }

  return (
    <Link
      trackingId={trackingId}
      target={target}
      href={url}
      trackingContentEntry={trackingContentEntry}
    >
      <StyledLink
        data-selector={formatSelector(selectorPrefix, 'footer')}
        className={className}
      >
        {label}
      </StyledLink>
    </Link>
  );
}

export default FooterLink;
