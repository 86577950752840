export const GTM = 'GTM-ZC88';

export const PINGDOM = {
  'bg-BG': '5d5e556e9623b0000800020a',
  'cs-CZ': '5d5e533e3a70310008000221',
  'da-DK': '5d5e552e9623b00008000209',
  'de-AT': '5d5e52b63a7031000800021f',
  'de-CH': '5d5e52829623b00008000203',
  'de-DE': '552f8842abe53dd35e899307',
  'de-LU': '5d5e506f3a7031000800021c',
  'el-CY': '5d5e54c33a70310008000225',
  'el-GR': '5d5e54de05a2410008000227',
  'en-CA': '5d5e55863a70310008000226',
  'en-GB': '5d5e51f405a241000800021f',
  'en-IE': '5d5e51b805a241000800021e',
  'en-MT': '5d5e54a99623b00008000208',
  'en-US': '5d52b40a3a70310008000184',
  'es-US': '5ef9d9748e83fa00150004cb',
  'es-CL': '5e849a23229e930008000815',
  'es-ES': '5d5e521205a2410008000220',
  'es-AR': '6009b5f0499446001100028d',
  'es-PE': '6009b64e06c837001300027b',
  'en-AU': '6009b613f4ca1e001100029d',
  'et-EE': '5d5e54929623b00008000207',
  'fi-FI': '5d5e551505a2410008000229',
  'fr-BE': '5d5e52573a7031000800021e',
  'fr-CH': '5d5e52e205a2410008000223',
  'fr-FR': '5d5e51943a7031000800021d',
  'fr-LU': '5d5e50b305a241000800021d',
  'hr-HR': '5ea7e54f11c07000080009e9',
  'hu-HU': '5d5e554a05a241000800022a',
  'it-CH': '5d5e54389623b00008000205',
  'it-IT': '5d5e503a9623b00008000202',
  'lt-LT': '5d5e53c705a2410008000225',
  'lv-LV': '5d5e53a69623b00008000204',
  'nb-NO': '5d5e54fc05a2410008000228',
  'nl-BE': '5d5e523005a2410008000221',
  'nl-NL': '5d5e530005a2410008000224',
  'pl-PL': '5d5e54143a70310008000224',
  'pt-BR': '5d516cba05a241000800017b',
  'pt-PT': '5d5e53fb3a70310008000223',
  'ro-RO': '5ea7e51f11c07000080009e8',
  'sk-SK': '5d5e547905a2410008000226',
  'sl-SI': '5d5e545c9623b00008000206',
  'sv-SE': '5d5e53e03a70310008000222',
};
