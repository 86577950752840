import Pythia, { getBrowserTags } from '@sumup/pythia';
import getConfig from 'next/config';

import isServer from '~/shared/util/is-server';

const { publicRuntimeConfig = {} } = getConfig();
const { appName, environment, pythia = {} } = publicRuntimeConfig;

function createMetricsClient() {
  if (isServer) {
    // eslint-disable-next-line no-console
    return { send: () => console.warn('Pythia SDK cannot be used on server') };
  }

  if (environment === 'production' && pythia.apiToken) {
    return new Pythia({
      env: 'production',
      defaultTags: { appName, ...getBrowserTags() },
      apiToken: pythia.apiToken,
    });
  }

  if (__TEST__) {
    return { send: () => {} };
  }

  // eslint-disable-next-line no-console
  return { send: (event) => console.info('Pythia metrics event:', event) };
}

const client = createMetricsClient();

export function sendEventAsync(fn) {
  const supportsIdleCallback = typeof requestIdleCallback !== 'undefined';

  if (supportsIdleCallback) {
    requestIdleCallback(fn, { timeout: 500 });
  } else {
    setTimeout(fn, 2000);
  }
}

export function sendEvent(event, sendAsync = false) {
  // try to minimize the number of longtasks in main thread
  // TO DO: should be removed once pythia supports async send by default
  // https://github.com/sumup/pythia-client/blob/master/src/utils.js#L44
  // https://github.com/sumup/pythia-client/pull/10
  if (sendAsync) {
    return sendEventAsync(() => client.send(event));
  }

  return client.send(event);
}
