import React, { FC, useContext, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import { Shop as ShopIcon } from '@sumup/icons';
import { useClickOutside, useModal, Badge } from '@sumup/circuit-ui';

import Cart from '~/domains/shop/components/Cart';
import { CartProps } from '~/domains/shop/components/Cart/Cart';
import dataSelector from '~/shared/util/data-selector';
import { CircuitEmotionModalProps } from '~/shared/types/shared';
import { ShopContext } from '~/domains/shop/providers/ShopProvider';
import SiteContext from '~/shared/providers/SiteContext';

const containerStyles = ({
  theme,
}: {
  theme: Theme;
  addRightSpace?: boolean;
}) => css`
  position: relative;
  z-index: 3;
  cursor: pointer;
  color: var(--cui-fg-normal);

  svg {
    width: var(--cui-spacings-mega);
    height: var(--cui-spacings-mega);
  }

  &:hover,
  &:focus {
    color: var(--cui-fg-accent-hovered);
  }

  &:active {
    color: var(--cui-fg-accent-pressed);
  }

  .cui-modal-close {
    top: 0;
  }

  ${theme.mq.giga} {
    padding: 0;
    width: auto;
  }
`;

const containerHighlightedStyles = ({
  theme,
  ...props
}: {
  theme?: Theme;
  highlighted?: boolean;
}) =>
  props.highlighted &&
  css`
    color: var(--cui-fg-normal);

    &:visited,
    &:hover,
    &:focus,
    &:active {
      color: currentColor;
    }
  `;

const tableOfContentsStyles = ({
  theme,
  ...props
}: {
  theme?: Theme;
  addRightSpace?: boolean;
}) =>
  props.addRightSpace &&
  css`
    margin-right: 75px;
  `;

const Container = styled('div')(
  containerStyles,
  containerHighlightedStyles,
  tableOfContentsStyles,
);

const cartWrapperStyles = ({ theme }: { theme: Theme }) => css`
  position: absolute;
  z-index: 3;
  right: -88px;
  top: var(--cui-spacings-tera);

  &:before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;

    border-left: var(--cui-spacings-byte) solid transparent;
    border-right: var(--cui-spacings-byte) solid transparent;
    border-bottom: var(--cui-spacings-byte) solid var(--cui-bg-highlight);
    top: calc(var(--cui-spacings-byte) * -1);

    right: 88px;
  }

  ${theme.mq.mega} {
    display: block;
  }
`;

const CartWrapper = styled('div')(cartWrapperStyles);

const CartContainer = styled('div')(
  ({ theme }: { theme: Theme }) => css`
    ${theme.mq.mega} {
      position: relative;
      cursor: auto;
      color: var(--cui-fg-normal);
      min-height: 205px;
      min-width: 390px;
      background-color: var(--cui-bg-normal);
      box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
      border-radius: var(--cui-border-radius-byte);
      overflow: hidden;
    }
  `,
);

const ShopIconWrapper = styled('button')(css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  color: var(--cui-fg-normal);
`);

const cartBadgeStyles = ({ theme }: { theme?: Theme }) => css`
  position: absolute;
  top: calc(var(--cui-spacings-mega) * -1);
  right: calc(var(--cui-spacings-kilo) * -1 - var(--cui-spacings-bit));
  border: solid var(--cui-border-width-mega) var(--cui-bg-normal);

  ${theme.mq.untilGiga} {
    right: calc(var(--cui-spacings-kilo) * -1);
  }
  ${theme.mq.untilMega} {
    right: calc(var(--cui-spacings-kilo) * -1 - var(--cui-spacings-bit));
  }
`;
const CartBadge = styled(Badge)(cartBadgeStyles);

export interface ShoppingCartProps extends CartProps {
  isMobile?: boolean;
  addRightSpace?: boolean;
}

export const ShoppingCart: FC<ShoppingCartProps> = ({
  isMobile = false,
  addRightSpace = false,
}) => {
  const [isCartOpen, setCartOpen] = useState(false);
  const { cartLabels } = useContext(SiteContext);
  const ref = useRef(null);
  useClickOutside(
    ref,
    () => {
      if (!isMobile) {
        setCartOpen(false);
      }
    },
    true,
  );
  const { order } = useContext(ShopContext);
  const { setModal } = useModal();

  const handleCartClick = () => {
    if (!isMobile) {
      setCartOpen(!isCartOpen);
    } else if (isMobile && !isCartOpen) {
      setCartOpen(true);
      setModal({
        onClose: () => {
          setCartOpen(false);
        },
        closeButtonLabel: cartLabels.closeCart,
        style: {
          padding: '0',
          borderTopLeftRadius: 'var(--cui-border-radius-mega)',
          borderTopRightRadius: 'var(--cui-border-radius-mega)',
        },
        children: <Cart isModal />,
      } as CircuitEmotionModalProps);
    }
  };

  const totalItems = order?.lineItems?.reduce((acc, lineItem) => {
    const quantity = ['bundles', 'skus'].includes(lineItem.itemType)
      ? lineItem.quantity
      : 0;

    return acc + quantity;
  }, 0);

  return (
    <Container addRightSpace={addRightSpace}>
      <ShopIconWrapper
        aria-label={cartLabels.viewCart}
        onClick={handleCartClick}
        data-selector={dataSelector('cart_btn', 'header_navigation')}
      >
        {totalItems > 0 && (
          <CartBadge circle variant="success">
            {totalItems}
          </CartBadge>
        )}
        <ShopIcon />
      </ShopIconWrapper>

      {isCartOpen && !isMobile && (
        <CartWrapper>
          <CartContainer
            ref={ref}
            data-selector={dataSelector('shopping_cart', 'header_navigation')}
          >
            <Cart />
          </CartContainer>
        </CartWrapper>
      )}
    </Container>
  );
};
