import { createContext } from 'react';

import { WebsiteProduct } from '~/shared/api-controllers/shared/transformers/products';

export type ProductsType = {
  products?: Record<string, WebsiteProduct>;
  siteProducts?: Record<string, WebsiteProduct>;
  pageProducts?: Record<string, WebsiteProduct>;
};

/**
 * Passes down products data for the current page
 */
const ProductsContext = createContext<ProductsType>({
  products: {},
  siteProducts: {},
  pageProducts: {},
});

export default ProductsContext;
