import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/fp/isNil';

export const YOUTUBE_PLAYER_STATES = {
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
};

export function booleanToNumber(bool?: boolean) {
  if (typeof bool === 'undefined') {
    return undefined;
  }
  return bool ? 1 : 0;
}

type YouTubeParams = {
  autoplay: boolean;
  mute: boolean;
  loop: boolean;
  language: string;
  hideControls: boolean;
  allowFullScreen: boolean;
  removeBranding: boolean;
  showRelatedVideos: boolean;
};
// Only a subset of parameters is supported, the full list can be found at:
// https://developers.google.com/youtube/player_parameters
export function mapPlayerVars(params: Partial<YouTubeParams> = {}) {
  const youtubeParams = {
    autoplay: booleanToNumber(params.autoplay),
    mute: booleanToNumber(params.mute),
    loop: booleanToNumber(params.loop),
    cc_lang_pref: params.language,
    controls: booleanToNumber(!params.hideControls),
    fs: booleanToNumber(params.allowFullScreen),
    hl: params.language,
    modestbranding: booleanToNumber(params.removeBranding),
    rel: booleanToNumber(params.showRelatedVideos),
  };
  return omitBy(isNil, youtubeParams);
}

export function extractYouTubeId(src: string) {
  // From https://stackoverflow.com/a/6904504/4620154
  const regex =
    /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/ ]{11})/i;
  const match = regex.exec(src);
  return match && match[1];
}

export function isYouTube(src: string) {
  const youTubeId = extractYouTubeId(src);
  return !!youTubeId;
}

type PlayerOptionsParams = {
  width: number;
  height: number;
  src: string;
  onLoad: (..._args: any) => void;
};
export function getPlayerOptions({
  width,
  height,
  src,
  onLoad,
  ...rest
}: PlayerOptionsParams) {
  return {
    playerVars: mapPlayerVars(rest),
    videoId: extractYouTubeId(src),
    width,
    height,
  };
}

type StateHandlers = {
  onEnd: (..._args: any) => void;
  onPause: (..._args: any) => void;
  onPlay: (..._args: any) => void;
};
export function getStates({ onEnd, onPause, onPlay }: StateHandlers) {
  return {
    [YOUTUBE_PLAYER_STATES.ENDED]: onEnd,
    [YOUTUBE_PLAYER_STATES.PAUSED]: onPause,
    [YOUTUBE_PLAYER_STATES.PLAYING]: onPlay,
  };
}

export function checkIsControlsHidden(
  isMobileSlide: boolean,
  hideControls?: boolean,
) {
  return isMobileSlide ? true : hideControls;
}
