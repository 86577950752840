import React, { FC, useState, useRef } from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import { ClickEvent } from '@sumup/circuit-ui';

import MenuItem from '../../../MenuItem';
import { LanguageLink } from '../../interfaces';

import dataSelector from '~/shared/util/data-selector';
import isTouchDevice from '~/shared/util/isTouchDevice';

const languageMenuWrapperStyles = ({ theme }: { theme: Theme }) => css`
  position: relative;
  height: calc(${theme.spacings.nav.mobile} - ${theme.spacings.mega});
  display: flex;
  align-items: center;

  ${theme.mq.tera} {
    height: calc(${theme.spacings.nav.desktop} - ${theme.spacings.giga});
  }
`;
const LanguageMenuWrapper = styled('div')(languageMenuWrapperStyles);

const languageMenuActiveWrapperStyles = ({ theme }: { theme: Theme }) => css`
  border-left: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
  padding-left: min(${theme.spacings.giga}, 1vw);
  height: 100%;
  align-items: center;

  ${theme.mq.tera} {
    padding-left: ${theme.spacings.giga};
  }

  &[aria-current] {
    font-weight: normal;
  }
`;
const LanguageMenuActiveWrapper = styled('div')(
  languageMenuActiveWrapperStyles,
);

const languageMenuActiveMenuItemStyles = css`
  height: 100%;
  &[aria-current] {
    font-weight: normal;
  }
`;
const LanguageMenuActiveMenuItem = styled(MenuItem)(
  languageMenuActiveMenuItemStyles,
);

const languageMenuDesktopDropdownStyles = ({
  theme,
  isOpen,
}: {
  theme?: Theme;
  isOpen?: boolean;
}) => css`
  display: none;
  list-style: none;
  position: absolute;
  top: calc(${theme.spacings.nav.mobile} - (${theme.spacings.giga} / 2));
  left: 0;
  background-color: var(--cui-bg-normal);
  padding-right: ${theme.spacings.giga};
  width: calc(100% + ${theme.spacings.giga});
  box-shadow: 0px 10px 10px 0px rgba(0, 0, 0, 0.1);
  ${theme.mq.tera} {
    top: calc(${theme.spacings.nav.desktop} - (${theme.spacings.giga} / 2));
  }
  ${isOpen &&
  `
    display: flex;
    flex-direction: column;
  `}
`;
const LanguageMenuDesktopDropdown = styled('ul')(
  languageMenuDesktopDropdownStyles,
);

const languageMenuDesktopMenuListItemStyles = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
`;
const LanguageMenuDesktopMenuListItem = styled('li')(
  languageMenuDesktopMenuListItemStyles,
);

const languageMenuDesktopMenuItemStyles = css`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
`;
const LanguageMenuDesktopMenuItem = styled(MenuItem)(
  languageMenuDesktopMenuItemStyles,
);

export interface DesktopLanguageMenuProps {
  activeLink?: LanguageLink;
  dropdownLinks?: LanguageLink[];
}

export const DesktopLanguageMenu: FC<DesktopLanguageMenuProps> = ({
  activeLink,
  dropdownLinks = [],
}) => {
  const timer = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const hasTouch = isTouchDevice();

  if (!activeLink) {
    return null;
  }

  const handleMenuItemHover = (isHovered: boolean) => () => {
    if (hasTouch) {
      return;
    }

    if (isHovered) {
      clearTimeout(timer.current);
      setOpen(true);
    } else {
      timer.current = setTimeout(() => {
        setOpen(false);
      }, 500);
    }
  };
  const handleActiveLanguageClick = (e: ClickEvent) => {
    e.preventDefault();

    setOpen(!isOpen);
  };
  const handleLanguageMenuItemClick = () => {
    setOpen(false);
  };

  return (
    <LanguageMenuWrapper
      data-selector={dataSelector('desktop', 'language_menu')}
    >
      <LanguageMenuActiveWrapper
        data-selector={dataSelector('active_language_item', 'language_menu')}
        onMouseEnter={handleMenuItemHover(true)}
        onMouseLeave={handleMenuItemHover(false)}
        onFocus={handleMenuItemHover(true)}
        onBlur={handleMenuItemHover(false)}
        onTouchStart={handleMenuItemHover(true)}
      >
        <LanguageMenuActiveMenuItem
          data-selector={dataSelector(
            'active_language_item_link',
            'language_menu',
          )}
          destination={{ url: activeLink.url }}
          label={activeLink.label}
          showArrowIcon
          rotateArrowIcon={isOpen}
          disableSPANavigation
          onClick={handleActiveLanguageClick}
        />
        <LanguageMenuDesktopDropdown
          isOpen={isOpen}
          aria-hidden={!isOpen}
          hidden={!isOpen}
          data-selector={dataSelector('dropdown', 'language_menu')}
        >
          {dropdownLinks.map((dropdownLink, itemIndex) => (
            <LanguageMenuDesktopMenuListItem
              key={`language-item-${itemIndex}-${dropdownLink.label}`}
              data-selector={dataSelector('language_item', 'language_menu')}
            >
              <LanguageMenuDesktopMenuItem
                data-selector={dataSelector(
                  'language_item_link',
                  'language_menu',
                )}
                destination={{ url: dropdownLink.url }}
                label={dropdownLink.label}
                onClick={handleLanguageMenuItemClick}
              />
            </LanguageMenuDesktopMenuListItem>
          ))}
        </LanguageMenuDesktopDropdown>
      </LanguageMenuActiveWrapper>
    </LanguageMenuWrapper>
  );
};
