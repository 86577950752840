import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { DROPDOWN_HEIGHT, DIRECTIONS } from '../../constants';

const toggleStyles = ({ theme, isOpen, direction }) => css`
  background-color: transparent;
  border: ${theme.borderWidth.kilo} solid var(--cui-border-normal);
  border-radius: ${theme.borderRadius.mega};
  position: relative;
  transition: border-color ${theme.transitions.default};
  z-index: ${direction === DIRECTIONS.DOWN ? 'auto' : '11'};

  &:after {
    border-color: var(--cui-border-normal) transparent;
    border-style: solid;
    border-width: 4px 4px 0;
    content: '';
    position: absolute;
    right: ${theme.spacings.kilo};
    top: ${DROPDOWN_HEIGHT / 2}px;
    transform: translateY(-50%) ${isOpen && 'rotate(180deg)'};
    transition: transform ${theme.transitions.default};
  }
`;

const hoverStyles = ({ disableToggleHoverStyles }) =>
  !disableToggleHoverStyles &&
  css`
    &:hover {
      border-color: var(--cui-border-accent-hovered);
    }
  `;

const focusStyles = ({ disableToggleFocusStyles }) =>
  !disableToggleFocusStyles &&
  css`
    &:focus {
      border-color: var(--cui-border-accent-pressed);
    }
  `;

const Toggle = styled('div')(toggleStyles, hoverStyles, focusStyles);

export default Toggle;
