import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/fp/get';

import SumUpLogo from './components/SumUpLogo';

import SiteContext from '~/shared/providers/SiteContext';

const logoMap = {
  default: SumUpLogo,
};

/**
 * Displays the logo for the local SumUp brand.
 */
function Logo({ isShort = false, ...rest }) {
  const site = useContext(SiteContext);
  const locale = get('locale', site);
  const Component = logoMap[locale] || logoMap.default;
  return <Component isShort={isShort} {...rest} />;
}

Logo.propTypes = {
  isShort: PropTypes.bool,
};

/**
 * @component
 */
export default Logo;
