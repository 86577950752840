import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';

import FooterLogo from './components/FooterLogo';
import PrimaryMenu from './components/PrimaryMenu';
import SecondaryMenu from './components/SecondaryMenu';
import CountrySelect from './components/CountrySelect';
import SeoPagesSelect from './components/SeoPagesSelect';
import DisclaimerLine from './components/DisclaimerLine';
import { DO_NOT_SELL_LINK } from './constants';

import dataSelector from '~/shared/util/data-selector';
import { SIDEBAR_WIDTH } from '~/shared/components/SideBarNavigation/constants';
import { PageContext } from '~/shared/providers/PageContext';
import useViewportName from '~/shared/hooks/use-viewport-name';
import { isVisibleSideBar } from '~/shared/components/SideBarNavigation/SideBarNavigationService';

const DATA_SELECTOR = 'footer';
const footerWrapperStyles = ({ theme }) => css`
  position: relative;
  z-index: ${theme.zIndex.fullMediaBgMoveToFront};
`;
const FooterWrapper = styled('footer')(footerWrapperStyles);

const topSectionStyles = ({ theme }) => css`
  background-color: var(--cui-bg-subtle);
  padding-top: ${theme.spacings.exa};
  padding-bottom: ${theme.spacings.tera};

  ${theme.mq.untilKilo} {
    padding-bottom: ${theme.spacings.mega};
  }
`;
const bottomSectionStyles = () => css`
  background-color: var(--cui-bg-normal);
  color: var(--cui-fg-subtle);
`;
const withSideBarStyles = ({ showSideBar }) =>
  showSideBar &&
  css`
    padding-left: ${SIDEBAR_WIDTH};
  `;

const TopSection = styled('div')(topSectionStyles, withSideBarStyles);
const BottomSection = styled('div')(bottomSectionStyles, withSideBarStyles);

function filterEmptyLinks(links = []) {
  return links.filter((link) => !isEmpty(link));
}

const gridStyles = ({ theme, hasLinks }) => css`
  display: grid;
  grid-auto-columns: minmax(0, 1fr);

  ${hasLinks
    ? `
    grid-template-areas:
    'logo'
    'links'
    'seo'
    'country';

    ${theme.mq.kilo} {
      grid-template-areas:
        'logo logo'
        'links links'
        'seo country';
    }

    ${theme.mq.mega} {
      grid-template-areas:
        'logo logo logo logo'
        'links links links seo'
        'links links links country';
    }`
    : `
    grid-template-areas:
    'logo'
    'seo'
    'country';

    ${theme.mq.kilo} {
      grid-template-areas:
        'logo logo'
        'seo country';
    }

    ${theme.mq.mega} {
      grid-template-areas:
        'logo logo logo seo'
        'logo logo logo country';
    }`}
`;
const StyledGrid = styled(Grid)(gridStyles);
const seoPagesSelectStyles = ({ theme }) => css`
  grid-area: seo;

  ${theme.mq.kiloToMega} {
    padding-right: 16px;
  }

  ${theme.mq.mega} {
    padding-left: 16px;
  }
`;
const StyledSeoPagesSelect = styled(SeoPagesSelect)(seoPagesSelectStyles);
const countrySelectStyles = ({ theme }) => css`
  grid-area: country;

  ${theme.mq.kilo} {
    padding-left: 16px;
  }
`;
const StyledCountrySelect = styled(CountrySelect)(countrySelectStyles);

function Footer({
  localizedUrls = {},
  disclaimer,
  countryDropdownLabel,
  learnMoreDropdownLabel,
  primaryLinks = [],
  secondaryLinks = [],
  contentType,
  name,
  id,
  primaryMenuLabel,
  secondaryMenuLabel,
}) {
  const { sideBarNavigation } = useContext(PageContext);
  const viewportName = useViewportName();
  const showSideBar = isVisibleSideBar(viewportName, sideBarNavigation);
  const [additionLinks, setAdditionalLinks] = useState([]);

  // returned data can contain empty objects
  const filteredPrimaryLinks = filterEmptyLinks(primaryLinks);
  const filteredSecondaryLinks = filterEmptyLinks(secondaryLinks);
  const hasPrimaryLinks = filteredPrimaryLinks.length > 0;

  useEffect(() => {
    const otGeoData = window?.OneTrust?.getGeolocationData() ?? {};
    // Add "Do not sell my Personal Information" link to the footer if the user is in California
    if (otGeoData?.state?.toLowerCase() === 'ca') {
      setAdditionalLinks([DO_NOT_SELL_LINK]);
    }
  }, []);

  return (
    <FooterWrapper data-selector={dataSelector('section', DATA_SELECTOR)}>
      <TopSection
        data-selector={dataSelector('top-section', DATA_SELECTOR)}
        showSideBar={showSideBar}
      >
        <StyledGrid hasLinks={hasPrimaryLinks}>
          <FooterLogo
            trackingContentEntry={{
              contentType,
              contentEntryName: name,
              contentEntryId: id,
            }}
          />
          <PrimaryMenu
            data-selector={dataSelector('primary-menu', DATA_SELECTOR)}
            label={primaryMenuLabel}
            links={filteredPrimaryLinks}
            trackingContentEntry={{
              contentType,
              contentEntryName: name,
              contentEntryId: id,
            }}
          />
          <StyledSeoPagesSelect
            id="select-page-tablet"
            data-selector={dataSelector('seo-pages-select', DATA_SELECTOR)}
            label={learnMoreDropdownLabel}
          />
          <StyledCountrySelect
            id="select-country-tablet"
            label={countryDropdownLabel}
            localizedUrls={localizedUrls}
            data-selector={dataSelector('country-select', DATA_SELECTOR)}
          />
        </StyledGrid>
      </TopSection>

      <BottomSection
        data-selector={dataSelector('bottom-section', DATA_SELECTOR)}
        showSideBar={showSideBar}
      >
        <Grid>
          {!isEmpty(filteredSecondaryLinks) && (
            <Row>
              <Col span="12">
                <SecondaryMenu
                  data-selector={dataSelector('secondary-menu', DATA_SELECTOR)}
                  label={secondaryMenuLabel}
                  links={[...filteredSecondaryLinks, ...additionLinks]}
                  trackingContentEntry={{
                    contentType,
                    contentEntryName: name,
                    contentEntryId: id,
                  }}
                />
              </Col>
            </Row>
          )}

          {disclaimer && (
            <Row>
              <Col span="12">
                <DisclaimerLine text={disclaimer} />
              </Col>
            </Row>
          )}
        </Grid>
      </BottomSection>
    </FooterWrapper>
  );
}

Footer.propTypes = {
  localizedUrls: PropTypes.object,
  primaryLinks: PropTypes.array,
  secondaryLinks: PropTypes.array,
  disclaimer: PropTypes.string,
  countryDropdownLabel: PropTypes.string,
  learnMoreDropdownLabel: PropTypes.string,
  contentType: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  /**
   * Accessibility label for primary menu
   */
  primaryMenuLabel: PropTypes.string,
  /**
   * Accessibility label for secondary menu
   */
  secondaryMenuLabel: PropTypes.string,
};

export default Footer;
