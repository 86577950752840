import isEmpty from 'lodash/fp/isEmpty';
import values from 'lodash/fp/values';
import min from 'lodash/fp/min';
import max from 'lodash/fp/max';
import get from 'lodash/fp/get';

import { CONTENTFUL_PAGE_TYPES } from '~/shared/constants/pages';

export const TOP_SECTION_INDEX = 0;

export function getProductSchema({ pageType = '', page = {}, products = {} }) {
  if (
    pageType !== CONTENTFUL_PAGE_TYPES.GENERIC_PAGE ||
    isEmpty(products) ||
    // Skip generic product schema for pages with their own product schema
    page.markupSchemaType === 'Product' ||
    page.markupSchema?.['@type'] === 'Product'
  ) {
    return null;
  }

  const activeProducts = values(products).filter((product) => product.isActive);

  if (isEmpty(activeProducts)) {
    return null;
  }

  const schema =
    activeProducts.length === 1
      ? getSingleProductSchema({
          product: activeProducts[0],
          page,
        })
      : getAggregateSchema({
          products: activeProducts,
          page,
        });

  return schema;
}

export function getSingleProductSchema({ product = {}, page = { route: {} } }) {
  const { productName = '', description = '' } = product;
  const { url = '' } = page.route;

  return JSON.stringify({
    '@context': 'https://schema.org/',
    '@type': 'Product',
    'name': productName,
    description,
    'image': getImageArray([product]),
    'brand': {
      '@type': 'Thing',
      'name': 'SumUp',
    },
    'offers': {
      '@type': 'Offer',
      url,
      'price': getPrice(product),
      'priceCurrency': product.currencyCode,
      'itemCondition': 'https://schema.org/NewCondition',
      'availability': 'https://schema.org/InStock',
    },
  });
}

export function getPrice(product = {}) {
  const installments = get('installments', product) || 1;
  const price = product.hasPromo
    ? get('original.promoPrice', product)
    : get('original.price', product);

  return parseFloat((price * installments).toFixed(2));
}

export function getAggregateSchema({ products = [], page = {} }) {
  const [firstProduct] = products;
  const productPrices = products.map(getPrice);
  const name = getAggregateName(page);

  return JSON.stringify({
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name,
    'image': getImageArray(products),
    'brand': {
      '@type': 'Thing',
      'name': 'SumUp',
    },
    'offers': {
      '@type': 'AggregateOffer',
      'lowPrice': min(productPrices),
      'highPrice': max(productPrices),
      'priceCurrency': get('currencyCode', firstProduct),
    },
  });
}

export function getAggregateName({ sections = [], metaTitle = '' }) {
  if (isEmpty(sections)) {
    return metaTitle;
  }

  const headline = get([TOP_SECTION_INDEX, 'headline'], sections);

  if (isEmpty(headline)) {
    return metaTitle;
  }

  return headline;
}

export function getImageArray(products = []) {
  return products
    .filter((product) => !isEmpty(product.image))
    .map((product) => `https:${product.image}`);
}
