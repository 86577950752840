import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';

import NavAnchor from '../../../NavAnchor';

import * as LanguageSwitcherService from './LanguageSwitcherService';

import {
  websitePropType,
  countryPropType,
  trackingContentEntryPropType,
} from '~/shared/util/shared-prop-types';
import Link from '~/shared/components/Link';
import {
  SELECTED_LOCALE_COOKIE,
  SELECTED_LOCALE_COOKIE_MAX_AGE_DAYS,
} from '~/shared/constants/cookies';
import useCookies from '~/shared/hooks/use-cookies';

const listItemStyles = ({ theme }) => css`
  display: inline-block;
  margin-left: ${theme.spacings.byte};
  margin-right: -${theme.spacings.byte};

  ${theme.mq.kilo} {
    padding-left: ${theme.spacings.bit};
    padding-right: ${theme.spacings.bit};
  }
`;

const ListItem = styled('li')(listItemStyles);

const anchorBaseStyles = ({ theme }) => css`
  padding-left: ${theme.spacings.byte};
  padding-right: ${theme.spacings.byte};

  ${theme.mq.nav} {
    padding-left: ${theme.spacings.byte};
    padding-right: ${theme.spacings.byte};
  }
`;

const StyledNavAnchor = styled(NavAnchor)(anchorBaseStyles);

/**
 * Displays links to translations of a page in multilingual countries.
 */
function LanguageSwitcher({
  websites = [],
  country = {},
  localizedUrls = {},
  locale,
  trackingContentEntry = {},
  ...rest
}) {
  const [, setCookie] = useCookies();
  const { locales = [], multilingual } = country;

  if (!multilingual) {
    return null;
  }

  const links = LanguageSwitcherService.getLanguageLinks(
    locales,
    websites,
    localizedUrls,
  );

  if (isEmpty(links)) {
    return null;
  }

  const handleClick = (linkLocale) => () => {
    setCookie(SELECTED_LOCALE_COOKIE, linkLocale?.toLowerCase(), {
      expires: SELECTED_LOCALE_COOKIE_MAX_AGE_DAYS,
    });
  };

  return (
    <ul {...rest}>
      {links.map((link) => (
        <ListItem key={link.locale}>
          <Link
            href={link.url}
            onClick={handleClick(link.locale)}
            target="_self"
            trackingContentEntry={trackingContentEntry}
            locale={false}
          >
            <StyledNavAnchor isCurrent={link.locale === locale}>
              {link.label}
            </StyledNavAnchor>
          </Link>
        </ListItem>
      ))}
    </ul>
  );
}

LanguageSwitcher.propTypes = {
  websites: PropTypes.arrayOf(PropTypes.shape(websitePropType)),
  country: PropTypes.shape(countryPropType),
  locale: PropTypes.string,
  localizedUrls: PropTypes.object,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
};

/**
 * @component
 */
export default LanguageSwitcher;
