/* eslint-disable max-len */

import { ConsentStatusObject } from '~/shared/services/onetrust';

export const GTM_URL = 'www.googletagmanager.com/gtm.js?id=';

export const script = (id) => `
  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
  '//${GTM_URL}'+i+dl;f.parentNode.insertBefore(j,f);
  })(window,document,'script','dataLayer','${id}');
`;

export const noScript = (id) => `
  <iframe src="//www.googletagmanager.com/ns.html?id=${id}" height="0" width="0" style="display:none;visibility:hidden"></iframe>
`;

// as per documentation -
// https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/3217719404/Website+OneTrust+Cookie+Compliance+GTM+Integration#Establish-consent-states-via-the-gtag()-API
// https://my.onetrust.com/s/article/UUID-d81787f6-685c-2262-36c3-5f1f3369e2a7
export const setComplianceDefaults = (consentStatus: ConsentStatusObject) => `
  window.dataLayer = window.dataLayer || [];
  function gtag(){ dataLayer.push(arguments); }

  gtag('consent', 'default', {
    'analytics_storage': '${consentStatus.performance ? 'granted' : 'denied'}',
    'ad_storage': '${consentStatus.targeting ? 'granted' : 'denied'}',
    'ad_user_data': '${consentStatus.targeting ? 'granted' : 'denied'}',
    'ad_personalization': '${consentStatus.targeting ? 'granted' : 'denied'}'
  });

  ${!consentStatus.targeting ? "gtag('set', 'ads_data_redaction', true);" : ''}
`;
