import get from 'lodash/fp/get';
import {
  Event as SentryEvent,
  EventHint as SentryEventHint,
} from '@sentry/nextjs';

import { GTM_URL } from '~/shared/scripts/gtm';

export function getFirstErrorFrame(sentryEvent: SentryEvent = {}) {
  return get('exception.values[0].stacktrace.frames[0]', sentryEvent);
}

export function isNextjsBundleError(sentryEvent: SentryEvent = {}) {
  const firstFrame = getFirstErrorFrame(sentryEvent) || {};

  return firstFrame.filename?.indexOf('_next/static') > -1;
}

export function isGTMError(sentryEventHint: SentryEventHint = {}) {
  const originalStack =
    (sentryEventHint.originalException as Error)?.stack || '';

  return originalStack.indexOf(GTM_URL) > -1;
}
