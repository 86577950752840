import { createContext } from 'react';

export type RequestType = {
  protocol: string;
  hostname: string;
  host: string;
  href: string;
  origin: string;
  pathname: string;
  hash?: string;
  query: Record<string, string | string[]>;
  userAgent: string;
  cookies: Record<string, string>;
  headers: Record<string, string | string[]>;
  contentType?: string;
  id?: string | number;
};

/**
 * Passes down request data. Updated on page change.
 */
const RequestContext = createContext<RequestType>({
  host: '',
  hostname: '',
  href: '',
  origin: '',
  pathname: '',
  protocol: '',
  query: {},
  userAgent: '',
  cookies: {},
  headers: {},
});

export default RequestContext;
