import { ExternalLink } from '../../interfaces';

import { RequestType } from '~/shared/providers/RequestContext/RequestContext';
import { formatURLToOneDomain } from '~/shared/services/LinkService';
import { addLeadingSlash, addTrailingSlash } from '~/shared/services/url';

export type ExternalLinkHighlighted = ExternalLink & {
  isHighlighted: boolean;
};

/*
 * make sure only one link in offer switcher is highlighted at a time.
 * e.g. if pathnames of both links intersect (e.g. Business -> '/a' vs. Private -> '/a/b)
 * prioritize highlighting the longer link in the list first.
 */
export function markHighlightedLinks(
  links: ExternalLink[],
  request: RequestType,
  siteLocale: string,
): ExternalLinkHighlighted[] {
  const linksWithHighlightsMark: ExternalLinkHighlighted[] = links.map(
    (link) => ({
      ...link,
      isHighlighted: false,
    }),
  );

  // sort descending - longer items first
  const sortedLinks = links
    .map((link) => {
      const formattedUrl = formatURLToOneDomain(
        new URL(link.url, request?.origin),
        request,
        siteLocale,
      ) as URL;
      return { ...link, formattedUrl, isHighlighted: false };
    })
    .sort(
      (a, b) => b.formattedUrl.pathname.length - a.formattedUrl.pathname.length,
    );

  for (const sortedLink of sortedLinks) {
    const shouldHighlight = request?.pathname?.startsWith(
      addLeadingSlash(addTrailingSlash(sortedLink.formattedUrl.pathname)),
    );

    if (shouldHighlight) {
      const targetLink = linksWithHighlightsMark.find(
        (link) => link.id === sortedLink.id,
      );
      if (targetLink) {
        targetLink.isHighlighted = true;
      }
      break;
    }
  }

  return linksWithHighlightsMark;
}
