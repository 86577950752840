import React, {
  FC,
  Fragment,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import Tagger from '@elbwalker/tagger';
import { ClickEvent } from '@sumup/circuit-ui';
import { lock, unlock } from 'tua-body-scroll-lock';

import { MenuItem as MenuItemType } from '../../../../interfaces';
import MenuItem from '../../../MenuItem';
import { DATA_SELECTOR } from '../../constants';

import dataSelector from '~/shared/util/data-selector';
import { NAVIGATION_EVENTS } from '~/shared/services/optimizely/navigation-revamp-experiment/constants';
import { ACTIONS, ENTITIES, TRIGGERS } from '~/shared/constants/tracking';

const DROPDOWN_TRANSITION = '0.3s ease-in-out';
const DROPDOWN_ITEM_HEIGHT = '48px';

const mobileContainerStyles = ({ theme }: { theme: Theme }) => css`
  display: none;
  height: 100%;

  ${theme.mq.untilMega} {
    display: flex;
    align-items: center;
  }
`;
const MobileContainer = styled('div')(mobileContainerStyles);

const mobileHighlightWrapperStyles = css`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  a:hover,
  a:active {
    color: inherit;
  }
`;
const MobileHighlightWrapper = styled('div')(mobileHighlightWrapperStyles);

const mobileHighlightPrefixStyles = ({
  theme,
  isOpen,
}: {
  theme?: Theme;
  isOpen?: boolean;
}) => css`
  ${theme.typography.headline.four}
  font-weight: ${theme.fontWeight.bold};
  padding: 0 ${theme.spacings.byte};
  visibility: visible;
  opacity: 1;
  transition:
    visibility ${DROPDOWN_TRANSITION},
    opacity ${DROPDOWN_TRANSITION};
  ${isOpen &&
  `
    visibility: hidden;
    opacity: 0;
  `}
`;
const MobileHighlightPrefix = styled('span')(mobileHighlightPrefixStyles);

const mobileHighlightMenuItemStyles = ({
  theme,
  isOpen,
  isReady,
}: {
  theme?: Theme;
  isOpen?: boolean;
  isReady?: boolean;
}) => css`
  display: none;
  width: 100%;
  pointer-events: none;
  ${isReady &&
  `
    pointer-events: auto;
  `}
  ${theme.typography.headline.four}
  &[aria-current='page'] {
    display: flex;
  }
  span {
    visibility: visible;
    opacity: 1;
    transition:
      visibility ${DROPDOWN_TRANSITION},
      opacity ${DROPDOWN_TRANSITION};
    ${isOpen &&
    `
    visibility: hidden;
    opacity: 0;
    `}
  }
  svg {
    position: absolute;
    right: 0;
  }
`;
const MobileHighlightMenuItem = styled(MenuItem)(mobileHighlightMenuItemStyles);

const mobileDropdownWrapperStyles = ({
  theme,
  isOpen,
  itemsCount,
}: {
  theme?: Theme;
  isOpen?: boolean;
  itemsCount?: number;
}) => css`
  overflow-y: scroll;
  pointer-events: none;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: ${theme.spacings.nav.mobile};
  background-color: var(--cui-bg-normal);
  height: 0;
  transition: height ${DROPDOWN_TRANSITION};
  z-index: ${theme.zIndex.navigation};
  box-shadow: 0 1px 2px 0 rgba(12, 15, 20, 0.07);

  ${isOpen &&
  `
    height: calc((${DROPDOWN_ITEM_HEIGHT} * ${itemsCount}) + (${theme.spacings.byte} * 2)) ;
    pointer-events: auto;
  `}
`;
const MobileDropdownWrapper = styled('div')(mobileDropdownWrapperStyles);

const mobileDropdownListStyles = ({
  theme,
  isOpen,
}: {
  theme?: Theme;
  isOpen?: boolean;
}) => css`
  list-style: none;
  display: flex;
  flex-direction: column;
  padding: ${theme.spacings.byte} 0;
  visibility: hidden;
  opacity: 0;
  transition:
    visibility ${DROPDOWN_TRANSITION},
    opacity ${DROPDOWN_TRANSITION};
  ${isOpen &&
  `
    visibility: visible;
    opacity: 1;
    `}
`;
const MobileDropdownList = styled('ul')(mobileDropdownListStyles);

const mobileDropdownListItemStyles = ({ theme }: { theme: Theme }) => css`
  padding: 0 calc(${theme.spacings.giga} * 2);
  height: ${theme.spacings.exa};
`;
const MobileDropdownListMenuItem = styled(MenuItem)(
  mobileDropdownListItemStyles,
);

export interface MobileTableOfContentsProps {
  items?: MenuItemType[];
  isOpen?: boolean;
  toggleMobileMenu?: () => void;
}

export const MobileTableOfContents: FC<MobileTableOfContentsProps> = ({
  items = [],
  isOpen = false,
  toggleMobileMenu = () => {},
}) => {
  const dropdownRef = useRef();
  const [isHighlightItemReady, setHighlightItemReady] = useState(false);

  const tagger = useMemo(() => {
    const taggerInfo = {
      action: ACTIONS.CLICK,
      entity: ENTITIES.NAV,
      trigger: TRIGGERS.CLICK,
    };
    const { action, entity, trigger } = taggerInfo;
    const t = Tagger();

    return {
      ...t.entity(entity),
      ...t.action(trigger, action),
    };
  }, []);

  // make links in highlight items clickable only after component is loaded
  useEffect(() => setHighlightItemReady(true), []);

  const handleMobileMenuToggle = (e: ClickEvent) => {
    e.preventDefault();
    e.stopPropagation();

    toggleMobileMenu();

    if (!isOpen) {
      lock(dropdownRef.current);
    } else {
      unlock(dropdownRef.current);
    }
  };

  const handleMenuItemClick = () => {
    toggleMobileMenu();
    unlock(dropdownRef.current);
  };

  return (
    <MobileContainer>
      {items.length && (
        <Fragment>
          <MobileHighlightWrapper>
            <MobileHighlightPrefix isOpen={isOpen}>/</MobileHighlightPrefix>
            {items.map((item, index) => (
              <MobileHighlightMenuItem
                {...item}
                key={`toc-mobile-highlight-item-${item.label}-${index}`}
                data-selector={dataSelector(
                  'highlight_menu_item',
                  DATA_SELECTOR,
                )}
                isOpen={isOpen}
                isReady={isHighlightItemReady}
                onClick={handleMobileMenuToggle}
                rotateArrowIcon={isOpen}
                showArrowIcon
                disableSPANavigation
              />
            ))}
          </MobileHighlightWrapper>
          <MobileDropdownWrapper
            ref={dropdownRef}
            isOpen={isOpen}
            itemsCount={items?.length}
            aria-hidden={!isOpen}
            data-selector={dataSelector('dropdown_wrapper', DATA_SELECTOR)}
          >
            <MobileDropdownList isOpen={isOpen}>
              {items.map((item, index) => (
                <li key={`toc-mobile-dropdown-item-${item.label}-${index}`}>
                  <MobileDropdownListMenuItem
                    {...item}
                    data-selector={dataSelector(
                      'dropdown_menu_item',
                      DATA_SELECTOR,
                    )}
                    onClick={handleMenuItemClick}
                    optimizelyFullStackClickEvents={[
                      {
                        eventName: NAVIGATION_EVENTS.THIRD_LEVEL,
                        eventTags: { labels: item.label },
                      },
                    ]}
                    data-elbcontext={`component:${DATA_SELECTOR}`}
                    data-elb="nav"
                    data-elb-nav={`description:${item.label}@${DATA_SELECTOR}`}
                    {...tagger}
                  />
                </li>
              ))}
            </MobileDropdownList>
          </MobileDropdownWrapper>
        </Fragment>
      )}
    </MobileContainer>
  );
};
