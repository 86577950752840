import fetch from 'isomorphic-unfetch';
import isEmpty from 'lodash/fp/isEmpty';

import { RequestError } from '~/shared/util/errors';
import { FetchTracer } from '~/shared/util/tracing/interfaces';

// NOTE: using open telemetry only in server-side to not increase client-side js bundles size
// request tracer adds necessary headers that connect traces of all website projects in Honeycomb
let createFetchTracer = (_url: string): FetchTracer | undefined => undefined;
if (!process.browser) {
  createFetchTracer =
    // eslint-disable-next-line
    require('../../util/tracing/fetch-tracing').createFetchTracer;
}

export const API_CALLERS = {
  NEXTJS_API_ROUTE: 'Next.js API Route',
  NEXTJS_PAGE: 'Next.js Page',
} as const;
type ApiCallersKeyType = keyof typeof API_CALLERS;
export type ApiCallersValueType = (typeof API_CALLERS)[ApiCallersKeyType];

const generateHeaders = (
  vercelPasswordProtectionCookie?: string,
  calledFrom?: ApiCallersValueType,
) => {
  const headers: Record<string, string> = {};
  let hasContent = false;

  if (vercelPasswordProtectionCookie) {
    headers.cookie = vercelPasswordProtectionCookie;
    hasContent = true;
  }

  if (
    calledFrom === API_CALLERS.NEXTJS_API_ROUTE &&
    process.env.WAF_HEADER_NAME &&
    process.env.WAF_HEADER_VALUE
  ) {
    headers[process.env.WAF_HEADER_NAME] = process.env.WAF_HEADER_VALUE;
    hasContent = true;
  }

  return hasContent ? headers : undefined;
};

interface MakeFetchOptions {
  vercelPasswordProtectionCookie?: string;
  calledFrom?: ApiCallersValueType;
  headers?: Record<string, string>;
}

const makeFetch = (url: string, options: MakeFetchOptions = {}) => {
  const {
    vercelPasswordProtectionCookie = '',
    calledFrom = API_CALLERS.NEXTJS_PAGE,
    headers: optionsHeaders = {},
  } = options;
  const fetchTracer = createFetchTracer(url);
  const generatedHeaders = generateHeaders(
    vercelPasswordProtectionCookie,
    calledFrom,
  );
  const headers = {
    ...fetchTracer?.tracingHeaders,
    ...generatedHeaders,
    ...optionsHeaders,
  };
  const fetchOptions = !isEmpty(headers) ? { headers } : undefined;

  return fetch(url, fetchOptions).then((res) => {
    if (fetchTracer) {
      fetchTracer.recordResponseTrace(res);
    }

    if (!res.ok) {
      throw new RequestError({
        status: res.status,
        data: {
          error: {
            message: `${res.statusText} (${url})`,
          },
        },
      });
    }

    // eslint-disable-next-line no-console
    console.log(
      JSON.stringify({
        url,
        calledFrom,
        status: res.status,
        message: 'api response status',
      }),
    );

    return res;
  });
};

export const getEntry = (url: string, options?: MakeFetchOptions) =>
  makeFetch(url, options).then((res) =>
    res.json().then((data) => Promise.resolve({ ...data })),
  );

export const getTextData = (url: string, options?: MakeFetchOptions) =>
  makeFetch(url, options).then((res) =>
    res.text().then((text) => Promise.resolve(text)),
  );
