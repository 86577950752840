import { FC, useContext } from 'react';
import { Anchor, Body } from '@sumup/circuit-ui';
import styled from '@emotion/styled';

import Image from '~/shared/components/Image';
import {
  LineItem as LineItemType,
  Order,
} from '~/domains/shop/providers/ShopProvider';
import SiteContext from '~/shared/providers/SiteContext';

const IMAGE_SIZE = 158;

const LineItemContainer = styled.div`
  display: flex;
  margin-bottom: var(--cui-spacings-giga);
  column-gap: var(--cui-spacings-byte);

  &:last-child {
    margin-bottom: 0;
  }
`;

// @ts-expect-error custom image does not meet all requirements for styled argument,
// but is a valid component
const StyledImage = styled(Image)`
  border-radius: var(--cui-border-radius-kilo);
  width: 125px;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

const ItemInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Controls = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: var(--cui-spacings-kilo);
`;

const QuantityInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: var(--cui-spacings-byte);
  border-right: var(--cui-border-width-kilo) solid var(--cui-border-divider);
  padding-right: var(--cui-spacings-byte);
  margin-right: var(--cui-spacings-byte);
`;

const PriceContainer = styled.div`
  display: flex;
`;

const FullPrice = styled(Body)`
  text-decoration: line-through;
  color: var(--cui-fg-subtle);
  margin-right: var(--cui-spacings-bit);
`;

export type LineItemProps = {
  lineItem: LineItemType;
  removeFromCart: (id: string) => Promise<Order>;
  isSubscriptionPlan?: boolean;
};

export const LineItem: FC<LineItemProps> = (lineItemProps) => {
  const {
    lineItem,
    removeFromCart,
    isSubscriptionPlan = false,
  } = lineItemProps;
  const { cartLabels } = useContext(SiteContext);

  return (
    <LineItemContainer>
      <StyledImage
        src={lineItem.image}
        width={IMAGE_SIZE}
        height={IMAGE_SIZE}
        alt="product image"
      />
      <InfoContainer>
        <div>
          <ItemInfo>
            <Body as="p" size="two">
              {lineItem.name}
            </Body>
            <PriceContainer>
              {lineItem.discountCents ? (
                <FullPrice as="p" size="two" variant="subtle">
                  {lineItem.fullPrice}
                </FullPrice>
              ) : null}
              <Body as="p" size="two" variant="highlight">
                {lineItem.price}
              </Body>
            </PriceContainer>
          </ItemInfo>
          {!isSubscriptionPlan && lineItem.vatPrice ? (
            <ItemInfo>
              <Body as="p" size="two" variant="subtle">
                {cartLabels.vat}
              </Body>
              <Body as="p" size="two" variant="subtle">
                {lineItem.vatPrice}
              </Body>
            </ItemInfo>
          ) : null}
        </div>

        <Controls>
          {!isSubscriptionPlan && (
            <QuantityInfoWrapper>
              <Body as="p" size="two" variant="subtle">
                {cartLabels.quantity}:
              </Body>
              <Body as="p" size="two" variant="subtle">
                {lineItem.quantity}
              </Body>
            </QuantityInfoWrapper>
          )}

          <Anchor
            onClick={() => removeFromCart(lineItem.id)}
            size="two"
            variant="subtle"
            as={'button'}
            data-selector="remove-button@cart"
          >
            {cartLabels.removeItem}
          </Anchor>
        </Controls>
      </InfoContainer>
    </LineItemContainer>
  );
};
