import React, { FC, useContext, useMemo, useRef } from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import { Anchor, Button, Hamburger, Hr } from '@sumup/circuit-ui';
import Tagger from '@elbwalker/tagger';
import { lock, unlock } from 'tua-body-scroll-lock';

import { ExternalLink, MenuItem as MenuItemType } from '../../interfaces';
import {
  DATA_SELECTOR,
  LINK_SELECTOR,
  SHOP_CATALOG_PAGES_ZONE,
} from '../../constants';
import LanguageMenu from '../LanguageMenu';
import ShoppingCart from '../ShoppingCart';
import OfferSwitcher from '../OfferSwitcher';

import MobileMenuItem from './components/MobileMenuItem';

import Link from '~/shared/components/Link';
import dataSelector from '~/shared/util/data-selector';
import SiteContext from '~/shared/providers/SiteContext';
import useWindowSize from '~/shared/hooks/use-window-size';
import { NAVIGATION_EVENTS } from '~/shared/services/optimizely/navigation-revamp-experiment/constants';
import { ACTIONS, ENTITIES, TRIGGERS } from '~/shared/constants/tracking';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';
import { PageContext } from '~/shared/providers/PageContext';

const LOGIN_AREA_HEIGHT = '77px';

export interface MobileMenuProps {
  items: MenuItemType[];
  staticLinks: Record<string, MenuItemType>;
  isOpen?: boolean;
  toggleMenu?: () => void;
  shopIntegration?: boolean;
  offerSwitcher?: ExternalLink[];
  isSticky?: boolean;
  stickyCtaItem?: MenuItemType;
}

const containerStyles = ({ theme }: { theme: Theme }) => css`
  display: none;

  ${theme.mq.untilMega} {
    display: flex;
    justify-content: space-between;
    justify-content: end;
    align-items: center;
    column-gap: ${theme.spacings.byte};
    width: auto;
  }
`;

const Container = styled('div')(containerStyles);

const mobileMenuContainerStyles = ({
  theme,
  isOpen,
}: {
  theme?: Theme;
  isOpen: boolean;
}) => css`
  position: absolute;
  top: ${theme.spacings.nav.mobile};
  left: 0;
  right: 0;
  height: 0;
  background-color: var(--cui-bg-normal);
  z-index: ${theme.zIndex.navigation};
  display: none;

  ${isOpen &&
  `
    display: block;
    border-top: ${theme.borderWidth.kilo} solid var(--cui-border-subtle);
    box-shadow: 0 1px 2px 0 rgba(12, 15, 20, 0.07) inset;
    height: calc(100vh - ${theme.spacings.nav.mobile});
  `}
`;

const MobileMenuContainer = styled('div')(mobileMenuContainerStyles);

const navWrapperStyles = () => css`
  width: 100%;
  height: 100%;
  position: relative;
`;

const NavWrapper = styled('div')(navWrapperStyles);

const navStyles = ({
  theme,
  isOpen,
  hasLoginArea,
  windowHeight,
}: {
  theme?: Theme;
  isOpen?: boolean;
  hasLoginArea?: boolean;
  windowHeight?: number;
}) => {
  // use window height bc 100vh doesn't work properly on iOS
  const viewportHeight = windowHeight ? `${windowHeight}px` : '100%';
  const loginAreaHeight = hasLoginArea ? LOGIN_AREA_HEIGHT : '0px';
  const navHeight = `calc(${viewportHeight} - ${loginAreaHeight} - ${theme.spacings.nav.mobile})`;

  return css`
    display: none;
    overflow-y: scroll;
    height: ${navHeight};
    max-height: ${navHeight};

    ${isOpen &&
    `
   display: flex; 
  `}
  `;
};

const Nav = styled('nav')(navStyles);

const listStyles = ({ theme }: { theme?: Theme }) => css`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${theme.spacings.mega};
  list-style: none;
  padding: ${theme.spacings.kilo} ${theme.spacings.giga};
`;

const StyledList = styled('ul')(listStyles);

const languageMenuItemStyles = ({ theme }: { theme?: Theme }) => css`
  margin-top: auto;
  padding-bottom: ${theme.spacings.kilo};
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;
const LanguageMenuItem = styled('li')(languageMenuItemStyles);

const anchorStyles = ({ theme }: { theme: Theme }) => css`
  font-weight: ${theme.fontWeight.bold};
  color: var(--cui-fg-normal);
  text-decoration: none;
  font-size: ${theme.typography.headline.four.fontSize};

  &:visited {
    color: var(--cui-fg-normal);
  }
`;

const StyledAnchor = styled(Anchor)(anchorStyles);

const loginAreaStyles = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacings.mega} ${theme.spacings.kilo};
  padding-top: 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: ${LOGIN_AREA_HEIGHT};
  text-align: center;
  background-color: var(--cui-bg-normal);
  z-index: ${theme.zIndex.navigation};
`;

const LoginArea = styled('div')(loginAreaStyles);

const styledButtonStyles = ({ theme }: { theme: Theme }) => css`
  margin-top: ${theme.spacings.kilo};
`;

const StyledButton = styled(Button)(styledButtonStyles);

const hrStyles = () => css`
  margin: 0;
`;

const StyledHr = styled(Hr)(hrStyles);

export const MobileMenu: FC<MobileMenuProps> = ({
  items = [],
  staticLinks,
  isOpen,
  toggleMenu,
  shopIntegration = false,
  offerSwitcher,
  stickyCtaItem,
  isSticky,
}) => {
  const page = useContext(PageContext);
  const { experiments } = useOptimizelyData();
  const navRef = useRef();
  const windowSize = useWindowSize();
  const { shop, help, login } = staticLinks;
  const {
    accessibilityLabels = {},
    country = {},
  }: {
    accessibilityLabels: { [key: string]: string };
    country: { multilingual?: boolean };
  } = useContext(SiteContext);
  const tagger = useMemo(() => {
    const taggerInfo = {
      action: ACTIONS.CLICK,
      entity: ENTITIES.NAV,
      trigger: TRIGGERS.CLICK,
    };
    const { action, entity, trigger } = taggerInfo;
    const t = Tagger();

    return {
      ...t.entity(entity),
      ...t.action(trigger, action),
    };
  }, []);

  const handleHamburgerClick = React.useCallback(() => {
    if (isOpen) {
      unlock(navRef.current);
    } else {
      lock(navRef.current);
    }
    toggleMenu();
  }, [isOpen, toggleMenu]);

  const handleLinkClick = (hasGroups) => {
    if (!hasGroups) {
      toggleMenu();
      unlock(navRef.current);
    }
  };

  const handleLanguageClick = () => {
    toggleMenu();
    unlock(navRef.current);
  };

  const hasLoginArea = !!(login?.label && login?.destination?.url);
  const hasShopLink = !!(shop?.label && shop?.destination?.url);
  const isOnShopCatalogPage = page?.route?.zoneId === SHOP_CATALOG_PAGES_ZONE;
  const hasStickyCta = !!(
    isSticky &&
    stickyCtaItem?.label &&
    stickyCtaItem?.destination?.url
  );
  // show shop link everywhere except shop catalog pages with sticky cta in nav
  const shouldShowShopLink =
    hasShopLink && !isOnShopCatalogPage && !hasStickyCta;

  return (
    <Container>
      {shopIntegration && <ShoppingCart isMobile />}
      {shouldShowShopLink && (
        <Link
          href={shop.destination?.url}
          trackingContentEntry={{}}
          trackingId=""
          optimizelyFullStackClickEvents={[
            {
              eventName: NAVIGATION_EVENTS.SHOP,
              eventTags: { label: shop.label },
            },
          ]}
        >
          <StyledAnchor
            {...shop}
            data-selector={dataSelector(LINK_SELECTOR, DATA_SELECTOR, 'mobile')}
            data-elbcontext={`component:${DATA_SELECTOR}`}
            data-elb="nav"
            data-elb-nav={`description:${shop.label}@${DATA_SELECTOR}`}
            {...tagger}
          >
            {shop.label}
          </StyledAnchor>
        </Link>
      )}

      {!isOnShopCatalogPage && (
        <>
          <Hamburger
            isActive={isOpen}
            aria-expanded={isOpen}
            onClick={handleHamburgerClick}
            activeLabel={accessibilityLabels.closeHamburgerMenu}
            inactiveLabel={accessibilityLabels.openHamburgerMenu}
            data-selector={dataSelector(
              'hamburger_btn',
              DATA_SELECTOR,
              'mobile',
            )}
          />
          <MobileMenuContainer isOpen={isOpen}>
            <NavWrapper>
              <OfferSwitcher items={offerSwitcher} />

              <Nav
                ref={navRef}
                isOpen={isOpen}
                aria-hidden={!isOpen}
                data-selector={dataSelector('section', DATA_SELECTOR, 'mobile')}
                hasLoginArea={hasLoginArea}
                windowHeight={windowSize?.height}
              >
                <StyledList>
                  {items?.map((originalItem, itemIndex) => {
                    const item = getVariationComponent(
                      originalItem,
                      experiments,
                    ) as MenuItemType;
                    if (!item || !Object.keys(item).length) {
                      return null;
                    }
                    return (
                      <MobileMenuItem
                        key={`${item.label}-${itemIndex}`}
                        item={item}
                        onLinkClick={handleLinkClick}
                        optimizelyFullStackClickEvents={[
                          {
                            eventName: NAVIGATION_EVENTS.FIRST_LEVEL,
                            eventTags: { label: item.label },
                          },
                        ]}
                        isOpen={isOpen}
                      />
                    );
                  })}

                  {help?.label && help?.destination?.url && (
                    <MobileMenuItem
                      item={help}
                      aria-haspopup="false"
                      onLinkClick={handleLinkClick}
                      optimizelyFullStackClickEvents={[
                        {
                          eventName: NAVIGATION_EVENTS.FIRST_LEVEL,
                          eventTags: { label: help.label },
                        },
                      ]}
                      isOpen={isOpen}
                    />
                  )}

                  {country?.multilingual && (
                    <LanguageMenuItem>
                      <LanguageMenu
                        isMobile
                        onLanguageClick={handleLanguageClick}
                      />
                    </LanguageMenuItem>
                  )}
                </StyledList>

                {hasLoginArea && (
                  <LoginArea>
                    <StyledHr />

                    <Link
                      href={login.destination?.url}
                      trackingId=""
                      trackingContentEntry={{}}
                      optimizelyFullStackClickEvents={[
                        {
                          eventName: NAVIGATION_EVENTS.LOGIN,
                          eventTags: { labels: login.label },
                        },
                      ]}
                    >
                      <StyledButton
                        as="a"
                        variant="primary"
                        stretch
                        data-selector={dataSelector(
                          'cta',
                          DATA_SELECTOR,
                          'mobile',
                        )}
                        data-elbcontext={`component:${DATA_SELECTOR}`}
                        data-elb="nav"
                        data-elb-nav={`description:${login.label}@${DATA_SELECTOR}`}
                        {...tagger}
                      >
                        {login.label}
                      </StyledButton>
                    </Link>
                  </LoginArea>
                )}
              </Nav>
            </NavWrapper>
          </MobileMenuContainer>
        </>
      )}
    </Container>
  );
};
