import isEmpty from 'lodash/fp/isEmpty';
import includes from 'lodash/fp/includes';
import get from 'lodash/fp/get';

import { RouteType } from '~/shared/types/shared';
import formatContentfulUrl from '~/shared/util/format-contentful-url';
import { CONTENTFUL_LIGHT_IMAGE_QUALITY } from '~/shared/constants/contentful';

export function getRobotsOptions(page, image) {
  const isSbg = includes('sbg', page.contentType);
  const isContentHub = page.name?.startsWith('CONTENT HUB');
  const imageHasProperDimensions = image.dimensions.width > 1200;

  if ((isSbg || isContentHub) && imageHasProperDimensions) {
    return { maxImagePreview: 'large' as const };
  }

  return {};
}

export function getRobots(
  route: Partial<RouteType> = {},
  opts: { maxImagePreview?: 'large' } = {},
) {
  const rules = [];

  if (isEmpty(route)) {
    rules.push('index', 'follow');
  } else {
    rules.push(route.index ? 'index' : 'noindex');
    rules.push(route.follow ? 'follow' : 'nofollow');
  }

  if (opts.maxImagePreview) {
    rules.push(`max-image-preview:${opts.maxImagePreview}`);
  }

  return rules.join(', ');
}

export function getAdsBotIndexing(route: Partial<RouteType> = {}) {
  if (isEmpty(route)) {
    return 'index';
  }

  return route.adsBotIndex ? 'index' : 'noindex';
}

export function getType(contentType) {
  return includes('sbg', contentType) ? 'article' : 'website';
}

function enrichSchemaWithProductData(schema, products) {
  if (schema['@type'] !== 'Product' || !schema.offers?.SUMUP_PRODUCT_ID) {
    return schema;
  }
  const productId = schema.offers.SUMUP_PRODUCT_ID;
  const newSchema = { ...schema };

  delete newSchema.offers;

  if (!productId) {
    return newSchema;
  }

  const product = products[productId];

  newSchema.offers = {
    '@type': 'Offer',
    'price': product.original.price,
    'priceCurrency': product.currencyCode,
  };

  return newSchema;
}

function getSbgPostSchema(page, image) {
  const author = {
    '@type': page.author.type || 'Person',
    'name': page.author.fullName,
    'url': page.author.url,
  };

  return {
    isSchemaEnabled: true,
    pageSchema: JSON.stringify({
      '@context': 'https://schema.org/',
      '@type': 'Article',
      'author': author,
      'dateModified': page.updatedAt,
      'datePublished': page.date,
      'headline': page.headline,
      'image': image,
      'articleSection': 'Business Guide',
      'inLanguage': page.locale.split('-')[0],
    }),
  };
}
function getPressArticleSchema(page) {
  const pageSchema: Record<string, unknown> = {
    '@context': 'https://schema.org/',
    '@type': 'Article',
    'headline': page.headline,
    'author': {
      '@type': 'Organization',
      'name': 'SumUp',
      'url': 'https://sumup.com',
    },
    'articleSection': 'Press',
  };

  if (page.locale) {
    pageSchema.inLanguage = page.locale.split('-')?.[0];
  }

  return {
    isSchemaEnabled: true,
    pageSchema: JSON.stringify(pageSchema),
  };
}

function getSocialTag(tag, fallbackTag, page = {}, meta = {}) {
  return page[tag] || page[fallbackTag] || meta[tag] || meta[fallbackTag];
}

export function getSocialTitle(page, meta) {
  return getSocialTag('socialSharingTitle', 'metaTitle', page, meta);
}

export function getSocialDescription(page, meta) {
  return getSocialTag(
    'socialSharingDescription',
    'metaDescription',
    page,
    meta,
  );
}

// Whatsapp used to filter out all the images that are bigger than
// 300kb, although they loosed a little the limits overtime it still
// can sometimes not work: https://stackoverflow.com/questions/19778620/provide-an-image-for-whatsapp-link-sharing
// This has changed since then, they increased the limit to 600kb: https://developers.facebook.com/docs/whatsapp/link-previews/
const WHATSAPP_LIMIT = 600000;

export function getSocialImageUrl(image) {
  const imageUrl = get('file.url', image);
  const imageSize = get('file.details.size', image);

  const url =
    imageSize <= WHATSAPP_LIMIT
      ? formatContentfulUrl(imageUrl)
      : formatContentfulUrl(imageUrl, { q: CONTENTFUL_LIGHT_IMAGE_QUALITY });
  return {
    url,
    dimensions: image?.file?.details?.image ?? { width: 0, height: 0 },
  };
}

export function getTitle(metaTitle, headline, meta: any = {}) {
  return metaTitle || headline || meta.metaTitle || 'SumUp';
}

export function generatePageSchema({
  page,
  title,
  products,
  image,
}): { isSchemaEnabled: true; pageSchema: string } | { isSchemaEnabled: false } {
  const markupSchemaType = get('markupSchemaType', page);
  const markupSchema = get('markupSchema', page) || {};

  if (page?.contentType === 'sbgPost') {
    return getSbgPostSchema(page, image);
  }

  if (page?.contentType === 'pressArticle') {
    return getPressArticleSchema(page);
  }

  if (!markupSchemaType && !markupSchema?.['@type']) {
    return { isSchemaEnabled: false };
  }

  if (
    Object.keys(markupSchema).length === 0 ||
    !Object.keys(markupSchema).every((key) => !!page.markupSchema[key])
  ) {
    return { isSchemaEnabled: false };
  }

  if (page.markupSchemaType === 'Organization') {
    return {
      isSchemaEnabled: true,
      pageSchema: JSON.stringify({
        '@context': 'https://schema.org/',
        '@type': page.markupSchemaType,
        'name': title,
        image,
        ...page.markupSchema,
      }),
    };
  }

  if (markupSchema['@type']) {
    const enrichedMarkupSchema = enrichSchemaWithProductData(
      markupSchema,
      products,
    );

    return {
      isSchemaEnabled: true,
      pageSchema: JSON.stringify({
        '@type': page.markupSchemaType,
        ...enrichedMarkupSchema,
        'dateModified': page.userDefinedUpdatedAt || page.updatedAt,
        'datePublished': page.userDefinedPublishedAt || page.createdAt,
      }),
    };
  }

  return {
    isSchemaEnabled: true,
    pageSchema: JSON.stringify({
      '@context': 'https://schema.org/',
      '@type': page.markupSchemaType,
      'name': title,
      image,
      'dateModified': page.userDefinedUpdatedAt || page.updatedAt,
      'datePublished': page.userDefinedUpdatedAt || page.updatedAt,
      ...page.markupSchema,
    }),
  };
}
