import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import {
  subSitePropType,
  trackingContentEntryPropType,
} from '~/shared/util/shared-prop-types';
import Link from '~/shared/components/Link';
import dataSelector from '~/shared/util/data-selector';

/**
 * Sub-site logo
 */

const subSiteBaseStyles = ({ theme }) => css`
  display: inline;
  margin-top: -3px;
  margin-left: ${theme.spacings.kilo};
  font-size: 20px;
  color: var(--cui-fg-subtle);

  ${theme.mq.kilo} {
    font-size: 22px;
  }
`;

const subSiteLongStyles = ({ isLong }) =>
  isLong &&
  css`
    font-size: 18px;
  `;

const SubSiteTitle = styled('span')(subSiteBaseStyles, subSiteLongStyles);

const SubSiteLogo = ({
  title = '',
  url = '',
  onClick = () => {},
  trackingContentEntry = {},
}) => {
  if (!title) {
    return null;
  }

  const isLong = title.length > 10;

  if (!url) {
    return <SubSiteTitle isLong={isLong}>{title}</SubSiteTitle>;
  }

  return (
    <Link
      href={url}
      onClick={onClick}
      trackingContentEntry={trackingContentEntry}
      trackingId="top_navigation_clicked"
      trackingLabel="secondary_logo"
    >
      <SubSiteTitle
        as="a"
        isLong={isLong}
        data-selector={dataSelector('secondary_logo')}
      >
        {title}
      </SubSiteTitle>
    </Link>
  );
};

SubSiteLogo.propTypes = {
  ...subSitePropType,
  onClick: PropTypes.func,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
};

/**
 * @component
 */
export default SubSiteLogo;
