import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';
import values from 'lodash/fp/values';

import BadgeContainer from '../BadgeContainer';

import Fee from './components/Fee';
import { DISPLAY_MODES, SUPPORTED_FEE_TYPES, DATA_SELECTOR } from './constants';

import FeesContext from '~/shared/providers/FeesContext';
import dataSelector from '~/shared/util/data-selector';
import { BADGE_BACKGROUNDS } from '~/shared/constants';

const doubleFeeStyles = css`
  display: flex;
  flex-direction: row;
`;
const DoubleFees = styled('div')(doubleFeeStyles);

const feeSeparatorStyles = css`
  width: 1px;
  background-color: rgba(255, 255, 255, 0.2);
  margin-right: 8px;
  margin-left: 8px;
`;
const FeeSeparator = styled('div')(feeSeparatorStyles);

/**
 * FeeBadge with single or double fees display
 */
function FeeBadge({
  background,
  className,
  feesDisplayMode = DISPLAY_MODES.SINGLE,
  firstFeeType,
  firstFeeLabel,
  secondFeeType,
  secondFeeLabel,
}) {
  const fees = useContext(FeesContext);

  if (isEmpty(fees)) {
    return null;
  }

  const firstFeeValue = fees.original[firstFeeType];

  if (!firstFeeValue) {
    return null;
  }

  const secondFeeValue = fees.original[secondFeeType];

  return (
    <BadgeContainer
      className={className}
      background={background}
      data-selector={dataSelector('wrapper', DATA_SELECTOR)}
      data-elbcontext="component:fee_badge"
    >
      {feesDisplayMode === DISPLAY_MODES.SINGLE && (
        <Fee
          value={firstFeeValue}
          label={firstFeeLabel}
          displayMode={feesDisplayMode}
        />
      )}

      {feesDisplayMode === DISPLAY_MODES.DOUBLE && secondFeeValue && (
        <DoubleFees>
          <Fee
            value={firstFeeValue}
            label={firstFeeLabel}
            displayMode={feesDisplayMode}
          />
          <FeeSeparator />
          <Fee
            value={secondFeeValue}
            label={secondFeeLabel}
            displayMode={feesDisplayMode}
          />
        </DoubleFees>
      )}
    </BadgeContainer>
  );
}

FeeBadge.propTypes = {
  className: PropTypes.string,
  /**
   * selected background from Contentful
   */
  background: PropTypes.oneOf(BADGE_BACKGROUNDS),
  /**
   * show one or two fees in the badge
   */
  feesDisplayMode: PropTypes.oneOf(values(DISPLAY_MODES)),
  /**
   * one of supported fee types by website,
   * it will be shown first in the badge
   */
  firstFeeType: PropTypes.oneOf(SUPPORTED_FEE_TYPES),
  /**
   * another supported fee types by website,
   * it will be shown second in the badge
   * and only when display mode is "Double"
   */
  secondFeeType: PropTypes.oneOf(SUPPORTED_FEE_TYPES),
  /**
   * text under or next to first fee type
   * (position depends on display mode)
   */
  firstFeeLabel: PropTypes.string,
  /**
   * text under second fee type
   */
  secondFeeLabel: PropTypes.string,
};

/**
 * @component
 */
export default FeeBadge;
