/* eslint-disable global-require */

import React from 'react';
import App from 'next/app';
import Router from 'next/router';
import Script from 'next/script';
import get from 'lodash/fp/get';
import { ThemeProvider, Global } from '@emotion/react';
import { ModalProvider } from '@sumup/circuit-ui';
import { elb } from '@elbwalker/walker.js';
import '@sumup/design-tokens/light.css';
import '@sumup/design-tokens/dark-scoped.css';
import '@sumup/circuit-ui/styles.css';
import { SpeedInsights } from '@vercel/speed-insights/next';

import ErrorPage from './_error';

import { getTheme } from '~/shared/styles/themes';
import getGlobalStyles from '~/shared/styles/global-styles';
import { getSalesforceStyles } from '~/shared/styles/salesforce';
import { pagePropsPropType } from '~/shared/util/shared-prop-types';
import isServer from '~/shared/util/is-server';
import captureException from '~/shared/util/capture-exception';
import NProgress from '~/shared/components/NProgress';
import SiteContext from '~/shared/providers/SiteContext';
import RequestContext from '~/shared/providers/RequestContext';
import CookiesContext from '~/shared/providers/CookiesContext';
import ProductsContext from '~/shared/providers/ProductsContext';
import FeesContext from '~/shared/providers/FeesContext';
import CardSchemesContext from '~/shared/providers/CardSchemesContext';
import GroupedCardSchemesContext from '~/shared/providers/GroupedCardSchemesContext';
import { PageProvider } from '~/shared/providers/PageContext';
import { StoreProvider } from '~/shared/providers/StoreContext';
import * as AppService from '~/shared/services/AppService';
import * as RequestQueryParamsStorage from '~/shared/services/request-query-params-storage';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';
import { getOptimizelyData } from '~/shared/services/optimizely/optimizely-data';
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';
import * as Analytics from '~/shared/services/analytics';
import * as OneTrustService from '~/shared/services/onetrust';
import * as SalesforceLiveagent from '~/shared/scripts/salesforce-liveagent';
import isVercelRequest from '~/shared/util/is-vercel-request';
import * as TOKENS from '~/shared/constants/tokens';
import * as gtm from '~/shared/scripts/gtm';
import { setupAnalytics } from '~/shared/scripts/setup-walker';
import {
  PRC_COUPON_FEATURE_TOGGLE,
  PRODUCT_CONFIGURATOR_FEATURE_TOGGLE,
} from '~/shared/services/optimizely/constants';
import { ShopProvider } from '~/domains/shop/providers/ShopProvider/provider';
import { preventWastefulNextDataRequests } from '~/shared/util/catch-next-data-requests';
import { PDP_PAGE_VIEW_EVENT } from '~/shared/services/optimizely/product-configurator/constants';
import { hasShopIntegration } from '~/domains/shop/util/has-shop-integration';
import { omitShopIntegrationFromPages } from '~/domains/shop/util/omit-shop-integration-from-pages';

// Expose custom OneTrust service to window for easier OneTrust events listening
// eslint-disable-next-line
require('expose-loader?OTS!../shared/services/onetrust');

setupAnalytics();

const DEFAULT_PAGE_PROPS = {
  request: {},
  site: {},
  page: {},
  products: {},
  fees: {},
  cardSchemes: [],
  ntExperiencesVariantsMap: {},
};

class CustomApp extends App {
  static propTypes = {
    pageProps: pagePropsPropType,
  };

  static defaultProps = {
    pageProps: DEFAULT_PAGE_PROPS,
  };

  static getDerivedStateFromProps(props) {
    const { site = {} } = props.pageProps;

    return { theme: getTheme(site.disableHyphenation) };
  }

  state = {
    theme: getTheme(get('pageProps.site.disableHyphenation', this.props)),
  };

  componentDidMount() {
    preventWastefulNextDataRequests();
    Router.events.on('routeChangeComplete', this.handlePageLoad);

    if (isServer) {
      return;
    }

    const site = this.props.pageProps?.site;
    const request = this.props.pageProps?.request;

    // initialize Optimizely Full Stack SDK
    OptimizelyFullStack.init(site, request);
    OptimizelyFullStack.createUserTimers();

    // inject Salesforce Chat script if possible
    SalesforceLiveagent.init(site, request);

    this.handlePageLoad();
    this.subscribeToOneTrust();
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidCatch(error, errorInfo) {
    captureException(error, { errorInfo });
  }

  handlePageLoad = () => {
    this.storeRequestQueryParams();
    this.trackAnalyticsPageView();
    this.trackOptimizelyPageView();
    AppService.scrollTopOnWrongAnchor();
  };

  subscribeToOneTrust = () => {
    OneTrustService.onFirstAccept(() => {
      this.trackAnalyticsPageView({
        isConsentUpdate: true,
      });
    });
  };

  trackOptimizelyPageView() {
    const optimizelyFullStackPageviewEvents = get(
      'pageProps.page.optimizelyFullStackPageviewEvents',
      this.props,
    );

    const pdpPageViewEventIndex = (
      optimizelyFullStackPageviewEvents || []
    ).findIndex((event) => event === PDP_PAGE_VIEW_EVENT);

    if (pdpPageViewEventIndex !== -1) {
      const optimizelyData = getOptimizelyData(
        this.props.pageProps.request.query,
      );
      const pdpConfiguratorIntegration =
        !!optimizelyData?.featureToggles[PRODUCT_CONFIGURATOR_FEATURE_TOGGLE];

      optimizelyFullStackPageviewEvents[pdpPageViewEventIndex] = {
        eventName: optimizelyFullStackPageviewEvents[pdpPageViewEventIndex],
        eventTags: {
          source: pdpConfiguratorIntegration ? 'website' : 'website-old-pdp',
        },
      };
    }

    OptimizelyFullStack.trackEvents(optimizelyFullStackPageviewEvents);
  }

  trackAnalyticsPageView(additionalData = {}) {
    const pageType = get('pageProps.request.contentType', this.props);
    const routeZoneId = get('pageProps.page.route.zoneId', this.props);
    const zoneId = Analytics.getZoneId(routeZoneId, pageType);

    Analytics.sendEvent({
      'content-name': get('pageProps.request.pathname', this.props),
      'event': 'content-view',
      pageType,
      zoneId,
      ...additionalData,
    });
    // track page view event for Google Analytics 4
    elb('walker run');
  }

  storeRequestQueryParams() {
    const request = this.props.pageProps?.request;

    RequestQueryParamsStorage.save(request.query);
  }

  render() {
    const { Component, pageProps } = this.props;
    const {
      error,
      request = {},
      site = {},
      page = {},
      fees = {},
      cardSchemes = [],
      groupedCardSchemes = [],
      products = {},
    } = pageProps;
    const optimizelyData = getOptimizelyData(request.query);
    const mappedPageProps = AppService.mapCallbackWidget(
      pageProps,
      optimizelyData.experiments,
    );
    mappedPageProps.page.popup = getVariationComponent(
      page.popup,
      optimizelyData.experiments,
    );
    const { theme } = this.state;

    const customStyles = getGlobalStyles({ theme });
    const Page = error ? ErrorPage : Component;
    const salesforceStyles = getSalesforceStyles(theme);
    const { greenhouse: positions } = page;

    return (
      <>
        <ThemeProvider theme={theme}>
          <CookiesContext.Provider value={request.cookies}>
            <RequestContext.Provider value={request}>
              <SiteContext.Provider value={site}>
                <PageProvider page={page}>
                  <ProductsContext.Provider value={products}>
                    <FeesContext.Provider value={fees}>
                      <CardSchemesContext.Provider value={cardSchemes}>
                        <GroupedCardSchemesContext.Provider
                          value={groupedCardSchemes}
                        >
                          <StoreProvider
                            positions={positions}
                            sessionQuery={request.query}
                          >
                            <ShopProvider>
                              <ModalProvider>
                                <Global
                                  styles={`
                                ${customStyles}
                                ${salesforceStyles}
                                `}
                                />

                                <NProgress theme={theme} />
                                <Page {...mappedPageProps} theme={theme} />

                                <Script
                                  id="gtm-script"
                                  strategy="afterInteractive"
                                  dangerouslySetInnerHTML={{
                                    __html: gtm.script(TOKENS.GTM),
                                  }}
                                />
                                <noscript
                                  dangerouslySetInnerHTML={{
                                    __html: gtm.noScript(TOKENS.GTM),
                                  }}
                                />
                              </ModalProvider>
                            </ShopProvider>
                          </StoreProvider>
                        </GroupedCardSchemesContext.Provider>
                      </CardSchemesContext.Provider>
                    </FeesContext.Provider>
                  </ProductsContext.Provider>
                </PageProvider>
              </SiteContext.Provider>
            </RequestContext.Provider>
          </CookiesContext.Provider>
        </ThemeProvider>
        <SpeedInsights sampleRate={0.1} />
      </>
    );
  }
}

async function getInitialProps({ Component, ctx }) {
  // NEXT.JS LOWER CASE LOCALE WORKAROUND
  // Decision record - https://github.com/sumup/website/blob/master/docs/adr/20240301-manual-nextjs-localization.md
  ctx.locale = AppService.getOneDomainLocaleFromPathname(ctx.asPath);

  const baseUrl = AppService.getBaseUrl(ctx);
  const request = AppService.getRequest(ctx, baseUrl);
  const optimizelyData = getOptimizelyData(request.query);
  const shopIntegration = hasShopIntegration(optimizelyData?.featureToggles);
  const prcCouponsIntegration =
    optimizelyData?.featureToggles?.[PRC_COUPON_FEATURE_TOGGLE];

  if (!process.browser) {
    if (isVercelRequest(ctx.req)) {
      AppService.vercelStaleWhileRevalidate(ctx.res, shopIntegration);
    }
  }

  if (shopIntegration) {
    ctx.query.shopIntegration = true;
  }

  if (shopIntegration && prcCouponsIntegration) {
    ctx.query.prcCouponsIntegration = true;
  }

  const pageProps = Component.getInitialProps
    ? await Component.getInitialProps(ctx, baseUrl, request)
    : {};

  // temp workaround until new navigation is rolled out completely
  // NOTE: this method mutates `pageProps.request`
  omitShopIntegrationFromPages(pageProps?.request);

  return {
    pageProps: {
      ...DEFAULT_PAGE_PROPS,
      request,
      ...pageProps,
    },
  };
}

if (!process.browser) {
  // NOTE: using open telemetry only in server-side to not increase client-side js bundles size
  const {
    withOpenTelemetryInitialProps,
    // eslint-disable-next-line
  } = require('../shared/util/tracing/with-open-telemetry');
  CustomApp.getInitialProps = withOpenTelemetryInitialProps(getInitialProps);
} else {
  CustomApp.getInitialProps = getInitialProps;
}

export default CustomApp;
