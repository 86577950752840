import React from 'react';
import ReactDOMServer from 'react-dom/server';
import find from 'lodash/fp/find';

import FAQSchemaQuestion from './components/FAQSchemaQuestion';

export const findFAQ = find(
  (section) =>
    section.contentType === 'expansionPanelList' && section.generateSEOSchema,
);

export function generateSchema(page, site, products, fees, theme, request) {
  const faqComponent = findFAQ(page.sections);

  if (!faqComponent) {
    return null;
  }

  const questions = faqComponent.expansionPanels;

  const mainEntity = questions.map((question) => ({
    '@type': 'Question',
    'name': question.headline,
    'acceptedAnswer': {
      '@type': 'Answer',
      'text': ReactDOMServer.renderToStaticMarkup(
        <FAQSchemaQuestion
          content={question.content}
          site={site}
          products={products}
          fees={fees}
          theme={theme}
          request={request}
        />,
      ),
    },
  }));

  const schema = JSON.stringify({
    '@context': 'https://schema.org',
    '@type': 'FAQPage',
    mainEntity,
  });

  return schema;
}
