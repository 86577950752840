export const SUMUP_DOMAINS = ['sumup', 'sam-app'];

export const SUMUP_VERCEL_SUFFIX =
  process.env.NEXT_PUBLIC_SUMUP_VERCEL_SUFFIX || '.sumup-vercel.app';

export const DUAL_TLD_DOMAINS = [
  'sumup.com.au',
  'sumup.com.ar',
  'sumup.com.br',
  'sumup.com.mt',
  'sumup.com.cy',
  'sumup.co.uk',
];

export const DASHBOARD_HOSTNAME = 'me.sumup.com';
export const STANDALONE_SHOP = 'buy.sumup.com';
export const NEW_STANDALONE_SHOP = 'store.sumup.com';
export const PRODUCT_SELECTION = 'product-selection';
export const DASHBOARD_TARGET = {
  LOGIN: 'login',
  SIGNUP: 'signup',
};
