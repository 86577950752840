import React, { FC, useContext } from 'react';
import { ClickEvent } from '@sumup/circuit-ui';

import { getLanguageLinks } from './LanguageMenuService';
import DesktopLanguageMenu from './components/DesktopLanguageMenu';
import MobileLanguageMenu from './components/MobileLanguageMenu';

import SiteContext from '~/shared/providers/SiteContext';
import { PageContext } from '~/shared/providers/PageContext';
import { LocalizedUrls } from '~/shared/api-controllers/route/transformers/localized-urls';

export interface LanguageMenuProps {
  isMobile?: boolean;
  onLanguageClick?: (_event?: ClickEvent) => void;
}

export const LanguageMenu: FC<LanguageMenuProps> = ({
  isMobile = false,
  onLanguageClick,
}) => {
  const site = useContext(SiteContext);
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
  const page = useContext(PageContext) as {
    localizedUrls: LocalizedUrls;
  };

  if (!site?.country?.multilingual) {
    return null;
  }

  const { activeLink, dropdownLinks, allLinks } = getLanguageLinks(
    site?.locale,
    site?.country?.locales,
    site?.websites,
    page?.localizedUrls,
  );

  if (isMobile) {
    return (
      <MobileLanguageMenu
        allLinks={allLinks}
        onLanguageClick={onLanguageClick}
      />
    );
  }

  return (
    <DesktopLanguageMenu
      activeLink={activeLink}
      dropdownLinks={dropdownLinks}
    />
  );
};
