import * as logger from '~/shared/services/logger';

export const CLICKED_LINKS_STORAGE_KEY =
  '__menu_clicked_link_ids_to_highlight__';

type LinkIds = string[];

let tempClickedLinkIds: LinkIds = [];
let storedClickedLinkIds: LinkIds = [];

export function getTempClickedLinkIdsToHighlight(): LinkIds {
  return tempClickedLinkIds;
}

export function getStoredClickedLinkIdsToHighlight(): LinkIds {
  return storedClickedLinkIds;
}

export function resetTempClickedLinkIdsToHighlight(): void {
  tempClickedLinkIds = [];
}

export function resetStoredClickedLinkIdsToHighlight(): void {
  storedClickedLinkIds = [];
}

export function saveClickedLinkIdToHighlight(
  linkId: string,
  navigationLevel: number,
  saveToStorage?: boolean,
): void {
  tempClickedLinkIds[navigationLevel] = linkId;

  if (saveToStorage) {
    storedClickedLinkIds = [...tempClickedLinkIds];

    try {
      window.localStorage.setItem(
        CLICKED_LINKS_STORAGE_KEY,
        JSON.stringify(storedClickedLinkIds),
      );
    } catch (err) {
      logger.error(err);
    }
  }
}

export function populateClickedLinkIdsToHighlight(): void {
  try {
    const localStorageValue = window.localStorage.getItem(
      CLICKED_LINKS_STORAGE_KEY,
    );
    storedClickedLinkIds = localStorageValue
      ? JSON.parse(localStorageValue)
      : [];
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function hasClickedLinkIdToHighlight(linkId: string): boolean {
  return storedClickedLinkIds.includes(linkId);
}
