const ANDROID = 'Android';
const IOS_DEVICES = ['iPhone', 'iPad', 'iPod'];

export const detectAndroidVersion = (agent) => {
  if (!agent) {
    return null;
  }

  const isAndroid = agent.match(new RegExp(ANDROID, 'i'));

  if (!isAndroid) {
    return null;
  }

  return parseFloat(agent.slice(agent.indexOf('Android') + 8));
};

export const detectIosVersion = (agent) => {
  if (!agent) {
    return null;
  }

  const isIosDevice = IOS_DEVICES.some((device) =>
    agent.match(new RegExp(device, 'i')),
  );
  if (!isIosDevice) {
    return null;
  }
  const version = agent.match(/OS (\d+)_(\d+)_?(\d+)?/)[1];
  return parseFloat(version);
};
