import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import get from 'lodash/fp/get';
import isString from 'lodash/fp/isString';

import FeesContext from '~/shared/providers/FeesContext';
import { PageContext } from '~/shared/providers/PageContext';
import RequestContext from '~/shared/providers/RequestContext';
import SiteContext from '~/shared/providers/SiteContext';
import ProductsContext from '~/shared/providers/ProductsContext';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import Dropdown from '~/shared/components/Dropdown';
import * as Url from '~/shared/services/url';
import * as Analytics from '~/shared/services/analytics';
import * as LinkService from '~/shared/services/LinkService';
import * as RequestQueryParamsStorage from '~/shared/services/request-query-params-storage';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';

const overrideStyles = ({ theme }) => css`
  color: var(--cui-fg-subtle);
  margin-bottom: ${theme.spacings.mega};

  ul {
    color: var(--cui-fg-subtle);
  }

  ${theme.mq.kiloToMega} {
    margin-top: ${theme.spacings.tera};
  }
`;
const StyledDropdown = styled(Dropdown)(overrideStyles);

type Props = {
  id?: string;
  /**
   * Tracking id is sent to analytics when option is selected.
   */
  trackingId?: string;
  onChange: (_item: any, _url: string) => void;
  /*
   * Disables the SPA navigation when selecting an item.
   */
  disableSPANavigation?: boolean;

  items: unknown[];
  initialItem: unknown;
  placeholder?: string;
  direction: string;
  shouldWrapLabelText?: boolean;
  hasPrefix?: boolean;
  className?: string;
  renderLabelPrefix?: (_item: unknown) => React.ReactNode;
};

function FooterSelect({
  trackingId = '',
  onChange = () => {},
  ...props
}: Props) {
  const { experiments } = useOptimizelyData();
  const request = useContext(RequestContext);
  const { products = {} } = useContext<any>(ProductsContext);
  const fees = useContext(FeesContext);
  const site = useContext(SiteContext);
  const page: any = useContext(PageContext);

  const handleChange = (item) => {
    Analytics.sendEvent({
      event: 'interaction',
      target: 'Mkt_Web',
      action: trackingId,
      contentType: 'footer',
      contentEntryName: get('footer.name', page),
      contentEntryId: get('footer.id', page),
      destinationCategory: Analytics.destinationCategory.UI_OPERATION,
      destinationUrl: item.href,
    });
    sendNinetailedEvent(trackingId);

    let formattedHref = '';

    if (item.href) {
      const url = isString(item.href) ? Url.parse(item.href) : item.href;
      const universalQueryParams = LinkService.getUniversalParams(
        site.universalQueryParams,
        page.universalQueryParams,
        experiments,
      );
      const localStorageRequestQuery = RequestQueryParamsStorage.getAll();
      const query = LinkService.determineQuery(
        url,
        request,
        products,
        fees,
        page,
        universalQueryParams,
        localStorageRequestQuery,
      );

      const formattedURL = LinkService.formatURLToOneDomain(
        url,
        request,
        site.locale,
      );
      formattedHref = LinkService.formatHref(formattedURL, query, request);
    }

    onChange(item, formattedHref);
  };

  // @ts-expect-error | TODO: remove this once the Dropdown is moved to TS
  return <StyledDropdown {...props} onChange={handleChange} />;
}

export default FooterSelect;
