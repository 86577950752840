// needed for /en-US/ path prefixing of default locale in Next.js
// https://nextjs.org/docs/advanced-features/i18n-routing#prefixing-the-default-locale
export const DEFAULT_LOCALE_KEY = 'defaultLocale';

// support legacy locales
export const DEFAULT_LOCALE = 'en-US';

export const DEFAULT_COUNTRY = 'US';

export const DEFAULT_LOCALE_ONE_DOMAIN = DEFAULT_LOCALE.toLowerCase();

export const AVAILABLE_LOCALES = [
  'bg-BG',
  'cs-CZ',
  'da-DK',
  'de-AT',
  'de-CH',
  'de-DE',
  'de-LU',
  'el-CY',
  'el-GR',
  'en-AU',
  'en-CA',
  'en-GB',
  'en-IE',
  'en-MT',
  'en-US',
  'es-AR',
  'es-CL',
  'es-CO',
  'es-ES',
  'es-MX',
  'es-PE',
  'es-US',
  'et-EE',
  'fi-FI',
  'fr-BE',
  'fr-CA',
  'fr-CH',
  'fr-FR',
  'fr-LU',
  'hr-HR',
  'hu-HU',
  'it-CH',
  'it-IT',
  'lt-LT',
  'lv-LV',
  'nb-NO',
  'nl-BE',
  'nl-NL',
  'pl-PL',
  'pt-BR',
  'pt-PT',
  'ro-RO',
  'sk-SK',
  'sl-SI',
  'sv-SE',
] as const;

export const AVAILABLE_LOCALES_ONE_DOMAIN = AVAILABLE_LOCALES.map((al) =>
  al.toLowerCase(),
);

export const AVAILABLE_WEBSITES_FOR_MAIN = [
  {
    label: 'Australia',
    countryCode: 'AU',
    locale: 'en-AU',
  },
  {
    label: 'Österreich',
    countryCode: 'AT',
    locale: 'de-AT',
  },
  {
    label: 'België (FR)',
    countryCode: 'BE',
    locale: 'fr-BE',
  },
  {
    label: 'België (NL)',
    countryCode: 'BE',
    locale: 'nl-BE',
  },
  {
    label: 'Brasil',
    countryCode: 'BR',
    locale: 'pt-BR',
  },
  {
    label: 'България',
    countryCode: 'BG',
    locale: 'bg-BG',
  },
  {
    label: 'Chile',
    countryCode: 'CL',
    locale: 'es-CL',
  },
  {
    label: 'Colombia',
    countryCode: 'CO',
    locale: 'es-CO',
  },
  {
    label: 'Hrvatska',
    countryCode: 'HR',
    locale: 'hr-HR',
  },
  {
    label: 'Κύπρος',
    countryCode: 'CY',
    locale: 'el-CY',
  },
  {
    label: 'Česká republika',
    countryCode: 'CZ',
    locale: 'cs-CZ',
  },
  {
    label: 'Danmark',
    countryCode: 'DK',
    locale: 'da-DK',
  },
  {
    label: 'Eesti',
    countryCode: 'EE',
    locale: 'et-EE',
  },
  {
    label: 'Suomi',
    countryCode: 'FI',
    locale: 'fi-FI',
  },
  {
    label: 'France',
    countryCode: 'FR',
    locale: 'fr-FR',
  },
  {
    label: 'Deutschland',
    countryCode: 'DE',
    locale: 'de-DE',
  },
  {
    label: 'Ελλάδα',
    countryCode: 'GR',
    locale: 'el-GR',
  },
  {
    label: 'Magyarország',
    countryCode: 'HU',
    locale: 'hu-HU',
  },
  {
    label: 'Ireland',
    countryCode: 'IE',
    locale: 'en-IE',
  },
  {
    label: 'Italia',
    countryCode: 'IT',
    locale: 'it-IT',
  },
  {
    label: 'Latvija',
    countryCode: 'LV',
    locale: 'lv-LV',
  },
  {
    label: 'Lietuva',
    countryCode: 'LT',
    locale: 'lt-LT',
  },
  {
    label: 'Luxembourg (DE)',
    countryCode: 'LU',
    locale: 'de-LU',
  },
  {
    label: 'Luxembourg (FR)',
    countryCode: 'LU',
    locale: 'fr-LU',
  },
  {
    label: 'Malta',
    countryCode: 'MT',
    locale: 'en-MT',
  },
  {
    label: 'Norge',
    countryCode: 'NO',
    locale: 'nb-NO',
  },
  {
    label: 'Perú',
    countryCode: 'PE',
    locale: 'es-PE',
  },
  {
    label: 'Polska',
    countryCode: 'PL',
    locale: 'pl-PL',
  },
  {
    label: 'Portugal',
    countryCode: 'PT',
    locale: 'pt-PT',
  },
  {
    label: 'România',
    countryCode: 'RO',
    locale: 'ro-RO',
  },
  {
    label: 'Slovensko',
    countryCode: 'SK',
    locale: 'sk-SK',
  },
  {
    label: 'Slovenija',
    countryCode: 'SI',
    locale: 'sl-SI',
  },
  {
    label: 'España',
    countryCode: 'ES',
    locale: 'es-ES',
  },
  {
    label: 'Sverige',
    countryCode: 'SE',
    locale: 'sv-SE',
  },
  {
    label: 'Schweiz (DE)',
    countryCode: 'CH',
    locale: 'de-CH',
  },
  {
    label: 'Schweiz (FR)',
    countryCode: 'CH',
    locale: 'fr-CH',
  },
  {
    label: 'Schweiz (IT)',
    countryCode: 'CH',
    locale: 'it-CH',
  },
  {
    label: 'Nederland',
    countryCode: 'NL',
    locale: 'nl-NL',
  },
  {
    label: 'United Kingdom',
    countryCode: 'GB',
    locale: 'en-GB',
  },
  {
    label: 'United States (EN)',
    countryCode: 'US',
    locale: 'en-US',
  },
  {
    label: 'United States (ES)',
    countryCode: 'US',
    locale: 'es-US',
  },
];

// List of Contentful locales and their fallbacks (should be always in sync)
// https://app.contentful.com/spaces/txhaodyqr481/settings/locales
export const LOCALES_WITH_FALLBACKS = [
  // Global Default
  {
    code: 'intl',
    default: true,
    fallbackCode: null,
    name: 'Global Default',
    optional: false,
  },

  // Language Defaults
  {
    code: 'en',
    default: false,
    fallbackCode: 'intl',
    name: 'English Language',
    optional: true,
  },
  {
    code: 'de',
    default: false,
    fallbackCode: 'intl',
    name: 'German Language',
    optional: true,
  },
  {
    code: 'fr',
    default: false,
    fallbackCode: 'intl',
    name: 'French Language',
    optional: true,
  },
  {
    code: 'it',
    default: false,
    fallbackCode: 'intl',
    name: 'Italian Language',
    optional: true,
  },
  {
    code: 'nl',
    default: false,
    fallbackCode: 'intl',
    name: 'Dutch Language',
    optional: true,
  },

  // Independent Country Team Locales
  {
    code: 'en-US',
    default: false,
    fallbackCode: 'intl',
    name: 'U.S. English',
    optional: true,
  },
  {
    code: 'en-CA',
    default: false,
    fallbackCode: 'intl',
    name: 'English (Canada)',
    optional: true,
  },
  {
    code: 'de-DE',
    default: false,
    fallbackCode: 'intl',
    name: 'German (Germany)',
    optional: true,
  },
  {
    code: 'it-IT',
    default: false,
    fallbackCode: 'intl',
    name: 'Italian (Italy)',
    optional: true,
  },
  {
    code: 'en-GB',
    default: false,
    fallbackCode: 'intl',
    name: 'English (United Kingdom)',
    optional: true,
  },
  {
    code: 'fr-FR',
    default: false,
    fallbackCode: 'intl',
    name: 'French (France)',
    optional: true,
  },
  {
    code: 'pt-BR',
    default: false,
    fallbackCode: 'intl',
    name: 'Portuguese (Brazil)',
    optional: true,
  },
  {
    code: 'es-CL',
    default: false,
    fallbackCode: 'intl',
    name: 'Spanish (Chile)',
    optional: true,
  },
  {
    code: 'es-CO',
    default: false,
    fallbackCode: 'intl',
    name: 'Spanish (Colombia)',
    optional: true,
  },
  {
    code: 'es-AR',
    default: false,
    fallbackCode: 'intl',
    name: 'Spanish (Argentina)',
    optional: true,
  },
  {
    code: 'es-PE',
    default: false,
    fallbackCode: 'intl',
    name: 'Spanish (Perú)',
    optional: true,
  },
  {
    code: 'es-MX',
    default: false,
    fallbackCode: 'intl',
    name: 'Spanish (Mexico)',
    optional: true,
  },

  // Multilingual Country Team Locales
  {
    code: 'es-US',
    default: false,
    fallbackCode: 'en-US',
    name: 'U.S. Spanish',
    optional: true,
  },
  {
    code: 'fr-CA',
    default: false,
    fallbackCode: 'en-CA',
    name: 'French (Canada)',
    optional: true,
  },

  // Rest of the World Locales
  // English speaking
  {
    code: 'en-IE',
    default: false,
    fallbackCode: 'en',
    name: 'English (Ireland)',
    optional: true,
  },
  {
    code: 'en-MT',
    default: false,
    fallbackCode: 'en',
    name: 'English (Malta)',
    optional: true,
  },
  {
    code: 'en-AU',
    default: false,
    fallbackCode: 'en',
    name: 'English (Australia)',
    optional: true,
  },

  // German speaking
  {
    code: 'de-CH',
    default: false,
    fallbackCode: 'de',
    name: 'German (Switzerland)',
    optional: true,
  },
  {
    code: 'de-AT',
    default: false,
    fallbackCode: 'de',
    name: 'German (Austria)',
    optional: true,
  },
  {
    code: 'de-LU',
    default: false,
    fallbackCode: 'de',
    name: 'German (Luxembourg)',
    optional: true,
  },

  // French speaking
  {
    code: 'fr-CH',
    default: false,
    fallbackCode: 'fr',
    name: 'French (Switzerland)',
    optional: true,
  },
  {
    code: 'fr-BE',
    default: false,
    fallbackCode: 'fr',
    name: 'French (Belgium)',
    optional: true,
  },
  {
    code: 'fr-LU',
    default: false,
    fallbackCode: 'fr',
    name: 'French (Luxembourg)',
    optional: true,
  },

  // Italian speaking
  {
    code: 'it-CH',
    default: false,
    fallbackCode: 'it',
    name: 'Italian (Switzerland)',
    optional: true,
  },

  // Dutch speaking
  {
    code: 'nl-NL',
    default: false,
    fallbackCode: 'nl',
    name: 'Dutch (Netherlands)',
    optional: true,
  },
  {
    code: 'nl-BE',
    default: false,
    fallbackCode: 'nl',
    name: 'Dutch (Belgium)',
    optional: true,
  },

  // Southern Europe
  {
    code: 'pt-PT',
    default: false,
    fallbackCode: 'en',
    name: 'Portuguese (Portugal)',
    optional: true,
  },
  {
    code: 'es-ES',
    default: false,
    fallbackCode: 'en',
    name: 'Spanish (Spain)',
    optional: true,
  },
  {
    code: 'el-CY',
    default: false,
    fallbackCode: 'en',
    name: 'Greek (Cyprus)',
    optional: true,
  },
  {
    code: 'el-GR',
    default: false,
    fallbackCode: 'en',
    name: 'Greek (Greece)',
    optional: true,
  },

  // Nordics
  {
    code: 'sv-SE',
    default: false,
    fallbackCode: 'en',
    name: 'Swedish (Sweden)',
    optional: true,
  },
  {
    code: 'fi-FI',
    default: false,
    fallbackCode: 'en',
    name: 'Finnish (Finland)',
    optional: true,
  },
  {
    code: 'nb-NO',
    default: false,
    fallbackCode: 'en',
    name: 'Norwegian Bokmål (Norway)',
    optional: true,
  },
  {
    code: 'da-DK',
    default: false,
    fallbackCode: 'en',
    name: 'Danish (Denmark)',
    optional: true,
  },

  // Eastern Europe
  {
    code: 'pl-PL',
    default: false,
    fallbackCode: 'en',
    name: 'Polish (Poland)',
    optional: true,
  },
  {
    code: 'bg-BG',
    default: false,
    fallbackCode: 'en',
    name: 'Bulgarian (Bulgaria)',
    optional: true,
  },
  {
    code: 'hu-HU',
    default: false,
    fallbackCode: 'en',
    name: 'Hungarian (Hungary)',
    optional: true,
  },
  {
    code: 'sl-SI',
    default: false,
    fallbackCode: 'en',
    name: 'Slovenian (Slovenia)',
    optional: true,
  },
  {
    code: 'sk-SK',
    default: false,
    fallbackCode: 'en',
    name: 'Slovak (Slovakia)',
    optional: true,
  },
  {
    code: 'cs-CZ',
    default: false,
    fallbackCode: 'en',
    name: 'Czech (Czech Republic)',
    optional: true,
  },

  // Baltics
  {
    code: 'lv-LV',
    default: false,
    fallbackCode: 'en',
    name: 'Latvian (Latvia)',
    optional: true,
  },
  {
    code: 'lt-LT',
    default: false,
    fallbackCode: 'en',
    name: 'Lithuanian (Lithuania)',
    optional: true,
  },
  {
    code: 'et-EE',
    default: false,
    fallbackCode: 'en',
    name: 'Estonian (Estonia)',
    optional: true,
  },
  {
    code: 'ro-RO',
    default: false,
    fallbackCode: 'en',
    name: 'Romania (Romanian)',
    optional: true,
  },
  {
    code: 'hr-HR',
    default: false,
    fallbackCode: 'en',
    name: 'Croatia (Croatian)',
    optional: true,
  },
];

// [MW-974] Officially we are not live in Canada.
// We are not currently live in AR; a homepage exists, but no immediate plans to launch
export const HIDDEN_LOCALES = {
  'es-AR': true,
  'fr-CA': true,
  'en-CA': true,
  'es-MX': true,
};

// for multilingual locales we need to decide on default locales
export const MULTILANG_COUNTRIES_DEFAULTS = {
  CA: 'en-CA',
  BE: 'nl-BE',
  CH: 'de-CH',
  LU: 'fr-LU',
  US: 'en-US',
};
