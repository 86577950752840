import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import values from 'lodash/fp/values';

import { DATA_SELECTOR, FORMATS, DISPLAY_MODES } from '../../constants';

import dataSelector from '~/shared/util/data-selector';

const feeWrapperModeStyles = {
  [DISPLAY_MODES.SINGLE]: css`
    display: flex;
    align-items: center;
  `,
  [DISPLAY_MODES.DOUBLE]: css`
    display: flex;
    flex-direction: column;
  `,
};
const feeWrapperStyles = ({ displayMode }) => feeWrapperModeStyles[displayMode];

const FeeWrapper = styled('div')(feeWrapperStyles);

const feeContainerStyles = css`
  display: flex;
  align-items: baseline;
  font-weight: bold;
`;
const FeeContainer = styled('div')(feeContainerStyles);

const feeValueStyles = ({ textSize }) => css`
  font-size: ${textSize}px;
  line-height: 1;
`;

const FeeValue = styled('span')(feeValueStyles);

const percentageContainerStyles = ({ textSize }) => css`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  font-size: ${textSize}px;
  line-height: 1;
`;

const Percentage = styled('div')(percentageContainerStyles);

const feeLabelStyles = ({ textSize, marginLeft }) => css`
  max-width: 82px;
  margin-left: ${marginLeft}px;
  line-height: 18px;
  font-size: ${textSize}px;
`;

const FeeLabel = styled('div')(feeLabelStyles);

function Fee({ value, label = '', displayMode = DISPLAY_MODES.SINGLE }) {
  if (!value && value !== 0) {
    return null;
  }

  const format = FORMATS[displayMode];

  return (
    <FeeWrapper displayMode={displayMode}>
      <FeeContainer>
        <FeeValue
          data-selector={dataSelector('value', DATA_SELECTOR)}
          textSize={format.sizes.value}
        >
          {value}
        </FeeValue>
        <Percentage textSize={format.sizes.percentage}>%</Percentage>
      </FeeContainer>
      {label && (
        <FeeLabel
          data-selector={dataSelector('label', DATA_SELECTOR)}
          textSize={format.sizes.label}
          marginLeft={format.spacings.label_left}
        >
          {label}
        </FeeLabel>
      )}
    </FeeWrapper>
  );
}

Fee.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  displayMode: PropTypes.oneOf(values(DISPLAY_MODES)),
};

/**
 * @component
 */
export default Fee;
