import { isPageUrlInMenuLinks, SiteData } from '~/shared/services/navigation';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

export interface NavigationLink {
  id: string;
  url: string;
}

export interface MobileMenuScreen {
  id: string;
  nesting: number;
  links: NavigationLink[];
}

export interface MobileMenuScreenMap {
  [key: string]: MobileMenuScreen;
}

export function hasScreenNesting(
  linkId: string,
  allScreens: MobileMenuScreenMap,
  currentScreen: MobileMenuScreen,
): boolean {
  if (!linkId) {
    return false;
  }

  const targetScreen = allScreens && allScreens[linkId];
  if (!targetScreen) {
    return false;
  }

  return targetScreen.nesting > currentScreen?.nesting;
}

export function hasPageUrlInLinkScreen(
  linkId: string,
  request: RequestType,
  site: SiteData,
  allScreens: MobileMenuScreenMap = {},
): boolean {
  if (!linkId) {
    return false;
  }

  const targetScreen = allScreens && allScreens[linkId];
  if (!targetScreen) {
    return false;
  }

  const { links: screenLinks = [] } = targetScreen;
  const isNested = isPageUrlInMenuLinks(request, site, screenLinks);

  if (isNested) {
    return isNested;
  }

  return screenLinks.some((screenLink) =>
    hasPageUrlInLinkScreen(screenLink.id, request, site, allScreens),
  );
}
