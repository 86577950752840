import { Ninetailed } from '@ninetailed/experience.js';

import isServer from '~/shared/util/is-server';

export type NinetailedWindow = Window['ninetailed'] & Ninetailed;

// https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/22253863013/Website+Shop+Ninetailed+Events
export const sendNinetailedEvent = (eventName = 'unknown-nt-event') => {
  if (isServer) {
    return;
  }

  if (!eventName) {
    return;
  }

  const ninetailed = window.ninetailed as NinetailedWindow;
  if (ninetailed) {
    try {
      ninetailed.track(eventName);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('Failed to track Ninetailed event due to exception', err);
    }
  }
};
