export function getHotjarIntegration() {
  const noop = () => {};
  const hjFunc = (...args) => {
    (window.hj.q = window.hj.q || []).push(...args);
  };

  window.hj = window.hj || hjFunc;

  return window.hj || noop;
}

export function track(heatMapTrigger, recordingsTag) {
  const hj = getHotjarIntegration();

  hj('trigger', heatMapTrigger);
  hj('tagRecording', [recordingsTag]);
}
