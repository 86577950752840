// NOTE: Error classes are ES6+ only and cannot be transpiled by Babel.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error

type StatusCode = string | number;
interface ErrorData {
  message?: string;
}
interface ErrorResponse {
  status?: StatusCode;
  data?: {
    error?: ErrorData;
  };
}

export class RequestError extends Error {
  public data?: ErrorData;

  public statusCode?: StatusCode;

  constructor(response: ErrorResponse = {}) {
    const { status, data = {} }: ErrorResponse = response;
    const { error = {} } = data;

    super(error.message);

    this.name = 'RequestError';
    this.data = error;
    this.statusCode = status || 500;

    Error.captureStackTrace(this, RequestError);
  }
}
