/*
 * Optimizely browser SDK used for tracking and decision making in SPA mode.
 */

import qs from 'querystring';

import optimizely, {
  EventDispatcher,
  OptimizelyDecideOption,
  OptimizelyUserContext,
} from '@optimizely/optimizely-sdk';
import fetch from 'isomorphic-unfetch';
import {
  get as getCookie,
  set as setCookie,
  getAll as getCookies,
} from 'es-cookie';
import getConfig from 'next/config';
import { v4 as uuidv4 } from 'uuid';
import pickBy from 'lodash/fp/pickBy';
import bowser from 'bowser';
import { elb } from '@elbwalker/walker.js';

import {
  EXPERIMENT_USER_COOKIE_NAME,
  EXPERIMENT_COOKIES_MAX_AGE_DAYS,
  THRESHOLD_TIMES,
  GA_ID_COOKIE_NAME,
  USE_BUILTIN_DISPATCHER_PARAM,
  OPTIMIZELY_EVENTS_ENDPOINT,
  SHOP_EXPERIENCE_ATTR,
} from './constants';
import {
  setInMemoryExperiments,
  setInMemoryFeatureToggles,
  getOptimizelyData,
} from './optimizely-data';
import { loadDatafile, getDatafileUrl } from './optimizely-shared-client-utils';
import { Event, Attributes, Decision, OptimizelySDKClient } from './types';

import isServer from '~/shared/util/is-server';
import * as Hotjar from '~/shared/services/hotjar';
import * as Url from '~/shared/services/url';
import * as Analytics from '~/shared/services/analytics';
import {
  getActiveCookieCategoriesFromCookies,
  getConsentStatus,
  onConsentChange,
} from '~/shared/services/onetrust';
import * as ENVIRONMENTS from '~/shared/constants/environments';
import { VERCEL_CDN_CACHE_PARAMS } from '~/shared/constants';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';
import { Site } from '~/shared/api-controllers/site';
import {
  NextJsConfig,
  PublicRuntimeConifg,
} from '~/shared/types/nextjs-config';

const { publicRuntimeConfig = {} } = getConfig() as NextJsConfig;
const { environment } = publicRuntimeConfig as PublicRuntimeConifg;

let userId: string;
let userContext: OptimizelyUserContext;
let client: OptimizelySDKClient;
let geoCountry: string;
let isReady = false; // set to true once SDK is initialized
let savedAttributes: Attributes = {};
let lazyEvents: Event[] = []; // events that were triggered before datafile was loaded
let showOptimizelyLogs = false;

// CUSTOM EVENT DISPATCHER FOR SDK CLIENT
// Inspired from: https://github.com/optimizely/javascript-sdk/blob/master/packages/optimizely-sdk/lib/plugins/event_dispatcher/index.browser.ts#L34
// Implemented as part of [SA-44212]
export const dispatchEvent: EventDispatcher['dispatchEvent'] = (
  { params, httpVerb },
  cb,
) => {
  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.info('Optimizely custom dispatch', httpVerb, params);

    return cb({ statusCode: 404 });
  }

  if (httpVerb === 'POST') {
    return fetch(OPTIMIZELY_EVENTS_ENDPOINT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
      keepalive: true,
    }).then(({ status }) => {
      cb({ statusCode: status });
    });
  }

  let queryParams = '?wxhr=true';

  if (params) {
    // `any` is returned by third-party @optimizely/optimizely-sdk
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    queryParams += `&${qs.stringify(params)}`;
  }

  const endpointWithParams = OPTIMIZELY_EVENTS_ENDPOINT + queryParams;

  return fetch(endpointWithParams).then(({ status }) => {
    cb({ statusCode: status });
  });
};

// SDK CLIENT
export async function init(site: Site, request: RequestType) {
  if (isServer) {
    return;
  }

  // do nothing if there is no site locale specified
  if (!site.locale) {
    return;
  }

  setGeoCountry(request);
  setShowOptimizelyLogsFlag(request);
  setInMemoryExperiments(
    request?.query?.[VERCEL_CDN_CACHE_PARAMS.AB_EXPERIMENTS],
  );
  setInMemoryFeatureToggles(
    request?.query?.[VERCEL_CDN_CACHE_PARAMS.FEATURE_TOGGLES],
  );
  setAttributesData(site.locale, request);

  await initOptimizelyUser();

  const { datafile } = await loadStaticExperimentsData();

  if (!Object.keys(datafile || {}).length) {
    return;
  }

  if (showOptimizelyLogs) {
    optimizely.setLogLevel(optimizely.enums.LOG_LEVEL.DEBUG);
  } else {
    optimizely.setLogger(null);
  }

  const useBuiltinDispatcher = !!request?.query?.[USE_BUILTIN_DISPATCHER_PARAM];

  // https://docs.developers.optimizely.com/full-stack/docs/initialize-sdk-javascript#section-set-a-fallback-datafile
  const clientOptions = useBuiltinDispatcher
    ? { datafile }
    : {
        datafile,
        eventDispatcher: {
          dispatchEvent,
        },
      };

  log('Optimizely Feature Experimentation create SDK client:', {
    clientOptions,
  });

  client = optimizely.createInstance(clientOptions);

  client
    .onReady()
    .then(finishClientInit)
    .catch((e) => {
      log('Optimizely Feature Experimentation SDK error on client is ready', e);
    });

  // listen to flag decision events in order to sync GA and Hotjar tracking
  subscribeToDecision();

  // listen to coookie consent change and reactivate experiments if necessary
  onConsentChange(() => {
    log('Optimizely Feature Experimentation consents changed');
    decideForImpressionEvents();
  });
}

export function finishClientInit() {
  log('Optimizely Feature Experimentation SDK client is ready');

  isReady = true;

  createUserContext();
  decideForImpressionEvents();
  trackEvents(lazyEvents);
  lazyEvents = [];
}

export function getSDKClient() {
  return client;
}

// DATAFILE
export async function loadStaticExperimentsData() {
  const datafileUrl = getDatafileUrl(environment);

  return loadDatafile(datafileUrl);
}

// USER ID
export async function initOptimizelyUser() {
  userId =
    // check existing experiment user id in current domain cookies first
    getCookie(EXPERIMENT_USER_COOKIE_NAME) ||
    // generate a new unique user id if there is no existing one
    uuidv4();

  Analytics.sendEvent({
    event: 'interaction',
    target: 'optimizely full stack',
    action: 'assign user id',
    targetProperties: userId,
  });

  elb('optimizely-user-id assigned', {
    optimizely_user_id: userId,
  });

  sendNinetailedEvent('optimizely-user-id-assigned');

  // save in current domain cookies
  const options = __DEV__
    ? { expires: EXPERIMENT_COOKIES_MAX_AGE_DAYS, path: '/' }
    : {
        expires: EXPERIMENT_COOKIES_MAX_AGE_DAYS,
        path: '/',
        secure: true,

        // always save user id in a cookie that can be accessed in subdomains too
        // e.g. buy.sumup.com, app.sumup.com, fr.sumup.be, etc.
        domain: `.${Url.getSecondLevelDomain(window.location.hostname)}`,
      };

  setCookie(EXPERIMENT_USER_COOKIE_NAME, userId, options);
}

export function getUserId() {
  return userId;
}

// USER CONTEXT
export function createUserContext() {
  if (!userId) {
    return;
  }
  userContext = client.createUserContext(userId, savedAttributes);
}

export function getUserContext() {
  return userContext;
}

// USER ATTRIBUTES
export function setAttributesData(
  locale: string,
  request: RequestType = {} as RequestType,
) {
  const browserInfo = bowser.parse(navigator.userAgent);
  const browserName = browserInfo?.browser?.name;
  const browserVersion = browserInfo?.browser?.version;
  const deviceType = browserInfo?.platform?.type;
  const osName = browserInfo?.os?.name;
  const osVersion = browserInfo?.os?.version;

  const browserLanguage =
    (navigator.languages && navigator.languages[0]) || // chrome / firefox
    navigator.language; // other browsers;

  const toString = (obj: { [key: string]: string | string[] } = {}) =>
    Object.entries(obj)
      .map(
        ([key, value]) => `${key}=${Array.isArray(value) ? value[0] : value}`,
      )
      .join('&');
  const cookiesObject = getCookies();
  const cookiestring = toString(cookiesObject);
  const querystring = toString(request.query);
  const GAUserId = cookiesObject?.[GA_ID_COOKIE_NAME];

  // use pickBy to remove all falsey values like `undefined`` and `null`
  // empty strings are required for optimizely matchers to work properly
  const optimizelyAttributes = pickBy((value) => value === '' || !!value, {
    'locale': locale?.toLowerCase(),
    'pathname': request.pathname,
    'cookiestring': cookiestring,
    'querystring': querystring,
    'browser-language': browserLanguage,
    'browser-name': browserName,
    'browser-version': browserVersion,
    'device-type': deviceType,
    'os-name': osName,
    'os-version': osVersion,
    'is-returning-session': userId ? 'yes' : 'no',
    'referrer-url': document.referrer,
    'google-analytics-user-id': GAUserId,
    'shopExperience': SHOP_EXPERIENCE_ATTR,
    'country': geoCountry,
  });

  saveAttributes(optimizelyAttributes);
}

function setGeoCountry(request: RequestType = {} as RequestType) {
  geoCountry = request?.query?.[VERCEL_CDN_CACHE_PARAMS.GEO_COUNTRY] as string;
}

export function saveAttributes(attributes: Attributes = {}) {
  savedAttributes = { ...savedAttributes, ...attributes };
}

export function getAttributes() {
  return savedAttributes;
}

// FIRE IMPRESSION EVENTS FOR EXPERIMENTS AND FLAGS
export function decideForImpressionEvents() {
  if (!userContext) {
    return;
  }

  const { featureToggles } = getOptimizelyData();
  const runningFlags = Object.keys(featureToggles);
  const decideOptions = [OptimizelyDecideOption?.ENABLED_FLAGS_ONLY];
  const cookiesObject = getCookies();
  const activeCookieCategories =
    getActiveCookieCategoriesFromCookies(cookiesObject);
  const consentStatus = getConsentStatus(activeCookieCategories);
  if (!consentStatus?.performance) {
    decideOptions.unshift(OptimizelyDecideOption?.DISABLE_DECISION_EVENT);
  }

  // we call decide on the client just to track impression event
  // actual variation bucketing for rendering is happening on Edge
  const decisions = userContext.decideForKeys(runningFlags, decideOptions);

  Object.values(decisions).forEach((decision) => {
    const logParams = {
      experimentKey: decision.ruleKey,
      variationKey: decision.variationKey,
      userId,
      attributes: savedAttributes,
    };
    if (!consentStatus?.performance) {
      log(
        'Optimizely Feature Experimentation decide impression was blocked by missing consent:',
        logParams,
      );
    } else {
      log(
        'Optimizely Feature Experimentation decide impression success:',
        logParams,
      );
    }
  });
}

// OTHER TRACKING EVENTS
export function trackEvent(
  event: string | Event = { eventName: '', eventTags: undefined },
) {
  const hasStringEvent = typeof event === 'string';
  const eventName = hasStringEvent ? event : event.eventName;
  const eventTags = hasStringEvent ? {} : event.eventTags || {};

  if (!isReady) {
    lazyEvents.push({ eventName, eventTags });
    return;
  }

  if (!client || !userContext || !eventName) {
    return;
  }

  const cookiesObject = getCookies();
  const activeCookieCategories =
    getActiveCookieCategoriesFromCookies(cookiesObject);
  const consentStatus = getConsentStatus(activeCookieCategories);

  if (!consentStatus.performance) {
    log('Optimizely Feature Experimentation event was blocked by consent:', {
      eventName,
      userId,
      attributes: savedAttributes,
      eventTags,
    });
    return;
  }

  log('Optimizely Feature Experimentation event:', {
    eventName,
    userId,
    attributes: savedAttributes,
    eventTags,
  });

  userContext.trackEvent(eventName, eventTags);
}

export function trackEvents(eventsList: string[] | Event[] = []) {
  eventsList.forEach(trackEvent);
}

export function trackLogin() {
  trackEvent('websiteClickLoginLink');
}

export function trackSignup() {
  trackEvent('websiteClickSignupLink');
}

export function trackLoginAndSignup() {
  trackEvent('websiteClickLoginLinkAndSignupLink');
}

export function createUserTimers() {
  THRESHOLD_TIMES.forEach((time) => {
    const timeString = time < 60 ? `${time}_sec` : `${time / 60}_min`;
    const eventName = `userTimePerSession_${timeString}`;

    setTimeout(() => {
      trackEvent(eventName);
    }, time * 1000);
  });
}

// GOOGLE ANALYTICS & HOTJAR
export function subscribeToDecision() {
  const onDecision = (decisionObject: Decision) => {
    if (!isReady) {
      return;
    }

    log('Optimizely Feature Experimentation decision notification:', {
      decisionObject,
    });

    const isFlag = decisionObject.type === 'flag';
    if (!isFlag) {
      return;
    }

    // We can distinguish between a targeted rollout or an experiment based on decisionEventDispatched.
    // An experiment will have this value set to `true`.
    // A targeted delivery will return `false` as there is no dispatched event for rollouts.
    // https://docs.developers.optimizely.com/feature-experimentation/docs/set-up-google-analytics-4-ga4
    const isExperiment = decisionObject.decisionInfo?.decisionEventDispatched;
    if (isExperiment) {
      const experimentKey = decisionObject.decisionInfo?.ruleKey;
      const variationKey = decisionObject.decisionInfo?.variationKey;
      if (experimentKey && variationKey) {
        log('Optimizely Feature Experimentation activated GA event:', {
          decisionObject,
        });

        // send special event to GA for active experiment-variation pair
        Analytics.sendEvent({
          event: 'optimizely',
          customParameters: {
            optimizelyExperimentKey: experimentKey,
            optimizelyVariationKey: variationKey,
          },
        });
        elb('optimizely-experiment activated', {
          optimizely_experiment_key: experimentKey,
          optimizely_variation_key: variationKey,
        });

        sendNinetailedEvent('optimizely-experiment-activated');

        // activate hotjar recordings for active experiment-variation pair
        const heatmapTrigger = `${experimentKey}_${variationKey}`;
        const recordingsTag = `recording_${heatmapTrigger}`;

        Hotjar.track(heatmapTrigger, recordingsTag);
      }
    } else {
      const flagKey = decisionObject.decisionInfo?.flagKey;
      if (flagKey) {
        log('Optimizely Feature Experimentation feature decided GA event:', {
          decisionObject,
        });

        elb('optimizely-feature decided', {
          optimizely_feature_enabled: decisionObject.decisionInfo?.enabled,
          optimizely_flag_key: flagKey,
        });
      }
    }
  };

  log(
    'Optimizely Feature Experimentation subscribed to notifications:',
    optimizely.enums.NOTIFICATION_TYPES.DECISION,
  );

  client.notificationCenter.addNotificationListener(
    optimizely.enums.NOTIFICATION_TYPES.DECISION,
    onDecision,
  );
}

// LOGS
export function setShowOptimizelyLogsFlag(
  request: RequestType = {} as RequestType,
) {
  showOptimizelyLogs =
    !!request.query?.showOptimizelyLogs ||
    environment !== ENVIRONMENTS.PRODUCTION;
}

export function getShowOptimizelyLogsFlag() {
  return showOptimizelyLogs;
}

function log(...args: unknown[]) {
  if (showOptimizelyLogs && !__TEST__) {
    // eslint-disable-next-line no-console
    console.info(...args);
  }
}
