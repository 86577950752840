import { useContext } from 'react';

import SiteContext from '~/shared/providers/SiteContext';
import RequestContext from '~/shared/providers/RequestContext';
import { getFeatureFlag } from '~/shared/services/feature-flags';

export default function useFeatureFlag(featureId) {
  const site = useContext(SiteContext);
  const request = useContext(RequestContext);

  if (__TEST__) {
    return true;
  }

  const featureFlag = getFeatureFlag(featureId, site, request);

  return featureFlag;
}
