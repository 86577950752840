// credentials for sdk
export const NINETAILED_CLIENT_ID =
  process.env.NEXT_PUBLIC_NINETAILED_CLIENT_ID;
export const NINETAILED_ENVIRONMENT =
  process.env.NEXT_PUBLIC_NINETAILED_ENVIRONMENT;
export const NINETAILED_IS_ENABLED = !!(
  NINETAILED_CLIENT_ID && NINETAILED_ENVIRONMENT
);

export const NINETAILED_EXPERIENCE_DELIMITER = ';';
export const NINETAILED_VARIANT_CONNECTOR = '=';

export const NINETAILED_COOKIE_MAX_AGE_DAYS = 90;
export const NINETAILED_COOKIE_MAX_AGE_MS =
  1000 * 60 * 60 * 24 * NINETAILED_COOKIE_MAX_AGE_DAYS;
