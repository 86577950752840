export const SDK_URL =
  'https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js';

export const DOT_COM_SDK_ID = '39e13b80-142e-45b8-bc9d-5c24917fec5e';

// how to run OneTrust locally - https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/3157272326/OneTrust+Cookie+Banners+Developer+s+Guide#Local-Development
export const OTHER_ENVS_SDK_ID = '72b0d409-97fd-4426-81ca-b0f8bb925fa5';

export const VERCEL_DOMAIN_SDK_ID = '5667d606-3c71-4361-b99b-ca612a6fe5ac';

export const TEST_SUFFIX = '-test';

export const FEATURE_FLAGS = {
  // enables test version of onetrust SDK instead of production one
  // (test script is better for previewing changes as there is no CDN in front)
  USE_TEST_SCRIPT: 'useOneTrustTestScript',

  // enables log messages in browser console
  LOG_CHANGES: 'logOneTrustConsentChanges',
};

// https://app-uk.onetrust.com/cookies/categorizations?tab=Categories
export const COOKIE_CATEGORIES = {
  STRICTLY_NECESSARY: 'C0001',
  PERFORMANCE: 'C0002',
  FUNCTIONAL: 'C0003',
  TARGETING: 'C0004',
  SOCIAL_MEDIA: 'C0005', // not used now
};
export const COOKIE_CATEGORIES_NAMES = {
  [COOKIE_CATEGORIES.STRICTLY_NECESSARY]: 'strictlyNecessary',
  [COOKIE_CATEGORIES.PERFORMANCE]: 'performance',
  [COOKIE_CATEGORIES.FUNCTIONAL]: 'functional',
  [COOKIE_CATEGORIES.TARGETING]: 'targeting',
  [COOKIE_CATEGORIES.SOCIAL_MEDIA]: 'socialMedia',
};
