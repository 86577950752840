import { RequestCookies } from 'next/dist/server/web/spec-extension/cookies';

import isServer from '~/shared/util/is-server';
import {
  COOKIE_CATEGORIES,
  COOKIE_CATEGORIES_NAMES,
} from '~/shared/constants/onetrust';
import { ONE_TRUST_CONSENT } from '~/shared/constants/cookies';

let isReady = false;
let didFirstConsentChange = false;

type OneTrustConsentCallback = () => void;
type OneTrustSDKType = {
  OnConsentChanged: (_cb: OneTrustConsentCallback) => void;
  ToggleInfoDisplay: () => void;
  changeLanguage: (_locale: string) => void;
  initializeCookiePolicyHtml: () => void;
  GetDomainData?: () => {
    ConsentModel?: { Name?: 'opt-out' | 'opt-in' };
    ShowAlertNotice?: boolean;
  };
  AllowAll: () => void;
};
interface ModifiedWindow extends Window {
  OnetrustActiveGroups?: string | undefined;
  OneTrust?: OneTrustSDKType;
  gtag?: (...args: unknown[]) => void;
}

type ActiveCookieCategories = string[];
type CallbackFn = (_activeCategories: ActiveCookieCategories) => void;
type ConsentCookieObject = {
  groups?: string;
};

export type CookieObjectType = {
  [key: string]: string;
};

export interface ConsentStatusObject {
  allAccepted: boolean;
  onlyStrict: boolean;
  strictlyNecessary?: boolean;
  performance?: boolean;
  functional?: boolean;
  targeting?: boolean;
  socialMedia?: boolean;
}

const firstAcceptCallbacks: CallbackFn[] = [];
const changeCallbacks: CallbackFn[] = [];

export function init(locale: string): void {
  if (isServer || isReady) {
    return;
  }

  const windowRef: ModifiedWindow = window;
  const OneTrustSDK = windowRef.OneTrust;

  if (!OneTrustSDK) {
    return;
  }

  // HACK: OneTrust does not support German Luxembourg (de-LU) locale
  // their support suggested to use Dutch Luxembourg locale (nl-LU)
  // to store the translations for now until they fix it
  // track solution progress here - https://ideas.onetrust.com/ideas/OT-I-11995
  // once it is solved we need to remove this logic
  if (locale === 'de-LU') {
    OneTrustSDK.changeLanguage('nl-LU');
  }

  OneTrustSDK.OnConsentChanged(() => {
    const activeCookieCategories = getActiveCookieCategoriesFromWindow();
    const acceptedSomeCategories = activeCookieCategories?.find(
      (groupId) =>
        groupId === COOKIE_CATEGORIES.PERFORMANCE ||
        groupId === COOKIE_CATEGORIES.FUNCTIONAL ||
        groupId === COOKIE_CATEGORIES.SOCIAL_MEDIA ||
        groupId === COOKIE_CATEGORIES.TARGETING,
    );

    if (!didFirstConsentChange && acceptedSomeCategories) {
      firstAcceptCallbacks.forEach((callback: CallbackFn) => {
        callback(activeCookieCategories);
      });
    }

    changeCallbacks.forEach((callback: CallbackFn) => {
      callback(activeCookieCategories);
    });

    didFirstConsentChange = true;
  });

  isReady = true;

  // hack for opt-out countries(they need to have all cookies accepted by default)
  const domainData = OneTrustSDK.GetDomainData() || {};
  const needsConsent = domainData?.ConsentModel?.Name === 'opt-in';
  const showBanner = domainData.ShowAlertNotice;
  if (!needsConsent && showBanner) {
    // OneTrustSDK.AllowAll will close the banner, which is not the desired outcome
    // thus, we need to manually update the consent to granted
    windowRef.gtag('consent', 'update', {
      ad_storage: 'granted',
      analytics_storage: 'granted',
      functionality_storage: 'granted',
      personalization_storage: 'granted',
      security_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
    });
    windowRef.gtag('consent', 'default', {
      analytics_storage: 'granted',
      ad_storage: 'granted',
      ad_user_data: 'granted',
      ad_personalization: 'granted',
    });
    return;
  }

  if (!needsConsent) {
    OneTrustSDK.AllowAll();
  }
}

export function onFirstAccept(callback: CallbackFn): void {
  firstAcceptCallbacks.push(callback);
}

export function onConsentChange(callback: CallbackFn): void {
  changeCallbacks.push(callback);
}

export function parseActiveCookieCategories(
  activeCookieCategories: string,
): ActiveCookieCategories {
  if (!activeCookieCategories) {
    return [];
  }

  const parsedCategoriesList = activeCookieCategories
    .split(',')
    .reduce((memo, parts) => {
      if (!parts) {
        return memo;
      }

      const [categoryId, status] = parts.split(':');
      const isActive = categoryId && (!status || status === '1');

      if (isActive) {
        memo.push(categoryId);
      }

      return memo;
    }, []);

  return parsedCategoriesList;
}

// should be used mostly on the server side to get active cookie categories from OneTrust cookie
export function getActiveCookieCategoriesFromCookies(
  cookies: CookieObjectType | RequestCookies = {},
): ActiveCookieCategories {
  const consentCookieValue =
    typeof cookies.get === 'function'
      ? cookies.get(ONE_TRUST_CONSENT)?.value
      : cookies[ONE_TRUST_CONSENT];

  if (!consentCookieValue) {
    return [];
  }

  const decodedCookieValue = decodeURIComponent(consentCookieValue);
  const consentCookieObject: ConsentCookieObject = decodedCookieValue
    .split('&')
    .reduce((memo, part) => {
      const [key, value] = part.split('=');

      return {
        ...memo,
        [key]: value,
      };
    }, {});

  if (!consentCookieObject.groups) {
    return [];
  }

  const activeCookieCategories = parseActiveCookieCategories(
    consentCookieObject.groups,
  );

  return activeCookieCategories;
}

// should be used only on client to get active cookie categories from OneTrust global var
export function getActiveCookieCategoriesFromWindow(): ActiveCookieCategories {
  const windowRef: ModifiedWindow = window;
  const activeCookieCategories = parseActiveCookieCategories(
    windowRef.OnetrustActiveGroups,
  );

  return activeCookieCategories;
}

export function openCookieSettingsModal(): void {
  const windowRef: ModifiedWindow = window;
  const OneTrustSDK = windowRef.OneTrust;

  if (!OneTrustSDK) {
    return;
  }

  OneTrustSDK.ToggleInfoDisplay();
}

export function getConsentStatus(
  activeCategories: ActiveCookieCategories = [],
): ConsentStatusObject {
  const defaults = {
    allAccepted: true,
    onlyStrict: false,
    strictlyNecessary: true,
  };
  const status = Object.values(COOKIE_CATEGORIES)
    // strictly necessary cookies are always enabled
    .filter((v) => v !== COOKIE_CATEGORIES.STRICTLY_NECESSARY)
    .reduce((memo, categoryId) => {
      /* eslint-disable no-param-reassign */
      const categoryName = COOKIE_CATEGORIES_NAMES[categoryId];

      if (!categoryName) {
        return memo;
      }

      const isEnabled = activeCategories.includes(categoryId);

      if (!isEnabled) {
        memo.allAccepted = false;
      }

      memo[categoryName] = isEnabled;

      return memo;
      /* eslint-enable no-param-reassign */
    }, defaults);

  if (!status.allAccepted) {
    const hasSingleCategory = activeCategories.length === 1;
    const isStrict =
      activeCategories[0] === COOKIE_CATEGORIES.STRICTLY_NECESSARY;

    status.onlyStrict = hasSingleCategory && isStrict;
  }

  return status;
}

export function initOneTrustCookieList() {
  const windowRef: ModifiedWindow = window;
  const OneTrustSDK = windowRef.OneTrust;
  if (!OneTrustSDK) {
    return;
  }

  OneTrustSDK.initializeCookiePolicyHtml();
}
