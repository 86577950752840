import React from 'react';
import PropTypes from 'prop-types';

import Icon from '../Icon';

const FlagIcon = ({ countryCode, ...rest }) => {
  if (!countryCode) {
    return null;
  }

  const url = `https://circuit.sumup.com/icons/v2/flag_${countryCode}_16.svg`;

  return <Icon src={url} {...rest} />;
};

FlagIcon.propTypes = {
  countryCode: PropTypes.string,
};

const propsAreEqual = (prevProps, nextProps) =>
  prevProps.countryCode === nextProps.countryCode;
/**
 * @component
 */
export default React.memo(FlagIcon, propsAreEqual);
