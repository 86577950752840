import values from 'lodash/fp/values';
import {
  shape,
  arrayOf,
  string,
  oneOf,
  object,
  func,
  oneOfType,
  bool,
  number,
  node,
  element,
} from 'prop-types';

import { CONTENTFUL_PAGE_TYPES } from '~/shared/constants/pages';
import { DAYS, INFO_BANNER_BACKGROUNDS } from '~/shared/constants';
import * as ENVIRONMENTS from '~/shared/constants/environments';

export const childrenPropType = oneOfType([arrayOf(node), node]);

export const childrenRenderPropType = func;

export const richTextPropType = shape({
  content: arrayOf(object),
  nodeType: string,
  data: object,
});

export const captionPropType = oneOfType([string, element]);

const urlPropType = shape({
  href: string,
  protocol: string,
  host: string,
  hostname: string,
  port: string,
  pathname: string,
  search: string,
  path: string,
  query: oneOfType([object, string]),
  hash: string,
});

export const productPropTypes = shape({
  contentType: string,
  currency: string,
  currencyCode: string,
  expirationDate: string,
  hasPromo: bool,
  id: string,
  image: string,
  isActive: bool,
  original: shape({
    expirationDate: string,
    price: number,
    promoCode: string,
    promoPrice: number,
  }),
  price: string,
  productId: string,
  productName: string,
  promoCode: string,
  promoPrice: string,
  termsAndConditions: string,
  termsAndConditionsPromo: string,
  updatedAt: string,
});

export const feesPropType = shape({
  creditCardFee: string,
  debitCardFee: string,
  monthlyCost: string,
  universalFee: string,
  original: object,
  acceleratedFee: string,
  acceleratedInstallmentFee: string,
  economicFee: string,
  economicInstallmentFee: string,
});

export const imagePropType = shape({
  url: string,
  alt: string,
  width: number,
  height: number,
});

export const mediaPropType = shape({
  data: object,
  contentType: string,
});

export const partnerPropType = {
  title: string,
  url: string,
  logo: imagePropType,
};

export const secondaryLogoPropType = {
  title: string,
  link: string,
  logo: imagePropType,
};

export const requestPropType = shape({
  environment: oneOf(values(ENVIRONMENTS)),
  href: string,
  protocol: string,
  hostname: string,
  pathname: string,
  hash: string,
  query: oneOfType([string, object]),
  userAgent: string,
  cookies: object,
  setCookie: func,
  contentType: string,
  id: string,
});

export const countryPropType = {
  code: string,
  locales: arrayOf(string),
  multilingual: bool,
  name: string,
};

export const websitePropType = {
  country: object,
  domain: urlPropType,
  siteLocale: string,
};

export const infoBannerPropType = {
  id: string,
  text: richTextPropType,
  isClickable: bool,
  clickableLink: string,
  isHidden: bool,
  infoBannerOffset: number,
  background: oneOf(values(INFO_BANNER_BACKGROUNDS)),
  trackingId: string,
  optimizelyFullStackClickEvents: arrayOf(string),
};

export const footnoteConfigPropType = shape({
  footnoteAsNumber: bool,
  foonote: string,
});

export const sitePropType = shape({
  locale: string,
  country: shape(countryPropType),
  domain: urlPropType,
  websites: arrayOf(shape(websitePropType)),
  support: shape({
    email: string,
    phone: string,
    openingHours: string,
  }),
  loadOptimizely: bool,
});

export const pagePropType = shape({
  contentType: oneOf(values(CONTENTFUL_PAGE_TYPES)),
  footer: shape({
    disclaimer: string,
    learnMoreDropDownLabel: string,
    primaryLinks: arrayOf(object),
    secondaryLinks: arrayOf(object),
    localizedUrls: object,
  }),
  navigation: shape({
    ctaButtonLabel: string,
    ctaButtonUrl: string,
    ctaButtonTrackingId: string,
    links: arrayOf(object),
    logoLink: bool,
  }),
  route: shape({
    url: string,
  }),
  secondaryLogo: shape(secondaryLogoPropType),
  metaTitle: string,
  metaDescription: string,
  localizedUrls: object,
  sections: arrayOf(object),
});

export const pagePropsPropTypes = {
  request: requestPropType,
  site: sitePropType,
  page: pagePropType,
  products: object,
  fees: feesPropType,
  cardSchemes: arrayOf(string),
  footnoteConfig: footnoteConfigPropType,
  ntExperiencesVariantsMap: object,
};

export const subSitePropType = {
  title: string,
  url: string,
};

export const linkPropType = {
  url: string,
  label: string,
  badgeLabel: string,
  trackingId: string,
  openLinkInNewWindow: bool,
};

export const pagePropsPropType = shape(pagePropsPropTypes);

export const trackingContentEntryProps = {
  contentType: string,
  contentEntryName: string,
  contentEntryId: string,
};

export const trackingContentEntryPropType = shape(trackingContentEntryProps);

export const callbackWidgetPropType = shape({
  previewHeadline: string,
  previewMessage: string,
  availability: arrayOf(oneOf(DAYS)),
  availableHours: object,
  unavailableMessageHeadline: string,
  unavailableMessageContent: richTextPropType,
  hideWhenUnavailable: bool,
  duringAvailableTime: bool,
  available: bool,
  successMessageHeadline: string,
  successMessageContent: richTextPropType,
  unavailableSuccessMessageHeadline: string,
  unavailableSuccessMessageContent: richTextPropType,
  form: object,
  widgetImage: object,
});
