import { useContext } from 'react';
import { useTheme } from '@emotion/react';
import { useMedia } from '@sumup/circuit-ui';

import RequestContext from '~/shared/providers/RequestContext';
import isServer from '~/shared/util/is-server';
import { VIEWPORTS, VERCEL_CDN_CACHE_PARAMS } from '~/shared/constants';

export default function useViewportName() {
  const theme = useTheme();
  const request = useContext(RequestContext);

  const isMobile = useMedia(theme.breakpoints.untilKilo);
  const isTablet = useMedia(theme.breakpoints.untilMega);

  if (isServer) {
    return request?.query?.[VERCEL_CDN_CACHE_PARAMS.VIEWPORT] as string;
  }

  if (isMobile) {
    return VIEWPORTS.MOBILE;
  }

  if (isTablet) {
    return VIEWPORTS.TABLET;
  }

  return VIEWPORTS.DESKTOP;
}
