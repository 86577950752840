/* eslint-disable react/display-name */

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';

import InfoBanner from '../InfoBanner';
import TertiaryMenu from '../TertiaryMenu';
import * as NavigationService from '../../NavigationService';

import {
  childrenPropType,
  infoBannerPropType,
} from '~/shared/util/shared-prop-types';
import dataSelector from '~/shared/util/data-selector';
import { useScrolledPastThreshold } from '~/shared/hooks/use-scroll';
import useIsMobile from '~/shared/hooks/use-is-mobile';
import SideBarNavigation from '~/shared/components/SideBarNavigation';
import { VARIANTS } from '~/shared/components/SideBarNavigation/constants';

const slideIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const spacerStyles = ({
  theme,
  offsetTop = 0,
  isTertiaryMenuShowing = false,
}) => css`
  height: calc(${theme.spacings.nav.mobile} + ${offsetTop}px);

  ${theme.mq.nav} {
    height: calc(
      ${theme.spacings.nav.desktop} +
        ${isTertiaryMenuShowing ? theme.spacings.tertiaryNav.desktop : '0px'} +
        ${offsetTop}px
    );
  }
`;

const Spacer = styled('div')(spacerStyles);

const baseStyles = ({ theme }) => css`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  transition:
    transform 0.2s ease-in-out,
    background-color 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out;
  z-index: ${theme.zIndex.navigation};
  background-color: var(--cui-bg-normal);
`;

const pinnedStyles = ({ theme, isPinned }) =>
  isPinned &&
  css`
    position: fixed;
    box-shadow:
      0 0 0 1px rgba(12, 15, 20, 0.07),
      0 0 1px 0 rgba(12, 15, 20, 0.07),
      0 2px 2px 0 rgba(12, 15, 20, 0.07);
    ${theme.mq.nav} {
      animation: ${slideIn} 0.3s ease-in-out;
    }
  `;

const openStyles = ({ isMenuOpen }) =>
  isMenuOpen &&
  css`
    animation: none;
  `;

const withSideBarStyles = ({ displaySideBarNavigation }) =>
  displaySideBarNavigation &&
  css`
    & {
      position: fixed;
      animation: none;
    }
  `;

const Header = styled('header')(
  baseStyles,
  pinnedStyles,
  openStyles,
  withSideBarStyles,
);

const wrapperStyles = ({ theme }) => css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${theme.spacings.kilo};
  height: ${theme.spacings.nav.mobile};
  position: relative;
  z-index: ${theme.zIndex.navigation};

  ${theme.mq.nav} {
    padding: 0 ${theme.spacings.giga};
    height: ${theme.spacings.nav.desktop};
  }

  ${theme.mq.tera} {
    padding: 0;
    margin: 0 auto;
    max-width: ${theme.maxWidth};
  }
`;

const wrapperHiddenStyles = ({ isHidden, offsetHeight }) =>
  isHidden &&
  css`
    // Use important to overwrite margin-top in tera
    margin-top: -${offsetHeight}px !important;
    transition: margin 0.3s;
  `;

const wrapperVisibleStyles = ({ isTertiaryMenuShowing }) =>
  isTertiaryMenuShowing &&
  css`
    margin-top: 0;
    transition: margin 0.3s;
  `;

const Wrapper = styled('div')(
  wrapperStyles,
  wrapperVisibleStyles,
  wrapperHiddenStyles,
);

const sideBarWrapperStyles = ({ theme }) => css`
  margin: 0 auto;
  max-width: ${theme.maxWidth};
  position: relative;
`;

const SideBarWrapper = styled('div')(sideBarWrapperStyles);

const sideBarBaseStyles = ({ headerHeight }) => css`
  max-height: calc(85vh - ${headerHeight}px);
  overflow-y: scroll;
  box-shadow:
    0 0 0 1px rgba(12, 15, 20, 0.07),
    0 0 1px 0 rgba(12, 15, 20, 0.07),
    0 2px 2px 0 rgba(12, 15, 20, 0.07);
`;

const SideBar = styled('div')(sideBarBaseStyles);

/**
 * The Container wraps the navigation content.
 */
const Container = forwardRef(
  (
    {
      infoBanner,
      infoBannerRef,
      isMenuOpen,
      children,
      isPinned,
      offsetTop,
      hideInfoBannerOnPage,
      infoBannerOffset,
      extraLevelNavigationGroupLabel,
      extraLevelNavigationIcon,
      extraLevelNavigation,
      isTertiaryMenuShowing,
      sideBarNavigation,
      containerHeight,
      ctaButtonOptimizelyFullStackClickEvents,
      canBeHidden,
      navigationOffset,
      displaySideBarNavigation,
    },
    ref,
  ) => {
    const isMobile = useIsMobile();
    const scrolledPastThreshold = useScrolledPastThreshold(
      isMobile ? offsetTop : navigationOffset,
    );
    const isInfoBannerHidden = NavigationService.isInfoBannerHidden(
      isMenuOpen,
      scrolledPastThreshold,
      hideInfoBannerOnPage,
    );

    return (
      <Spacer
        offsetTop={offsetTop}
        isTertiaryMenuShowing={isTertiaryMenuShowing}
      >
        <Header
          data-selector={dataSelector('header', 'navigation')}
          isMenuOpen={isMenuOpen}
          isPinned={isPinned}
          displaySideBarNavigation={displaySideBarNavigation}
        >
          <InfoBanner
            ref={infoBannerRef}
            infoBannerOffset={infoBannerOffset}
            isHidden={isInfoBannerHidden}
            hideInfoBannerOnPage={hideInfoBannerOnPage}
            {...infoBanner}
          />
          <Wrapper
            ref={ref}
            isTertiaryMenuShowing={isTertiaryMenuShowing}
            isHidden={isPinned && isTertiaryMenuShowing}
            offsetHeight={containerHeight}
          >
            {children}
          </Wrapper>
          <TertiaryMenu
            label={extraLevelNavigationGroupLabel}
            icon={extraLevelNavigationIcon}
            isTertiaryMenuShowing={isTertiaryMenuShowing}
            ctaButtonOptimizelyFullStackClickEvents={
              ctaButtonOptimizelyFullStackClickEvents
            }
            canBeHidden={canBeHidden}
            {...extraLevelNavigation}
          />
          {displaySideBarNavigation && (
            <SideBarWrapper>
              <SideBar headerHeight={offsetTop}>
                <SideBarNavigation
                  variant={VARIANTS.COLLAPSABLE}
                  {...sideBarNavigation}
                />
              </SideBar>
            </SideBarWrapper>
          )}
        </Header>
      </Spacer>
    );
  },
);

Container.propTypes = {
  children: childrenPropType,
  isPinned: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
  infoBanner: PropTypes.shape(infoBannerPropType),
  infoBannerRef: PropTypes.object,
  offsetTop: PropTypes.number,
  hideInfoBannerOnPage: PropTypes.bool,
  infoBannerOffset: PropTypes.number,
  extraLevelNavigation: PropTypes.object,
  extraLevelNavigationGroupLabel: PropTypes.string,
  extraLevelNavigationIcon: PropTypes.object,
  isTertiaryMenuShowing: PropTypes.bool,
  sideBarNavigation: PropTypes.object,
  containerHeight: PropTypes.number,
  canBeHidden: PropTypes.bool,
  isZeroWidth: PropTypes.bool,
  ctaButtonOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  navigationOffset: PropTypes.number,
  displaySideBarNavigation: PropTypes.bool,
};

Container.defaultProps = {
  isPinned: false,
  isMenuOpen: false,
};

/**
 * @component
 */
export default Container;
