export const ACTIONS = {
  VIEW: 'view',
  CLICK: 'click',
  ADD: 'add',
} as const;

export const ENTITIES = {
  PRODUCT: 'product',
  PROMOTION: 'promotion',
  ORDER: 'order',
  CONTENT: 'content',
  PRODUCT_CATEGORY: 'product category',
  NAV: 'nav',
  LINK: 'link',
} as const;

export const TRIGGERS = {
  LOAD: 'load',
  CLICK: 'click',
  VISIBLE: 'visible',
  SUBMIT: 'submit',
} as const;

export const CONTEXTS = {
  EXPERIMENT: 'experiment',
  JOURNEY: 'journey',
  OFFER: 'offer',
} as const;

export const PROPERTIES = {
  DESCRIPTION: 'description',
} as const;
