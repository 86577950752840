import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline } from '@sumup/circuit-ui';
import isPropValid from '@emotion/is-prop-valid';

import * as Service from './DynamicHeadingService';

const baseStyles = ({ theme, chars, maxChars }) => css`
  margin-top: 0;
  clear: both;
  line-height: 1.2em;
  font-size: ${Service.calculateFontSize(chars, 24, 36, maxChars)}px;
  margin-bottom: ${theme.spacings.giga};

  ${theme.mq.kilo} {
    line-height: 1.2em;
    font-size: ${Service.calculateFontSize(chars, 32, 48, maxChars)}px;
  }

  ${theme.mq.mega} {
    line-height: 1.2em;
    font-size: ${Service.calculateFontSize(chars, 36, 60, maxChars)}px;
  }
`;

/**
 * A headline component that scales the text based on its length.
 */
const DynamicHeading = styled(Headline, {
  shouldForwardProp: (prop) => prop === 'as' || isPropValid(prop),
})(baseStyles);

DynamicHeading.propTypes = {
  /**
   * The amount of chars of the heading string.
   */
  chars: PropTypes.number.isRequired,
  /**
   * The maximum length of the heading string.
   */
  maxChars: PropTypes.number.isRequired,
  blacklist: PropTypes.object,
};

DynamicHeading.defaultProps = {
  'maxChars': 110,
  'size': 'one',
  'blacklist': {
    chars: true,
    maxChars: true,
  },
  'data-elbcontext': 'component:dynamic_heading',
};

/**
 * @component
 */
export default DynamicHeading;
