import { useEffect } from 'react';

import isServer from '~/shared/util/is-server';

export default function useMeasurePerformance(identifier = '') {
  const isSupported =
    !isServer &&
    identifier &&
    'performance' in window &&
    typeof window.performance.mark === 'function' &&
    typeof window.performance.measure === 'function';

  useEffect(() => {
    if (isSupported) {
      window.performance.mark(`${identifier}:end`);
      window.performance.measure(
        identifier,
        `${identifier}:start`,
        `${identifier}:end`,
      );
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isSupported) {
    window.performance.mark(`${identifier}:start`);
  }
}
