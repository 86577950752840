import find from 'lodash/fp/find';
import toUpper from 'lodash/fp/toUpper';

export function getLanguageLinks(
  locales = [],
  websites = [],
  localizedUrls = {},
) {
  return locales.reduce((allLinks, locale) => {
    const website = find({ siteLocale: locale }, websites);
    if (!website) {
      return allLinks;
    }
    const url = localizedUrls[locale] || website.domain;
    const label = toUpper(locale.slice(0, 2));
    allLinks.push({ url, label, locale });
    return allLinks;
  }, []);
}
