import {
  LIVE_AGENT_BUTTON_LOCALES,
  COUNTRIES_EN,
  LANGUAGES_EN,
} from '~/shared/constants/salesforce-liveagent';

export const getLocaleId = (locale) => LIVE_AGENT_BUTTON_LOCALES[locale];

export const getLocalization = (locale) => {
  const [language, country] = locale && locale.split('-');
  return {
    salesforceCountry: COUNTRIES_EN[country],
    salesforceLanguage: LANGUAGES_EN[language],
  };
};

export const openChat = () => {
  if (window.embedded_svc) {
    window.embedded_svc.onHelpButtonClick();
  }
};
