import isNumber from 'lodash/fp/isNumber';
import isString from 'lodash/fp/isString';

import {
  LOCALE_FORMATS,
  CURRENCY_SYMBOLS,
  SYMBOL_POSITIONS,
} from '~/shared/constants/currency';

export type CurrencyFormat = {
  prepend?: boolean;
  decimalSep?: string;
  thousandSep?: string;
  addSpace?: boolean;
};

export function prependSymbol(
  amount: string,
  symbol: string,
  addSpace: boolean,
) {
  return symbol + (addSpace ? '\xA0' : '') + amount;
}

export function appendSymbol(
  amount: string,
  symbol: string,
  addSpace: boolean,
) {
  return amount + (addSpace ? '\xA0' : '') + symbol;
}

export function addSymbol(
  amount: string,
  symbol: string,
  { addSpace = true, prepend = false } = {},
) {
  const symbolAddingFn = prepend ? prependSymbol : appendSymbol;

  return symbolAddingFn(amount, symbol, addSpace);
}

export function getCurrencyFormat(
  currency: string,
  locale: string,
): CurrencyFormat {
  const fallbackConfig = LOCALE_FORMATS[currency]?.default;
  const formatConfig = LOCALE_FORMATS[currency]?.[locale];

  const [symbolPosition, decimalSep, thousandSep, addSpace = false] =
    formatConfig || fallbackConfig;

  const prepend = symbolPosition === SYMBOL_POSITIONS.PREPEND;

  return {
    prepend,
    decimalSep: decimalSep as string,
    thousandSep: thousandSep as string,
    addSpace: addSpace as boolean,
  };
}

export function getCurrencySymbol(currency: string) {
  const currencySymbol = CURRENCY_SYMBOLS[currency] as string;
  return currencySymbol;
}

/**
 * @description Formats a numeric value or string to a string representation
 *              of a decimal number with custom digit groups, decimal separator,
 *              and thousands separator.
 *
 * @param {(string|number)} number - The number value to convert to a decimal
 *        string.
 * @param {object} [options] - Options object containing the values for
 *        groupLength, decimalSep, and thousandSep. `groupLength` is an integer
 *        representing the length of a group separated by the thousands
 *        separator. `decimalSep` is the symbol used for separating the integers
 *        from the decimal values, and `thousandSep` is the symbol used to
 *        separate the groups of thousands in the integer part.
 *
 * @return {string} A formatted number string.
 * @example
 *
 * formatNumber(1337.45);
 * // Result: '1,337.45'
 *
 */
export function formatNumber(
  num: string | number,
  { groupLength = 3, decimalSep = '.', thousandSep = ',' } = {},
) {
  if (!isNumber(num) && !isString(num)) {
    return num;
  }
  // Capture integer and decimal part of num
  const { integer, decimals } = getIntegerAndDecimals(num, decimalSep);

  // Format the integer part of the num by inserting thousands separators.
  const preGroupDigitPattern = `\\d(?=(\\d{${groupLength}})+$)`;
  const preGroupDigitRegex = new RegExp(preGroupDigitPattern, 'g');
  const preGroupDigitReplacement = `$&${thousandSep}`;
  const formattedInteger = integer.replace(
    preGroupDigitRegex,
    preGroupDigitReplacement,
  );

  if (Number(decimals) === 0) {
    return formattedInteger;
  }

  // Format remaining number, but adding decimal separator and decimals to
  // formatted integer string.
  return `${formattedInteger}${decimalSep}${decimals}`;
}

export function getIntegerAndDecimals(
  numberString: string | number,
  decimalSep: string,
) {
  const num = Number(numberString);
  const [integer, decimals] = num.toFixed(2).split('.');
  return decimalSep ? { integer, decimals } : { integer, decimals: '' };
}

/**
 * @description Converts an amount (i.e. a number inf form of a numeric value or
 *              string) to a currency string.
 *
 * @param {(integer|string)} amount - The amount to convert to a currency
 *        string.
 * @param {string} currency - A string identifying the target currency. For
 *         example 'EUR'.
 * @param {string} locale - The ISO locale for which to convert into a currency.
 *        For example 'de-DE'. Note, different countries with the same currency
 *        might have a different standard currency format.
 *
 * @return {string} The formatted currency string with added currency symbol,
 *         thousand separators, and decimal separator.
 *
 */
export function formatCurrency(
  amount: string | number,
  currency: string,
  locale = 'default',
): string | number {
  if (!currency || (!isNumber(amount) && !isString(amount))) {
    return amount;
  }

  // Build the locale-sensitive arguments for formatting money
  const currencyFormat = getCurrencyFormat(currency, locale);
  const currencySymbol = getCurrencySymbol(currency);
  const numberFormat = {
    decimalSep: currencyFormat.decimalSep,
    thousandSep: currencyFormat.thousandSep,
  };
  const formattedAmount = formatNumber(amount, numberFormat);
  const currencyString = addSymbol(
    formattedAmount,
    currencySymbol,
    currencyFormat,
  );

  return currencyString;
}

export function formatPercentage(
  amount?: string | number,
  currency?: string,
  locale: string = 'default',
) {
  if (typeof amount === 'undefined' || amount === '') {
    return undefined;
  }

  const currencyFormat = getCurrencyFormat(currency, locale);
  const { decimalSep, thousandSep } = currencyFormat;
  const decimalNumber = formatNumber(amount, {
    decimalSep,
    thousandSep,
  });

  return `${decimalNumber}%`;
}
