import React, { FC, useContext } from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';

import * as SocialIconsService from './SocialIconsService';

import SiteContext from '~/shared/providers/SiteContext';
import Link from '~/shared/components/Link';
import SocialIcon from '~/shared/components/icons/SocialIcon';
import { SocialIdsType } from '~/shared/components/icons/SocialIcon/SocialIcon';
import formatSelector from '~/shared/util/data-selector';
import { TrackingContentEntryType } from '~/shared/types/shared';

const iconLinkStyles = ({
  theme,
  withLabel = false,
}: {
  theme?: Theme;
  withLabel?: boolean;
}) => css`
  display: inline-block;
  margin-top: ${theme.spacings.kilo};
  margin-bottom: ${theme.spacings.kilo};
  margin-left: 0;
  margin-right: ${theme.spacings.giga};

  span {
    display: none;
  }

  ${theme.mq.mega} {
    margin-right: ${theme.spacings.tera};
    font-size: ${theme.typography.body.two.fontSize};

    span {
      display: block;
    }

    ${withLabel &&
    `
        display: inline-flex;
        align-items: center;
        
      `}
  }

  &:last-child {
    margin-right: 0;
  }

  &:hover,
  &:focus {
    fill: var(--cui-fg-accent-hovered);
    color: var(--cui-fg-accent-hovered);
  }
`;
const iconStyles = ({
  theme,
  withLabel = false,
}: {
  theme?: Theme;
  withLabel?: boolean;
}) => css`
  fill: var(--cui-fg-placeholder);
  width: ${theme.iconSizes.kilo};
  height: ${theme.iconSizes.kilo};
  transition: all ${theme.transitions.default};
  ${withLabel && `margin-right: ${theme.spacings.byte};`}
  &:hover,
  &:focus {
    fill: var(--cui-fg-accent-hovered);
    color: var(--cui-fg-accent-hovered);
    ${!withLabel && 'transform: scale(1.1);'}
  }
`;
const StyledLink = styled('a')(iconLinkStyles);
const StyledSocialIcon = styled(SocialIcon)(iconStyles);

export interface SocialIconsProps {
  trackingContentEntry?: TrackingContentEntryType;
  withLabel?: boolean;
}

const SocialIcons: FC<SocialIconsProps> = ({
  trackingContentEntry,
  withLabel = false,
}) => {
  const site = useContext(SiteContext);
  const icons = SocialIconsService.getIcons(site.meta);

  if (!icons.length) {
    return null;
  }

  return (
    <>
      {icons.map(
        (icon: { id: SocialIdsType; name: string; href: string }, i) => (
          <Link
            href={icon.href}
            key={i}
            trackingContentEntry={trackingContentEntry}
            trackingId={undefined}
          >
            <StyledLink
              title={icon.name}
              data-selector={formatSelector('link-social', 'footer')}
              withLabel={withLabel}
            >
              <StyledSocialIcon id={icon.id} withLabel={withLabel} />
              {withLabel && <span>{icon.name}</span>}
            </StyledLink>
          </Link>
        ),
      )}
    </>
  );
};

export default SocialIcons;
