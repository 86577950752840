import React from 'react';
import PropTypes from 'prop-types';
import ReactLazyHydration from 'react-lazy-hydration';

import useFeatureFlag from '~/shared/hooks/use-feature-flag';
import { childrenPropType } from '~/shared/util/shared-prop-types';

function LazyHydrate({ children, skipLazyHydration = false, ...rest }) {
  const featureFlag = useFeatureFlag('disableLazyHydration');
  const disableLazyHydration =
    __DEV__ || // always during `yarn dev` to catch hydration errors early during development
    process.env.NEXT_PUBLIC_GLOBAL_DISABLE_LAZY_HYDRATION || // global
    featureFlag || // per locale in Contentful
    skipLazyHydration; // per component

  if (disableLazyHydration) {
    return children;
  }

  return <ReactLazyHydration {...rest}>{children}</ReactLazyHydration>;
}

LazyHydrate.propTypes = {
  skipLazyHydration: PropTypes.bool,
  children: childrenPropType,
};

export default LazyHydrate;
