import { SUMUP_VERCEL_SUFFIX } from '~/shared/constants/websites';
import { DEFAULT_LOCALE } from '~/shared/constants/locales';
import { WEBSITE_HOSTNAMES_WITH_LOCALES } from '~/shared/constants/hostname-locales';

export function getLocaleFromHostname(
  host: string,
  queryHostname?: string,
): string | undefined {
  const isVercelOrLocalHost =
    host.includes(SUMUP_VERCEL_SUFFIX) || host.includes('localhost');

  if (isVercelOrLocalHost) {
    const locale = WEBSITE_HOSTNAMES_WITH_LOCALES[queryHostname];

    return locale || DEFAULT_LOCALE;
  }

  const cleanHost = host.replace(/^(?:www\.)?/i, '');

  return WEBSITE_HOSTNAMES_WITH_LOCALES[cleanHost];
}
