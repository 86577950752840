import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Image as CircuitImage } from '@sumup/circuit-ui';

import LazyImage from './components/LazyImage';
import * as ImageService from './ImageService';
import { lazyLoadPropType } from './shared';
import Picture from './components/Picture';

const baseStyles = css`
  position: relative;
  overflow: hidden;
`;
const Wrapper = styled('div')(baseStyles);

function Image({
  src = '',
  srcSet = [],
  width,
  height,
  lazyLoad = true,
  contentful = {},
  className = '',
  ...props
}) {
  if (!src) {
    return null;
  }

  const hasDimensions = ImageService.hasDimensions(width, height);
  const formattedSrc = ImageService.getSrc({ src, width, height }, contentful);
  const formattedSrcSet = ImageService.getSrcSet(srcSet, contentful);

  const isLazy = lazyLoad && hasDimensions;
  const imageProps = {
    src: formattedSrc,
    width,
    height,
    ...props,
  };

  return (
    <Wrapper className={className}>
      {isLazy ? (
        <LazyImage
          {...imageProps}
          srcSet={srcSet}
          formattedSrcSet={formattedSrcSet}
          contentful={contentful}
        />
      ) : (
        <Picture srcSet={srcSet} contentful={contentful}>
          <CircuitImage srcSet={formattedSrcSet} {...imageProps} />
        </Picture>
      )}
    </Wrapper>
  );
}

Image.propTypes = {
  /**
   * The source URL of the image.
   */
  src: PropTypes.string,
  /**
   * An array of images for different media sizes.
   */
  srcSet: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      size: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      contentful: PropTypes.object,
    }),
  ),
  /**
   * Provides alternative information if a user cannot view the image.
   */
  alt: PropTypes.string,
  /**
   * Image width in pixels.
   */
  width: PropTypes.number,
  /**
   * Image height in pixels.
   */
  height: PropTypes.number,
  /**
   * A set of media conditions.
   */
  sizes: PropTypes.string,
  /**
   * Image custom aspect ratio.
   */
  aspectRatio: PropTypes.number,
  /**
   * Enable image lazy-loading
   * It requires width and height props for correct aspect ratio of the image.
   */
  lazyLoad: lazyLoadPropType,
  /**
   * Configuration options for the Contentful Image API.
   * See https://www.contentful.com/developers/docs/references/images-api/
   */
  contentful: PropTypes.object,
  /**
   * Enables custom styles.
   */
  className: PropTypes.string,
};

/**
 * An image component with support for lazy-loading and the Contentful Image API
 */
export default Image;
