import React, { useContext } from 'react';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';
import toLower from 'lodash/fp/toLower';
import styled from '@emotion/styled';
import { elb } from '@elbwalker/walker.js';
import { Theme } from '@sumup/design-tokens';

import FooterSelect from '../FooterSelect';

import * as CountrySelectService from './CountrySelectService';

import SiteContext from '~/shared/providers/SiteContext';
import RequestContext from '~/shared/providers/RequestContext';
import FlagIcon from '~/shared/components/icons/FlagIcon';
import formatSelector from '~/shared/util/data-selector';
import {
  SELECTED_LOCALE_COOKIE,
  SELECTED_LOCALE_COOKIE_MAX_AGE_DAYS,
} from '~/shared/constants/cookies';
import useCookies from '~/shared/hooks/use-cookies';
import { DIRECTIONS } from '~/shared/components/Dropdown/constants';
import { LocalizedUrls } from '~/shared/api-controllers/route/transformers/localized-urls';

const flagIconStyles = ({ theme }: { theme?: Theme }) => css`
  display: block;
  position: absolute;
  top: ${theme.spacings.kilo};
  left: ${theme.spacings.kilo};
  width: 24px;
  height: 24px !important;
  pointer-events: none;
  z-index: 40;
`;

export const StyledFlagIcon = styled(FlagIcon)(flagIconStyles);

type Props = {
  id?: string;
  localizedUrls?: LocalizedUrls;
  label?: string;
  className?: string;
};
function CountrySelect({
  id = 'select-country',
  localizedUrls = {},
  label = '',
  className,
}: Props) {
  const [, setCookie] = useCookies();
  const site = useContext(SiteContext);
  const request = useContext(RequestContext);

  const { websites = [], locale } = site;
  const activeWebsites = CountrySelectService.getActiveWebsites(
    request.host,
    request.protocol,
    websites,
    localizedUrls,
  );
  if (isEmpty(activeWebsites)) {
    return null;
  }

  const selectedWebsite = CountrySelectService.getSelectedWebsite(
    locale,
    request.host,
    request.protocol,
    websites,
    localizedUrls,
  );

  const mapToDropdownItem = (website) => ({
    label: website.countryName,
    href: website.url,
    countryCode: website.countryCode,
    locale: website.locale,
  });
  const items = activeWebsites.map(mapToDropdownItem);
  const selectedItem = mapToDropdownItem(selectedWebsite);

  const handleCountryChange = async (website, formattedUrl) => {
    elb('country selected', {
      button_description: 'select_locale@footer',
      option: website.locale,
    });

    setCookie(SELECTED_LOCALE_COOKIE, website.locale?.toLowerCase(), {
      expires: SELECTED_LOCALE_COOKIE_MAX_AGE_DAYS,
    });

    window.location.assign(formattedUrl);
  };

  return (
    <FooterSelect
      id={id}
      items={items}
      initialItem={selectedItem}
      direction={DIRECTIONS.UP}
      placeholder={label}
      renderLabelPrefix={({ item }) => (
        <StyledFlagIcon countryCode={toLower(item.countryCode)} />
      )}
      onChange={handleCountryChange}
      data-selector={formatSelector('select_country', 'footer')}
      aria-label={label}
      hasPrefix
      className={className}
    />
  );
}

export default CountrySelect;
