import React from 'react';
import dynamic from 'next/dynamic';
import styled from '@emotion/styled';
import { css, SerializedStyles } from '@emotion/react';
import keys from 'lodash/fp/keys';
import isEmpty from 'lodash/fp/isEmpty';
import { clearfix } from '@sumup/circuit-ui/legacy';

import useEditButton from '~/shared/hooks/use-edit-button';
import LazyHydrate from '~/shared/components/LazyHydrate';
import {
  BACKGROUNDS,
  TYPES,
  HIDE_ON,
  BackgroundType,
  BreakpointSpacingsType,
  HideOnType,
  DirectionType,
  Breakpoint,
} from '~/shared/constants/sections';
import { VIEWPORTS } from '~/shared/constants';
import { getTheme } from '~/shared/styles/themes';
import { DarkModeProvider } from '~/shared/providers/DarkModeContext';

const EditButton = dynamic(
  () =>
    import(
      /* webpackChunkName: 'component-EditButton' */ '~/shared/components/editor/EditButton'
    ),
  {
    ssr: false,
  },
);

const baseTheme = getTheme();
const BREAKPOINT_SPACING = baseTheme.spacings.section;

const baseStyles = (): SerializedStyles => css`
  ${clearfix()};
  display: block;
  clear: both;
  position: relative;
`;

const getSpacingStyles = ({
  theme,
  spacings,
  direction,
}: {
  theme?: typeof baseTheme;
  spacings: BreakpointSpacingsType;
  direction: DirectionType;
}): SerializedStyles => {
  const breakpoints = {
    [VIEWPORTS.MOBILE]: theme.mq.untilKilo,
    [VIEWPORTS.TABLET]: theme.mq.kiloToMega,
    [VIEWPORTS.DESKTOP]: theme.mq.mega,
  };

  if (!spacings) {
    return null;
  }

  const sectionBreakpoints = keys(spacings) as Breakpoint[];

  const styles = sectionBreakpoints
    .map((breakpoint) => {
      const spacingConf = spacings[breakpoint];

      if (!spacingConf) {
        return '';
      }

      const size = spacingConf[direction];

      if (!size) {
        return '';
      }

      const spacing = BREAKPOINT_SPACING[breakpoint][size];

      return `
        ${breakpoints[breakpoint]} {
          padding-${direction}: ${spacing};
        }
      `;
    })
    .join(' ');

  return css`
    ${styles};
  `;
};

const visibilityStyles = ({
  theme,
  hideOn = [],
}: {
  theme?: typeof baseTheme;
  hideOn: string[];
}): SerializedStyles => {
  const breakpoints = {
    [HIDE_ON.DESKTOP]: theme.mq.mega,
    [HIDE_ON.MOBILE]: theme.mq.untilKilo,
    [HIDE_ON.TABLET]: theme.mq.kiloToMega,
  };

  const hideOnBreakpointStyle = (hideOnBreakpoint: HideOnType) => {
    const breakpoint = breakpoints[hideOnBreakpoint];

    return `
      ${breakpoint} {
        display: none;
      }
    `;
  };

  const cssDefinitions = hideOn.map(hideOnBreakpointStyle).join(' ');

  return css`
    ${cssDefinitions};
  `;
};

const spacingsTopStyles = ({
  theme,
  spacings,
}: {
  theme?: typeof baseTheme;
  spacings: BreakpointSpacingsType;
}): SerializedStyles => getSpacingStyles({ theme, spacings, direction: 'top' });

const spacingsBottomStyles = ({
  theme,
  spacings,
}: {
  theme?: typeof baseTheme;
  spacings: BreakpointSpacingsType;
}): SerializedStyles =>
  getSpacingStyles({ theme, spacings, direction: 'bottom' });

const backgroundStyles = ({
  background,
}: {
  background?: BackgroundType;
}): SerializedStyles => {
  switch (background) {
    case BACKGROUNDS.BLACK:
    case BACKGROUNDS.WHITE: {
      return css`
        background-color: var(--cui-bg-normal);
      `;
    }
    case BACKGROUNDS.PEARL: {
      return css`
        background-color: var(--cui-bg-highlight);
      `;
    }
    case BACKGROUNDS.LIGHTGREY: {
      // Deprecated
      return css`
        background-color: var(--cui-bg-subtle);
      `;
    }
    default: {
      return null;
    }
  }
};

const heroAndPricingCardsStyles = ({
  theme,
  contentType,
}: {
  theme?: typeof baseTheme;
  contentType: string;
}): SerializedStyles =>
  contentType === TYPES.HERO &&
  css`
    &.su-hero + .su-pricingCards {
      ${theme.mq.kilo} {
        margin-top: -142px;
      }

      ${theme.mq.mega} {
        margin-top: -166px;
      }
    }
  `;

const internalLinkStyles = ({
  spacings,
  theme,
}: {
  spacings: BreakpointSpacingsType;
  theme?: typeof baseTheme;
}): SerializedStyles => {
  const breakpoints = {
    [VIEWPORTS.MOBILE]: theme.mq.untilKilo,
    [VIEWPORTS.TABLET]: theme.mq.kiloToMega,
    [VIEWPORTS.DESKTOP]: theme.mq.mega,
  };

  const sectionBreakpoints = keys(spacings) as Breakpoint[];

  const topStyles = sectionBreakpoints
    .map((breakpoint) => {
      const spacingConf = spacings[breakpoint];

      if (!spacingConf) {
        return '';
      }

      const size = spacingConf.top;

      if (!size) {
        return '';
      }

      const spacing = BREAKPOINT_SPACING[breakpoint][size];
      const navigationHeight =
        breakpoint === VIEWPORTS.MOBILE
          ? theme.spacings.nav.mobile
          : theme.spacings.nav.desktop;

      return `
        ${breakpoints[breakpoint]} {
          top: calc((${navigationHeight} + ${spacing}) * -1);
        }
      `;
    })
    .join(' ');

  return css`
    ${topStyles};

    display: block;
    visibility: hidden;
    display: block;
    position: relative;
  `;
};

const soloDesignStyles = ({
  theme,
  soloDesign,
}: {
  theme?: typeof baseTheme;
  soloDesign: Record<string, string>;
}): SerializedStyles =>
  soloDesign &&
  css`
    ${soloDesign.backgroundColor &&
    css`
      background-color: ${soloDesign.backgroundColor};
    `}
    ${soloDesign.textColor &&
    css`
      color: ${soloDesign.textColor};
    `}

    ${theme.mq.kilo} {
      ${soloDesign.spacingTop &&
      css`
        padding-top: ${soloDesign.spacingTop};
      `}
      ${soloDesign.spacingBottom &&
      css`
        padding-bottom: ${soloDesign.spacingBottom};
      `}
    }

    ${theme.mq.untilKilo} {
      ${soloDesign.spacingTopMobile &&
      css`
        padding-top: ${soloDesign.spacingTopMobile};
      `}
      ${soloDesign.spacingBottom &&
      css`
        padding-bottom: ${soloDesign.spacingBottomMobile};
      `}
    }
  `;

const fullWidthStyles = ({
  isFullWidth,
}: {
  isFullWidth: boolean;
}): SerializedStyles =>
  isFullWidth
    ? css`
        width: 100vw;
      `
    : css`
        max-width: 1440px;
        margin: auto;
      `;

const InternalLink = styled('div')(internalLinkStyles);

const Wrapper = styled('section')<{
  background?: BackgroundType;
  contentType: string;
  spacings: BreakpointSpacingsType;
  hideOn: string[];
  soloDesign: Record<string, string>;
  isFullWidth: boolean;
}>(
  baseStyles,
  spacingsTopStyles,
  spacingsBottomStyles,
  backgroundStyles,
  heroAndPricingCardsStyles,
  visibilityStyles,
  soloDesignStyles,
  fullWidthStyles,
);

type SoloDesign = {
  backgroundColor?: string;
  textColor?: string;
  [key: string]: string;
};

type SectionProps = {
  children: React.ReactNode;
  contentType?: string;
  id?: string;
  index?: number;
  background?: BackgroundType;
  spacings?: BreakpointSpacingsType;
  hideOn?: HideOnType[];
  soloDesign?: SoloDesign;
  skipLazyHydration?: boolean;
  isFullWidth?: boolean;
};

export function transformPropsForDarkMode(
  background: BackgroundType,
  soloDesign: SoloDesign,
): {
  isActive: boolean;
  props: {
    'background'?: BackgroundType;
    'soloDesign': SoloDesign;
    'data-color-scheme'?: 'dark';
  };
} {
  const { backgroundColor, textColor, ...rest } = soloDesign;
  const darkBackgrounds: string[] = [
    BACKGROUNDS.BLACK,
    BACKGROUNDS.BLUE,
    BACKGROUNDS.SKY_BLUE,
    BACKGROUNDS.TRUE_BLUE,
  ];
  const darkBackgroundColors = ['#000', '#000000', '#1B1B1B'];
  const isDarkMode =
    darkBackgrounds.includes(background) ||
    darkBackgroundColors.includes(backgroundColor);

  if (!isDarkMode) {
    return {
      isActive: isDarkMode,
      props: { background, soloDesign },
    };
  }

  return {
    isActive: isDarkMode,
    props: {
      'background': BACKGROUNDS.BLACK,
      'soloDesign': rest,
      'data-color-scheme': 'dark',
    },
  };
}

/**
 * A wrapper component for sections. Handles shared features such as the
 * background, spacing, and edit button.
 */
const Section = ({
  children = {} as React.ReactNode,
  contentType = '',
  id = '',
  index,
  background = BACKGROUNDS.WHITE as BackgroundType,
  spacings = {},
  hideOn = [],
  soloDesign = {},
  skipLazyHydration,
  isFullWidth,
}: SectionProps): JSX.Element => {
  const loadEditButton = useEditButton();

  if (isEmpty(children)) {
    return null;
  }

  const darkMode = transformPropsForDarkMode(background, soloDesign);

  return (
    <Wrapper
      data-content-type={contentType}
      data-entry-id={id}
      contentType={contentType}
      spacings={spacings}
      hideOn={hideOn}
      isFullWidth={isFullWidth}
      {...darkMode.props}
    >
      <InternalLink id={id} spacings={spacings} />
      {/* skip lazy hydration for first section in viewport
      to get rid of unnecessary re-render on "visible" event */}
      <LazyHydrate
        whenVisible
        skipLazyHydration={skipLazyHydration || index === 0}
      >
        <DarkModeProvider value={darkMode.isActive}>
          {children}
        </DarkModeProvider>
      </LazyHydrate>
      {loadEditButton && (
        <LazyHydrate ssrOnly>
          <EditButton id={id}>Edit</EditButton>
        </LazyHydrate>
      )}
    </Wrapper>
  );
};

/**
 * @component
 */
export default Section;
