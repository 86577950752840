import React from 'react';
import styled from '@emotion/styled';

const StrikeThrough = styled.span`
  opacity: 0.85;
  display: inline-block;
  position: relative;
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  ::before {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    right: 0;
    border-top: 1px solid;
    border-color: inherit;
    transform: rotate(-10deg);
  }
`;

interface Props {
  /** The original fee. */
  children: React.ReactNode;
  promotionalFee?: string;
}

function Fee({ children, promotionalFee }: Props) {
  if (!promotionalFee) {
    return <>{children}</>;
  }

  return (
    <>
      <StrikeThrough>{children}</StrikeThrough> {promotionalFee}
    </>
  );
}

export default Fee;
