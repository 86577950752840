/*
 * Shared utils and functions used in both Optimizely SDK clients (browser and edge servers).
 */

import fetch from 'isomorphic-unfetch';

import { STATIC_DATA_APIS_ONE_DOMAIN } from './constants';
import { DatafileResponse, Datafile, FetchResponse } from './types';

import * as ENVIRONMENTS from '~/shared/constants/environments';

export function getDatafileUrl(environment?: string): string {
  const api = STATIC_DATA_APIS_ONE_DOMAIN;
  return environment === ENVIRONMENTS.PRODUCTION ||
    environment === ENVIRONMENTS.THETA
    ? api.production
    : api.staging;
}
export async function loadDatafile(
  fileApiUrl: string,
): Promise<DatafileResponse> {
  const { datafile = {} }: { datafile: Datafile } = await fetch(fileApiUrl)
    .then((res) => res.json() as unknown as FetchResponse)
    .catch(() => ({ experimentsMap: {}, datafile: {} }));

  return { datafile };
}
