import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';

import { ShopContext } from './context';
import { shopReducer } from './reducers';
import {
  addToOrder,
  removeFromOrder,
  retrieveOrder,
  updateLineItemQuantity,
} from './actions';
import { CLAYER_ORDER_ID, CLAYER_ORDER_LOCALE } from './constants';
import { CartState, ProductInfo } from './types';

import {
  getClient,
  PurchaseClientType,
} from '~/domains/shop/services/purchasable-client';
import SiteContext from '~/shared/providers/SiteContext';
import ProductsContext from '~/shared/providers/ProductsContext';
import { DEFAULT_LOCALE } from '~/shared/constants/locales';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import { hasShopIntegration } from '~/domains/shop/util/has-shop-integration';

const initialState: CartState = {
  order: null,
  status: 'idle',
};

export function ShopProvider({
  children,
  defaultCartState,
}: {
  children: React.ReactNode;
  defaultCartState?: CartState;
}) {
  const { locale = DEFAULT_LOCALE } = useContext(SiteContext);
  const { products } = useContext(ProductsContext);
  const { plans } = useContext(SiteContext);
  const clientRef = useRef<PurchaseClientType>();
  const { featureToggles } = useOptimizelyData();
  const shopIntegration = hasShopIntegration(featureToggles);

  const [cartState, dispatch] = useReducer(shopReducer, {
    ...initialState,
    ...(defaultCartState || {}),
  });

  const fetchOrder = useCallback(
    (orderId: string) =>
      retrieveOrder({
        orderId,
        client: clientRef.current,
        dispatch,
        siteProducts: Object.values(products),
        sitePlans: plans,
      }),
    [products, plans],
  );

  const addToCart = async (productInfoList: ProductInfo[]) =>
    addToOrder({
      dispatch,
      locale,
      cartState,
      client: clientRef.current,
      productInfoList,
      siteProducts: Object.values(products),
      sitePlans: plans,
    });

  const removeFromCart = async (id: string) =>
    removeFromOrder({
      dispatch,
      id,
      client: clientRef.current,
      cartState,
      siteProducts: Object.values(products),
      locale,
      sitePlans: plans,
    });

  const updateQuantity = async (id: string, quantity: number) =>
    updateLineItemQuantity({
      dispatch,
      client: clientRef.current,
      id,
      quantity,
      cartState,
      siteProducts: Object.values(products),
      locale,
      sitePlans: plans,
    });

  useEffect(() => {
    if (shopIntegration) {
      clientRef.current = getClient(locale);
    }
  }, [locale, shopIntegration]);

  useEffect(() => {
    const orderId = window.localStorage.getItem(CLAYER_ORDER_ID);
    const orderLocale = window.localStorage.getItem(CLAYER_ORDER_LOCALE);
    if (orderId && shopIntegration && orderLocale === locale?.toLowerCase()) {
      fetchOrder(orderId);
    }
  }, [fetchOrder, shopIntegration, locale]);

  if (!shopIntegration) {
    return <>{children}</>;
  }

  return (
    <ShopContext.Provider
      value={{
        order: cartState.order,
        status: cartState.status,
        error: cartState.error,
        addToCart,
        removeFromCart,
        updateQuantity,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
}
