import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

import { MenuItem, MenuItemGroup } from '../interfaces';
import { doesURLWithSegmentsMatch } from '../services/url-match';

type Props = {
  isOpen: boolean;
  isMobile?: boolean;
  group?: MenuItemGroup;
  item?: MenuItem;
};

export const useExpanded = ({ isOpen, isMobile, group, item }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const { asPath = '' } = useRouter();

  useEffect(() => {
    if (!isMobile) {
      return;
    }

    if (!!asPath && asPath !== '/' && (item || group)) {
      const pathnameWithLocale = asPath.split('?')[0];
      const pathname = pathnameWithLocale
        .split('/')
        .slice(1)
        .slice(1)
        .join('/');

      if (pathname === '/' || !pathname) {
        return;
      }

      if (group?.items?.length > 0) {
        setExpanded(
          group.items.some((menuItem) =>
            doesURLWithSegmentsMatch(asPath, menuItem?.destination?.url),
          ),
        );
        return;
      }

      if (item?.menuItemGroups?.length > 0) {
        setExpanded(
          item?.menuItemGroups?.some((menuItemGroup) =>
            menuItemGroup.items.some((menuItem) =>
              doesURLWithSegmentsMatch(asPath, menuItem?.destination?.url),
            ),
          ),
        );
      }

      return;
    }

    if (!isOpen) {
      setExpanded(false);
    }
  }, [isMobile, asPath, isOpen, item, group]);

  return {
    expanded,
    setExpanded,
  };
};
