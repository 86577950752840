export const DISPLAY_MODES = {
  SINGLE: 'Single',
  DOUBLE: 'Double',
};

export const SUPPORTED_FEE_TYPES = [
  // all locales
  'debitCardFee',

  // Brazil only
  'acceleratedFee',
  'acceleratedInstallmentFee',
  'economicFee',
  'economicInstallmentFee',

  // other locales
  'creditCardFee',
  'universalFee',
];

export const DATA_SELECTOR = 'feeBadge';

export const FORMATS = {
  [DISPLAY_MODES.SINGLE]: {
    sizes: {
      value: 32,
      percentage: 16,
      label: 13,
    },
    spacings: {
      label_left: 8,
    },
  },
  [DISPLAY_MODES.DOUBLE]: {
    sizes: {
      value: 24,
      percentage: 16,
      label: 13,
    },
    spacings: {
      label_left: 0,
    },
  },
};
