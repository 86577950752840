import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';

import LogoBranded, {
  BRAND_HEIGHT_DESKTOP,
  BRAND_HEIGHT_MOBILE,
} from '~/shared/components/LogoBranded';

const brandAreaStyles = ({ theme }: { theme: Theme }) => css`
  position: relative;
  margin-top: ${theme.spacings.mega};
  margin-bottom: ${theme.spacings.mega};
  height: ${BRAND_HEIGHT_MOBILE}px;

  ${theme.mq.tera} {
    height: ${BRAND_HEIGHT_DESKTOP}px;
    margin: calc(
        (${theme.spacings.nav.desktop} - ${BRAND_HEIGHT_DESKTOP}px) / 2
      )
      0;
  }
`;

const BrandArea = styled(LogoBranded)(brandAreaStyles);

export default BrandArea;
