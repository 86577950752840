import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import values from 'lodash/fp/values';

import { stripCurrency } from '~/shared/services/products';
import { PRICE_FORMATS } from '~/shared/constants/price';
import { footnoteConfigPropType } from '~/shared/util/shared-prop-types';

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: normal;
`;

const firstRowStyles = () => css`
  display: flex;
  align-items: baseline;
  font-weight: bold;
`;

const FirstRow = styled('div')(firstRowStyles);

const currencyContainerStyles = ({ textSize }) => css`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;
  font-size: ${textSize}px;
  line-height: 1;
`;

const CurrencyContainer = styled('div')(currencyContainerStyles);

const priceContainerStyles = ({
  textSize,
  marginRight,
  prependCurrencySymbol,
}) => css`
  font-size: ${textSize}px;
  line-height: 1;
  margin-left: ${prependCurrencySymbol ? marginRight : 0}px;
  margin-right: ${!prependCurrencySymbol ? marginRight : 0}px;
`;

const PriceContainer = styled('span')(priceContainerStyles);

const originalPriceStyles = ({ hasPromo }) => css`
  opacity: 0.92;
  display: inline-block;
  position: relative;
  ${hasPromo ? 'text-decoration: line-through;' : ''}
`;

const originalPriceSizeStyles = ({ textSize, marginTop }) => css`
  font-size: ${textSize}px;
  line-height: 1;
  margin-top: ${marginTop}px;
`;

const inlinePriceStyles = ({ hasPromo }) => css`
  ${hasPromo
    ? `
      font-weigth: bold;
      text-decoration: line-through;
    `
    : ''}
`;

const InlinePrice = styled('span')(inlinePriceStyles);

const OriginalPrice = styled('span')(originalPriceStyles);
const OriginalPriceSized = styled('span')(
  originalPriceStyles,
  originalPriceSizeStyles,
);

const asteriskStyles = ({ textSize, marginLeft }) => css`
  font-size: ${textSize}px;
  align-self: flex-start;
  margin-left: ${marginLeft}px;
`;

const Asterisk = styled('span')(asteriskStyles);

const installmentsContainerStyles = ({ marginBottom }) => css`
  margin-bottom: ${marginBottom}px;
`;

const InstallmentsContainer = styled('span')(installmentsContainerStyles);

const footnoteStyles = ({ asNumber }) => css`
  vertical-align: ${asNumber && 'super'};
  font-size: ${asNumber && 'x-small'};
  line-height: 1;
`;

const Footnote = styled('sup')(footnoteStyles);

const Price = ({
  product: {
    price,
    promoPrice,
    installments,
    currency,
    hasPromo,
    prependCurrencySymbol,
    footnoteConfig = { footnoteAsNumber: false, footnote: '' },
    productId,
  } = {},
  format,
  dataSelector,
}) => {
  if (!price) {
    return null;
  }
  const currentPrice = hasPromo ? promoPrice : price;
  const currentPriceWithoutCurrency = stripCurrency(currentPrice);
  let productProperties = '';
  if (productId) {
    productProperties += `name:${productId};`;
  }
  if (currentPriceWithoutCurrency) {
    productProperties += `price:${currentPriceWithoutCurrency}`;
  }

  return (
    <Fragment>
      {format ? (
        <PriceWrapper data-elb-product={productProperties}>
          <FirstRow className={`${hasPromo ? 'su-promo-price' : ''}`}>
            {!prependCurrencySymbol && (
              <PriceContainer
                textSize={format.sizes.current_price}
                marginRight={format.spacings.currency_right}
                prependCurrencySymbol={prependCurrencySymbol}
                data-selector={dataSelector}
              >
                {currentPriceWithoutCurrency}
              </PriceContainer>
            )}

            <CurrencyContainer textSize={format.sizes.currency}>
              {prependCurrencySymbol ? (
                <span>{currency}</span>
              ) : (
                <span>
                  {currency}
                  {footnoteConfig.footnote ? (
                    <Footnote asNumber={footnoteConfig.footnoteAsNumber}>
                      {footnoteConfig.footnote}
                    </Footnote>
                  ) : null}
                </span>
              )}
              {installments > 0 && (
                <InstallmentsContainer
                  marginBottom={format.spacings.installments_bottom}
                >{`${installments}x`}</InstallmentsContainer>
              )}
            </CurrencyContainer>

            {prependCurrencySymbol && (
              <PriceContainer
                textSize={format.sizes.current_price}
                marginRight={format.spacings.currency_right}
                prependCurrencySymbol={prependCurrencySymbol}
                data-selector={dataSelector}
              >
                {currentPriceWithoutCurrency}
              </PriceContainer>
            )}
            {footnoteConfig.footnote && prependCurrencySymbol ? (
              <Asterisk
                textSize={format.sizes.asterisk}
                marginLeft={format.spacings.asterisk_left}
              >
                {footnoteConfig.footnote}
              </Asterisk>
            ) : null}
          </FirstRow>
          {hasPromo && (
            <OriginalPriceSized
              textSize={format.sizes.old_price}
              marginTop={format.spacings.old_price_top}
              hasPromo={hasPromo}
            >
              {installments ? `${installments}x ${price}` : `${price}`}
            </OriginalPriceSized>
          )}
        </PriceWrapper>
      ) : (
        <Fragment>
          {hasPromo && (
            <Fragment>
              <OriginalPrice className="su-promo-price">
                {installments
                  ? `${installments}x ${currentPrice}`
                  : `${currentPrice}`}
              </OriginalPrice>{' '}
            </Fragment>
          )}
          <InlinePrice
            hasPromo={hasPromo}
            data-selector={dataSelector}
            data-elb-product={productProperties}
            className={hasPromo ? 'su-regular-price' : ''}
          >
            {installments ? (
              <>
                {`${installments}x ${price}`}
                {footnoteConfig.footnote ? (
                  <Footnote asNumber={footnoteConfig.footnoteAsNumber}>
                    {footnoteConfig.footnote}
                  </Footnote>
                ) : null}
              </>
            ) : (
              <>
                {price}
                {footnoteConfig.footnote ? (
                  <Footnote asNumber={footnoteConfig.footnoteAsNumber}>
                    {footnoteConfig.footnote}
                  </Footnote>
                ) : null}
              </>
            )}
          </InlinePrice>
        </Fragment>
      )}
    </Fragment>
  );
};

Price.propTypes = {
  product: PropTypes.shape({
    price: PropTypes.string,
    promoPrice: PropTypes.string,
    installments: PropTypes.number,
    currency: PropTypes.string,
    hasPromo: PropTypes.bool,
    prependCurrencySymbol: PropTypes.bool,
    footnoteConfig: footnoteConfigPropType,
  }),
  format: PropTypes.oneOf(values(PRICE_FORMATS)),
  dataSelector: PropTypes.string,
};

/**
 * @component
 */
export default Price;
