import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { DROPDOWN_HEIGHT, DIRECTIONS } from '../../constants';

const containerStyles = ({ direction }) => css`
  label: dropdown__container;
  width: 100%;
  height: ${DROPDOWN_HEIGHT}px;
  position: relative;
  z-index: ${direction === DIRECTIONS.DOWN ? 'auto' : '12'};
`;

// Forwarding ref due to warnings produced by downshift-js
// https://github.com/downshift-js/downshift/issues/731
const StyledContainer = styled('div')(containerStyles);
const Container = React.forwardRef((props, ref) => (
  <StyledContainer {...props} ref={ref} />
));
Container.displayName = 'Container';

export default Container;
