import { useState, useEffect } from 'react';

import isSlowConnection from '~/shared/util/is-slow-connection';

export default function useLiteMode(initialValue = false) {
  const [isLiteMode, setIsLiteMode] = useState(initialValue);

  useEffect(() => {
    const updatedMode = isSlowConnection();

    setIsLiteMode(updatedMode);
  }, []);

  return isLiteMode;
}
