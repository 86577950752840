import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';

import BadgeContainer from '../BadgeContainer';

import dataSelector from '~/shared/util/data-selector';
import { BADGE_BACKGROUNDS } from '~/shared/constants';

const DATA_SELECTOR = 'textBadge';

const badgeContainerStyles = () => css`
  max-width: 196px;
`;

const StyledBadgeContainer = styled(BadgeContainer)(badgeContainerStyles);

const bodyStyles = () => css`
  margin: 0;
  font-weight: bold;
`;

const StyledBody = styled(Body)(bodyStyles);

/**
 * TextBadge with duration label
 */
function TextBadge({ background, className, content }) {
  if (!content) {
    return null;
  }

  return (
    <StyledBadgeContainer
      className={className}
      background={background}
      data-selector={dataSelector('wrapper', DATA_SELECTOR)}
      data-elbcontext="component:text_badge"
    >
      <StyledBody>{content}</StyledBody>
    </StyledBadgeContainer>
  );
}

TextBadge.propTypes = {
  className: PropTypes.string,
  /**
   * selected background from Contentful
   */
  background: PropTypes.oneOf(BADGE_BACKGROUNDS),
  /**
   * the text of the badge
   */
  content: PropTypes.string,
};

/**
 * @component
 */
export default TextBadge;
