import React, { ReactElement, Ref, forwardRef } from 'react';
import { css, Theme } from '@emotion/react';
import styled from '@emotion/styled';
import { ButtonProps } from '@sumup/circuit-ui';

/**
 * This component is Circuit UI's ButtonGroup in version 4,
 * because from version 5 on it doesn't cover our use case
 */

export interface CircuitButtonGroupProps {
  children?:
    | (ReactElement<ButtonProps> | null | undefined)[]
    | ReactElement<ButtonProps>;
  /**
   * Direction to align the content. Either left/right
   */
  align?: 'left' | 'center' | 'right';
  /**
   * Whether to display buttons inline on mobile.
   */
  inlineMobile?: boolean;
  /**
   * The ref to the HTML DOM element.
   */
  ref?: Ref<HTMLDivElement>;
}

const getInlineStyles = ({ theme }: { theme?: Theme }) => css`
  flex-wrap: wrap;
  > button,
  > a {
    width: auto;
    &:not(:last-child) {
      margin-right: ${theme.spacings.mega};
      margin-bottom: 0;
    }
  }
`;

const baseStyles = ({ theme }: { theme?: Theme }) => css`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  width: 100%;
  > button,
  > a {
    width: 100%;
    &:not(:last-child) {
      margin-bottom: ${theme.spacings.mega};
    }
  }
  ${theme.mq.kilo} {
    ${getInlineStyles({ theme })};
  }
`;

const alignmentMap = {
  left: 'flex-start',
  center: 'center',
  right: 'flex-end',
} as const;

const alignmentStyles = ({ align = 'right' }: CircuitButtonGroupProps) => css`
  justify-content: ${alignmentMap[align]};
`;

const inlineMobileStyles = ({
  theme,
  inlineMobile = false,
}: { theme?: Theme } & CircuitButtonGroupProps) =>
  inlineMobile &&
  css`
    ${getInlineStyles({ theme })}
  `;

const Wrapper = styled('div')<CircuitButtonGroupProps>(
  baseStyles,
  alignmentStyles,
  inlineMobileStyles,
);

/**
 * Groups its Button children into a list and adds margins between.
 */
const CircuitButtonGroup = forwardRef(
  (
    { children, ...props }: CircuitButtonGroupProps,
    ref: CircuitButtonGroupProps['ref'],
  ) => (
    <Wrapper {...props} ref={ref}>
      {children}
    </Wrapper>
  ),
);

CircuitButtonGroup.displayName = 'CircuitButtonGroup';

export default CircuitButtonGroup;
