import React, { FC, useContext } from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import { Button } from '@sumup/circuit-ui';

import { MenuItem as MenuItemType } from '../../interfaces';
import { SHOP_CATALOG_PAGES_ZONE } from '../../constants';

import Link from '~/shared/components/Link';
import { PageContext } from '~/shared/providers/PageContext';
import SiteContext from '~/shared/providers/SiteContext';
import { ShopContext } from '~/domains/shop/providers/ShopProvider';
import { getStorefrontReviewCartLink } from '~/domains/shop/services/purchasable-client';
import { trackGoToCart } from '~/domains/shop/providers/ShopProvider/tracking';

export interface MobileStickyCtaProps {
  ctaItem?: MenuItemType;
  isSticky?: boolean;
}

const containerStyles = ({
  theme,
  isSticky,
}: {
  theme?: Theme;
  isSticky: boolean;
}) => css`
  display: none;

  ${theme.mq.untilMega} {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: ${theme.spacings.kilo};
    background-color: var(--cui-bg-normal);
    box-shadow: 0 -2px 2px 0 rgba(12, 15, 20, 0.07);
    z-index: ${theme.zIndex.underCircuitUIModal};
  }

  ${isSticky &&
  `
    animation-name: slidein;
    animation-duration: 0.3s;
    @keyframes slidein {
      from {
        transform: translateY(100%);
      }
      to {
        translateY(0);
      }
    }
  `}
`;
const Container = styled('div')(containerStyles);

export const MobileStickyCta: FC<MobileStickyCtaProps> = ({
  ctaItem,
  isSticky = false,
}) => {
  const page = useContext(PageContext);
  const { cartLabels, locale } = useContext(SiteContext);
  const { order, status } = useContext(ShopContext);

  if (!isSticky) {
    return null;
  }

  const isShopPage = page?.route?.zoneId === SHOP_CATALOG_PAGES_ZONE;
  if (isShopPage) {
    const isCartEmpty =
      (!order && status === 'idle') || order?.lineItems?.length === 0;
    if (isCartEmpty) {
      return null;
    }
    const isCartLoading = status !== 'idle';
    return (
      <Container data-selector="mobile-sticky-cta" isSticky={isSticky}>
        <Link
          href={getStorefrontReviewCartLink(locale, order?.id)}
          trackingId={''}
          trackingContentEntry={{}}
          onClick={() => {
            trackGoToCart({ order });
          }}
        >
          <Button
            variant="primary"
            stretch
            isLoading={isCartLoading}
            loadingLabel={cartLabels?.loadingLabel}
            disabled={isCartEmpty || isCartLoading}
          >
            {cartLabels?.goToCart}
          </Button>
        </Link>
      </Container>
    );
  }

  const hasCtaUrl = !!(ctaItem?.label && ctaItem?.destination?.url);
  if (!hasCtaUrl) {
    return null;
  }

  return (
    <Container data-selector="mobile-sticky-cta" isSticky={isSticky}>
      <Link
        href={ctaItem.destination?.url}
        trackingId=""
        trackingContentEntry={{}}
      >
        <Button variant="primary" stretch>
          {ctaItem.label}
        </Button>
      </Link>
    </Container>
  );
};
