import isNil from 'lodash/fp/isNil';
import omit from 'lodash/fp/omit';
import omitBy from 'lodash/fp/omitBy';
import pick from 'lodash/fp/pick';

import * as Url from '~/shared/services/url';
import {
  CONTENTFUL_SUPPORTED_PARAMS,
  CONTENTFUL_DEFAULT_IMAGE_QUALITY,
} from '~/shared/constants/contentful';

export default function formatContentfulUrl(url, config) {
  if (!url) {
    return '';
  }

  const parsedUrl = Url.parse(url, 'https://images.ctfassets.net');
  const { query } = parsedUrl;
  const params = { q: CONTENTFUL_DEFAULT_IMAGE_QUALITY, ...config, ...query };
  const supportedParams = pick(CONTENTFUL_SUPPORTED_PARAMS, params);
  const truthyParams = omitBy(isNil, supportedParams);
  const parsedUrlWithoutParams = omit(['search', 'query'], parsedUrl);

  return Url.format({ ...parsedUrlWithoutParams, query: truthyParams }, false);
}
