import * as Url from '~/shared/services/url';
import { getOneDomainHost } from '~/shared/services/edge/edge-one-domain-redirects';
import { LiveWebsite } from '~/shared/api-controllers/site/transformers/config';
import { LocalizedUrls } from '~/shared/api-controllers/route/transformers/localized-urls';

export type CountrySelectWebsite = {
  countryName?: string;
  countryCode?: string;
  url?: string;
  locale?: string;
};

function getLocaleHomepage(_protocol?: string, host?: string, locale?: string) {
  const protocol = _protocol.endsWith(':') ? _protocol : `${_protocol}:`;
  const urlObj = new URL(`/${locale.toLowerCase()}/`, `${protocol}//${host}`);
  return urlObj.toString();
}

function getLocalizedUrl(
  website: LiveWebsite,
  localizedUrls: LocalizedUrls = {},
  host: string = '',
  protocol: string = 'http',
): string {
  const { siteLocale, domain } = website || {};
  const url =
    host && siteLocale
      ? getLocaleHomepage(protocol, getOneDomainHost(host), siteLocale)
      : Url.format(domain || {});
  const localizedUrl = localizedUrls[siteLocale];

  return localizedUrl || url || '';
}

function formatWebsite(
  host: string,
  protocol: string,
  website: LiveWebsite,
  localizedUrls: LocalizedUrls = {},
): CountrySelectWebsite {
  const countryName = website?.country?.name;
  const countryCode = website?.country?.code;
  const locale = website?.siteLocale;
  const url = getLocalizedUrl(website, localizedUrls, host, protocol);

  if (!countryName || !countryCode || !url) {
    return {};
  }

  return { countryName, countryCode, url, locale };
}

function isNotDefaultMultilingualLocale(website: LiveWebsite) {
  const isMultilingual = website?.country?.multilingual;
  const defaultLocale = website?.country?.defaultLocale;
  const currentLocale = website?.siteLocale;

  return isMultilingual && currentLocale !== defaultLocale;
}

export function getActiveWebsites(
  host: string,
  protocol: string,
  websites: LiveWebsite[] = [],
  localizedUrls: LocalizedUrls = {},
): CountrySelectWebsite[] {
  return websites.reduce<CountrySelectWebsite[]>((activeWebsites, website) => {
    if (!website.isVisible || isNotDefaultMultilingualLocale(website)) {
      return activeWebsites;
    }

    const formattedWebsite = formatWebsite(
      host,
      protocol,
      website,
      localizedUrls,
    );

    if (Object.keys(formattedWebsite)?.length) {
      activeWebsites.push(formattedWebsite);
    }

    return activeWebsites;
  }, []);
}

export function getSelectedWebsite(
  locale: string,
  host: string,
  protocol: string,
  websites: LiveWebsite[] = [],
  localizedUrls: LocalizedUrls = {},
): CountrySelectWebsite {
  const selectedWebsite = websites?.find(
    (website) => website.siteLocale === locale,
  );
  const formattedWebsite = formatWebsite(
    host,
    protocol,
    selectedWebsite,
    localizedUrls,
  );

  return formattedWebsite;
}
