import isEmpty from 'lodash/fp/isEmpty';
import max from 'lodash/fp/max';
import min from 'lodash/fp/min';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';

import {
  ICON_WIDTH as TERTIARY_ICON_WIDTH,
  MAX_WIDTH_LEFT_CONTAINER,
} from './components/TertiaryMenu/TertiaryMenu';
import { INFO_BANNER_DEFAULT_HEIGHT } from './components/InfoBanner/constants';

import { VIEWPORTS } from '~/shared/constants';

export const SCROLL_THRESHOLD = 700;

const OUTER_SPACING = 2 * 40;

const LOGO_WIDTH = 125;
const LOGO_HEIGHT = 36;
const DEFAULT_LOGO_RATIO = 4;
const DIVIDER_WIDTH = 32;
const SUBSITE_LETTER_WIDTH = 10;

const LINK_LETTER_WIDTH = 8;
const LINK_SPACING = 48;
const ICON_WIDTH = 16;

const LANGUAGE_SPACING = 16;

const BUTTON_LETTER_WIDTH = 8;
const BUTTON_SPACING = 48;

/** Tertiary Menu */
const LEFT_CONTAINER_PADDING = 24;
const ICON_RIGHT_MARGIN = 16;
const MENU_LINK_SPACING = 24;

const INFO_BANNER_EXCLUSION_OPTIONS = {
  partnerPage: 'Partner Pages',
  sbgPost: 'Blog Posts',
  sbgIndexPage: 'Blog Posts',
  sbgCategoryPage: 'Blog Posts',
  careersPage: 'Career Pages',
};

/**
 * Estimate the breakpoint at which the navigation should switch to a hamburger
 * menu, by adding together the widths of the navigation UI elements.
 * This method is preferable to querying the DOM for the widths since this
 * calculation can be done server-side and is more performant.
 */
export function calculateBreakpoint(
  props = {},
  country = {},
  fullWidth = false,
) {
  return max([
    firstLevelNavBarWidth(props, country, fullWidth),
    tertiaryMenuWidth(props, fullWidth),
  ]);
}

export function firstLevelNavBarWidth(
  props = {},
  country = {},
  fullWidth = false,
) {
  const brandAreaWidth = calculateBrandAreaWidth(props);
  const actionAreaWidth = calculateActionAreaWidth(props, country);
  const linksWidth = calculateLinksWidth(props, fullWidth);

  // Find the widest side that would overlap with the links first.
  const sideWidth = Math.max(brandAreaWidth, actionAreaWidth);

  return Math.round(OUTER_SPACING + 2 * sideWidth + linksWidth);
}

export function tertiaryMenuWidth(props = {}, fullWidth = false) {
  const leftContainerWidth = calculateLeftContainerWidth(props);
  const rightContainerWidth = calculateRightContainerWidth(props);
  const linksWidth = calculateCenterLinksWidth(
    props.extraLevelNavigation,
    fullWidth,
  );

  return leftContainerWidth + rightContainerWidth + linksWidth;
}

export function calculateBrandAreaWidth({
  subSite = {},
  partner = {},
  secondaryLogo = {},
}) {
  const { logo = {} } = isEmpty(partner) ? secondaryLogo : partner;

  let width = LOGO_WIDTH;

  const hasSubSiteTitle = !!subSite.title;
  const hasPartnerLogo = !!logo.url;

  if (hasSubSiteTitle) {
    width += subSite.title.length * SUBSITE_LETTER_WIDTH;
  }

  if (hasPartnerLogo) {
    const ratio =
      logo.width && logo.height ? logo.width / logo.height : DEFAULT_LOGO_RATIO;
    width += ratio * LOGO_HEIGHT;
  }

  if (hasSubSiteTitle || hasPartnerLogo) {
    width += DIVIDER_WIDTH;
  }

  return width;
}

export function calculateActionAreaWidth(
  { loginLabel, loginUrl, ctaButtonLabel, ctaButtonUrl },
  { multilingual, locales = [] },
) {
  const hasCtaButton = ctaButtonLabel && ctaButtonUrl;
  const hasLoginButton = loginLabel && loginUrl;
  const hasLanguageSwitcher = !!multilingual;

  let firstGroupWidth = 0;

  if (hasCtaButton) {
    firstGroupWidth +=
      ctaButtonLabel.length * BUTTON_LETTER_WIDTH + BUTTON_SPACING;
  }

  let secondGroupWidth = 0;

  if (hasLoginButton) {
    secondGroupWidth += loginLabel.length * LINK_LETTER_WIDTH + ICON_WIDTH;
  }

  if (hasLanguageSwitcher) {
    // Each language code is two letters long.
    secondGroupWidth +=
      locales.length * 2 * LINK_LETTER_WIDTH +
      locales.length * LANGUAGE_SPACING;
  }

  if (hasLoginButton && hasLanguageSwitcher) {
    secondGroupWidth += DIVIDER_WIDTH;
  }

  // Either the CTA button or the login button & language switcher are shown.
  return Math.max(firstGroupWidth, secondGroupWidth);
}

export function calculateLinksWidth({ links = [] }, fullWidth) {
  /* eslint-disable no-param-reassign */
  return links.reduce((totalWidth, link = {}) => {
    const linkWidth =
      LINK_SPACING + // The side padding and margin of each link
      (link.label ? link.label.length * LINK_LETTER_WIDTH : 0) + // The rough width of the link label
      (!isEmpty(link.links) ? ICON_WIDTH : 0); // Width of the dropdown icon

    if (fullWidth || !link.isCollapsible) {
      totalWidth += linkWidth;
    }
    return totalWidth;
  }, 0);
  /* eslint-enable no-param-reassign */
}

/** Calculate width of left container in Tertiary Menu */
export function calculateLeftContainerWidth({
  extraLevelNavigationGroupLabel,
  extraLevelNavigationIcon,
}) {
  let width = LEFT_CONTAINER_PADDING;

  if (!isEmpty(extraLevelNavigationIcon)) {
    width += TERTIARY_ICON_WIDTH;
    width += ICON_RIGHT_MARGIN;
  }

  if (extraLevelNavigationGroupLabel) {
    width += extraLevelNavigationGroupLabel.length * SUBSITE_LETTER_WIDTH;
  }

  return min([width, MAX_WIDTH_LEFT_CONTAINER]);
}

/** Calculate width of right container in Tertiary Menu */
export function calculateRightContainerWidth({ extraLevelNavigation = {} }) {
  const hasCtaButton =
    extraLevelNavigation.ctaButtonLabel && extraLevelNavigation.ctaButtonUrl;
  if (hasCtaButton) {
    return (
      extraLevelNavigation.ctaButtonLabel.length * BUTTON_LETTER_WIDTH +
      BUTTON_SPACING
    );
  }

  return 0;
}

/** Calculate width of center link in Tertiary Menu */
export function calculateCenterLinksWidth(extraLevelNavigation = {}) {
  const { links = [], fullWidth = false } = extraLevelNavigation;
  /* eslint-disable no-param-reassign */
  return links.reduce((width, link = {}) => {
    const linkWidth =
      // The side padding and margin of each link
      MENU_LINK_SPACING +
      // The rough width of the link label
      (link.label ? link.label.length * LINK_LETTER_WIDTH : 0);

    if (fullWidth || !link.isCollapsible) {
      width += linkWidth;
    }

    return width;
  }, 0);
  /* eslint-disable no-param-reassign */
}

export function isPinned(scrolledPastThreshold, isMobile, windowWidth) {
  if (windowWidth) {
    return isMobile || scrolledPastThreshold;
  }
  return false;
}

export function isInfoBannerHidden(
  isMenuOpen,
  scrolledPastThreshold,
  hideInfoBanner,
) {
  return hideInfoBanner || isMenuOpen || scrolledPastThreshold;
}

export function hasInfoBannerOnPage(
  infoBanner,
  hideInfoBanner,
  pageContentType,
  infoBannerExclusionList,
) {
  const pageType = INFO_BANNER_EXCLUSION_OPTIONS[pageContentType];
  if (
    pageType &&
    infoBannerExclusionList &&
    infoBannerExclusionList.includes(pageType)
  ) {
    return false;
  }
  if (!isEmpty(infoBanner) && !hideInfoBanner) {
    return true;
  }
  return false;
}

export function calculateInfoBannerHeight(viewportName, infoBannerContent) {
  if (viewportName !== VIEWPORTS.MOBILE || isEmpty(infoBannerContent)) {
    return INFO_BANNER_DEFAULT_HEIGHT;
  }

  const text = documentToPlainTextString(infoBannerContent);

  // One line of info banner takes up to 44 characters on 360px mobile screen
  const LINE_LENGTH = 44;
  const LINE_HEIGHT = 24;
  const PADDING = 24;

  const infoBannerHeight =
    Math.ceil(text.length / LINE_LENGTH) * LINE_HEIGHT + PADDING;
  return infoBannerHeight;
}
