export function getAspectRatio(width, height) {
  return width && height ? width / height : undefined;
}

export function getWidthFromHeight(height, aspectRatio) {
  return height && aspectRatio ? Math.round(height * aspectRatio) : undefined;
}

export function getHeightFromWidth(width, aspectRatio) {
  return width && aspectRatio ? Math.round(width / aspectRatio) : undefined;
}
