import keys from 'lodash/fp/keys';

const CHAT_ID = 'LiveAgent';
const SCRIPT_INJECTOR_CONFIG = {
  src: {
    production: 'https://service.force.com/embeddedservice/5.0/esw.min.js',
    development:
      'https://sumup--quality.my.salesforce.com/embeddedservice/5.0/esw.min.js',
  },
  async: true,
};

export const LIVE_AGENT_BUTTON_LOCALES = {
  'nl-NL': '5732p000000CslX',
  'nl-BE': '5732p000000Csl2',
  'fr-FR': '5732p000000CslC',
  'fr-BE': '5732p000000Csl3',
  'fr-LU': '5732p000000CslK',
  'fr-CH': '5732p000000CslU',
  'en-GB': '5732p000000CslY',
  'en-IE': '5732p000000CslG',
  'it-IT': '5732p000000CslH',
  'it-CH': '5732p000000CslW',
  'de-DE': '5732p000000CslD',
  'de-AT': '5732p000000Csl1',
  'de-CH': '5732p000000CslV',
  'de-LU': '5732p000000CslL',
  'pl-PL': '5732p000000CslO',
  'pt-BR': '573D0000000CcTY',
};

const SUPPORTED_COUNTRIES = keys(LIVE_AGENT_BUTTON_LOCALES);
const DEFAULT_LIVE_AGENT_BUTTON_COUNTRY = LIVE_AGENT_BUTTON_LOCALES['en-GB'];

const AVATAR_URL =
  'https://sumup.my.salesforce.com/servlet/servlet.FileDownload?file=015570000019uRz';
const WAITING_STATE_BACKGROUND_IMG_URL =
  'https://sumup.my.salesforce.com/servlet/servlet.FileDownload?file=015570000019uRu';
const SMALL_COMPANY_LOGO_IMG_URL =
  'https://sumup.my.salesforce.com/servlet/servlet.FileDownload?file=015570000019uRp';

const SALESFORCE_URL = {
  production: 'https://sumup.my.salesforce.com',
  development: 'https://sumup--quality.my.salesforce.com',
};
const SALESFORCE_CHAT_URL = {
  production: 'https://sumup.secure.force.com/Chat',
  development: 'https://quality-sumup.cs109.force.com/Chat',
};
const LIVE_AGENT_CONTENT_URL = {
  production: 'https://c.la1-c2-fra.salesforceliveagent.com/content',
  development: 'https://c.la1-c1cs-fra.salesforceliveagent.com/content',
};
const LIVE_AGENT_URL = {
  production: 'https://d.la1-c2-fra.salesforceliveagent.com/chat',
  development: 'https://d.la1-c1cs-fra.salesforceliveagent.com/chat',
};

const ORIG_ID = {
  production: '00DD0000000pnkQ',
  development: '00D0Q0000000Z1g',
};
const DEPLOYMENT_ID = '572D00000008Q3X';

const LIVE_AGENT_DEV_NAME =
  'EmbeddedServiceLiveAgent_Parent04I570000000006EAA_161715453d0';

export const getSalesforceConfig = (env) => ({
  CHAT_ID,
  SCRIPT_INJECTOR_CONFIG: {
    src: SCRIPT_INJECTOR_CONFIG.src[env],
    async: SCRIPT_INJECTOR_CONFIG.async,
  },
  LIVE_AGENT_BUTTON_LOCALES,
  SUPPORTED_COUNTRIES,
  DEFAULT_LIVE_AGENT_BUTTON_COUNTRY,
  AVATAR_URL,
  WAITING_STATE_BACKGROUND_IMG_URL,
  SMALL_COMPANY_LOGO_IMG_URL,
  SALESFORCE_URL: SALESFORCE_URL[env],
  SALESFORCE_CHAT_URL: SALESFORCE_CHAT_URL[env],
  ORIG_ID: ORIG_ID[env],
  LIVE_AGENT_CONTENT_URL: LIVE_AGENT_CONTENT_URL[env],
  DEPLOYMENT_ID,
  LIVE_AGENT_URL: LIVE_AGENT_URL[env],
  LIVE_AGENT_DEV_NAME,
});

export const getSalesforceEnv = (env) => {
  switch (env) {
    case 'production':
    case 'theta':
      return 'production';
    case 'staging':
    case 'dev':
    case 'test':
      return 'development';
    default:
      return false;
  }
};

export const COUNTRIES_EN = {
  AT: 'Austria',
  BE: 'Belgium',
  BG: 'Bulgaria',
  BR: 'Brazil',
  CH: 'Switzerland',
  CL: 'Chile',
  CO: 'Columbia',
  CN: 'China',
  CY: 'Cyprus',
  CZ: 'Czech Republic',
  DE: 'Germany',
  DK: 'Denmark',
  EE: 'Estonia',
  ES: 'Spain',
  FI: 'Finland',
  FR: 'France',
  GB: 'United Kingdom',
  GR: 'Greece',
  HU: 'Hungary',
  HR: 'Croatia',
  IE: 'Ireland',
  IT: 'Italy',
  LT: 'Lithuania',
  LV: 'Latvia',
  LU: 'Luxembourg',
  MT: 'Malta',
  NL: 'Netherlands',
  NO: 'Norway',
  PL: 'Poland',
  PT: 'Portugal',
  RO: 'Romania',
  SE: 'Sweden',
  SI: 'Slovenia',
  SK: 'Slovakia',
  US: 'United States',
};

export const LANGUAGES_EN = {
  bg: 'Bulgarian',
  cs: 'Czech',
  da: 'Danish',
  de: 'German',
  el: 'Greek',
  en: 'English',
  es: 'Spanish',
  et: 'Estonian',
  fi: 'Finnish',
  fr: 'French',
  hr: 'Croatian',
  hu: 'Hungarian',
  it: 'Italian',
  lt: 'Lithuanian',
  lv: 'Latvian',
  nb: 'Norwegian',
  nn: 'Norwegian',
  nl: 'Dutch',
  pl: 'Polish',
  pt: 'Portuguese',
  ro: 'Romanian',
  sk: 'Slovak',
  sl: 'Slovenian',
  sv: 'Swedish',
  zh: 'Mandarin',
};

export const ENABLED_WIDGET_BUTTON_LOCALES = ['es-CO'];

export const LIVEAGENT_INIT_SCRIPT_ID = 'salesforce-liveagent-init-script';
export const LIVEAGENT_SCRIPT_ID = 'salesforce-liveagent-script';

export const LIVEAGENT_ENABLE_FEATURE_FLAG = 'enableSalesforceLiveagent';
