import PropTypes from 'prop-types';

import * as ImageService from './ImageService';

// eslint-disable-next-line import/prefer-default-export
export const lazyLoadPropType = (props, propName, componentName) => {
  const { lazyLoad, width, height, src } = props;

  if (lazyLoad === true && !ImageService.hasDimensions(width, height)) {
    return new Error(
      `lazyLoad prop requires width and height props${src ? `for ${src}` : ''}`,
    );
  }

  return PropTypes.checkPropTypes(
    { lazyLoad: PropTypes.bool },
    props,
    propName,
    componentName,
  );
};
