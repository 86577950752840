import Downshift from 'downshift';

export function getItemToString(initialItem = {}) {
  return (item) => (item == null ? initialItem.label : item.label) || '';
}

export function getStateReducer() {
  return (state, changes) => {
    switch (changes.type) {
      // prevent downshift default state reset when clicking `esc`
      case Downshift.stateChangeTypes.keyDownEscape:
        return { ...state, isOpen: false };
      default:
        return changes;
    }
  };
}
