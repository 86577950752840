export function formatUtcDate(expirationDate: string, locale: string) {
  const dateTimeFormat = new Intl.DateTimeFormat(locale, {
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
  });
  const formatted = dateTimeFormat.format(new Date(expirationDate));

  return formatted;
}
