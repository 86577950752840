import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const baseStyles = ({ theme, inline, isTertiaryAnchor }) => css`
  display: ${inline ? 'inline-block' : 'block'};
  font-size: 18px;
  line-height: 1;
  padding: calc(${theme.spacings.giga} - 4px) 0;
  transition: color 0.1s ease-in-out;
  cursor: pointer;
  ${isTertiaryAnchor && 'color: var(--cui-bg-subtle);'}

  ${theme.mq.kilo} {
    padding: ${theme.spacings.giga} 0;
  }

  ${theme.mq.nav} {
    font-size: 16px;
    padding: ${theme.spacings.kilo};
    margin: ${theme.spacings.mega} 0;
  }
`;

const actionStyles = ({ isTertiaryAnchor }) => css`
  &:hover,
  &:focus {
    color: ${isTertiaryAnchor
      ? 'var(--cui-fg-on-strong-hovered)'
      : 'var(--cui-fg-accent-hovered)'};
  }

  &:active {
    color: ${isTertiaryAnchor
      ? 'var(--cui-fg-on-strong-pressed)'
      : 'var(--cui-fg-accent-pressed)'};
  }
`;

const verticalStyles = ({ theme, isVertical }) =>
  isVertical &&
  css`
    ${theme.mq.nav} {
      margin: ${theme.spacings.bit} 0;
      padding: ${theme.spacings.kilo} ${theme.spacings.mega};
    }
  `;

const currentStyles = ({ theme, ...props }) =>
  (props['aria-current'] === 'page' || props.isCurrent) &&
  css`
    font-weight: ${theme.fontWeight.bold};
  `;

const tertiaryCurrentStyles = ({
  theme,
  isTertiaryAnchor,
  isVertical,
  ...props
}) =>
  props['aria-current'] === 'page' &&
  isTertiaryAnchor &&
  css`
    color: var(--cui-fg-on-strong);
    ${isVertical
      ? 'box-shadow: inset 4px 0 var(--cui-border-normal);'
      : 'box-shadow: inset 0 -4px var(--cui-border-normal);'}
  `;

/**
 * A styled anchor tag for the navigation.
 */
const NavAnchor = styled('a')(
  baseStyles,
  actionStyles,
  verticalStyles,
  currentStyles,
  tertiaryCurrentStyles,
);

NavAnchor.propTypes = {
  'aria-current': PropTypes.oneOf(['page']),
  'inline': PropTypes.bool,
  'isTertiaryAnchor': PropTypes.bool,
  'isVertical': PropTypes.bool,
};

/**
 * @component
 */
export default NavAnchor;
