import { useContext, useState, useCallback } from 'react';
import { set, getAll } from 'es-cookie';

import isServer from '~/shared/util/is-server';
import CookiesContext from '~/shared/providers/CookiesContext';

export default function useCookies() {
  const requestCookies = useContext(CookiesContext);
  const [version, setVersion] = useState(1);

  const getCookies = useCallback(() => {
    if (isServer) {
      return requestCookies;
    }

    const clientCookies = getAll();

    return {
      ...requestCookies,
      ...clientCookies,
    };
  }, [requestCookies]);

  const setCookie = useCallback(
    (name, value, options) => {
      if (isServer) {
        return;
      }
      set(name, value, options);
      // Changing the version number causes the consuming component
      // to get the fresh cookies since the hook's return value changes.
      setVersion(version + 1);
    },
    [version],
  );

  return [getCookies, setCookie];
}
