export const TYPES = {
  BENEFIT_LIST: 'benefitList',
  CAREERS_POSITIONS_COMPONENT: 'careersPositionsComponent',
  CALL_TO_ACTION: 'callToAction',
  CAPTIONED_SHOWCASE: 'captionedShowcase',
  CARD_SCHEMES: 'cardSchemes',
  COSTS_LIST: 'costsList',
  COUNTRY_LIST: 'countryList',
  MULTISTEP_FORM: 'multiStepForm',
  FORM: 'form',
  FINE_PRINT: 'finePrint',
  GALLERY: 'gallery',
  HERO: 'hero', // Hero (full)
  HERO_NEW: 'heroNew', // Hero (framed)
  LONG_TEXT: 'longText',
  MADE_BY_SUMUP: 'madeBySumup',
  PARTNER_LOGOS: 'partnerLogos',
  POS_INQUIRY_FORM: 'posInquiryForm',
  POS_LEAD_FORM: 'posLeadForm',
  PRESS_ARTICLE: 'pressArticle',
  PRESS_ARTICLES: 'pressArticles',
  PRESS_COVERAGE: 'pressCoverage',
  PRESS_COVERAGES: 'pressCoverages',
  PRICING_CALCULATOR: 'pricingCalculator',
  PRICING_CARDS: 'pricingCards',
  PRICING_CARDS_BR: 'pricingCardsBr',
  PRICING_CARDS_WITH_CARD_SCHEMES: 'pricingCardsWithCardSchemes',
  PRICING_TABLE: 'pricingTable',
  PRICING_PLAN: 'pricingPlan',
  PLAN_COMPARISON: 'planComparison',
  PRODUCT_PAYMENT_METHODS: 'productPaymentMethods',
  SUMUP_PARTNER: 'sumupPartner',
  TERMS_AND_CONDITIONS: 'termsAndConditions', // Deprecated
  GENERIC_CAROUSEL: 'genericCarousel',
  EXTENDED_PRODUCT_COMPARISON: 'extendedProductComparison',
  COLUMN_LAYOUT: 'columnLayout',
  TRUSTPILOT: 'trustpilot',
  FULL_MEDIA: 'fullMedia',
  SUPPORT_SECTION: 'supportSection',
  EXPANSION_PANEL_LIST: 'expansionPanelList',
  MULTI_FEATURE_SHOWCASE: 'multiFeatureShowcase',
  EXPERIMENT: 'experiment',
  VARIATION_CONTAINER: 'variationContainer',
  IN_PAGE_NAVIGATION: 'inPageNavigation',
  FEE_CALCULATOR_UPDATED: 'feeCalc',
  GENERIC_IMAGE: 'genericImage',
  TESTIMONIAL: 'testimonial',
  EXTERNAL_VIDEO: 'externalVideo',
  BACKGROUND_VIDEO: 'backgroundVideo',
  SHOWCASE_EMBEDDED_VIDEO: 'showcaseEmbeddedVideo',
  FEE_OVERRIDE: 'feesConfiguration',
  FEE_OVERRIDE_BR: 'feesConfigurationBr',
  RICHTEXT_COLUMN: 'richtextColumn',
  EMBEDDED_VIDEO: 'embeddedVideo',
  EMBEDDED_BUTTON: 'embeddedButton',
  SHOP_CATALOG: 'shopCatalog',
  SHOP_CATALOG_ITEM: 'shopCatalogItem',
} as const;

type TypesKeys = keyof typeof TYPES;
export type ContentTypesType = (typeof TYPES)[TypesKeys];

export const BACKGROUNDS = {
  BLUE: 'blue',
  WHITE: 'white',
  LIGHTGREY: 'lightgrey',
  PEARL: 'pearl',
  SKY_BLUE: 'skyblue',
  TRUE_BLUE: 'trueblue',
  TANGERINE: 'tangerine',
  CORAL: 'coral',
  FRESH_LIME: 'freshlime',
  HONEY_YELLOW: 'honeyyellow',
  ORANGE: 'orange',
  PURPLE: 'purple',
  YELLOW: 'yellow',
  BLACK: 'black',
  RED: 'red',
  GREEN: 'green',
} as const;

type BackgroundKeys = keyof typeof BACKGROUNDS;
export type BackgroundType = (typeof BACKGROUNDS)[BackgroundKeys];

export const SPACINGS = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
} as const;

type SpacingsKeys = keyof typeof SPACINGS;
export type SpacingsType = (typeof SPACINGS)[SpacingsKeys];

export type DirectionType = 'top' | 'bottom';

export type Direction = {
  [key in DirectionType]?: SpacingsType;
};

export type Breakpoint = 'desktop' | 'tablet' | 'mobile';
export type BreakpointSpacingsType = {
  [key in Breakpoint]?: Direction;
};

export const HIDE_ON = {
  MOBILE: 'Hide on mobile',
  TABLET: 'Hide on tablet',
  DESKTOP: 'Hide on desktop',
} as const;

type HideOnKeys = keyof typeof HIDE_ON;
export type HideOnType = (typeof HIDE_ON)[HideOnKeys];
