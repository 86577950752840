import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { elb } from '@elbwalker/walker.js';

import * as inline from '~/shared/components/RichText/configs/inline';
import { infoBannerPropType } from '~/shared/util/shared-prop-types';
import RichText from '~/shared/components/RichText';
import dataSelector from '~/shared/util/data-selector';
import * as Analytics from '~/shared/services/analytics';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';

const DATA_SELECTOR = 'info_banner';

const wrapperBaseStyles = ({ theme }) => css`
  padding: ${theme.spacings.kilo};
  width: 100%;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--cui-fg-on-strong);

  a,
  a:visited {
    border-bottom: 0;
    text-decoration: underline;
    font-weight: bold;
    color: var(--cui-fg-on-strong);
  }

  a:hover,
  a:visited:hover,
  a:visited:active {
    color: var(--cui-fg-on-strong);
    opacity: 0.8;
  }
`;

const wrapperHiddenStyles = ({ offsetHeight }) => css`
  margin-top: -${offsetHeight}px;
  transition:
    visibility 0.3s,
    margin 0.3s;
  visibility: hidden;
`;

const wrapperVisibleStyles = () => css`
  margin-top: 0;
  transition: margin 0.3s;
`;

const wrapperIsClickableStyles = css`
  cursor: pointer;
`;

const wrapperBackgroundStyles = () => css`
  background-color: var(--cui-fg-accent);
`;

const wrapperStyles = (props) => css`
  ${wrapperBaseStyles(props)};
  ${(props.isHidden && wrapperHiddenStyles(props)) ||
  wrapperVisibleStyles(props)}
  ${props.isClickable && wrapperIsClickableStyles}
  ${wrapperBackgroundStyles()};
`;

const Wrapper = styled('div')(wrapperStyles);

/**
 * InfoBanner that shows important information on our website on top of nav
 */
const InfoBanner = React.forwardRef(
  (
    {
      text,
      isHidden,
      isClickable = false,
      clickableLink = false,
      hideInfoBannerOnPage = false,
      infoBannerOffset = 0,
      background,
      trackingId,
      optimizelyFullStackClickEvents,
      contentType,
      name,
      id,
    },
    ref,
  ) => {
    if (!text || hideInfoBannerOnPage) {
      return null;
    }

    const trackingContentEntry = {
      contentType,
      contentEntryName: name,
      contentEntryId: id,
    };

    const onClick = () => {
      if (isClickable) {
        Analytics.sendEvent({
          event: 'interaction',
          target: 'Mkt_Web',
          action: trackingId,
          destinationCategory: undefined,
          destinationUrl: clickableLink,
          ...trackingContentEntry,
        });
        sendNinetailedEvent(trackingId);

        elb('button clicked', {
          button_description: trackingId,
        });

        OptimizelyFullStack.trackEvents(optimizelyFullStackClickEvents);
        window.location.assign(clickableLink);
      }
    };

    return (
      <Wrapper
        ref={ref}
        isHidden={isHidden}
        isClickable={isClickable}
        offsetHeight={infoBannerOffset}
        data-selector={dataSelector('wrapper', DATA_SELECTOR)}
        onClick={onClick}
        background={background}
      >
        <RichText
          renderNode={inline.createRenderNode(inline.ONE, trackingContentEntry)}
          renderMark={inline.createRenderMark(inline.ONE, trackingContentEntry)}
          richText={text}
        />
      </Wrapper>
    );
  },
);

InfoBanner.displayName = 'InfoBanner';

InfoBanner.propTypes = infoBannerPropType;

/**
 * @component
 */
export default React.memo(InfoBanner);
