import bowser from 'bowser';

import isServer from '~/shared/util/is-server';

export const DEFAULT_VALUE = 'unknown';

// get prometheus labels for filtering in grafana dashboard
export default function getPerformanceLabels() {
  if (isServer) {
    return {};
  }

  // desktop vs. tablet vs. mobile
  const browserInfo = bowser.parse(navigator.userAgent);
  const deviceType = browserInfo?.platform?.type || DEFAULT_VALUE;

  // slow-2g vs. 2g vs. 3g vs. 4g
  // https://developer.mozilla.org/en-US/docs/Glossary/Effective_connection_type
  const connectionType = navigator.connection?.effectiveType || DEFAULT_VALUE;

  return {
    deviceType,
    connectionType,
  };
}
