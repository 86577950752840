import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Spinner } from '@sumup/circuit-ui';

import dataSelector from '~/shared/util/data-selector';

export const DATA_SELECTOR = 'loading_background';

const spinnerStyles = ({ theme }) => css`
  max-width: ${theme.iconSizes.giga};
  svg path {
    fill: var(--cui-fg-placeholder);
  }
`;

const StyledSpinner = styled(Spinner)(spinnerStyles);

const wrapperStyles = ({ isLoading }) => css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${isLoading ? 'var(--cui-bg-subtle)' : 'transparent'};
  transition: background-color 0.2s ease-out;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled('span')(wrapperStyles);

/**
 * Loading Background with spinner that spreads over parent element
 */
const LoadingBackground = ({ isLoading = false }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <Wrapper isLoading={isLoading}>
      <StyledSpinner
        role="progressbar"
        {...{
          // disable default aria attrs that are too distracting for the user in this case
          'role': null,
          'aria-busy': null,
          'aria-live': null,
        }}
        data-selector={dataSelector('spinner', DATA_SELECTOR)}
      />
    </Wrapper>
  );
};

LoadingBackground.propTypes = {
  /**
   * Is the background currently loading?
   */
  isLoading: PropTypes.bool,
};

/**
 * @component
 */
export default LoadingBackground;
