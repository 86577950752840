import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import get from 'lodash/fp/get';
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';
import { css } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';
import { spacing, focusVisible } from '@sumup/circuit-ui/legacy';

import { getIconSrcSet } from './SubMenuService';

import dataSelector from '~/shared/util/data-selector';
import Link from '~/shared/components/Link';
import Image from '~/shared/components/Image';
import { linkPropType, imagePropType } from '~/shared/util/shared-prop-types';
import { mapVariations } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import { NAVIGATION_EVENTS } from '~/shared/services/optimizely/navigation-revamp-experiment/constants';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';

const DATA_SELECTOR = 'main_nav_menu';
const ICON_WIDTH = 60;

const listStyles = ({ theme }) => css`
  display: flex;
  max-height: 0;
  overflow: hidden;
  list-style-type: none;
  background: var(--cui-bg-normal);
  opacity: 0;
  transition: max-height 0.15s ease-in-out;
  z-index: 1;
  min-width: 160px;
  max-width: 300px;

  ${theme.mq.nav} {
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
    display: flex;

    position: absolute;

    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-radius: ${theme.borderRadius.byte};
    max-height: none;
    overflow: hidden;
    visibility: hidden;
    pointer-events: none;

    border: ${theme.borderWidth.kilo} solid var(--cui-border-subtle);
    /* HACK: Hide the shadow at the top edge */
    &::before {
      display: block;
      content: '';
      position: absolute;
      top: -1px;
      right: 0;
      height: 2px;
      background-color: var(--cui-bg-normal);
    }

    /* NOTE: Extend the hover area so the submenu doesn't inadvertently close */
    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 100%;
      right: 0;
      height: ${theme.spacings.mega};
      background-color: transparent;
    }
  }
`;

const listWithIconStyles = ({ hasIcons }) =>
  hasIcons &&
  css`
    display: flex;
    flex-wrap: wrap;
  `;

const listOpenStyles = ({ theme, isOpen, links }) =>
  isOpen &&
  css`
    opacity: 1;
    max-height: calc(${links.length * 110}px + ${theme.spacings.mega});
    display: flex;
    flex-direction: column;

    ${theme.mq.nav} {
      max-height: none;
      visibility: visible;
      pointer-events: auto;
    }
  `;

const List = styled('ul')(listStyles, listWithIconStyles, listOpenStyles);

const listItemStyles = ({ theme, isOpen }) => css`
  &:last-of-type {
    margin-bottom: ${theme.spacings.mega};
  }

  ${theme.mq.nav} {
    text-align: center;
    white-space: nowrap;
    margin: 0;
    position: relative;

    &:last-of-type {
      margin-bottom: 0;
    }

    &:nth-last-of-type(n + 2) {
      margin-bottom: ${theme.borderWidth.kilo};

      &::after {
        content: '';
        display: block;
        position: absolute;
        width: calc(100% - 2 * ${theme.spacings.mega});
        height: ${theme.borderWidth.kilo};
        top: 100%;
        right: ${theme.spacings.mega};
        left: ${theme.spacings.mega};
        background-color: var(--cui-bg-subtle);
      }
    }
  }
  ${!isOpen && 'display: none;'}
`;

const listItemWithIconStyles = ({ theme, hasIcon }) =>
  hasIcon &&
  css`
    width: 50%;
    text-align: center;

    ${theme.mq.kilo} {
      width: 33.3333%;
    }

    ${theme.mq.nav} {
      width: auto;
    }
  `;

const ListItem = styled('li')(listItemStyles, listItemWithIconStyles);

const anchorBaseStyles = ({ theme }) => css`
  display: block;
  font-size: 16px;
  line-height: 1;
  text-align: left;
  padding: ${theme.spacings.kilo} 0;

  &:hover,
  &:focus {
    color: var(--cui-fg-accent-hovered);
    background-color: var(--cui-bg-normal-hovered);
  }

  &:active {
    color: var(--cui-fg-accent-pressed);
    background-color: var(--cui-bg-normal-pressed);
  }

  ${theme.mq.nav} {
    text-align: center;
    padding: ${theme.spacings.giga} ${theme.spacings.mega};
  }
`;

const anchorCurrentStyles = ({ theme, ...rest }) =>
  rest['aria-current'] === 'page' &&
  css`
    font-weight: ${theme.fontWeight.bold};
  `;

const anchorIconStyles = ({ hasIcon }) =>
  hasIcon &&
  css`
    display: flex;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  `;

const Anchor = styled('a')(
  anchorBaseStyles,
  anchorCurrentStyles,
  anchorIconStyles,
  focusVisible('inset'),
);

const badgeStyles = ({ theme, hasIcon }) => css`
  color: var(--cui-fg-warning);
  display: inline-block;
  margin-left: ${hasIcon ? 0 : theme.spacings.byte};

  ${theme.mq.nav} {
    display: block;
    margin-left: 0;
    margin-top: ${theme.spacings.bit};
  }
`;

const Badge = styled(Body)(badgeStyles);

const iconStyles = ({ width }) => css`
  display: inline-block;
  width: ${width}px;
`;

const Icon = styled(Image)(iconStyles);

const labelStyles = ({ hasIcon }) => css`
  display: ${hasIcon ? 'block' : 'inline'};
`;

const Label = styled('span')(labelStyles);

/**
 * Dropdown style sub menu
 */
function SubMenu({
  links: originalLinks = [],
  isOpen = false,
  onClick,
  ...rest
}) {
  const { experiments } = useOptimizelyData();
  const links = mapVariations(originalLinks, experiments);

  if (isEmpty(links)) {
    return null;
  }
  // icons should be shown only if ALL links have one
  const showIcons = !find((link) => !get('icon.url', link), links);

  const handleSubMenuClick = (label) => (event) => {
    OptimizelyFullStack.trackEvent({
      eventName: NAVIGATION_EVENTS.SECOND_LEVEL,
      eventTags: { label },
    });

    onClick(event);
  };

  return (
    <List
      links={links}
      isOpen={isOpen}
      hasIcons={showIcons}
      aria-hidden={!isOpen}
      hidden={!isOpen}
      {...rest}
    >
      {links.map(
        (
          {
            url,
            label,
            badgeLabel,
            icon,
            openLinkInNewWindow,
            contentType,
            name,
            id,
            linksGroupLabel,
            extraLevelNavigation,
            ...link
          },
          i,
        ) => (
          <ListItem
            key={i}
            hasIcon={showIcons}
            isOpen={isOpen}
            tabindex={!isOpen ? -1 : null}
          >
            <Link
              href={url}
              onClick={handleSubMenuClick(label)}
              target={openLinkInNewWindow ? '_blank' : undefined}
              trackingContentEntry={{
                contentType,
                contentEntryName: name,
                contentEntryId: id,
              }}
              {...link}
            >
              <Anchor
                hasIcon={showIcons}
                data-selector={dataSelector('sub_menu_link', DATA_SELECTOR)}
              >
                {showIcons && (
                  <Icon
                    src={icon.url}
                    alt={icon.alt}
                    lazyLoad={false}
                    width={ICON_WIDTH}
                    height={ICON_WIDTH}
                    srcSet={getIconSrcSet(icon.url, ICON_WIDTH)}
                  />
                )}
                <Label hasIcon={showIcons}>{label}</Label>
                {badgeLabel && (
                  <Badge
                    size="two"
                    as="span"
                    variant="highlight"
                    hasIcon={showIcons}
                    blacklist={{ hasIcon: true }}
                    css={spacing({ bottom: 'mega' })}
                  >
                    {badgeLabel}
                  </Badge>
                )}
              </Anchor>
            </Link>
          </ListItem>
        ),
      )}
    </List>
  );
}

SubMenu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      ...linkPropType,
      icon: imagePropType,
    }),
  ),
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

SubMenu.defaultProps = {
  'data-selector': `sub_menu@${DATA_SELECTOR}`,
};

/**
 * @component
 */
export default SubMenu;
