import React from 'react';

import {
  getInitialScreen,
  createScreens,
  buildScreensStack,
} from '../MobileMenuService';

import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import RequestContext from '~/shared/providers/RequestContext';
import SiteContext from '~/shared/providers/SiteContext';

const useMobileScreens = (links, defaultLinksGroupLabel) => {
  const { experiments } = useOptimizelyData();
  const site = React.useContext(SiteContext);
  const request = React.useContext(RequestContext);
  const { allScreens, linksToScreensMap } = React.useMemo(
    () => createScreens(experiments, defaultLinksGroupLabel, links),
    [defaultLinksGroupLabel, experiments, links],
  );
  const initialScreen = React.useMemo(
    () => getInitialScreen(allScreens, request, site),
    [request, allScreens, site],
  );

  const [screens, setScreens] = React.useState(() =>
    buildScreensStack(initialScreen, allScreens, linksToScreensMap),
  );

  const handleScreenChange = React.useCallback(
    ({ id, forward = true }) => {
      if (!linksToScreensMap[id]) {
        return;
      }

      if (forward) {
        const { toScreenId } = linksToScreensMap[id];
        const newScreen = allScreens[toScreenId || 'default'];

        setScreens((oldScreens) => {
          const newScreens = [...oldScreens];
          newScreens.push(newScreen);
          return newScreens;
        });
      } else {
        setScreens((oldScreens) => {
          const newScreens = [...oldScreens];
          newScreens.pop();
          return newScreens;
        });
      }
    },
    [linksToScreensMap, allScreens],
  );

  return {
    currentScreen: screens[screens.length - 1],
    screens,
    allScreens,
    linksToScreensMap,
    handleScreenChange,
  };
};

export default useMobileScreens;
