import * as ENVIRONMENTS from '~/shared/constants/environments';

export const ONE_DOMAIN_HOSTS = {
  [ENVIRONMENTS.PRODUCTION]: 'www.sumup.com',
  [ENVIRONMENTS.STAGING]: 'www.sam-app.ro',
  [ENVIRONMENTS.THETA]: 'theta.sam-app.ro',
};

export const SUMUP_DOMAINS_REGEX =
  /^([a-zA-Z]{2,3}(-[a-zA-Z]{2})?\.)?sumup\.[a-zA-Z]{2,3}(\.[a-zA-Z]{2})?$/;

export const STAGING_DOMAIN_REGEX =
  /^([a-zA-Z]{2,3}(-[a-zA-Z]{2})?\.)?sam-app\.ro?$/;

export const THETA_DOMAIN_REGEX =
  /^([a-zA-Z]{2}(-[a-zA-Z]{2})?-)?theta.sam-app\.ro?$/;
