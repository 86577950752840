import { PropsWithChildren, createContext, useContext } from 'react';

/**
 * Propagates whether the current section is rendered in dark mode.
 */
const DarkModeContext = createContext(false);

export function useDarkMode() {
  return useContext(DarkModeContext);
}

export function DarkModeProvider({
  value,
  children,
}: PropsWithChildren<{ value: boolean }>) {
  const parentValue = useDarkMode();

  return (
    <DarkModeContext.Provider value={parentValue || value}>
      {children}
    </DarkModeContext.Provider>
  );
}
