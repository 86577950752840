export const CLAYER_ORDER_ID = 'CLAYER_ORDER_ID';
export const CLAYER_ORDER_LOCALE = 'CLAYER_ORDER_LOCALE';

export const FETCH_ORDER = 'FETCH_ORDER';
export const FETCH_ORDER_SUCCESS = 'FETCH_ORDER_SUCCESS';
export const FETCH_ORDER_ERROR = 'FETCH_ORDER_ERROR';

export const UPDATE_QUANTITY_SUCCESS = 'UPDATE_QUANTITY_SUCCESS';
export const UPDATE_QUANTITY_ERROR = 'UPDATE_QUANTITY_ERROR';
export const UPDATING_QUANTITY = 'UPDATING_QUANTITY';

export const REMOVING_LINE_ITEM = 'REMOVING_LINE_ITEM';
export const LINE_ITEM_REMOVED = 'LINE_ITEM_REMOVED';
export const LINE_ITEM_ERROR = 'LINE_ITEM_ERROR';
export const LINE_ITEM_LIMITED_QUANTITY = 'LINE_ITEM_LIMITED_QUANTITY';
export const LINE_ITEM_REDUCED_QUANTITY = 'LINE_ITEM_REDUCED_QUANTITY';

export const ADDING_LINE_ITEM = 'ADDING_LINE_ITEM';
export const LINE_ITEM_ADDED = 'LINE_ITEM_ADDED';

export const MIN_QUANTITY = 1;
export const MAX_QUANTITY = 10;
export const MAX_QUANTITY_WITH_PROMO = 3;
export const getMaxQuantity = (hasPromo?: boolean) =>
  hasPromo ? MAX_QUANTITY_WITH_PROMO : MAX_QUANTITY;

export const SUMUP_ONE_SKU = 'SRV-SU1-001';
export const SUMUP_ONE_MAX_QUANTITY = 1;
