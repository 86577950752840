import omitBy from 'lodash/fp/omitBy';
import isNil from 'lodash/fp/isNil';

type VimeoParams = {
  src?: string;
  title?: string;
  autoplay?: boolean;
  mute?: boolean;
  loop?: boolean;
  transparent?: boolean;
  width?: number;
  height?: number;
  hideControls?: boolean;
};

// Only a subset of parameters is supported, the full list can be found at:
// https://help.vimeo.com/hc/en-us/articles/360001494447-Using-Player-Parameters
export function mapToVimeoParams(params: VimeoParams = {}) {
  const vimeoParams = {
    url: params.src,
    title: params.title,
    autoplay: params.autoplay,
    muted: params.mute,
    loop: params.loop,
    transparent: params.transparent,
    width: params.width,
    height: params.height,
    background:
      params.autoplay && params.loop && params.mute && params.hideControls,
  };

  return omitBy(isNil, vimeoParams);
}

export function extractVimeoId(src) {
  // From https://github.com/regexhq/vimeo-regex
  // eslint-disable-next-line max-len
  const regex =
    /(?:http|https)?:\/\/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^/]*)\/videos\/|video\/|)(\d+)(?:|\/\?)/i;
  const match = regex.exec(src);
  return match && match[1];
}

export function isVimeo(src) {
  const vimeoId = extractVimeoId(src);
  return !!vimeoId;
}
