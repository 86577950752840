import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Body } from '@sumup/circuit-ui';
import { spacing } from '@sumup/circuit-ui/legacy';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { MergeTag } from '@ninetailed/experience.js-next';

import EmbeddedVariable from '../components/EmbeddedVariable';
import EmbeddedLink from '../components/EmbeddedLink';
import { getFields, getContentType } from '../util';

import Anchor from '~/shared/components/Anchor';
import { NINETAILED_IS_ENABLED } from '~/shared/services/ninetailed/constants';

const soloDesignStyles = ({ theme, soloDesign = {} }) => css`
  margin-bottom: 0;

  ${soloDesign.textColor &&
  css`
    color: ${soloDesign.textColor};
  `}
  ${theme.mq.untilKilo} {
    ${soloDesign.textAlignContentMobile &&
    css`
      text-align: ${soloDesign.textAlignContentMobile};
    `}
    ${soloDesign.enableRoundedDesign &&
    css`
      font-size: 18px;
      line-height: 18px;
    `}
  }

  ${theme.mq.kilo} {
    ${soloDesign.textAlignContent &&
    css`
      text-align: ${soloDesign.textAlignContent};
    `}
    ${soloDesign.enableRoundedDesign &&
    css`
      font-size: 18px;
      line-height: 18px;
    `}
  }
`;
const StyledBody = styled(Body)(soloDesignStyles);

const StyledBodyBold = styled(Body)(css`
  font-size: inherit !important;
`);

const StyledBodyItalic = styled(Body)(css`
  font-size: inherit !important;
  font-weight: inherit !important;
  font-style: italic;
`);

const StyledBodyStrike = styled(Body)(css`
  font-size: inherit !important;
  font-weight: inherit !important;
  text-decoration: line-through;
`);

/* eslint-disable prefer-destructuring */
export const TWO = 'two';
export const ONE = 'one';
/* eslint-enable prefer-destructuring */

export function createRenderNode(
  size = ONE,
  trackingContentEntry = {},
  additionalProps = {},
) {
  return {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <StyledBody size={size} {...additionalProps.text}>
        {children}
      </StyledBody>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <Anchor
        href={node.data.uri}
        trackingContentEntry={trackingContentEntry}
        size={size}
      >
        {children}
      </Anchor>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const contentType = getContentType(node, additionalProps);
      const fields = getFields(node, additionalProps);

      if (
        node?.data?.target?.contentType === 'nt_mergetag' &&
        node?.data?.target?.nt_mergetag_id &&
        NINETAILED_IS_ENABLED
      ) {
        return <MergeTag id={node.data.target.nt_mergetag_id} />;
      }

      switch (contentType) {
        case 'embeddedVariable':
          return <EmbeddedVariable {...fields} />;
        case 'embeddedLink':
          return <EmbeddedLink {...fields} />;
        default:
          return null;
      }
    },
  };
}

export function createRenderMark(size = ONE) {
  const defaultProps = {
    size,
  };

  return {
    [MARKS.BOLD]: (text) => (
      <StyledBodyBold
        {...defaultProps}
        as="strong"
        variant="highlight"
        css={spacing({ bottom: 'mega' })}
      >
        {text}
      </StyledBodyBold>
    ),
    [MARKS.ITALIC]: (text) => (
      <StyledBodyItalic
        {...defaultProps}
        as="em"
        css={spacing({ bottom: 'mega' })}
      >
        {text}
      </StyledBodyItalic>
    ),
    strike: (text) => (
      <StyledBodyStrike
        {...defaultProps}
        as="s"
        css={spacing({ bottom: 'mega' })}
      >
        {text}
      </StyledBodyStrike>
    ),
  };
}
