import {
  CONTACT_SALES_PAGES_ZONE,
  SHOP_CATALOG_PAGES_ZONE,
} from '../../constants';
import { MenuItem as MenuItemType } from '../../interfaces';

import { PageRoute } from '~/shared/api-controllers/route/transformers/route';

export function shouldShowStickyCta(stickyCta: MenuItemType, route: PageRoute) {
  const isContactSalesPage = route?.zoneId === CONTACT_SALES_PAGES_ZONE;
  const isShopCatalogPage = route?.zoneId === SHOP_CATALOG_PAGES_ZONE;
  if (isContactSalesPage || isShopCatalogPage) {
    return false;
  }

  return Boolean(stickyCta?.label && stickyCta?.destination?.url);
}
