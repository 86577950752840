import { NAVIGATION_EVENTS } from './constants';

export const getMobileTrackingEvent = (screenNesting: number): string => {
  if (!screenNesting) {
    return NAVIGATION_EVENTS.FIRST_LEVEL;
  }

  return screenNesting > 1
    ? NAVIGATION_EVENTS.THIRD_LEVEL
    : NAVIGATION_EVENTS.SECOND_LEVEL;
};
