import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Chevron from './svg/chevron.svg';

import { UP, DOWN, RIGHT, LEFT } from '~/shared/constants/directions';

const rotateMap = {
  [UP]: '270deg',
  [DOWN]: '90deg',
  [RIGHT]: '0deg',
  [LEFT]: '180deg',
};

const directionStyles = ({ theme, direction = RIGHT }) => css`
  label: chevron;
  transform: rotate(${rotateMap[direction]});
  transition: transform ${theme.transitions.default};
`;

const ChevronIcon = styled(Chevron)(directionStyles);

ChevronIcon.propTypes = {
  direction: PropTypes.oneOf([UP, DOWN, RIGHT, LEFT]),
};

/**
 * @component
 */
export default ChevronIcon;
