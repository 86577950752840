import flow from 'lodash/fp/flow';
import map from 'lodash/fp/map';
import get from 'lodash/fp/get';
import compact from 'lodash/fp/compact';

import {
  SocialIdsType,
  SOCIALS,
} from '~/shared/components/icons/SocialIcon/SocialIcon';

const SOCIAL_SERVICES = [
  {
    id: SOCIALS.FACEBOOK,
    name: 'Facebook',
    url: 'https://facebook.com',
  },
  {
    id: SOCIALS.TWITTER,
    name: 'Twitter',
    url: 'https://twitter.com',
  },
  {
    id: SOCIALS.INSTAGRAM,
    name: 'Instagram',
    url: 'https://instagram.com',
  },
  {
    id: SOCIALS.YOUTUBE,
    name: 'Youtube',
    url: 'https://www.youtube.com',
  },
  {
    id: SOCIALS.LINKEDIN,
    name: 'Linkedin',
    url: 'https://linkedin.com/company',
  },
];

type SocialIcon = {
  id: SocialIdsType;
  name: string;
  href: string;
};

export function getIcons(meta = {}): SocialIcon[] {
  return flow(
    map((service: { id: SocialIdsType; name: string; url: string }) => {
      const socialName = get(service.id, meta);

      if (socialName) {
        return {
          id: service.id,
          name: service.name,
          href: `${service.url}/${socialName}`,
        };
      }

      return null;
    }),
    compact,
  )(SOCIAL_SERVICES);
}
