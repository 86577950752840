import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useInView } from 'react-intersection-observer';
import styled from '@emotion/styled';
import { css, keyframes } from '@emotion/react';
import { Image as CircuitImage } from '@sumup/circuit-ui';

import Picture from '../Picture';

import AspectRatio from '~/shared/components/AspectRatio';
import { getAspectRatio } from '~/shared/util/media-size-calculator';
import LoadingBackground from '~/shared/components/LoadingBackground';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;
const imageStyles = ({ isVisible }) =>
  isVisible &&
  css`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    will-change: opacity;
    z-index: 2;
    animation: 0.2s ease-in ${fadeIn};
    animation-fill-mode: forwards;
  `;
const StyledImage = styled(CircuitImage)(imageStyles);

function LazyImage({
  src = '',
  formattedSrcSet = '',
  srcSet = [],
  sizes,
  alt = '',
  aspectRatio,
  width,
  height,
  contentful,
  ...rest
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '15% 0%',
  });

  if (!src || !width || !height) {
    return null;
  }

  // eslint-disable-next-line no-param-reassign
  aspectRatio = aspectRatio || getAspectRatio(width, height);

  const renderImage = (isVisible) => (
    <Picture srcSet={srcSet} contentful={contentful}>
      <StyledImage
        src={src}
        srcSet={formattedSrcSet}
        sizes={sizes}
        alt={alt}
        isVisible={isVisible}
        onLoad={() => setIsLoaded(true)}
        {...rest}
      />
    </Picture>
  );

  return (
    <AspectRatio ref={ref} aspectRatio={aspectRatio} isTransparent={isLoaded}>
      <LoadingBackground isLoading={!isLoaded} />
      {inView && renderImage(isLoaded)}
      <noscript>{renderImage(true)}</noscript>
    </AspectRatio>
  );
}

LazyImage.propTypes = {
  /**
   * The source URL of the image.
   */
  src: PropTypes.string.isRequired,
  /**
   * The source URLs of the image for different media sizes.
   */
  srcSet: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      size: PropTypes.string,
      width: PropTypes.number,
      height: PropTypes.number,
      contentful: PropTypes.object,
    }),
  ),
  /**
   * The source URLs of the image for different media sizes.
   */
  formattedSrcSet: PropTypes.string,
  /**
   * Provides alternative information if a user cannot view the image.
   */
  alt: PropTypes.string,
  /**
   * Image width in pixels.
   */
  width: PropTypes.number.isRequired,
  /**
   * Image height in pixels.
   */
  height: PropTypes.number.isRequired,
  /**
   * A set of media conditions.
   */
  sizes: PropTypes.string,
  /**
   * Image custom aspect ratio.
   */
  aspectRatio: PropTypes.number,
  /**
   * Contentful
   */
  contentful: PropTypes.object,
};

export default LazyImage;
