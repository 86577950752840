export const ID_TOP = 'top';

export const ALIGNMENT = {
  LEFT: 'Left',
  CENTER: 'Center',
  RIGHT: 'Right',
  WIDE: 'Wide',
  TOP: 'Top',
  MIDDLE: 'Middle',
  BOTTOM: 'Bottom',
} as const;

export const ALIGNMENT_VERTICAL = {
  LEFT: 'left',
  MIDDLE: 'middle',
  RIGHT: 'right',
};

export const ALIGNMENT_HORIZONTAL = {
  TOP: 'top',
  MIDDLE: 'middle',
  BOTTOM: 'bottom',
};

export const COMBINED_ALIGNMENTS = {
  TOP_LEFT: `${ALIGNMENT.TOP} ${ALIGNMENT.LEFT}`,
  TOP_CENTER: `${ALIGNMENT.TOP} ${ALIGNMENT.CENTER}`,
  TOP_RIGHT: `${ALIGNMENT.TOP} ${ALIGNMENT.RIGHT}`,
  MIDDLE_LEFT: `${ALIGNMENT.MIDDLE} ${ALIGNMENT.LEFT}`,
  MIDDLE_CENTER: `${ALIGNMENT.MIDDLE} ${ALIGNMENT.CENTER}`,
  MIDDLE_RIGHT: `${ALIGNMENT.MIDDLE} ${ALIGNMENT.RIGHT}`,
  BOTTOM_LEFT: `${ALIGNMENT.BOTTOM} ${ALIGNMENT.LEFT}`,
  BOTTOM_CENTER: `${ALIGNMENT.BOTTOM} ${ALIGNMENT.CENTER}`,
  BOTTOM_RIGHT: `${ALIGNMENT.BOTTOM} ${ALIGNMENT.RIGHT}`,
};

export const COLORS = {
  ORANGE: 'Orange',
  BLUE: 'Blue',
  YELLOW: 'Yellow',
  WHITE: 'White',
  BLACK: 'Black',
};

export const DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

export const BADGE_TYPES = {
  PRICING_BADGE: 'pricingBadge',
  FEE_BADGE: 'feeBadge',
  TEXT_BADGE: 'textBadge',
};

export const BADGE_BACKGROUNDS = ['Orange', 'Purple'];

export const INFO_BANNER_BACKGROUNDS = [
  'Blue',
  'Orange',
  'Purple',
  'Yellow',
  'Black',
];

export const PRESET_IMAGE_SIZE = {
  ICON: 'Icon',
  MEDIUM: 'Medium',
  FULL: 'Full',
};

export const CACHE_CONTROL_HEADER = 'Cache-Control';

// specify default CDN TTL value to support CloudFront CDN caching
// when left empty Vercel will always overwrite with its default value
// https://vercel.com/docs/concepts/edge-network/caching#serverless-functions---lambdas
// https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Expiration.html#ExpirationDownloadDist
export const DEFAULT_CDN_TTL = 172800; // seconds

export const VIEWPORTS = {
  MOBILE: 'mobile',
  DESKTOP: 'desktop',
  TABLET: 'tablet',
} as const;

type ViewportKeys = keyof typeof VIEWPORTS;
export type ViewportType = (typeof VIEWPORTS)[ViewportKeys];

export const BACKGROUND_MAX_WIDTHS = {
  [VIEWPORTS.TABLET]: 1200,
  [VIEWPORTS.DESKTOP]: 1500,
  [VIEWPORTS.MOBILE]: 900,
};

// Vercel CDN Stale While Revalidate
// https://vercel.com/docs/concepts/edge-network/caching#stale-while-revalidate
// production

// 1800s = 30m
export const VERCEL_CDN_CACHE_MAX_AGE = 1800;
// 86400s = 24h
export const VERCEL_CDN_CACHE_REVALIDATION_THRESHOLD = 86400;
// staging, theta, preview envs
export const VERCEL_CDN_CACHE_MAX_AGE_PREVIEW = 1;
export const VERCEL_CDN_CACHE_REVALIDATION_THRESHOLD_PREVIEW = 59;

// shop integration needs shorter cdn cache and revalidation
// to not have mismatches with CommerceLayer API in shopping cart
// similar to https://github.com/sumup/storefront-web/blob/fba34e9955a58b0d81bbc303d2cb843ce7b36e07/src/shared/infra/cacheControl/index.ts#L9
export const VERCEL_CDN_CACHE_MAX_AGE_SHOP_INTEGRATION = 60;
export const VERCEL_CDN_CACHE_REVALIDATION_THRESHOLD_SHOP_INTEGRATION = 120;

// Query params used as keys for Vercel CDN cache
// https://sumupteam.atlassian.net/wiki/spaces/DEV/pages/22043953489/Vercel+Edge+Middleware+Functions+on+Marketing+Website#Rewrites-&-Vercel-CDN-%E2%80%9CCache-Keys%E2%80%9D
export const VERCEL_CDN_CACHE_PARAMS = {
  ACTIVE_CONSENTS: 'vcc_active_consents',
  VIEWPORT: 'vcc_viewport',
  AB_EXPERIMENTS: 'vcc_ab_experiments',
  GEO_COUNTRY: 'vcc_geo_country',
  FEATURE_TOGGLES: 'vcc_feature_toggles',
  NT_EXPERIENCES: 'vcc_nt_experiences',
};

// https://vercel.com/docs/errors#error-list/bypassing-password-protection-programmatically
export const VERCEL_PASSWORD_PROTECTION_COOKIE_NAME = '_vercel_jwt';

export const USE_WEBSITE_BACKEND_REPLACEMENT_PARAM =
  'useWebsiteBackendReplacement';

export const ASPECT_RATIOS = {
  fourByThree: 1.34,
  sixteenByNine: 1.78,
  squared: 1,
  facebookMobile: 0.8,
  default: 1.78,
};
