import flow from 'lodash/fp/flow';
import snakeCase from 'lodash/fp/snakeCase';
import map from 'lodash/fp/map';
import filter from 'lodash/fp/filter';
import join from 'lodash/fp/join';
import identity from 'lodash/fp/identity';

export default function dataSelector(...sections: string[]): string {
  return flow(filter(identity), map(snakeCase), join('@'))(sections) as string;
}
