import { ALIGNMENT_VERTICAL, VIEWPORTS } from '~/shared/constants';

/**
 * Our website has a custom non standard circuit ui
 * breakpoint on tablet.
 */
export const DESKTOP_BREAKPOINT_MIN = 899;

export const BACKGROUND_MEDIA_TYPES = {
  COLORED: 'backgroundColor',
  IMAGE: 'genericImage',
  BACKGROUND_VIDEO: 'backgroundVideo',
  VIDEO: 'video',
};

export const TEXT_MODE = {
  BRIGHT: 'bright',
  DARK: 'dark',
};

export const ALIGNMENT_ELEMENTS = {
  LEFT: 'left',
  MIDDLE: 'middle',
  RIGHT: 'right',
};

export const TYPES = {
  HERO: 'hero', // high height, bigger fonts (good for top of the page)
  BANNER: 'banner', // short height, smaller fonts (good in mid-page)
  TOP_BANNER: 'top-banner', // short height, bigger fonts (good for top of the page)
};

type FullMediaTypesKeys = keyof typeof TYPES;
export type FullMediaTypesType = (typeof TYPES)[FullMediaTypesKeys];

export const GRID_WIDTH = '12';

export const TYPE_HEIGHTS = {
  [VIEWPORTS.MOBILE]: {
    [TYPES.HERO]: 360,
    [TYPES.BANNER]: 360,
    [TYPES.TOP_BANNER]: 360,
  },
  [VIEWPORTS.DESKTOP]: {
    [TYPES.HERO]: 600,
    [TYPES.BANNER]: 520,
    [TYPES.TOP_BANNER]: 520,
  },
  [VIEWPORTS.TABLET]: {
    [TYPES.HERO]: 720,
    [TYPES.BANNER]: 420,
    [TYPES.TOP_BANNER]: 420,
  },
};

export const DESKTOP_MAX_HEIGHT = 720;

export const SOLO_MAX_HEIGHTS = {
  [VIEWPORTS.TABLET]: 880,
  [VIEWPORTS.DESKTOP]: 880,
  [VIEWPORTS.MOBILE]: 580,
};

export const ALIGNMENT_VERTICAL_ORDER = {
  [ALIGNMENT_VERTICAL.LEFT]: 0,
  [ALIGNMENT_VERTICAL.MIDDLE]: 1,
  [ALIGNMENT_VERTICAL.RIGHT]: 2,
};
