import React, { useReducer, useRef, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { More } from '@sumup/icons';
import filter from 'lodash/fp/filter';
import isEmpty from 'lodash/fp/isEmpty';

import MenuSection from '../MenuSection';
import useHighlightedMenuLinks from '../../hooks/use-highlighted-menu-links';

import { getDefaultOpenLinkIndex } from './DesktopMenuService';

import * as Analytics from '~/shared/services/analytics';
import dataSelector from '~/shared/util/data-selector';
// eslint-disable-next-line max-len
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';
import isTouchDevice from '~/shared/util/isTouchDevice';
import RequestContext from '~/shared/providers/RequestContext';
import SiteContext from '~/shared/providers/SiteContext';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';

export const OPEN_LINK_TIME = 800;

export const DATA_SELECTOR = 'main_nav_menu';

export const HIGHLIGHTED_MENU_LEVEL = 0;
export const HIGHLIGHTED_MENU_SAVE_TO_STORAGE = true;

const listStyles = ({ theme, actionAreaHeight }) => css`
  list-style-type: none;
  padding-bottom: ${actionAreaHeight};

  ${theme.mq.nav} {
    display: inline-block;
    padding-bottom: 0;
  }
`;

const List = styled('ul')(listStyles);

const collapsibleContainerStyles = ({ theme }) => css`
  position: absolute;
  right: -50%;
  top: 100%;
  background: var(--cui-bg-normal);
  visibility: hidden;
  flex-direction: column;
  box-shadow:
    0px -2px 5px rgba(12, 15, 20, 0.08),
    0px 4px 4px rgba(12, 15, 20, 0.06),
    0px 8px 8px rgba(12, 15, 20, 0.06);
  border: 1px solid var(--cui-border-normal);
  border-radius: ${theme.borderRadius.byte};
  padding: ${theme.spacings.kilo};
  transition: visibility 0.2s ease-in-out 0.4s;
  z-index: 2;
`;

const collapsibleContainerOpenStyles = ({ isCollapsibleOpen }) =>
  isCollapsibleOpen &&
  css`
    visibility: visible;
    transition-delay: 0s;
  `;

const CollapsibleContainer = styled('ul')(
  collapsibleContainerStyles,
  collapsibleContainerOpenStyles,
);

const listItemStyles = ({ theme }) => css`
  display: none;

  ${theme.mq.mainNavCollapsibleMenu} {
    display: inline-flex;
    position: relative;
    padding: ${theme.spacings.byte};
    text-align: center;
    margin: 0 ${theme.spacings.bit};
    width: auto;
    cursor: pointer;
  }

  ${theme.mq.mega} {
    margin: 0 ${theme.spacings.byte};
  }
`;
const ListItem = styled('li')(listItemStyles);

const iconStyles = () => css`
  transform: translateY(25%) rotate(90deg);
`;

const StyledMoreIcon = styled(More)(iconStyles);

const UPDATE = 'UPDATE';

function reducer(state, { type, ...newState }) {
  switch (type) {
    case UPDATE: {
      return { ...state, ...newState };
    }
    default: {
      return state;
    }
  }
}

function DesktopMenu({
  links,
  actionAreaHeight,
  closeMenu,
  canBeHidden,
  navigationTrackingData,
  isMenuOpen,
}) {
  const { experiments } = useOptimizelyData();
  const { saveLinkToHighlight } = useHighlightedMenuLinks();
  const request = useContext(RequestContext);
  const site = useContext(SiteContext);
  const [state, dispatch] = useReducer(reducer, {
    openLinkIndex: -1,
    isCollapsibleOpen: false,
  });

  useEffect(() => {
    // default open index is needed only when mobile nav is open to pre-open correct submenu
    if (isMenuOpen === true) {
      const defaultOpenLinkIndex = getDefaultOpenLinkIndex(
        request,
        site,
        links,
      );
      dispatch({ type: UPDATE, openLinkIndex: defaultOpenLinkIndex });
    }
  }, [isMenuOpen, links, request, site]);

  const timer = useRef(null);
  const hasTouch = isTouchDevice();
  const handleLinkHover = (isLinkHovered, linkIndex) => {
    if (hasTouch) {
      return;
    }

    if (isLinkHovered) {
      clearTimeout(timer.current);
      dispatch({ type: UPDATE, openLinkIndex: linkIndex });
    } else {
      timer.current = setTimeout(() => {
        dispatch({ type: UPDATE, openLinkIndex: -1 });
      }, OPEN_LINK_TIME);
    }
  };
  const handleLinkToggle = (linkIndex) => {
    if (linkIndex === state.openLinkIndex) {
      dispatch({ type: UPDATE, openLinkIndex: -1 });
    } else {
      dispatch({ type: UPDATE, openLinkIndex: linkIndex });
    }
  };
  const handleMenuLinkClick = (linkId) => {
    saveLinkToHighlight(
      linkId,
      HIGHLIGHTED_MENU_LEVEL,
      HIGHLIGHTED_MENU_SAVE_TO_STORAGE,
    );
  };

  const collapsibleLinks = filter(
    ({ isCollapsible, label }) => !isEmpty(label) && isCollapsible === true,
    links,
  );
  const triggerCollapsible = (isCollapsibleOpen) => () => {
    dispatch({ type: UPDATE, isCollapsibleOpen });

    const action = isCollapsibleOpen
      ? 'open@collapsible_menu'
      : 'close@collapsible_menu';
    Analytics.sendEvent({
      event: 'interaction',
      target: 'Mkt_Web',
      action,
      destinationCategory: Analytics.destinationCategory.UI_OPERATION,
      destinationUrl: undefined,
      ...navigationTrackingData,
    });

    sendNinetailedEvent(action);
  };

  return (
    <List actionAreaHeight={actionAreaHeight}>
      {links.map((link, index) => (
        <MenuSection
          closeMenu={closeMenu}
          key={index}
          applyCollapsibleStyles={link.isCollapsible}
          index={index}
          isOpen={index === state.openLinkIndex}
          onHover={handleLinkHover}
          onToggle={handleLinkToggle}
          onLinkClick={handleMenuLinkClick}
          navigationTrackingData={navigationTrackingData}
          {...getVariationComponent(link, experiments)}
        />
      ))}

      {!isEmpty(collapsibleLinks) && (
        <ListItem
          onMouseEnter={triggerCollapsible(true)}
          onMouseLeave={triggerCollapsible(false)}
          onFocus={triggerCollapsible(true)}
          onBlur={triggerCollapsible(false)}
          onTouchStart={triggerCollapsible(true)}
        >
          <StyledMoreIcon
            data-selector={dataSelector('more_icon', DATA_SELECTOR)}
          />
          {canBeHidden ? (
            <CollapsibleContainer
              data-selector={dataSelector(
                'collapsible_container',
                DATA_SELECTOR,
              )}
              isCollapsibleOpen={state.isCollapsibleOpen}
            >
              {collapsibleLinks.map((link, index) => (
                <MenuSection
                  closeMenu={closeMenu}
                  key={index}
                  isVertical={true}
                  {...getVariationComponent(link, experiments)}
                />
              ))}
            </CollapsibleContainer>
          ) : null}
        </ListItem>
      )}
    </List>
  );
}

DesktopMenu.propTypes = {
  links: PropTypes.array,
  actionAreaHeight: PropTypes.string,
  canBeHidden: PropTypes.bool,
  navigationTrackingData: PropTypes.object,
  closeMenu: PropTypes.func,
  isMenuOpen: PropTypes.bool,
};

export default React.memo(DesktopMenu);
