import React from 'react';
import PropTypes from 'prop-types';

import Anchor from '~/shared/components/Anchor';

/**
 * Embed a link into rich text.
 */
function EmbeddedLink({
  label,
  url,
  trackingId,
  optimizelyFullStackClickEvents,
  id,
  name,
  contentType,
}) {
  if (!label || !url) {
    return null;
  }

  return (
    <Anchor
      href={url}
      trackingId={trackingId}
      optimizelyFullStackClickEvents={optimizelyFullStackClickEvents}
      trackingContentEntry={{
        contentType,
        contentEntryName: name,
        contentEntryId: id,
      }}
    >
      {label}
    </Anchor>
  );
}

EmbeddedLink.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  trackingId: PropTypes.string,
  optimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  id: PropTypes.string,
  name: PropTypes.string,
  contentType: PropTypes.string,
};

/**
 * @component
 */
export default EmbeddedLink;
