// eslint-disable-next-line import/prefer-default-export
export const PRICE_FORMATS = {
  SIZE_62: {
    sizes: {
      current_price: 62,
      currency: 22,
      old_price: 18,
      asterisk: 22,
    },
    spacings: {
      asterisk_left: 5,
      old_price_top: 16,
      currency_right: 4,
      installments_bottom: 6,
    },
  },
  SIZE_42: {
    sizes: {
      current_price: 42,
      currency: 16,
      old_price: 18,
      asterisk: 16,
    },
    spacings: {
      asterisk_left: 4,
      old_price_top: 12,
      currency_right: 4,
      installments_bottom: 2,
    },
  },
  SIZE_32: {
    sizes: {
      current_price: 32,
      currency: 13,
      old_price: 16,
      asterisk: 16,
    },
    spacings: {
      asterisk_left: 3,
      old_price_top: 8,
      currency_right: 6,
      installments_bottom: 0,
    },
  },
  SIZE_22: {
    sizes: {
      current_price: 22,
      currency: 9,
      old_price: 12,
      asterisk: 12,
    },
    spacings: {
      asterisk_left: 2,
      old_price_top: 8,
      currency_right: 2,
      installments_bottom: 0,
    },
  },
};

export const PRICE_BACKGROUNDS = {
  WHITE: 'WHITE',
  PEARL: 'PEARL',
  SKY_BLUE: 'SKY_BLUE',
  CHILEAN_ORANGE: 'CHILEAN_ORANGE',
  TANGERINE: 'TANGERINE',
  CORAL: 'CORAL',
  FRESH_LIME: 'FRESH_LIME',
  HONEY_YELLOW: 'HONEY_YELLOW',
  TRUE_BLUE: 'TRUE_BLUE',
};

export const PRICE_TEXT_COLORS = {
  [PRICE_BACKGROUNDS.WHITE]: {
    current: 'var(--cui-fg-normal)',
    background: 'var(--cui-bg-normal)',
  },
  [PRICE_BACKGROUNDS.PEARL]: {
    current: 'var(--cui-fg-normal)',
    background: 'var(--cui-bg-highlight)',
  },
  [PRICE_BACKGROUNDS.SKY_BLUE]: {
    current: 'var(--cui-fg-on-strong)',
    background: 'var(--cui-bg-accent-strong)',
  },
  [PRICE_BACKGROUNDS.TRUE_BLUE]: {
    current: 'var(--cui-fg-on-strong)',
    background: 'var(--cui-bg-accent-strong)',
  },
  /** For badge component */
  [PRICE_BACKGROUNDS.TANGERINE]: {
    current: 'var(--cui-fg-on-strong)',
    background: 'var(--cui-bg-warning-strong)',
  },
  /** For badge component */
  [PRICE_BACKGROUNDS.CORAL]: {
    current: 'var(--cui-fg-on-strong)',
    background: 'var(--cui-bg-danger-strong)',
  },
  /** For badge component */
  [PRICE_BACKGROUNDS.FRESH_LIME]: {
    current: 'var(--cui-fg-subtle)',
    background: 'var(--cui-bg-success)',
  },
  /** For badge component */
  [PRICE_BACKGROUNDS.HONEY_YELLOW]: {
    current: 'var(--cui-fg-subtle)',
    background: 'var(--cui-bg-warning)',
  },
};
