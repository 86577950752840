const salesforceStyles = (theme) => `
  .embeddedServiceHelpButton {
    z-index: 999999;
  }
  .sidebarMinimized button {
    background-color: var(--cui-bg-accent-strong) !important;
    border-radius: ${theme.spacings.giga} !important;
    margin-bottom: ${theme.spacings.kilo};
  }

  .helpButtonDisabled {
    display: none !important;
  }

  .helpButton {
    bottom: ${theme.spacings.kilo} !important;

    :focus {
      &::before {
        border-radius: ${theme.spacings.giga} !important;
      }
    }

    .uiButton {
      background-color: var(--cui-bg-accent-strong) !important;
      border-radius: ${theme.spacings.giga} !important;

      &:focus {
        outline: 1px solid var(--cui-border-accent);
      }

      .helpButtonLabel {
        justify-content: left !important;
      }

      .embeddedServiceIcon {
        margin: 0 10px;
      }
    }
  }

  .embeddedServiceLiveAgentStateChatInputFooter
    .embeddedServiceLiveAgentStateChatUnreadMessageBanner,
  .chasitorInputWrapper .footerMenuWrapper {
    display: none;
  }
`;

// eslint-disable-next-line import/prefer-default-export
export const getSalesforceStyles = (theme) =>
  `
    ${salesforceStyles(theme)}
  `;
