// Adapted from https://usehooks.com/useWindowSize/
import { useState, useEffect } from 'react';

import isServer from '~/shared/util/is-server';

function getSize() {
  return {
    width:
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth,
    height:
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight,
  };
}

// Initial width is predefined because it can't be calculated on server side.
// Calculations based on user-agent are also not possible here because of limitations
// due to Cloudflare caching (page is getting recreated only periodically and not on every request)
export default function useWindowSize({
  initialWidth = 0,
  initialHeight = Infinity,
} = {}) {
  // Set initial width and height to ensure they match between server & client.
  const [windowSize, setWindowSize] = useState({
    width: initialWidth,
    height: initialHeight,
  });

  useEffect(() => {
    if (isServer) {
      return undefined;
    }

    // Get the real window width and size after the initial hydration.
    setWindowSize(getSize());

    let ticking = null;

    function handleResize() {
      if (ticking === null) {
        ticking = window.requestAnimationFrame(() => {
          setWindowSize(getSize());
          ticking = null;
        });
      }
    }

    window.addEventListener('resize', handleResize);

    return function cleanup() {
      window.cancelAnimationFrame(ticking);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return windowSize;
}
