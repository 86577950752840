import React, { useMemo } from 'react';

import * as ImageService from '../../ImageService';

type SrcSet = {
  src: string;
  size: string;
  width: number;
  height: number;
  contentful: string;
};

type Props = {
  srcSet: SrcSet[];
  children: React.ReactNode;
  contentful: object;
};

function Picture({ srcSet, contentful, children }: Props) {
  const avifFormattedSrcSet = useMemo(
    () => ImageService.getSrcSetForFormat(srcSet, contentful, 'avif'),
    [srcSet, contentful],
  );
  const webpFormattedSrcSet = useMemo(
    () => ImageService.getSrcSetForFormat(srcSet, contentful, 'webp'),
    [srcSet, contentful],
  );

  return (
    <picture>
      {avifFormattedSrcSet?.length > 0 && (
        <source type="image/avif" srcSet={avifFormattedSrcSet} />
      )}
      {webpFormattedSrcSet?.length > 0 && (
        <source type="image/webp" srcSet={webpFormattedSrcSet} />
      )}
      {children}
    </picture>
  );
}

/**
 * An source component that renders all sources for a picture element
 */
export default Picture;
