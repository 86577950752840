import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { DROPDOWN_HEIGHT } from '../../constants';

const baseStyles = ({ theme }) => css`
  label: dropdown__label;
  background-color: var(--cui-bg-normal);
  cursor: pointer;
  padding: ${theme.spacings.kilo} ${theme.spacings.mega};
  border-radius: ${theme.borderRadius.mega};
  overflow-wrap: break-word;
  hyphens: auto;
  position: relative;
  transition: all ${theme.transitions.default};
`;
const heightStyles = ({ fixedHeight }) =>
  fixedHeight
    ? css`
        height: ${DROPDOWN_HEIGHT}px;
      `
    : css`
        min-height: ${DROPDOWN_HEIGHT}px;
      `;

const activeStyles = ({ isActive }) =>
  isActive &&
  css`
    background-color: var(--cui-bg-subtle);

    span {
      color: var(--cui-fg-accent);
    }
  `;

const withPrefixStyles = ({ theme, hasPrefix }) =>
  hasPrefix &&
  css`
    label: --with-prefix;
    padding-left: ${theme.spacings.exa};
  `;

const Wrapper = styled('div')(
  baseStyles,
  heightStyles,
  activeStyles,
  withPrefixStyles,
);

const bodyStyles = ({ theme }) => css`
  label: dropdown__label--text;
  display: block;
  font-size: ${theme.typography.body.one.fontSize};
  line-height: ${DROPDOWN_HEIGHT / 2}px;

  ${theme.mq.mega} {
    font-size: ${theme.typography.body.one.fontSize};
  }
`;
const textOverflowStyles = ({ noWrap }) =>
  noWrap &&
  css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `;

const Text = styled('span')(bodyStyles, textOverflowStyles);

const prefixStyles = (theme) => css`
  label: dropdown__label--prefix;
  display: block;
  position: absolute;
  top: ${theme.spacings.mega};
  left: ${theme.spacings.mega};
  width: 24px;
  height: 18px;
  pointer-events: none;
  z-index: 40;
`;

function Label({
  text = '',
  isActive,
  shouldWrapText,
  renderPrefix: RenderPrefix,
  hasPrefix,
  ...props
}) {
  if (!text) {
    return null;
  }
  const prefix = RenderPrefix && <RenderPrefix css={prefixStyles} />;

  return (
    <Wrapper
      hasPrefix={hasPrefix}
      isActive={isActive}
      fixedHeight={!shouldWrapText}
      {...props}
    >
      {prefix}
      <Text noWrap={!shouldWrapText}>{text}</Text>
    </Wrapper>
  );
}

Label.propTypes = {
  /**
   * Label text.
   */
  text: PropTypes.string.isRequired,
  /**
   * Specify if label should have highlighted styles.
   */
  isActive: PropTypes.bool,
  /**
   * Render prop that should render a left-aligned overlay icon or element.
   * Receives a `className` prop.
   */
  renderPrefix: PropTypes.func,
  /**
   * Specify if label text should suppress line breaks
   */
  shouldWrapText: PropTypes.bool,
  /*
   * Need to be set to true when you want your prefix to be rendered.
   */
  hasPrefix: PropTypes.bool,
};

export default Label;
