/* eslint-disable */
// THIS FILE IS AUTOGENERATED. DO NOT MODIFY.
// TO REGENERATE EXECUTE IN TERMINAL: yarn cf:generate:locales
export const DEFAULT_LOCALE = 'intl';

export const WEBSITE_HOSTNAMES_WITH_LOCALES = {
  "theta.sam-app.ro": "en-US",
  "sam-app.ro": "en-US",
  "sumup.com": "en-US",
  "it-theta.sam-app.ro": "it-IT",
  "it.sam-app.ro": "it-IT",
  "sumup.it": "it-IT",
  "pl-theta.sam-app.ro": "pl-PL",
  "pl.sam-app.ro": "pl-PL",
  "sumup.pl": "pl-PL",
  "mx-theta.sam-app.ro": "es-MX",
  "mx.sam-app.ro": "es-MX",
  "sumup.mx": "es-MX",
  "cl-theta.sam-app.ro": "es-CL",
  "cl.sam-app.ro": "es-CL",
  "sumup.cl": "es-CL",
  "pt-theta.sam-app.ro": "pt-PT",
  "pt.sam-app.ro": "pt-PT",
  "sumup.pt": "pt-PT",
  "fi-theta.sam-app.ro": "fi-FI",
  "fi.sam-app.ro": "fi-FI",
  "sumup.fi": "fi-FI",
  "at-theta.sam-app.ro": "de-AT",
  "at.sam-app.ro": "de-AT",
  "sumup.at": "de-AT",
  "de-ch-theta.sam-app.ro": "de-CH",
  "de-ch.sam-app.ro": "de-CH",
  "ch-de.sumup.com": "de-CH",
  "it-ch-theta.sam-app.ro": "it-CH",
  "it-ch.sam-app.ro": "it-CH",
  "ch-it.sumup.com": "it-CH",
  "fr-ch-theta.sam-app.ro": "fr-CH",
  "fr-ch.sam-app.ro": "fr-CH",
  "ch-fr.sumup.com": "fr-CH",
  "uk-theta.sam-app.ro": "en-GB",
  "uk.sam-app.ro": "en-GB",
  "sumup.co.uk": "en-GB",
  "es-theta.sam-app.ro": "es-ES",
  "es.sam-app.ro": "es-ES",
  "sumup.es": "es-ES",
  "de-theta.sam-app.ro": "de-DE",
  "de.sam-app.ro": "de-DE",
  "sumup.de": "de-DE",
  "nl-theta.sam-app.ro": "nl-NL",
  "nl.sam-app.ro": "nl-NL",
  "sumup.nl": "nl-NL",
  "fr-theta.sam-app.ro": "fr-FR",
  "fr.sam-app.ro": "fr-FR",
  "sumup.fr": "fr-FR",
  "nl-be-theta.sam-app.ro": "nl-BE",
  "nl-be.sam-app.ro": "nl-BE",
  "nl.sumup.be": "nl-BE",
  "fr-be-theta.sam-app.ro": "fr-BE",
  "fr-be.sam-app.ro": "fr-BE",
  "fr.sumup.be": "fr-BE",
  "ie-theta.sam-app.ro": "en-IE",
  "ie.sam-app.ro": "en-IE",
  "sumup.ie": "en-IE",
  "no-theta.sam-app.ro": "nb-NO",
  "no.sam-app.ro": "nb-NO",
  "sumup.no": "nb-NO",
  "de-lu-theta.sam-app.ro": "de-LU",
  "de-lu.sam-app.ro": "de-LU",
  "de.sumup.lu": "de-LU",
  "dk-theta.sam-app.ro": "da-DK",
  "dk.sam-app.ro": "da-DK",
  "sumup.dk": "da-DK",
  "cz-theta.sam-app.ro": "cs-CZ",
  "cz.sam-app.ro": "cs-CZ",
  "sumup.cz": "cs-CZ",
  "hr-theta.sam-app.ro": "hr-HR",
  "hr.sam-app.ro": "hr-HR",
  "sumup.hr": "hr-HR",
  "co-theta.sam-app.ro": "es-CO",
  "co.sam-app.ro": "es-CO",
  "sumup.co": "es-CO",
  "se-theta.sam-app.ro": "sv-SE",
  "se.sam-app.ro": "sv-SE",
  "sumup.se": "sv-SE",
  "lv-theta.sam-app.ro": "lv-LV",
  "lv.sam-app.ro": "lv-LV",
  "sumup.lv": "lv-LV",
  "es-us-theta.sam-app.ro": "es-US",
  "es-us.sam-app.ro": "es-US",
  "es.sumup.com": "es-US",
  "si-theta.sam-app.ro": "sl-SI",
  "si.sam-app.ro": "sl-SI",
  "sumup.si": "sl-SI",
  "sk-theta.sam-app.ro": "sk-SK",
  "sk.sam-app.ro": "sk-SK",
  "sumup.sk": "sk-SK",
  "ee-theta.sam-app.ro": "et-EE",
  "ee.sam-app.ro": "et-EE",
  "sumup.ee": "et-EE",
  "gr-theta.sam-app.ro": "el-GR",
  "gr.sam-app.ro": "el-GR",
  "sumup.gr": "el-GR",
  "mt-theta.sam-app.ro": "en-MT",
  "mt.sam-app.ro": "en-MT",
  "sumup.com.mt": "en-MT",
  "au-theta.sam-app.ro": "en-AU",
  "au.sam-app.ro": "en-AU",
  "sumup.com.au": "en-AU",
  "ro-theta.sam-app.ro": "ro-RO",
  "ro.sam-app.ro": "ro-RO",
  "sumup.ro": "ro-RO",
  "fr-lu-theta.sam-app.ro": "fr-LU",
  "fr-lu.sam-app.ro": "fr-LU",
  "fr.sumup.lu": "fr-LU",
  "cy-theta.sam-app.ro": "el-CY",
  "cy.sam-app.ro": "el-CY",
  "sumup.com.cy": "el-CY",
  "lt-theta.sam-app.ro": "lt-LT",
  "lt.sam-app.ro": "lt-LT",
  "sumup.lt": "lt-LT",
  "bg-theta.sam-app.ro": "bg-BG",
  "bg.sam-app.ro": "bg-BG",
  "sumup.bg": "bg-BG",
  "br-theta.sam-app.ro": "pt-BR",
  "br.sam-app.ro": "pt-BR",
  "sumup.com.br": "pt-BR",
  "pe-theta.sam-app.ro": "es-PE",
  "pe.sam-app.ro": "es-PE",
  "sumup.pe": "es-PE",
  "hu-theta.sam-app.ro": "hu-HU",
  "hu.sam-app.ro": "hu-HU",
  "sumup.hu": "hu-HU",
  "ca-theta.sam-app.ro": "en-CA",
  "ca.sam-app.ro": "en-CA",
  "sumup.ca": "en-CA",
  "ar-theta.sam-app.ro": "es-AR",
  "ar.sam-app.ro": "es-AR",
  "sumup.com.ar": "es-AR",
  "fr-ca-theta.sam-app.ro": "fr-CA",
  "fr-ca.sam-app.ro": "fr-CA",
  "fr.sumup.ca": "fr-CA",
  "sumup.be": "nl-BE",
  "sumup.lu": "fr-LU"
};

export const LOCALE_CURRENCIES = {
  "en-US": "USD",
  "it-IT": "EUR",
  "pl-PL": "PLN",
  "es-MX": "MXN",
  "es-CL": "CLP",
  "pt-PT": "EUR",
  "fi-FI": "EUR",
  "de-AT": "EUR",
  "de-CH": "CHF",
  "it-CH": "CHF",
  "fr-CH": "CHF",
  "en-GB": "GBP",
  "es-ES": "EUR",
  "de-DE": "EUR",
  "nl-NL": "EUR",
  "fr-FR": "EUR",
  "nl-BE": "EUR",
  "fr-BE": "EUR",
  "en-IE": "EUR",
  "nb-NO": "NOK",
  "de-LU": "EUR",
  "da-DK": "DKK",
  "cs-CZ": "CZK",
  "hr-HR": "HRK",
  "es-CO": "COP",
  "sv-SE": "SEK",
  "lv-LV": "EUR",
  "es-US": "USD",
  "sl-SI": "EUR",
  "sk-SK": "EUR",
  "et-EE": "EUR",
  "el-GR": "EUR",
  "en-MT": "EUR",
  "en-AU": "AUD",
  "ro-RO": "RON",
  "fr-LU": "EUR",
  "el-CY": "EUR",
  "lt-LT": "EUR",
  "bg-BG": "BGN",
  "pt-BR": "BRL",
  "es-PE": "PEN",
  "hu-HU": "HUF",
  "en-CA": "CAD",
  "es-AR": "ARS",
  "fr-CA": "CAD"
};
  