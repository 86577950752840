import React, { FC } from 'react';
import { Facebook, Instagram, Linkedin, Twitter, Youtube } from '@sumup/icons';

export const SOCIALS = {
  TWITTER: 'twitter',
  FACEBOOK: 'facebook',
  INSTAGRAM: 'instagram',
  LINKEDIN: 'linkedin',
  YOUTUBE: 'youtube',
} as const;

const nameIconMap = {
  [SOCIALS.TWITTER]: Twitter,
  [SOCIALS.FACEBOOK]: Facebook,
  [SOCIALS.INSTAGRAM]: Instagram,
  [SOCIALS.LINKEDIN]: Linkedin,
  [SOCIALS.YOUTUBE]: Youtube,
};

export type SocialIdsType = (typeof SOCIALS)[keyof typeof SOCIALS];

export interface SocialIconProps {
  id: SocialIdsType;
  withLabel?: boolean;
}

const SocialIcon: FC<SocialIconProps> = ({ id, withLabel, ...rest }) => {
  const Icon = nameIconMap[id];
  if (!Icon) {
    return null;
  }
  return <Icon {...rest} />;
};

export default SocialIcon;
