import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';

import { dropdownItemPropType } from '../../shared';

import {
  childrenPropType,
  trackingContentEntryPropType,
} from '~/shared/util/shared-prop-types';
import Link from '~/shared/components/Link';

function Item({
  item = {},
  children = null,
  trackingContentEntry = {},
  disableSPANavigation,
  ...props
}) {
  if (isEmpty(item)) {
    return null;
  }

  const { href, target } = item;

  // prevent links click behavior in of favor Dropdown's `onChange` handler
  const handleClick = (e) => e.preventDefault();

  return (
    <li {...props}>
      <Link
        href={href}
        target={target}
        onClick={handleClick}
        shouldTrack={false}
        trackingContentEntry={trackingContentEntry}
        disableSPANavigation={disableSPANavigation}
      >
        <a>{children}</a>
      </Link>
    </li>
  );
}

Item.propTypes = {
  /*
   * Item data object.
   */
  item: dropdownItemPropType,
  /**
   * Render any components inside of an item.
   */
  children: childrenPropType,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,

  /*
   * Disables the SPA navigation when selecting an item.
   */
  disableSPANavigation: PropTypes.bool,
};

export default Item;
