import React, { useState, useContext, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@emotion/react';
import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import { lock, unlock } from 'tua-body-scroll-lock';

import Container from './components/Container';
import BrandArea from './components/BrandArea';
import Menu from './components/Menu';
import ActionArea from './components/ActionArea';
import * as NavigationService from './NavigationService';
import { INFO_BANNER_DEFAULT_HEIGHT } from './components/InfoBanner/constants';
import useNavigation from './hooks/use-navigation';

import SiteContext from '~/shared/providers/SiteContext';
import useMeasurePerformance from '~/shared/hooks/use-measure-performance';
import useComponentSize from '~/shared/hooks/use-component-size';
import { useScrolledPastThreshold } from '~/shared/hooks/use-scroll';
import { isCollapsibleSideBar } from '~/shared/components/SideBarNavigation/SideBarNavigationService';
import * as Analytics from '~/shared/services/analytics';
import {
  linkPropType,
  partnerPropType,
  subSitePropType,
  secondaryLogoPropType,
  infoBannerPropType,
} from '~/shared/util/shared-prop-types';
import { base } from '~/shared/styles/themes';
import useViewportName from '~/shared/hooks/use-viewport-name';
import { DEFAULT_SIDEBAR_COLLAPSIBLE_HEIGHT } from '~/shared/components/SideBarNavigation/constants';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';

/**
 * The Navigation contains the branding, language switcher, normal menu links,
 * and specific links to login and signup.
 */
function Navigation(props) {
  const {
    infoBanner = {},
    logoLink,
    secondaryLogo,
    subSite = {},
    partner = {},
    links = [],
    loginUrl,
    loginLabel,
    loginTrackingId,
    loginOptimizelyFullStackClickEvents,
    ctaButtonUrl,
    ctaButtonLabel,
    ctaButtonTrackingId,
    ctaButtonOptimizelyFullStackClickEvents,
    localizedUrls = {},
    contentType,
    name,
    id,
    useNewMobileNavigation,
    linksGroupLabel,
    backButtonLabel,
    extraLevelNavigationGroupLabel,
    extraLevelNavigationIcon,
    extraLevelNavigation = {},
    sideBarNavigation,
    soloDesign = {},
  } = props;
  useMeasurePerformance('navigation-component-render');

  const site = useContext(SiteContext);
  const {
    windowWidth,
    breakpoint,
    fullWidthBreakpoint,
    navsCanBeHidden,
    navsDoNotFitScreen,
    hasExtraLevelNavigationOnPage,
    hasInfoBannerOnPage,
  } = useNavigation(props);
  const [isOpen, setOpen] = useState(false);
  const menuRef = useRef();
  const containerRef = useRef();
  const scrolledPastThreshold = useScrolledPastThreshold(
    NavigationService.SCROLL_THRESHOLD,
  );
  const viewportName = useViewportName();
  const hasCtaButton = !!(ctaButtonLabel && ctaButtonUrl);
  const [isShortLogo, setIsShortLogo] = useState(false);

  useEffect(() => {
    if (isOpen && !navsDoNotFitScreen) {
      unlock(menuRef.current);
      setOpen(false);
    }
  }, [isOpen, navsDoNotFitScreen]);

  useEffect(() => {
    const isShortLogoUpdated =
      hasCtaButton && navsDoNotFitScreen && scrolledPastThreshold && !isOpen;
    setIsShortLogo(isShortLogoUpdated);
  }, [hasCtaButton, navsDoNotFitScreen, scrolledPastThreshold, isOpen]);

  const navigationTrackingData = React.useMemo(
    () => ({
      contentType,
      contentEntryName: name,
      contentEntryId: id,
    }),
    [contentType, id, name],
  );
  const openMenu = React.useCallback(
    (e) => {
      const targetDataSelector = e.target.dataset.selector;
      if (targetDataSelector === 'hamburger@action-area') {
        const trackingId = 'open@navigation';
        Analytics.sendEvent({
          event: 'interaction',
          target: 'Mkt_Web',
          action: trackingId,
          destinationCategory: Analytics.destinationCategory.UI_OPERATION,
          destinationUrl: undefined,
          ...navigationTrackingData,
        });

        sendNinetailedEvent(trackingId);
      }
      lock(menuRef.current);
      setOpen(true);
    },
    [navigationTrackingData],
  );

  const closeMenu = React.useCallback(
    (e) => {
      const targetDataSelector = e.target.dataset.selector;
      if (targetDataSelector === 'hamburger@action-area') {
        const trackingId = 'close@navigation';
        Analytics.sendEvent({
          event: 'interaction',
          target: 'Mkt_Web',
          action: trackingId,
          destinationCategory: Analytics.destinationCategory.UI_OPERATION,
          destinationUrl: undefined,
          ...navigationTrackingData,
        });
        sendNinetailedEvent(trackingId);
      }
      unlock(menuRef.current);
      setOpen(false);
    },
    [navigationTrackingData],
  );

  const toggleMenu = React.useCallback(
    (event) => {
      if (isOpen) {
        closeMenu(event);
        return;
      }
      openMenu(event);
    },
    [closeMenu, isOpen, openMenu],
  );

  const hasLinks = !isEmpty(links);
  const isPinned = NavigationService.isPinned(
    scrolledPastThreshold,
    navsDoNotFitScreen,
    windowWidth,
  );

  const applyMaxWidthBreakpoint = windowWidth > fullWidthBreakpoint;
  const theme = (ancestorTheme) => ({
    ...ancestorTheme,
    mq: {
      ...ancestorTheme.mq,
      nav: `@media (min-width: ${breakpoint}px)`,
      mainNavCollapsibleMenu: applyMaxWidthBreakpoint
        ? `@media (min-width: ${breakpoint}px) and (max-width: ${fullWidthBreakpoint}px)`
        : `@media (min-width: ${breakpoint}px)`,
    },
  });

  const infoBannerRef = useRef();
  const { height: infoBannerHeight } = useComponentSize(infoBannerRef, {
    defaultValues: {
      height: isEmpty(infoBanner) ? 0 : INFO_BANNER_DEFAULT_HEIGHT,
    },
  });

  const trackingContentEntry = React.useMemo(
    () => ({
      contentType,
      contentEntryName: name,
      contentEntryId: id,
    }),
    [contentType, id, name],
  );

  const { height: containerHeight } = useComponentSize(containerRef, {
    defaultValues: {
      height: isEmpty(links) ? 0 : parseInt(base.spacings.nav.desktop, 10),
    },
  });

  let offsetTop = 0;

  if (hasInfoBannerOnPage) {
    offsetTop =
      windowWidth === 0
        ? NavigationService.calculateInfoBannerHeight(
            viewportName,
            infoBanner.text,
          )
        : infoBannerHeight;
  }

  const displaySideBarNavigation = isCollapsibleSideBar(
    viewportName,
    sideBarNavigation,
  );

  if (displaySideBarNavigation) {
    offsetTop += DEFAULT_SIDEBAR_COLLAPSIBLE_HEIGHT;
  }

  const infoBannerOffset = get('current.offsetHeight', infoBannerRef) || 0;

  const isActionAreaVisible = !!(
    hasCtaButton ||
    loginLabel ||
    get('multilingual', site.country)
  );

  return (
    <ThemeProvider theme={theme}>
      <Container
        isPinned={isPinned}
        ref={containerRef}
        isMenuOpen={isOpen}
        infoBanner={infoBanner}
        infoBannerRef={infoBannerRef}
        infoBannerOffset={infoBannerOffset}
        hideInfoBannerOnPage={!hasInfoBannerOnPage}
        offsetTop={offsetTop}
        navigationOffset={NavigationService.SCROLL_THRESHOLD}
        extraLevelNavigationGroupLabel={extraLevelNavigationGroupLabel}
        extraLevelNavigationIcon={extraLevelNavigationIcon}
        extraLevelNavigation={extraLevelNavigation}
        isTertiaryMenuShowing={hasExtraLevelNavigationOnPage}
        sideBarNavigation={sideBarNavigation}
        containerHeight={containerHeight}
        ctaButtonOptimizelyFullStackClickEvents={
          ctaButtonOptimizelyFullStackClickEvents
        }
        canBeHidden={navsCanBeHidden}
        displaySideBarNavigation={displaySideBarNavigation}
      >
        <BrandArea
          onClick={closeMenu}
          primaryLogoLinksToHomepage={logoLink}
          subSite={subSite}
          partner={partner}
          secondaryLogo={secondaryLogo}
          isShortLogo={isShortLogo}
          trackingContentEntry={trackingContentEntry}
        />
        <Menu
          links={links}
          closeMenu={closeMenu}
          isMenuOpen={isOpen}
          ref={menuRef}
          isActionAreaVisible={isActionAreaVisible}
          navigationTrackingData={navigationTrackingData}
          useNewMobileNavigation={useNewMobileNavigation}
          linksGroupLabel={linksGroupLabel}
          backButtonLabel={backButtonLabel}
          willNotFit={navsDoNotFitScreen}
          canBeHidden={navsCanBeHidden}
        />
        <ActionArea
          loginUrl={loginUrl}
          loginLabel={loginLabel}
          loginTrackingId={loginTrackingId}
          loginOptimizelyFullStackClickEvents={
            loginOptimizelyFullStackClickEvents
          }
          ctaButtonUrl={ctaButtonUrl}
          ctaButtonLabel={ctaButtonLabel}
          ctaButtonTrackingId={ctaButtonTrackingId}
          ctaButtonOptimizelyFullStackClickEvents={
            ctaButtonOptimizelyFullStackClickEvents
          }
          hasLinks={hasLinks}
          isPinned={isPinned}
          isMenuOpen={isOpen}
          closeMenu={closeMenu}
          toggleMenu={toggleMenu}
          localizedUrls={localizedUrls}
          trackingContentEntry={trackingContentEntry}
          willNotFit={navsDoNotFitScreen}
          scrolledPastThreshold={scrolledPastThreshold}
          soloDesign={soloDesign}
          {...site}
        />
      </Container>
    </ThemeProvider>
  );
}

Navigation.propTypes = {
  // TODO: Change field id in Contentful and update prop name.
  //       Don't forget to update the partner page mapper.
  logoLink: PropTypes.bool,
  subSite: PropTypes.shape(subSitePropType),
  partner: PropTypes.shape(partnerPropType),
  secondaryLogo: PropTypes.shape(secondaryLogoPropType),
  loginUrl: PropTypes.string,
  loginLabel: PropTypes.string,
  loginTrackingId: PropTypes.string,
  loginOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  ctaButtonUrl: PropTypes.string,
  ctaButtonLabel: PropTypes.string,
  ctaButtonTrackingId: PropTypes.string,
  ctaButtonOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  infoBanner: PropTypes.shape(infoBannerPropType),
  links: PropTypes.arrayOf(
    PropTypes.shape({
      ...linkPropType,
      links: PropTypes.arrayOf(PropTypes.shape(linkPropType)),
    }),
  ),
  localizedUrls: PropTypes.object,
  contentType: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  useNewMobileNavigation: PropTypes.bool,
  linksGroupLabel: PropTypes.string,
  backButtonLabel: PropTypes.string,
  extraLevelNavigationGroupLabel: PropTypes.string,
  extraLevelNavigationIcon: PropTypes.object,
  extraLevelNavigation: PropTypes.object,
  sideBarNavigation: PropTypes.object,

  /*
   * TEMPORARY EXPERIMENTAL SOLO STYLES EXTENSIONS!
   * added via https://sumupteam.atlassian.net/browse/SA-32606
   * TBD: will be removed or converted to proper features
   */
  soloDesign: PropTypes.object,
};

/**
 * @component
 */
export default Navigation;
