import { useTheme } from '@emotion/react';
import { useMedia } from '@sumup/circuit-ui';

import isServer from '~/shared/util/is-server';

export default function useIsMobile() {
  const theme = useTheme();
  const isMobile = useMedia(theme.breakpoints.untilKilo);

  if (isServer) {
    return undefined;
  }

  return isMobile;
}
