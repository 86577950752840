import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { trackingContentEntryPropType } from '~/shared/util/shared-prop-types';
import Logo from '~/shared/components/icons/Logo';
import Link from '~/shared/components/Link';
import dataSelector from '~/shared/util/data-selector';

/**
 * Primary logo
 */

const linkStyles = css`
  height: 100%;
`;

const StyledLink = styled('a')(linkStyles);

const logoStyles = css`
  width: auto;
  min-width: 0;
  line-height: 0;
  vertical-align: middle;
  height: 100%;
`;

const StyledLogo = styled(Logo)(logoStyles);

const PrimaryLogo = ({
  onClick = () => {},
  logoLinksToHomepage = false,
  id = '',
  trackingContentEntry = {},
  ...rest
}) => {
  let href = '';

  if (id) {
    href = `#${id}`;
  }

  if (logoLinksToHomepage) {
    href = '/';
  }

  return (
    <Link
      href={href}
      onClick={onClick}
      trackingContentEntry={trackingContentEntry}
      trackingId="top_navigation_clicked"
      trackingLabel="sumup_logo"
    >
      <StyledLink data-selector={dataSelector('primary_logo')}>
        <StyledLogo {...rest} />
      </StyledLink>
    </Link>
  );
};

PrimaryLogo.propTypes = {
  logoLinksToHomepage: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
};

/**
 * @component
 */
export default PrimaryLogo;
