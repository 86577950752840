import getConfig from 'next/config';

import * as ENVIRONMENTS from '~/shared/constants/environments';
import {
  TEST_SUFFIX,
  DOT_COM_SDK_ID,
  OTHER_ENVS_SDK_ID,
  VERCEL_DOMAIN_SDK_ID,
} from '~/shared/constants/onetrust';
import { SUMUP_VERCEL_SUFFIX } from '~/shared/constants/websites';
import {
  NextJsConfig,
  PublicRuntimeConifg,
} from '~/shared/types/nextjs-config';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

const { publicRuntimeConfig = {} } = getConfig() as NextJsConfig;
const { environment: configEnvironment } =
  publicRuntimeConfig as PublicRuntimeConifg;

function getSDKIdValue(
  request: Partial<RequestType>,
  environment: string = configEnvironment,
) {
  const isVercelHost = request?.host?.indexOf(SUMUP_VERCEL_SUFFIX) > -1;

  if (isVercelHost) {
    return VERCEL_DOMAIN_SDK_ID;
  }

  const isProduction = environment === ENVIRONMENTS.PRODUCTION;
  if (isProduction) {
    return DOT_COM_SDK_ID;
  }

  return OTHER_ENVS_SDK_ID;
}

export function getSDKId(
  request: Partial<RequestType>,
  useTestVersion = false,
  environment: string = configEnvironment,
) {
  let id = getSDKIdValue(request, environment);

  if (id && useTestVersion) {
    id += TEST_SUFFIX;
  }

  return id;
}

// NOTE: `init()` function is exported globally from '~/services/onetrust'
export function getOptanonWrapper(siteLocale: string, enableLog = false) {
  return `
function OptanonWrapper() {
  window.OTS.init('${siteLocale}');
  ${enableLog ? 'OneTrust.testLog();' : ''}
}`;
}
