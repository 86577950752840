import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import dataSelector from '~/shared/util/data-selector';

const DATA_SELECTOR = 'footer';

const disclaimerTextStyles = ({ theme }) => css`
  margin-top: ${theme.spacings.tera};
  margin-bottom: ${theme.spacings.peta};
  text-align: left;
  ${theme.typography.body.two};

  ${theme.mq.mega} {
    text-align: center;
    ${theme.typography.body.one};
  }
`;

const DisclaimerText = styled('p')(disclaimerTextStyles);

function DisclaimerLine({ text = '' }) {
  if (!text) {
    return null;
  }

  return (
    <DisclaimerText data-selector={dataSelector('disclaimer', DATA_SELECTOR)}>
      {text}
    </DisclaimerText>
  );
}

DisclaimerLine.propTypes = {
  text: PropTypes.string,
};

export default DisclaimerLine;
