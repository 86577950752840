import isEmpty from 'lodash/fp/isEmpty';
import values from 'lodash/fp/values';

import * as Logger from './logger';

import { CONTENTFUL_PAGE_TYPES } from '~/shared/constants/pages';
import isServer from '~/shared/util/is-server';
import { DASHBOARD_TARGET } from '~/shared/constants/websites';

export const destinationCategory = {
  ...DASHBOARD_TARGET,
  INTERNAL: 'internal',
  EXTERNAL: 'external',
  DOWNLOAD: 'download',
  FORM_SUBMISSION: 'form_submission',
  UI_OPERATION: 'UI_operation',
  SUPPORT_CENTER: 'support_center',
  UNCATEGORIZED: 'uncategorized',
};

export function getDataLayer() {
  window.dataLayer = window.dataLayer || [];

  return window.dataLayer || [];
}

export function sendEvent(event) {
  if (isEmpty(event)) {
    Logger.warn(`'event' must be an object, received ${event}`);
    return;
  }

  if (isServer) {
    return;
  }

  if (__DEV__) {
    // eslint-disable-next-line no-console
    console.info('Analytics event:', event);
    return;
  }

  getDataLayer().push(event);
}

// Export a string of all destination categories concated by comma.
export const ALL_DESTINATION_CATEGORY = values(destinationCategory)
  // escape underscores in mdx file to comply with original string
  .map((category) => category.replace('_', '\\_'))
  .join(', ');

export const BUSINESS_GUIDE_ZONE_ID = 'business-guide';

export function getZoneId(routeZoneId, pageType) {
  if (routeZoneId) {
    return routeZoneId;
  }

  switch (true) {
    case pageType === CONTENTFUL_PAGE_TYPES.SBG_INDEX_PAGE:
    case pageType === CONTENTFUL_PAGE_TYPES.SBG_POST:
    case pageType === CONTENTFUL_PAGE_TYPES.SBG_CATEGORY_PAGE:
      return BUSINESS_GUIDE_ZONE_ID;
    default:
      return undefined;
  }
}
