import get from 'lodash/fp/get';
import includes from 'lodash/fp/includes';
import { BLOCKS } from '@contentful/rich-text-types';

import {
  VARIABLES,
  TEXT_FORMATS,
} from '../components/EmbeddedVariable/constants';

const HEADING_TYPES = [
  BLOCKS.HEADING_1,
  BLOCKS.HEADING_2,
  BLOCKS.HEADING_3,
  BLOCKS.HEADING_4,
  BLOCKS.HEADING_5,
  BLOCKS.HEADING_6,
];

export function getContentType(node, additionalProps) {
  const originalContentType = get(['data', 'target', 'contentType'], node);
  const transformLinkToVariable = get(
    ['embeddedLink', 'changeToVariable'],
    additionalProps,
  );

  if (transformLinkToVariable && originalContentType === 'embeddedLink') {
    return 'embeddedVariable';
  }

  return originalContentType;
}
export function getFields(node, additionalProps) {
  const fields = get(['data', 'target'], node) || {};

  const transformLinkToVariable = get(
    ['embeddedLink', 'changeToVariable'],
    additionalProps,
  );
  const originalContentType = getContentType(node);

  if (transformLinkToVariable && originalContentType === 'embeddedLink') {
    return {
      variable: VARIABLES.GLOBAL_VARIABLE,
      globalVariable: {
        value: fields.label,
      },
      textFormats: [TEXT_FORMATS.BOLD],
    };
  }

  return fields;
}

export function contentHasHeadings(content, types = HEADING_TYPES) {
  let hasHeadings = false;

  content.content.forEach((block) => {
    const { nodeType } = block;

    if (includes(nodeType, types)) {
      hasHeadings = true;
    }
  });

  return hasHeadings;
}

export function mapHeadingsContent(content, as, types = HEADING_TYPES) {
  if (!content || !content.content) {
    return content;
  }

  const updatedContent = content.content.map((block, index) => {
    const { nodeType } = block;

    if (includes(nodeType, types)) {
      if (index === 0) {
        return { ...block, as };
      }

      return {
        ...block,
        as: 'p',
      };
    }

    return block;
  });

  return {
    ...content,
    content: updatedContent,
  };
}
