/*
 * Optimizely HOC used to wrap React components and add support for rendering proper variation data.
 */

import React from 'react';
import PropTypes from 'prop-types';
import hoistNonReactStatics from 'hoist-non-react-statics';
import isEmpty from 'lodash/fp/isEmpty';

import useOptimizelyData from './use-optimizely-data';
import { getVariationProps } from './OptimizelyVariationsService';

import { TYPES } from '~/shared/constants/sections';

function withOptimizelyVariation(Component) {
  const componentName = Component.displayName || Component.name || 'Component';
  const WithOptimizelyVariation = React.forwardRef((props, ref) => {
    const { experiments } = useOptimizelyData();

    if (props.contentType !== TYPES.VARIATION_CONTAINER) {
      return <Component ref={ref} {...props} />;
    }

    const variationProps = getVariationProps(props, experiments);

    // if experiment data is not found in headers/cookies do not render anything
    if (isEmpty(variationProps)) {
      return null;
    }

    return <Component ref={ref} {...variationProps} />;
  });

  WithOptimizelyVariation.propTypes = {
    contentType: PropTypes.string,
  };

  // eslint-disable-next-line max-len
  WithOptimizelyVariation.displayName = `WithOptimizelyVariation(${componentName})`;

  return hoistNonReactStatics(WithOptimizelyVariation, Component);
}

export default withOptimizelyVariation;
