import React from 'react';
import isEmpty from 'lodash/fp/isEmpty';
import { Anchor as CircuitAnchor } from '@sumup/circuit-ui';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

import Link from '../Link';

const anchorStyles = () => css`
  display: inline;
`;

const StyledAnchor = styled(CircuitAnchor)(anchorStyles);

export interface AnchorProps {
  'children'?: React.ReactNode;
  'title'?: string;
  'id'?: string;
  'className'?: string;
  'data-selector'?: string;
  'size'?: 'one' | 'two';
  'tabIndex'?: number;
  'href'?: string;
}

/**
 * A basic anchor component for text links.
 */
const Anchor = ({
  children,
  title,
  className,
  id,
  size = 'one',
  'data-selector': dataSelector,
  tabIndex,
  ...otherProps
}: AnchorProps) => {
  if (isEmpty(children)) {
    return null;
  }
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    <Link {...otherProps}>
      <StyledAnchor
        size={size}
        data-selector={dataSelector}
        title={title}
        className={className}
        id={id}
        tabIndex={tabIndex}
      >
        {children}
      </StyledAnchor>
    </Link>
  );
};

/**
 * @component
 */
export default Anchor;
