import { useContext } from 'react';

import useFeatureFlag from '~/shared/hooks/use-feature-flag';
import SiteContext from '~/shared/providers/SiteContext';

export default function useEditButton() {
  const site = useContext(SiteContext);
  const { spaceId, environmentId } = site;
  const hideButton = useFeatureFlag('hideEditButton');

  // spaceId is false on production that's why it won't show up.
  if (spaceId && environmentId && !hideButton) {
    return true;
  }

  return false;
}
