import get from 'lodash/fp/get';
import isEmpty from 'lodash/fp/isEmpty';
import omit from 'lodash/fp/omit';

import { hasClickedLinkIdToHighlight as hasClicked } from '../../MenuHighlightService';

import { isPageUrlInMenuLinks } from '~/shared/services/navigation';
// eslint-disable-next-line max-len
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';

export function createScreens(experiments, defaultLinksGroupLabel, links = []) {
  const allScreens = {};
  const linksToScreensMap = {};
  allScreens.default = {
    links: links.map(omit(['links'])),
    id: 'default',
    nesting: 0,
    linksGroupLabel: defaultLinksGroupLabel,
  };

  function populateScreensObjects(getPath, screenId, nesting, arr = []) {
    arr
      .map((link) => getVariationComponent(link, experiments))
      .forEach((link) => {
        const subLinks = get(getPath, link) || [];

        if (isEmpty(subLinks)) {
          return;
        }
        const linkId = link.id;
        if (!linksToScreensMap[linkId]) {
          linksToScreensMap[linkId] = {
            screenId: [screenId],
            toScreenId: linkId,
            label: link.label,
            id: linkId,
            nesting,
          };
        } else {
          linksToScreensMap[linkId].screenId.push(screenId);
        }

        allScreens[linkId] = {
          links: subLinks.map(omit(['links'])),
          id: linkId,
          nesting,
          linksGroupLabel: link.linksGroupLabel,
        };

        populateScreensObjects(
          'extraLevelNavigation.links',
          linkId,
          2,
          subLinks,
        );
      });
  }

  populateScreensObjects('links', 'default', 1, links);

  return { allScreens, linksToScreensMap };
}

export function getInitialScreen(allScreens, request, site) {
  const foundScreen = Object.values(allScreens)
    .sort(
      // search for initial screen down to top nesting: 2 => 1 => 0
      (screenA, screenB) => screenB.nesting - screenA.nesting,
    )
    .find((screen) => isPageUrlInMenuLinks(request, site, screen.links));

  if (!foundScreen) {
    return allScreens.default;
  }

  return foundScreen;
}

export function buildScreensStack(
  initialScreen,
  allScreens,
  linksToScreensMap,
) {
  const screensStack = [];

  if (isEmpty(allScreens)) {
    return screensStack;
  }

  screensStack[0] = allScreens.default;

  if (initialScreen.id !== 'default') {
    screensStack[initialScreen.nesting] = initialScreen;
    const previousScreen = Object.values(linksToScreensMap).find(
      ({ toScreenId }) => toScreenId === initialScreen.id,
    );

    if (previousScreen) {
      const screenId =
        previousScreen.screenId.find((sid) => hasClicked(sid)) ||
        previousScreen.screenId[0];
      const mappedPreviousScreen = allScreens[screenId];
      screensStack[mappedPreviousScreen.nesting] = mappedPreviousScreen;
    }
  }

  return screensStack;
}
