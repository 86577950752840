import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';
import { Col, Row, Grid } from '@sumup/circuit-ui/legacy';

import BackgroundImage from '../components/BackgroundImage';
import BackgroundVideo from '../components/BackgroundVideo';
import DynamicHeading from '../components/DynamicHeading';
import Badge from '../components/Badge';
import * as HeroService from '../HeroService';

import { ALIGNMENT } from '~/shared/constants';
import { richTextPropType } from '~/shared/util/shared-prop-types';
import dataSelector from '~/shared/util/data-selector';
import LegacyButtonGroup from '~/shared/components/LegacyButtonGroup';
import RichText from '~/shared/components/RichText';
import * as inline from '~/shared/components/RichText/configs/inline';
import useWindowSize from '~/shared/hooks/use-window-size';
import useIsLiteMode from '~/shared/hooks/use-is-lite-mode';
import RequestContext from '~/shared/providers/RequestContext';
import useMeasurePerformance from '~/shared/hooks/use-measure-performance';
// eslint-disable-next-line max-len
import { getVariationComponent } from '~/shared/services/optimizely/OptimizelyVariationsService';
import useOptimizelyData from '~/shared/services/optimizely/use-optimizely-data';

const DATA_SELECTOR = 'hero_full';

const centeredStyles = ({ variant }) =>
  variant.isCentered &&
  css`
    text-align: center;
  `;

const gridStyles = ({ theme, variant }) => css`
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: ${variant.hasMediaBackground ? '500px' : '160px'};
  padding-top: ${variant.hasMediaBackground ? '240px' : theme.spacings.giga};
  padding-bottom: ${theme.spacings.giga};

  ${theme.mq.kilo} {
    justify-content: center;
    min-height: 520px;
    padding-top: 130px;
    padding-bottom: 130px;
  }

  ${theme.mq.mega} {
    min-height: 600px;
    padding-top: 150px;
    padding-bottom: 150px;
  }

  *:last-child {
    margin-bottom: 0;
  }
`;

const isNotFirstStyles = ({ theme, variant }) =>
  variant.isNotFirst &&
  css`
    min-height: 550px;

    ${theme.mq.kilo} {
      min-height: 360px;
      padding-top: 60px;
      padding-bottom: 60px;
    }
  `;

const Wrapper = styled('div')(centeredStyles);
const StyledGrid = styled(Grid)(gridStyles, isNotFirstStyles);

/**
 * A fullbleed section with a large heading, subheading, call to action buttons,
 * badge, and an optional background image or video.
 */
function HeroFull({
  headline = '',
  subheadline = {},
  heroImageDesktop: originalHeroImageDesktop = {},
  heroImageTablet: originalHeroImageTablet = {},
  heroImageMobile: originalHeroImageMobile = {},
  heroVideo: originalHeroVideo = {},
  badge: originalBadge,
  alignment = ALIGNMENT.LEFT,
  index,
  className,
  contentType,
  name,
  id,
  background,
  ...buttonProps
}) {
  useMeasurePerformance('hero-full-component-render');

  const { experiments } = useOptimizelyData();
  const heroImageDesktop = getVariationComponent(
    originalHeroImageDesktop,
    experiments,
  );
  const heroImageTablet = getVariationComponent(
    originalHeroImageTablet,
    experiments,
  );
  const heroImageMobile = getVariationComponent(
    originalHeroImageMobile,
    experiments,
  );
  const hasImage = heroImageDesktop || heroImageTablet || heroImageMobile;
  const heroImage = hasImage && {
    mobile: heroImageMobile?.image?.file?.url,
    tablet: heroImageTablet?.image?.file?.url,
    desktop: heroImageDesktop?.image?.file?.url,
    altText: heroImageDesktop?.altText,
  };
  const heroVideo = getVariationComponent(originalHeroVideo, experiments);
  const badge = getVariationComponent(originalBadge, experiments);

  const request = useContext(RequestContext);
  const windowSize = useWindowSize();
  const isLiteMode = useIsLiteMode();

  const showVideo = HeroService.shouldRenderVideo(
    heroVideo,
    windowSize.width,
    request.userAgent,
    isLiteMode,
  );
  const showImage = !isEmpty(heroImage) && !showVideo;

  const isCentered = alignment === ALIGNMENT.CENTER;
  const isNotFirst = index > 0;

  return (
    <Wrapper
      variant={{ isCentered }}
      className={className}
      data-selector={dataSelector('section', DATA_SELECTOR)}
      data-elbcontext="component:hero_full"
    >
      {showImage && <BackgroundImage {...heroImage} />}
      {showVideo && <BackgroundVideo {...heroVideo} />}
      <StyledGrid
        variant={{
          hasMediaBackground: showVideo || showImage,
          isNotFirst,
        }}
      >
        <Row>
          <Col
            span={{ default: 12, kilo: 8, mega: 6 }}
            skip={{
              default: 0,
              kilo: isCentered ? 2 : 0,
              mega: isCentered ? 3 : 0,
            }}
          >
            {headline && (
              <DynamicHeading
                as={isNotFirst ? 'h2' : 'h1'}
                chars={headline.length}
              >
                {headline}
              </DynamicHeading>
            )}
            <RichText
              richText={subheadline}
              renderMark={inline.createRenderMark(inline.ONE)}
              renderNode={inline.createRenderNode(inline.ONE, {
                contentType,
                contentEntryName: name,
                contentEntryId: id,
              })}
            />
            <LegacyButtonGroup
              align={
                isCentered ? LegacyButtonGroup.CENTER : LegacyButtonGroup.LEFT
              }
              buttonDataSelector={dataSelector('button_primary', DATA_SELECTOR)}
              secondaryButtonDataSelector={dataSelector(
                'button_secondary',
                DATA_SELECTOR,
              )}
              trackingContentEntry={{
                contentType,
                contentEntryName: name,
                contentEntryId: id,
              }}
              {...buttonProps}
            />
          </Col>
        </Row>
      </StyledGrid>
      {badge && <Badge badge={badge} parentContentType={contentType} />}
    </Wrapper>
  );
}

HeroFull.propTypes = {
  headline: PropTypes.string,
  subheadline: richTextPropType,
  buttonLabel: PropTypes.string,
  buttonUrl: PropTypes.string,
  buttonTrackingId: PropTypes.string,
  buttonOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  secondaryButtonLabel: PropTypes.string,
  secondaryButtonUrl: PropTypes.string,
  secondaryButtonTrackingId: PropTypes.string,
  secondaryButtonOptimizelyFullStackClickEvents: PropTypes.arrayOf(
    PropTypes.string,
  ),
  heroImage: PropTypes.object,
  heroVideo: PropTypes.object,
  badge: PropTypes.object,
  alignment: PropTypes.oneOf([ALIGNMENT.LEFT, ALIGNMENT.CENTER]),
  index: PropTypes.number,
  className: PropTypes.string,
  contentType: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  background: PropTypes.string,
};

export default HeroFull;
