import getConfig from 'next/config';
import includes from 'lodash/fp/includes';

import {
  getSalesforceConfig,
  getSalesforceEnv,
  ENABLED_WIDGET_BUTTON_LOCALES,
  LIVEAGENT_SCRIPT_ID,
  LIVEAGENT_ENABLE_FEATURE_FLAG,
} from '~/shared/constants/salesforce-liveagent';
import { getLocalization } from '~/shared/services/salesforce-liveagent';
import { getFeatureFlag } from '~/shared/services/feature-flags';
import {
  getActiveCookieCategoriesFromWindow,
  onConsentChange,
} from '~/shared/services/onetrust';
import * as ONE_TRUST from '~/shared/constants/onetrust';

export function getLiveAgentInitScript(locale) {
  const { publicRuntimeConfig = {} } = getConfig();
  const env = getSalesforceEnv(publicRuntimeConfig.environment);
  const salesforceConfig = getSalesforceConfig(env);
  const { salesforceCountry, salesforceLanguage } = getLocalization(locale);
  const showWidgetHelpButton = includes(locale, ENABLED_WIDGET_BUTTON_LOCALES);

  return `
    var initESW = function(gslbBaseURL) {
      embedded_svc.settings.displayHelpButton = ${showWidgetHelpButton};
      embedded_svc.settings.language = '${locale}';
      embedded_svc.settings.showIcon = false;
      embedded_svc.settings.storageDomain = window.location.hostname;
      embedded_svc.settings.enabledFeatures = ['LiveAgent'];
      embedded_svc.settings.entryFeature = 'LiveAgent';
      embedded_svc.settings.extraPrechatFormDetails = [
        {
          label: 'Origin',
          name: 'Origin__c',
          value: 'Marketing_Website',
          displayToAgent: true,
          transcriptFields: ['Origin__c']
        },
        {
          label: 'Country',
          name: 'Country__c',
          value: '${salesforceCountry}',
          displayToAgent: true,
          transcriptFields: ['Country__c']
        },
        {
          label: 'Language',
          name: 'Language__c',
          value: '${salesforceLanguage}',
          displayToAgent: true,
          transcriptFields: ['Language__c']
        }
      ]

      embedded_svc.init(
        '${salesforceConfig.SALESFORCE_URL}',
        '${salesforceConfig.SALESFORCE_CHAT_URL}',
        gslbBaseURL,
        '${salesforceConfig.ORIG_ID}',
        'snapin_test',
        {
          baseLiveAgentContentURL: '${salesforceConfig.LIVE_AGENT_CONTENT_URL}',
          deploymentId: '${salesforceConfig.DEPLOYMENT_ID}',
          buttonId: '${salesforceConfig.LIVE_AGENT_BUTTON_LOCALES[locale]}',
          baseLiveAgentURL: '${salesforceConfig.LIVE_AGENT_URL}',
          eswLiveAgentDevName: '${salesforceConfig.LIVE_AGENT_DEV_NAME}',
          isOfflineSupportEnabled: false
        }
      );
    };
  `;
}

export const injectLiveAgentScript = () => {
  if (window.embedded_svc) {
    window.initESW('https://service.force.com');
    return;
  }

  if (document.getElementById(LIVEAGENT_SCRIPT_ID)) {
    return;
  }

  const script = document.createElement('script');
  script.id = LIVEAGENT_SCRIPT_ID;
  script.setAttribute(
    'src',
    'https://sumup--quality.my.salesforce.com/embeddedservice/5.0/esw.min.js',
  );
  script.onload = function onloadLiveagentScript() {
    window.initESW(null);
  };

  document.body.appendChild(script);
};

export const syncLiveAgentScript = (opts = {}) => {
  const activeCookieCategories = getActiveCookieCategoriesFromWindow();
  const hasEnabledFunctionalCookies = includes(
    ONE_TRUST.COOKIE_CATEGORIES.FUNCTIONAL,
    activeCookieCategories,
  );

  if (hasEnabledFunctionalCookies) {
    injectLiveAgentScript();
  } else if (opts.reloadOnNotEnabledCookies) {
    // if user changes functional cookies to be not enabled after they were enabled before
    // we need to reload the page to get rid of all the scripts and iframes that Salesforce created in DOM
    // ad presence of salesforce main liveagent script in DOM indicates that reload is necessary.
    const liveagentScript = document.getElementById(LIVEAGENT_SCRIPT_ID);

    if (liveagentScript) {
      window.location.reload();
    }
  }
};

export function init(site, request) {
  const isSalesforceChatEnabled = getFeatureFlag(
    LIVEAGENT_ENABLE_FEATURE_FLAG,
    site,
    request,
  );
  if (!isSalesforceChatEnabled) {
    return;
  }

  syncLiveAgentScript();
  onConsentChange(() => {
    syncLiveAgentScript({ reloadOnNotEnabledCookies: true });
  });
}
