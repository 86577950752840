export type Timer = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

export const getTimerValues = (endDate: Date): Timer => {
  if (!endDate) {
    return undefined;
  }

  const now = new Date().getTime();
  const timeLeft = endDate.getTime() - now;

  const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

  return { days, hours, minutes, seconds };
};
