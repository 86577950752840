/*
 * Optimizely utils used in React components to map and render proper variation data.
 */
import find from 'lodash/fp/find';
import isEmpty from 'lodash/fp/isEmpty';

import { TYPES } from '~/shared/constants/sections';

export function getVariationProps(
  experimentProps = {},
  activeExperiments = [],
) {
  const {
    variations,
    experimentKey,
    meta,
    experimentId,
    experimentTitle,
    ...componentProps
  } = experimentProps;
  const targetExperiment = find(
    (activeExperiment) => activeExperiment.experimentKey === experimentKey,
    activeExperiments,
  );

  if (isEmpty(targetExperiment)) {
    return {};
  }

  const variationContentId = meta[targetExperiment.variationKey];
  const variationProps = find(
    (variation) => variation.id === variationContentId,
    variations,
  );

  if (isEmpty(variationProps)) {
    return {};
  }

  return {
    ...componentProps,
    ...variationProps,
  };
}

export function mapVariations(components = [], activeExperiments = []) {
  return components.reduce((memo, component) => {
    const variationComponent = getVariationComponent(
      component,
      activeExperiments,
    );

    if (!isEmpty(variationComponent)) {
      memo.push(variationComponent);
    }

    return memo;
  }, []);
}

export function getVariationComponent(component, activeExperiments = []) {
  if (component && component.contentType === TYPES.VARIATION_CONTAINER) {
    const componentVariation = getVariationProps(component, activeExperiments);

    return componentVariation;
  }

  return component;
}

export function hasExperimentVariation(
  experimentKey,
  variationKey,
  activeExperiments = [],
) {
  if (!experimentKey || !variationKey) {
    return false;
  }

  const targetExperiment = find(
    (experiment) => experiment.experimentKey === experimentKey,
    activeExperiments,
  );

  if (!targetExperiment) {
    return false;
  }

  return targetExperiment.variationKey === variationKey;
}
