export const VARIANTS = {
  PRIMARY: 'primary',
  COLLAPSABLE: 'collapsable',
} as const;

export const LEVELS = {
  ONE: 'one',
  TWO: 'two',
} as const;

export const SIZES = {
  THREE: 'three',
  FOUR: 'four',
} as const;

export type LevelsType = (typeof LEVELS)[keyof typeof LEVELS];

export type VariantsType = (typeof VARIANTS)[keyof typeof VARIANTS];

export const SIDEBAR_WIDTH = '240px';
export const DEFAULT_SIDEBAR_COLLAPSIBLE_HEIGHT = 56;
export const DEFAULT_HEADER_HEIGHT = 100;
export const SIDEBAR_TOP_PADDING = 56;
