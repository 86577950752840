import isEmpty from 'lodash/fp/isEmpty';

import {
  detectAndroidVersion,
  detectIosVersion,
} from '~/shared/services/user-agent';

const MIN_WINDOW_WIDTH = 800;
const MIN_IOS_VERSION = 10;
const MIN_ANDROID_VERSION = 6;

export const shouldRenderVideo = (
  heroVideo,
  windowWidth,
  userAgent,
  isLiteMode,
) => {
  if (
    isEmpty(heroVideo) ||
    heroVideo.isHidden ||
    isLiteMode ||
    !windowWidth ||
    windowWidth < MIN_WINDOW_WIDTH
  ) {
    return false;
  }
  const iOSVersion = detectIosVersion(userAgent);
  const androidVersion = detectAndroidVersion(userAgent);
  return !(
    (iOSVersion && iOSVersion < MIN_IOS_VERSION) ||
    (androidVersion && androidVersion < MIN_ANDROID_VERSION)
  );
};

export const getPretitleAndTitleHeadings = (pretitle, headline, index) => {
  if (pretitle) {
    return index === 0 ? ['h1', 'p'] : ['h2', 'p'];
  }

  if (headline) {
    return index === 0 ? ['p', 'h1'] : ['p', 'h2'];
  }

  return ['p', 'p'];
};
