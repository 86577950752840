import React, { FC } from 'react';
import styled from '@emotion/styled';
import { Theme, css } from '@emotion/react';
import { ClickEvent } from '@sumup/circuit-ui';

import { LanguageLink } from '../../interfaces';
import MenuItem from '../../../MenuItem';

import dataSelector from '~/shared/util/data-selector';

const wrapperStyles = css`
  display: flex;
  flex-direction: row;
`;
const LanguageMenuWrapper = styled('div')(wrapperStyles);

const selectStyles = ({ theme }: { theme: Theme }) => css`
  padding: ${theme.spacings.bit} ${theme.spacings.byte};
`;
const LanguageMenuItem = styled(MenuItem)(selectStyles);

export interface MobileLanguageMenuProps {
  allLinks?: LanguageLink[];
  onLanguageClick?: (_event?: ClickEvent) => void;
}

export const MobileLanguageMenu: FC<MobileLanguageMenuProps> = ({
  allLinks = [],
  onLanguageClick = () => {},
}) => (
  <LanguageMenuWrapper data-selector={dataSelector('mobile', 'language_menu')}>
    {allLinks.map((link) => (
      <LanguageMenuItem
        key={link.label}
        data-selector={dataSelector('language_item_link', 'language_menu')}
        destination={{ url: link.url }}
        label={link.label}
        onClick={onLanguageClick}
      />
    ))}
  </LanguageMenuWrapper>
);
