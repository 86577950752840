import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isEmpty from 'lodash/fp/isEmpty';
import values from 'lodash/fp/values';

import BadgeContainer from '../BadgeContainer';

import ProductsContext from '~/shared/providers/ProductsContext';
import Price from '~/shared/components/Price';
import dataSelector from '~/shared/util/data-selector';
import { PRICE_FORMATS } from '~/shared/constants/price';
import { BADGE_BACKGROUNDS } from '~/shared/constants';

const DATA_SELECTOR = 'pricingBadge';

const expiryLabelStyles = css`
  margin-left: 11px;
  line-height: 18px;
  font-size: 13px;
  text-transform: uppercase;
`;
const ExpiryLabel = styled('div')(expiryLabelStyles);

/**
 * PricingBadge with duration label
 */
function PricingBadge({
  background,
  className,
  durationLabel,
  isHidden = false,
  product: productProp,
  format = PRICE_FORMATS.SIZE_32,
  hideDate = false,
}) {
  const { products = {} } = useContext(ProductsContext);
  const product = products[productProp?.productId];

  if (isEmpty(product) || isHidden) {
    return null;
  }

  const {
    promoPrice,
    price,
    expirationDate,
    hasPromo,
    installments,
    currency,
    footnoteConfig,
    prependCurrencySymbol,
    productId,
  } = product;

  return (
    <BadgeContainer
      className={className}
      background={background}
      data-selector={dataSelector('wrapper', DATA_SELECTOR)}
      data-elbcontext="component:pricing_badge"
    >
      <Price
        product={{
          /** Set price for showing price */
          price: hasPromo ? promoPrice : price,
          promoPrice,
          installments,
          currency,
          /** Set hasPromo to false to avoid showing original price */
          hasPromo: false,
          prependCurrencySymbol,
          footnoteConfig,
          productId,
        }}
        format={format}
      />
      {!hideDate && hasPromo && expirationDate && (
        <ExpiryLabel>
          {durationLabel}
          <br />
          {expirationDate}
        </ExpiryLabel>
      )}
    </BadgeContainer>
  );
}

PricingBadge.propTypes = {
  className: PropTypes.string,
  /**
   * selected background from Contentful
   */
  background: PropTypes.oneOf(BADGE_BACKGROUNDS),
  /**
   * duration label from Contentful
   */
  durationLabel: PropTypes.string,
  /**
   * @deprecated
   * Should badge hidden in locale? (set up in Contentful)
   */
  isHidden: PropTypes.bool,
  /**
   * selected product id from Contentful
   */
  product: PropTypes.object,
  /**
   * format for Price component, should be one of PRICE_FORMATS
   */
  format: PropTypes.oneOf(values(PRICE_FORMATS)),
  /**
   * decides whether to show the promo duration or not
   */
  hideDate: PropTypes.bool,
};

/**
 * @component
 */
export default PricingBadge;
