import getConfig from 'next/config';

import { WEBSITE_BACKEND_URLS } from './constants';

import { ensureLocaleOnPath } from '~/shared/services/LinkService';
import * as url from '~/shared/services/url';
import * as ENVIRONMENTS from '~/shared/constants/environments';
import { DEFAULT_LOCALE_KEY } from '~/shared/constants/locales';

const { publicRuntimeConfig = {} } = getConfig();
const { environment } = publicRuntimeConfig;

const configBackendUrl = {
  host: publicRuntimeConfig.backendUrl,
  protocol: 'https',
};

const getWebsiteBackendUrl = (
  env = environment,
  backendUrls = WEBSITE_BACKEND_URLS,
) =>
  publicRuntimeConfig.backendUrl
    ? configBackendUrl
    : backendUrls[env.toLowerCase()] || backendUrls[ENVIRONMENTS.DEV];

export const formatUrl = (pathname, protocol, host, query = {}) => {
  const urlObj = {
    protocol,
    host,
    pathname,
    query,
  };

  return url.format(urlObj);
};

export const formatBackendUrl = (
  _pathname?: string,
  query: Record<string, string | string[]> = {},
  locale: string = undefined,
  env: string = environment,
) => {
  const { host, protocol } = getWebsiteBackendUrl(env);

  const shouldEnsureLocale = locale && locale !== DEFAULT_LOCALE_KEY;

  const pathname = shouldEnsureLocale
    ? ensureLocaleOnPath(locale, _pathname)
    : _pathname;

  return formatUrl(pathname, protocol, host, query);
};
