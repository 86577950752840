import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import isPropValid from '@emotion/is-prop-valid';
import isEmpty from 'lodash/fp/isEmpty';

import NavAnchor from '../../../NavAnchor';
import SubMenu from '../SubMenu';

import ArrowIcon from './svg/arrow.svg';

import * as Analytics from '~/shared/services/analytics';
import dataSelector from '~/shared/util/data-selector';
import Link from '~/shared/components/Link';
import { linkPropType } from '~/shared/util/shared-prop-types';
import RequestContext from '~/shared/providers/RequestContext';
import isTouchDevice from '~/shared/util/isTouchDevice';
import { sendNinetailedEvent } from '~/shared/services/ninetailed/events';
import { NAVIGATION_EVENTS } from '~/shared/services/optimizely/navigation-revamp-experiment/constants';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';

const DATA_SELECTOR = 'main_nav_menu';

export const ARROW_CLICK_TRACKING_EVENT = 'arrow_click@vertical_navigation';

const listItemStyles = ({ theme }) => css`
  margin: 0 ${theme.spacings.giga};
  width: calc(100% - 2 * ${theme.spacings.giga});
  border-bottom: 1px solid var(--cui-border-normal);
  position: relative;

  ${theme.mq.nav} {
    display: inline-flex;
    text-align: center;
    margin: 0 ${theme.spacings.bit};
    width: auto;
    border: none;
    position: static;
  }

  ${theme.mq.mega} {
    margin: 0 ${theme.spacings.byte};
  }
`;

const verticalListItemStyles = ({ theme, isVertical }) =>
  isVertical &&
  css`
    ${theme.mq.nav} {
      width: 100%;
    }
  `;

const collapsibleItemStyles = ({ theme, applyCollapsibleStyles }) =>
  applyCollapsibleStyles &&
  css`
    ${theme.mq.mainNavCollapsibleMenu} {
      display: none;
    }
  `;

const ListItem = styled('li')(
  listItemStyles,
  verticalListItemStyles,
  collapsibleItemStyles,
);

const wrapperStyles = ({ theme }) => css`
  ${theme.mq.nav} {
    position: relative;
    display: inline-block;
    padding-right: ${theme.spacings.giga};
  }
`;

const Wrapper = styled('div')(wrapperStyles);

const navAnchorStyles = ({ theme }) => css`
  position: relative;
  display: inline-block;
  z-index: 3;
  cursor: pointer;

  ${theme.mq.nav} {
    margin: 0;
    white-space: nowrap;
    padding-top: calc((${theme.spacings.nav.desktop} - 1em) / 2);
    padding-bottom: calc((${theme.spacings.nav.desktop} - 1em) / 2);
  }
`;

const navAnchorOpenStyles = ({ isOpen }) =>
  isOpen &&
  css`
    color: var(--cui-fg-accent);
  `;

const navAnchorVerticalStyles = ({ theme, isVertical }) =>
  isVertical &&
  css`
    ${theme.mq.nav} {
      margin: 12px 0;
      padding: 0;
    }
  `;

const StyledNavAnchor = styled(NavAnchor)(
  navAnchorStyles,
  navAnchorOpenStyles,
  navAnchorVerticalStyles,
);

const buttonStyles = ({ theme }) => css`
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  background: none;
  border: 0;
  outline: none;
  text-align: right;
  z-index: 2;
  padding: ${theme.spacings.giga} ${theme.spacings.byte};

  &:hover,
  &:focus {
    cursor: pointer;
  }

  ${theme.mq.nav} {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    margin-left: -${theme.spacings.peta};
  }
`;

export const Button = styled('button')(buttonStyles);

const buttonLabelStyles = () => css`
  /** hide visually  */
  border: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const ButtonLabel = styled('span')(buttonLabelStyles);

const iconStyles = () => css`
  transition:
    transform 0.1s ease-in-out,
    color 0.1s ease-in-out;
`;

const iconOpenStyles = ({ isOpen }) =>
  isOpen &&
  css`
    color: var(--cui-fg-accent);
    transform: rotate(180deg);
  `;

export const StyledArrowIcon = styled(ArrowIcon, {
  shouldForwardProp: isPropValid,
})(iconStyles, iconOpenStyles);

const linkBadgeLabelStyles = ({ theme }) => css`
  background: var(--cui-fg-success);
  color: white;
  font-size: 10px;
  padding: ${theme.spacings.bit} calc(${theme.spacings.kilo} / 2);
  line-height: 0.8;
  text-transform: uppercase;
  border-radius: ${theme.borderRadius.tera};
  margin-left: ${theme.spacings.bit};
  display: inline-block;

  ${theme.mq.nav} {
    margin-left: -${theme.spacings.bit};
    margin-top: calc(${theme.spacings.giga} + ${theme.spacings.bit});
  }
`;

const verticalLinkBadgeLabelStyles = ({ theme, isVertical }) =>
  isVertical &&
  css`
    ${theme.mq.nav} {
      margin: calc(${theme.spacings.giga} / 2) ${theme.spacings.bit} 0;
    }
  `;

export const LinkBadgeLabel = styled('div')(
  linkBadgeLabelStyles,
  verticalLinkBadgeLabelStyles,
);

const linkBadgeContainerStyles = () => css`
  display: inline-block;
`;

export const LinkBadgeContainer = styled('div')(linkBadgeContainerStyles);

/**
 * A link with an optional submenu that expands on hover, focus, and click.
 */
export default function MenuSection({
  closeMenu = () => {},
  labelOpen = 'Open sub menu',
  labelClose = 'Close sub menu',
  label = '',
  url = '',
  links = [],
  openLinkInNewWindow,
  contentType,
  name,
  id,
  linkBadgeLabel,
  isVertical = false,
  linksGroupLabel,
  applyCollapsibleStyles = false,
  index,
  isOpen,
  onHover = () => {},
  onToggle = () => {},
  onLinkClick = () => {},
  navigationTrackingData = {},
  ...rest
}) {
  const request = useContext(RequestContext);
  const { pathname } = request;

  if (!label) {
    return null;
  }

  if (isEmpty(links)) {
    const handleMenuLinkClick = (event) => {
      OptimizelyFullStack.trackEvent({
        eventName: NAVIGATION_EVENTS.FIRST_LEVEL,
        eventTags: { label },
      });
      onLinkClick(id);
      closeMenu(event);
    };

    return (
      <ListItem
        applyCollapsibleStyles={applyCollapsibleStyles}
        isVertical={isVertical}
        data-selector={dataSelector('list_item', DATA_SELECTOR)}
      >
        <Link
          href={url}
          onClick={handleMenuLinkClick}
          target={openLinkInNewWindow ? '_blank' : undefined}
          trackingContentEntry={{
            contentType,
            contentEntryName: name,
            contentEntryId: id,
          }}
          {...rest}
        >
          <StyledNavAnchor
            isVertical={isVertical}
            data-selector={dataSelector('link', DATA_SELECTOR)}
          >
            {label}
          </StyledNavAnchor>
        </Link>
        {!isEmpty(linkBadgeLabel) && (
          <LinkBadgeContainer>
            <LinkBadgeLabel isVertical={isVertical}>
              {linkBadgeLabel}
            </LinkBadgeLabel>
          </LinkBadgeContainer>
        )}
      </ListItem>
    );
  }

  const handleHover = (isHovered) => () => {
    onHover(isHovered, index);
  };
  const hasTouch = isTouchDevice();
  const handleMenuDropdownLinkClick = (event) => {
    if (hasTouch) {
      onToggle(index);
    }
    if (event.currentTarget.href) {
      closeMenu(event);
    }
  };
  const handleSubmenuLinkClick = (event) => {
    onLinkClick(id);
    handleMenuDropdownLinkClick(event);
  };
  const handleArrowClick = () => {
    Analytics.sendEvent({
      event: 'interaction',
      target: 'Mkt_Web',
      action: ARROW_CLICK_TRACKING_EVENT,
      destinationCategory: Analytics.destinationCategory.UI_OPERATION,
      destinationUrl: undefined,
      ...navigationTrackingData,
    });

    sendNinetailedEvent(ARROW_CLICK_TRACKING_EVENT);

    onToggle(index);
  };

  return (
    <ListItem
      onMouseEnter={handleHover(true)}
      onMouseLeave={handleHover(false)}
      onFocus={handleHover(true)}
      onBlur={handleHover(false)}
      onTouchStart={handleHover(true)}
      data-selector={dataSelector('list_item', DATA_SELECTOR)}
    >
      <Wrapper data-selector={dataSelector('list_wrapper', DATA_SELECTOR)}>
        <Link
          href={url}
          onClick={handleMenuDropdownLinkClick}
          target={openLinkInNewWindow ? '_blank' : undefined}
          trackingContentEntry={{
            contentType,
            contentEntryName: name,
            contentEntryId: id,
          }}
          {...rest}
        >
          <StyledNavAnchor
            isOpen={isOpen}
            isCurrent={isOpen}
            data-selector={dataSelector('link', DATA_SELECTOR)}
          >
            {label}
          </StyledNavAnchor>
        </Link>

        <SubMenu
          isOpen={isOpen}
          links={links}
          pathname={pathname}
          onClick={handleSubmenuLinkClick}
          data-selector={dataSelector('sub_menu', DATA_SELECTOR)}
        />
      </Wrapper>

      <Button
        isOpen={isOpen}
        onClick={handleArrowClick}
        aria-expanded={isOpen}
        data-selector={dataSelector('sub_menu_button', DATA_SELECTOR)}
      >
        <StyledArrowIcon isOpen={isOpen} />
        <ButtonLabel>{isOpen ? labelClose : labelOpen}</ButtonLabel>
      </Button>
    </ListItem>
  );
}

MenuSection.propTypes = {
  closeMenu: PropTypes.func,
  labelOpen: PropTypes.string,
  labelClose: PropTypes.string,
  label: PropTypes.string,
  url: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape(linkPropType)),
  openLinkInNewWindow: PropTypes.bool,
  contentType: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  linkBadgeLabel: PropTypes.string,
  isVertical: PropTypes.bool,
  linksGroupLabel: PropTypes.string,
  applyCollapsibleStyles: PropTypes.bool,
  index: PropTypes.number,
  isOpen: PropTypes.bool,
  onHover: PropTypes.func,
  onToggle: PropTypes.func,
  onLinkClick: PropTypes.func,
  navigationTrackingData: PropTypes.object,
};
