import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const baseStyles = ({ theme, size }) => css`
  display: inline-block;
  vertical-align: middle;
  width: ${theme.iconSizes[size]};
  height: ${theme.iconSizes[size]};
  object-fit: cover;
  position: relative;
`;

/**
 * Icon component which is using external SVG files.
 * Using img to display the svg following:
 * https://vecta.io/blog/best-way-to-embed-svg/
 */
const Icon = styled('img')(baseStyles);

Icon.BYTE = 'byte';
Icon.KILO = 'kilo';
Icon.MEGA = 'mega';
Icon.GIGA = 'giga';

Icon.propTypes = {
  /**
   * Icon size
   */
  size: PropTypes.oneOf(['byte', 'kilo', 'mega', 'giga']),
  /**
   * Icon description
   */
  alt: PropTypes.string,
  /**
   * Icon external URL source
   */
  src: PropTypes.string.isRequired,
};

Icon.defaultProps = {
  size: 'mega',
  alt: '',
  loading: 'lazy',
  decoding: 'async',
};

/**
 * @component
 */
export default Icon;
