import * as ENVIRONMENTS from '../../constants/environments';

export const DEFAULT_HOSTNAMES = {
  [ENVIRONMENTS.PRODUCTION]: 'sumup.com',
  [ENVIRONMENTS.DEV]: 'sumup.com',
  [ENVIRONMENTS.TEST]: 'sam-app.ro',
  [ENVIRONMENTS.STAGING]: 'sam-app.ro',
  [ENVIRONMENTS.THETA]: 'theta.sam-app.ro',
};

export const WEBSITE_BACKEND_URLS = {
  [ENVIRONMENTS.PRODUCTION]: {
    host: 'website-backend.sumup.com',
    protocol: 'https',
  },
  [ENVIRONMENTS.STAGING]: {
    host: 'website-backend.sam-app.ro',
    protocol: 'https',
  },
  [ENVIRONMENTS.THETA]: {
    host: 'website-backend.k8s-eu-central-1-theta.external.sam-app.ro',
    protocol: 'https',
  },
  [ENVIRONMENTS.DEV]: { host: 'localhost:3001', protocol: 'http' },
  [ENVIRONMENTS.TEST]: {
    host: 'website-backend.sam-app.ro',
    protocol: 'https',
  },
};

export const FORMATTED_WEBSITE_BACKEND_URLS = {
  [ENVIRONMENTS.PRODUCTION]: 'https://website-backend.sumup.com',

  [ENVIRONMENTS.STAGING]: 'https://website-backend.sam-app.ro',

  [ENVIRONMENTS.THETA]:
    'https://website-backend.k8s-eu-central-1-theta.external.sam-app.ro',

  [ENVIRONMENTS.DEV]: 'http://localhost:3001',
  [ENVIRONMENTS.TEST]: 'https://website-backend.sam-app.ro',
};
