import Elbwalker, { elb, WebDestination } from '@elbwalker/walker.js';

import { destinationSumUpGTM } from './walker';

import isServer from '~/shared/util/is-server';

export function setupAnalytics() {
  if (isServer) {
    return;
  }

  window.elbLayer = [];
  window.elbwalker = Elbwalker();
  window.elb = elb;

  // Google Tag Manager
  elb('walker destination', destinationSumUpGTM, {});

  if (__DEV__) {
    elb('walker destination', {
      push: (...args) => {
        // eslint-disable-next-line no-console
        console.log('elbwalker destination', ...args);
      },
    } as WebDestination.Function);
  }
}
