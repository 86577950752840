export const EXPERIMENTS_COOKIE_NAME = 'optimizely_experiments'; // shared between all SumUp apps!
export const EXPERIMENT_USER_COOKIE_NAME = 'optimizely_experiment_user'; // shared between all SumUp apps!

export const USER_LOGGED_IN_COOKIE_NAME = 'user_has_already_logged_in';
export const USER_LOGGED_IN_COOKIE_MAX_AGE = 1000 * 60 * 60 * 24 * 365 * 2; // 2 years

export const USER_VISITED_STORE = 'user_visited_store';
export const USER_VISITED_STORE_MAX_AGE = 1000 * 60 * 60 * 24 * 365 * 2; // 2 years

export const EXPERIMENT_DELIMITER = '&';
export const EXPERIMENT_EV = ':';

export const EXPERIMENT_COOKIES_MAX_AGE_DAYS = 90; // days;
export const EXPERIMENT_COOKIES_MAX_AGE_MS =
  1000 * 60 * 60 * 24 * EXPERIMENT_COOKIES_MAX_AGE_DAYS; // miliseconds

export const EXPERIMENT_PREVIEW_PARAM = 'optimizelyExperiment';

export const ACCOUNT_ID = '10733391090';

export const PROJECT_ID = '18423480104';

export const STATIC_DATA_APIS_ONE_DOMAIN = {
  // use website-backend as a proxy due to CORS
  production:
    'https://website-backend.sumup.com/static/op-data/data-onedomain.json',
  staging:
    'https://website-backend.sam-app.ro/static/op-data/data-onedomain.json',
};

export const THRESHOLD_TIMES = [
  1,
  3,
  5,
  10,
  30,
  60,
  2 * 60,
  5 * 60,
  10 * 60,
  15 * 60,
  20 * 60,
  30 * 60,
  60 * 60,
];

export const GA_ID_COOKIE_NAME = '_ga';

export const USE_BUILTIN_DISPATCHER_PARAM = 'useBuiltinDispatcher';

export const OPTIMIZELY_EVENTS_ENDPOINT = '/v1/events/';

// "shopExperience" attribute is heavily used in storefront-web Optimizely audiences and thus
// needs to be present in website attributes too in order to be able to reuse audiences in both apps
export const SHOP_EXPERIENCE_ATTR = 'n/a';

export const NAVIGATION_REVAMP_FEATURE_TOGGLE = '_website__navigation_revamp';

// Enabling this feature replaces calls to website-backend /site/xx-xx API with internal website implementation
export const WB_REPLACEMENT_SITE_FEATURE_TOGGLE =
  '_website__wb_replacement_site_endpoint';

// Enabling this feature replaces calls to website-backend /pages/xx-xx/careers API with internal website implementation
export const WB_REPLACEMENT_PAGE_CAREERS_FEATURE_TOGGLE =
  '_website__wb_replacement_page_endpoint_careers';
// Enabling this feature replaces calls to website-backend /pages/en-us/(air-manual|3g-manual|plus|fees|contact) API with internal website implementation
export const WB_REPLACEMENT_PAGE_MANUALS_FEATURE_TOGGLE =
  '_website__wb_replacement_page_endpoint_manuals';
// Enabling this feature replaces calls to website-backend /pages/xx-xx/press API with internal website implementation
export const WB_REPLACEMENT_PAGE_PRESS_FEATURE_TOGGLE =
  '_website__wb_replacement_page_endpoint_press';

// Feature toggle that enables the Shop Integration feature
export const SHOP_INTEGRATION_FEATURE_TOGGLE = '_website__shop_integration';
export const COMPUTED_PRICE_FEATURE_TOGGLE = '_website__computed_price';
export const PRC_COUPON_FEATURE_TOGGLE = '_website__prc_coupons';

// NEW PDP
export const PRODUCT_CONFIGURATOR_FEATURE_TOGGLE =
  '_website__product_configurator';
