export const SYMBOL_POSITIONS = {
  PREPEND: 'pre',
  POSTPEND: 'pos',
};

// [prepend/postpend, decimal, thousands, addSpace]
export const LOCALE_FORMATS: Record<
  string,
  Record<string, (string | boolean)[]>
> = {
  EUR: {
    'de-AT': [SYMBOL_POSITIONS.POSTPEND, ',', '.', true],
    'de-DE': [SYMBOL_POSITIONS.POSTPEND, ',', '.', true],
    'en-IE': [SYMBOL_POSITIONS.PREPEND, '.', ','],
    'en-MT': [SYMBOL_POSITIONS.PREPEND, '.', ','],
    'es-ES': [SYMBOL_POSITIONS.POSTPEND, ',', '.', true],
    'et-EE': [SYMBOL_POSITIONS.POSTPEND, '.', '\xA0', true],
    'fi-FI': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
    'fr-BE': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
    'fr-FR': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
    'fr-LU': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
    'it-IT': [SYMBOL_POSITIONS.POSTPEND, ',', '.', true],
    'lt-LT': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
    'lv-LV': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
    'nl-BE': [SYMBOL_POSITIONS.PREPEND, ',', '.', true],
    'nl-NL': [SYMBOL_POSITIONS.PREPEND, ',', '.', true],
    'pt-PT': [SYMBOL_POSITIONS.POSTPEND, ',', '.', true],
    'sk-SK': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
    'default': [SYMBOL_POSITIONS.POSTPEND, ',', '.', true],
  },
  CHF: {
    'de-CH': [SYMBOL_POSITIONS.PREPEND, '.', "'", true],
    'it-CH': [SYMBOL_POSITIONS.PREPEND, '.', "'", true],
    'fr-CH': [SYMBOL_POSITIONS.PREPEND, '.', "'", true],
    'default': [SYMBOL_POSITIONS.PREPEND, '.', ','],
  },
  GBP: {
    'en-GB': [SYMBOL_POSITIONS.PREPEND, '.', ','],
    'default': [SYMBOL_POSITIONS.PREPEND, '.', ','],
  },
  PLN: {
    'pl-PL': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
    'default': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
  },
  RUB: {
    'ru-RU': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
    'default': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
  },
  BRL: {
    'pt-BR': [SYMBOL_POSITIONS.PREPEND, ',', '.', true],
    'default': [SYMBOL_POSITIONS.PREPEND, '.', ',', true],
  },
  SEK: {
    'sv-SE': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
    'default': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
  },
  USD: {
    'en-US': [SYMBOL_POSITIONS.PREPEND, '.', ','],
    'es-US': [SYMBOL_POSITIONS.PREPEND, '.', ','],
    'default': [SYMBOL_POSITIONS.PREPEND, '.', ','],
  },
  CAD: {
    'en-CA': [SYMBOL_POSITIONS.PREPEND, '.', ','],
    'fr-CA': [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
    'default': [SYMBOL_POSITIONS.PREPEND, '.', ','],
  },
  AUD: {
    'en-AU': [SYMBOL_POSITIONS.PREPEND, '.', ','],
    'default': [SYMBOL_POSITIONS.PREPEND, '.', ','],
  },
  ARS: {
    'es-AR': [SYMBOL_POSITIONS.PREPEND, ',', '.'],
    'default': [SYMBOL_POSITIONS.PREPEND, ',', '.'],
  },
  PEN: {
    'es-PE': [SYMBOL_POSITIONS.PREPEND, ',', '.'],
    'default': [SYMBOL_POSITIONS.PREPEND, ',', '.'],
  },
  CLP: {
    'es-CL': [SYMBOL_POSITIONS.PREPEND, ',', '.', '\xA0', true],
    'default': [SYMBOL_POSITIONS.PREPEND, ',', '.', '\xA0', true],
  },
  MXN: {
    'es-MX': [SYMBOL_POSITIONS.PREPEND, '.', ','],
    'default': [SYMBOL_POSITIONS.PREPEND, '.', ','],
  },
  BGN: {
    default: [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
  },
  CZK: {
    default: [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
  },
  DKK: {
    default: [SYMBOL_POSITIONS.POSTPEND, ',', '.', true],
  },
  HUF: {
    default: [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
  },
  NOK: {
    default: [SYMBOL_POSITIONS.POSTPEND, ',', '\xA0', true],
  },
  RON: {
    default: [SYMBOL_POSITIONS.POSTPEND, ',', '.', true],
  },
  HRK: {
    default: [SYMBOL_POSITIONS.POSTPEND, ',', '.', true],
  },
  COP: {
    default: [SYMBOL_POSITIONS.PREPEND, ',', '.', true],
  },
};

export const CURRENCY_SYMBOLS = {
  default: '\u20AC',
  ARS: '$',
  AUD: '$',
  CAD: '$',
  EUR: '\u20AC',
  PEN: 'S/',
  PLN: 'z\u0142',
  GBP: '\u00A3',
  RUB: '\u0440\u0443\u0431.',
  BRL: 'R$',
  CHF: 'CHF',
  SEK: 'kr',
  USD: '$',
  CLP: '$',
  BGN: 'лв.',
  CZK: 'Kč',
  DKK: 'kr',
  HUF: 'Ft',
  NOK: 'kr',
  RON: 'Lei',
  HRK: 'kn',
  COP: '$',
  MXN: '$',
} as const;

export const CURRENCIES = {
  default: 'EUR',
  BR: 'BRL',
  PL: 'PLN',
  GB: 'GBP',
  CH: 'CHF',
  SE: 'SEK',
  US: 'USD',
  CL: 'CLP',
  BG: 'BGN',
  CZ: 'CZK',
  DK: 'DKK',
  HU: 'HUF',
  NO: 'NOK',
  RO: 'RON',
  HR: 'HRK',
  CO: 'COP',
  PE: 'PEN',
  MX: 'MXN',
} as const;
