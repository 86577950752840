/* eslint-disable no-param-reassign */
import { produce } from 'immer';

import {
  FETCH_ORDER,
  UPDATING_QUANTITY,
  REMOVING_LINE_ITEM,
  ADDING_LINE_ITEM,
  FETCH_ORDER_SUCCESS,
  UPDATE_QUANTITY_SUCCESS,
  LINE_ITEM_REMOVED,
  LINE_ITEM_ADDED,
  FETCH_ORDER_ERROR,
  UPDATE_QUANTITY_ERROR,
  LINE_ITEM_ERROR,
  LINE_ITEM_LIMITED_QUANTITY,
  LINE_ITEM_REDUCED_QUANTITY,
} from './constants';
import { ReducerAction, CartState, Order } from './types';

export const shopReducer = (state: CartState, action: ReducerAction) => {
  switch (action.type) {
    case FETCH_ORDER:
      return produce(state, (draft) => {
        draft.status = 'fetching';
      });
    case UPDATING_QUANTITY:
      return produce(state, (draft) => {
        draft.status = 'updating_quantity';
      });
    case REMOVING_LINE_ITEM:
      return produce(state, (draft) => {
        draft.status = 'removing_item';
      });
    case ADDING_LINE_ITEM:
      return produce(state, (draft) => {
        draft.status = 'adding_item';
      });

    case FETCH_ORDER_SUCCESS:
    case UPDATE_QUANTITY_SUCCESS:
    case LINE_ITEM_REMOVED:
    case LINE_ITEM_ADDED:
    case LINE_ITEM_LIMITED_QUANTITY:
    case LINE_ITEM_REDUCED_QUANTITY:
      return produce(state, (draft) => {
        draft.status = 'idle';
        draft.order = action.data as Order;
      });

    case FETCH_ORDER_ERROR:
    case UPDATE_QUANTITY_ERROR:
    case LINE_ITEM_ERROR:
      return produce(state, (draft) => {
        draft.status = 'idle';
        draft.error = action.data;
      });

    default:
      return state;
  }
};
