import { hasClickedLinkIdToHighlight } from '../../MenuHighlightService';

import { isPageUrlInMenuLinks, SiteData } from '~/shared/services/navigation';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

interface Link {
  id: string;
  url?: string;
  links?: Link[];
}

export function getDefaultOpenLinkIndex(
  request: RequestType,
  site: SiteData,
  links: Link[] = [],
): number {
  // pre-open menu that has submenu link with url similar to requested page url
  const pageUrlOpenSublinkIndex = links.findIndex(({ links: subLinks }) =>
    isPageUrlInMenuLinks(request, site, subLinks),
  );

  if (pageUrlOpenSublinkIndex > -1) {
    // in case of multiple similar submenu links pre-open menu with recently clicked one
    const clickedOpenSublinkIndex = links.findIndex(
      ({ id, links: subLinks }) =>
        hasClickedLinkIdToHighlight(id) &&
        isPageUrlInMenuLinks(request, site, subLinks),
    );

    return clickedOpenSublinkIndex > -1
      ? clickedOpenSublinkIndex
      : pageUrlOpenSublinkIndex;
  }

  return -1;
}
