/* eslint-disable import/prefer-default-export */
import isEmpty from 'lodash/fp/isEmpty';

import { NavigationLink, SideBarNavigationProps } from './types';

import { SiteData, isPageUrlInMenuLink } from '~/shared/services/navigation';
import { VIEWPORTS } from '~/shared/constants';
import { RequestType } from '~/shared/providers/RequestContext/RequestContext';

export function isVisibleSideBar(
  viewportName: string,
  sideBarNavigation: SideBarNavigationProps,
): boolean {
  return viewportName === VIEWPORTS.DESKTOP && !isEmpty(sideBarNavigation);
}

export function isCollapsibleSideBar(
  viewportName: string,
  sideBarNavigation: SideBarNavigationProps,
): boolean {
  return (
    (viewportName === VIEWPORTS.MOBILE || viewportName === VIEWPORTS.TABLET) &&
    !isEmpty(sideBarNavigation)
  );
}

export function markActiveLinkRecursively(
  request: RequestType,
  site: SiteData,
  links: NavigationLink[] = [],
): boolean {
  return links.some((link) => {
    const isActive = !isEmpty(link.links)
      ? markActiveLinkRecursively(request, site, link.links)
      : isPageUrlInMenuLink(request, site, link);

    if (isActive) {
      // eslint-disable-next-line no-param-reassign
      link.isActive = isActive;
    }

    return isActive;
  });
}
