import { useContext, useMemo } from 'react';
import isEmpty from 'lodash/fp/isEmpty';

import * as NavigationService from '../NavigationService';

import SiteContext from '~/shared/providers/SiteContext';
import { PageContext } from '~/shared/providers/PageContext';
import useWindowSize from '~/shared/hooks/use-window-size';
import { base } from '~/shared/styles/themes';

export default function useNavigation(props = {}) {
  const site = useContext(SiteContext);
  const page = useContext(PageContext);
  const {
    localizedUrls = {},
    navigation = {},
    infoBanner = {},
    extraLevelNavigation = {},
    secondaryLogo = {},
    extraLevelNavigationIcon,
    extraLevelNavigationGroupLabel,
    hideInfoBanner,
    hasSideBarNavigation = true,
    contentType,
  } = page;
  const navProps = useMemo(
    () =>
      !isEmpty(props)
        ? props
        : {
            ...navigation,
            localizedUrls,
            secondaryLogo,
            infoBanner,
            extraLevelNavigation,
            extraLevelNavigationIcon,
            extraLevelNavigationGroupLabel,
          },
    [
      extraLevelNavigation,
      extraLevelNavigationGroupLabel,
      extraLevelNavigationIcon,
      infoBanner,
      localizedUrls,
      navigation,
      props,
      secondaryLogo,
    ],
  );

  const breakpoint = useMemo(
    () => NavigationService.calculateBreakpoint(navProps, site.country),
    [navProps, site.country],
  );
  const fullWidthBreakpoint = useMemo(
    () => NavigationService.calculateBreakpoint(navProps, site.country, true),
    [navProps, site.country],
  );
  const { width: windowFullWidth } = useWindowSize();
  const maxWrapperWidth = +base.maxWidth.match(/\d/g).join('');
  const windowWidth = Math.min(windowFullWidth, maxWrapperWidth);

  const navsDoNotFitScreen = windowWidth < breakpoint;
  const navsCanBeHidden =
    windowWidth < fullWidthBreakpoint && !navsDoNotFitScreen;
  const hasInfoBannerOnPage = NavigationService.hasInfoBannerOnPage(
    infoBanner,
    hideInfoBanner,
    contentType,
    site.infoBannerExclusionList,
  );
  const hasExtraLevelNavigationOnPage = !(
    isEmpty(extraLevelNavigation.links) || navsDoNotFitScreen
  );

  return {
    windowWidth,
    breakpoint,
    fullWidthBreakpoint,
    navsCanBeHidden,
    navsDoNotFitScreen,
    hasInfoBannerOnPage,
    hasExtraLevelNavigationOnPage,
    hasSideBarNavigation,
  };
}
