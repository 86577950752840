import get from 'lodash/fp/get';
import sortBy from 'lodash/fp/sortBy';

export function sortByPrice(products) {
  return sortBy((product) => get('original.price', product) || 0, products);
}

// Get the price value only with decimal dot or comma, and remove everything else
export function stripCurrency(price) {
  return price && price.replace(/^[^\d]+/g, '').replace(/[^\d]+$/g, '');
}
