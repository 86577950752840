import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import Image from '~/shared/components/Image';
import { VIEWPORTS } from '~/shared/constants';
import useViewportName from '~/shared/hooks/use-viewport-name';

const wrapperStyles = () => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  width: 100%;
`;
const BackgroundImageWrapper = styled('div')(wrapperStyles);

const imageStyles = () => css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  picture {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
`;
const StyledImage = styled(Image)(imageStyles);

function createSrcSet(src, minWidth, minHeight) {
  return [
    {
      src,
      size: '1x',
      width: minWidth,
      height: minHeight,
    },
    {
      src,
      size: '2x',
      width: minWidth * 2,
      height: minHeight * 2,
    },
  ];
}

function createViewportContent(src, minWidth, minHeight) {
  return {
    src,
    srcSet: createSrcSet(src, minWidth, minHeight),
    minWidth,
  };
}

const HeroBackgroundImage = ({
  className,
  altText,
  mobile,
  tablet,
  desktop,
}) => {
  const viewportName = useViewportName();
  const viewports = {
    [VIEWPORTS.MOBILE]: createViewportContent(mobile, 600, 600),
    [VIEWPORTS.TABLET]: createViewportContent(tablet, 1000, 600),
    [VIEWPORTS.DESKTOP]: createViewportContent(desktop, 1440, 720),
  };
  const viewportContent =
    viewports[viewportName] || viewports[VIEWPORTS.DESKTOP];

  return (
    <BackgroundImageWrapper className={className}>
      <StyledImage
        src={viewportContent.src}
        srcSet={viewportContent.srcSet}
        width={viewportContent.minWidth}
        alt={altText}
        lazyLoad={false}
      />
    </BackgroundImageWrapper>
  );
};

HeroBackgroundImage.propTypes = {
  /**
   * The image source URLs for wide viewports.
   */
  desktop: PropTypes.string,
  /**
   * The image source URLs for medium viewports.
   */
  tablet: PropTypes.string,
  /**
   * The image source URLs for narrow viewports.
   */
  mobile: PropTypes.string,
  /**
   * Circuit-UI theme
   */
  theme: PropTypes.object,
  className: PropTypes.string,
  altText: PropTypes.string,
};

/**
 * @component
 */
export default HeroBackgroundImage;
