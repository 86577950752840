import { Theme } from '@emotion/react';

import { VARIANTS, LEVELS, LevelsType } from './constants';

interface VariantsProps {
  theme?: Theme;
  level?: LevelsType;
  active?: Boolean;
}

const variants = ({ theme, level, active }: VariantsProps) => ({
  [VARIANTS.PRIMARY]: {
    paddingLeft: theme.spacings.mega,
    marginBottom: theme.spacings.kilo,
    components: {
      menu: {},
      menuItem: {
        'minHeight': level === LEVELS.TWO ? '48px' : '52px',
        'padding': `${theme.spacings.kilo} ${
          level === LEVELS.TWO ? theme.spacings.giga : theme.spacings.mega
        }`,
        ':hover': {
          background: 'var(--cui-bg-subtle)',
        },
        ':active': {
          background: 'var(--cui-bg-subtle-pressed)',
        },
        ...(active && {
          ':hover': {
            background: 'var(--cui-bg-accent)',
          },
        }),
      },
    },
  },
  [VARIANTS.COLLAPSABLE]: {
    display: 'flex',
    alignItems: 'center',
    height: theme.spacings.zetta,
    marginBottom: 0,
    paddingLeft: theme.spacings.kilo,
    paddingRight: theme.spacings.kilo,
    cursor: 'pointer',
    ...(!active && {
      borderBottom: '1px solid',
      borderBottomColor: 'var(--cui-border-normal)',
    }),
    components: {
      menu: {
        paddingLeft: level === LEVELS.ONE ? theme.spacings.mega : 0,
        paddingRight: level === LEVELS.ONE ? theme.spacings.mega : 0,
      },
      menuItem: {
        maxWidth: '475px',
        padding: `${theme.spacings.kilo} ${
          level === LEVELS.TWO ? theme.spacings.byte : '0'
        }`,
        minHeight: '60px',
        ...(level === LEVELS.ONE && {
          ':not(:first-child)': {
            borderTop: '1px solid',
            borderTopColor: 'var(--cui-border-subtle)',
          },
        }),
      },
    },
  },
});

export type { VariantsProps };
export default variants;
