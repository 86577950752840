import isEmpty from 'lodash/fp/isEmpty';
import isNumber from 'lodash/fp/isNumber';

import { MAX_SIZE } from './constants';

import formatContentfulUrl from '~/shared/util/format-contentful-url';
import imageFormatExtensions, {
  getSupportedSrcFormat,
  imageParametersForFormat,
} from '~/shared/util/image-format-extensions';

function getAdjustedWidth(maxHeight, originalWidth, originalHeight) {
  return Math.round((maxHeight * originalWidth) / originalHeight);
}

function getAdjustedHeight(maxWidth, originalWidth, originalHeight) {
  return Math.round((maxWidth * originalHeight) / originalWidth);
}

export function ensureImageSize(width = 0, height = 0) {
  const size = { width, height };

  if (width > MAX_SIZE || height > MAX_SIZE) {
    const maxDimension = Math.max(width, height);

    if (maxDimension === width) {
      size.width = MAX_SIZE;
      size.height = getAdjustedHeight(size.width, width, height);
    } else if (maxDimension === height) {
      size.height = MAX_SIZE;
      size.width = getAdjustedWidth(size.height, width, height);
    }
  }

  return size;
}

export function getSizeParams(source = {}, contentful = {}) {
  const params = {};

  if (source.src && source.src.includes('.svg')) {
    return params;
  }

  let { width, height } = source;
  let imageSize;

  if (contentful.w || contentful.h) {
    width = contentful.w;
    height = contentful.h;
  }

  if (width || height) {
    imageSize = ensureImageSize(width, height);

    if (imageSize.width) {
      params.w = imageSize.width;
    }

    if (imageSize.height) {
      params.h = imageSize.height;
    }
  }

  return params;
}

export function getSrc(source = {}, contentful = {}) {
  const params = {
    ...contentful,
    ...imageFormatExtensions(source.src),
    ...getSizeParams(source, contentful),
  };

  return formatContentfulUrl(source.src, params);
}

export function getSrcSet(srcSet, contentful) {
  const sources = srcSet.reduce((allSources, source) => {
    const contentfulUrl = getSrc(source, contentful);

    if (contentfulUrl) {
      allSources.push(`${contentfulUrl} ${source.size}`);
    }

    return allSources;
  }, []);

  return isEmpty(sources) ? undefined : sources.join(', ');
}

function getSrcForFormat(source = {}, contentful = {}, format = 'webp') {
  const supportedSrcFormat = getSupportedSrcFormat(source.src);

  if (!supportedSrcFormat) {
    return undefined;
  }

  const params = {
    ...contentful,
    ...imageParametersForFormat(source.src, format),
    ...getSizeParams(source, contentful),
  };

  return formatContentfulUrl(source.src, params);
}

export function getSrcSetForFormat(srcSet, contentful, format) {
  const sources = srcSet.reduce((allSources, source) => {
    const contentfulUrl = getSrcForFormat(source, contentful, format);

    if (contentfulUrl) {
      allSources.push(`${contentfulUrl} ${source.size}`);
    }

    return allSources;
  }, []);

  return isEmpty(sources) ? undefined : sources.join(', ');
}

export function hasDimensions(width, height) {
  return isNumber(width) && isNumber(height);
}
