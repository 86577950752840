import React from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/fp/isEmpty';
// eslint-disable-next-line max-len
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

/**
 * A component that renders a Contentful rich text AST to React components.
 */
function RichText({ richText = {}, renderNode = {}, renderMark = {} }) {
  if (isEmpty(richText)) {
    return null;
  }
  return <>{documentToReactComponents(richText, { renderNode, renderMark })}</>;
}

RichText.propTypes = {
  /**
   * The rich text AST to be parsed and rendered.
   */
  richText: PropTypes.object,
  /**
   * Override a rich text node with a React component,
   * e.g. `a` -> `<Link />`
   * The React component is passed all node data as props.
   */
  renderNode: PropTypes.object,
  /**
   * Override a rich text mark with a React component,
   * e.g. `em` -> `<Text bold />`
   * The React component is passed the text string as prop.
   */
  renderMark: PropTypes.object,
};

/**
 * @component
 */
export default RichText;
