import { light } from '@sumup/design-tokens';

export const zIndex = {
  ...light.zIndex,
  badge: 11,
  fullMediaBgMoveToFront: 100,
  fullMediaBadge: 111,
  underCircuitUIModal: 999,
  editButton: 22222,
  navigation: 33333,
  modal: 444444,
  nprogress: 55555,
};

export const spacings = {
  nav: {
    mobile: '64px',
    desktop: '72px',
  },
  tertiaryNav: {
    desktop: '64px',
  },
  infoBanner: {
    mobile: '72px',
    desktop: '48px',
  },
  section: {
    mobile: {
      small: '24px',
      medium: '30px',
      large: '56px',
    },
    tablet: {
      small: '27px',
      medium: '36px',
      large: '72px',
    },
    desktop: {
      small: '36px',
      medium: '48px',
      large: '96px',
    },
  },
};

const maxWidth = '1440px';

const grid = {
  ...light.grid,
  default: {
    priority: 0,
    breakpoint: 'default',
    cols: 12,
    maxWidth: '1060px',
    gutter: light.spacings.tera,
  },
  untilKilo: {
    priority: 1,
    breakpoint: 'untilKilo',
    cols: 12,
    maxWidth: '540px',
    gutter: light.spacings.giga,
  },
  kilo: {
    priority: 2,
    breakpoint: 'kilo',
    cols: 12,
    maxWidth: '720px',
    gutter: light.spacings.tera,
  },
  mega: {
    priority: 3,
    breakpoint: 'mega',
    cols: 12,
    maxWidth: '880px',
    gutter: light.spacings.tera,
  },
  giga: {
    priority: 4,
    breakpoint: 'giga',
    cols: 12,
    maxWidth: '1060px',
    gutter: light.spacings.tera,
  },
};

const breakpoints = {
  untilKilo: '(max-width: 599px)',
  kilo: '(min-width: 600px)',
  kiloToMega: '(min-width: 600px) and (max-width: 899px)',
  untilMega: '(max-width: 899px)',
  mega: '(min-width: 900px)',
  megaToGiga: '(min-width: 900px) and (max-width: 1199px)',
  giga: '(min-width: 1200px)',
  gigaToTera: '(min-width: 1200px) and (max-width: 1499px)',
  untilTera: '(max-width: 1499px)',
  tera: '(min-width: 1500px)',
};

export const mq = {
  untilKilo: '@media (max-width: 599px)',
  kilo: '@media (min-width: 600px)',
  kiloToMega: '@media (min-width: 600px) and (max-width: 899px)',
  untilMega: '@media (max-width: 899px)',
  mega: '@media (min-width: 900px)',
  megaToGiga: '@media (min-width: 900px) and (max-width: 1199px)',
  untilGiga: '@media (max-width: 1199px)',
  giga: '@media (min-width: 1200px)',
  gigaToTera: '@media (min-width: 1200px) and (max-width: 1499px)',
  untilTera: '@media (max-width: 1499px)',
  tera: '@media (min-width: 1500px)',
};

export const base = {
  ...light,
  zIndex,
  grid,
  mq,
  breakpoints,
  maxWidth,
  spacings: {
    ...light.spacings,
    ...spacings,
  },
  borderRadius: {
    ...light.borderRadius,
    tera: '8px',
    exa: '30px',
  },
  transitions: {
    ...light.transitions,
    easeInOutCubic: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  },
  hyphens: {
    headings: {
      default: 'manual',
      untilKilo: 'auto',
    },
    headingTwo: 'auto',
    text: {
      default: 'manual',
    },
  },
};
const noHyphens = {
  ...base,
  hyphens: {
    headings: {
      default: 'none',
      untilKilo: 'none',
    },
    headingTwo: 'none',
    text: {
      default: 'none',
    },
  },
};

const THEME_MAP = {
  noHyphens,
  standard: base,
};

export function getTheme(disableHyphenation = false) {
  return disableHyphenation ? THEME_MAP.noHyphens : THEME_MAP.standard;
}
