export const CONTENTFUL_PAGE_TYPES = {
  GENERIC_PAGE: 'genericPage',
  LANDING_PAGE: 'prospectingPage',
  CAREERS: 'careersPage',
  CAREERS_POSITION: 'careersPosition',
  PARTNER: 'partnerPage',
  PRESS: 'press',
  PRESS_ARTICLE: 'pressArticle',
  SBG_INDEX_PAGE: 'sbgIndexPage',
  SBG_CATEGORY_PAGE: 'sbgCategoryPage',
  SBG_POST: 'sbgPost',
  EXPERIENCE: 'pageExperience',
} as const;

type ContentfulPageTypeKey = keyof typeof CONTENTFUL_PAGE_TYPES;
export type ContentfulPageType =
  (typeof CONTENTFUL_PAGE_TYPES)[ContentfulPageTypeKey];
