import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Button } from '@sumup/circuit-ui';

import { trackingContentEntryPropType } from '~/shared/util/shared-prop-types';
import Link from '~/shared/components/Link';
import { NAVIGATION_EVENTS } from '~/shared/services/optimizely/navigation-revamp-experiment/constants';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';
import * as Url from '~/shared/services/url';

const baseStyles = ({ theme }) => css`
  width: 100%;
  margin-top: ${theme.spacings.bit};

  ${theme.mq.nav} {
    width: auto;
    margin-top: 0;
  }
`;

const soloButtonBaseStyles = ({ theme, soloDesign = {} }) =>
  soloDesign.enableRoundedDesign &&
  css`
    border-radius: ${theme.borderRadius.exa};
  `;

const StyledButton = styled(Button)(baseStyles, soloButtonBaseStyles);

/**
 * A prominent call-to-action button.
 */
function CtaButton({
  ctaButtonUrl,
  ctaButtonLabel,
  ctaButtonTrackingId,
  ctaButtonOptimizelyFullStackClickEvents,
  onClick,
  trackingContentEntry = {},
  ...rest
}) {
  if (!ctaButtonUrl || !ctaButtonLabel) {
    return null;
  }

  const handleClick = (event) => {
    const allowedHosts = ['store.sumup.com'];

    if (allowedHosts.includes(Url.parse(ctaButtonUrl).host)) {
      OptimizelyFullStack.trackEvent({
        eventName: NAVIGATION_EVENTS.SHOP,
      });
    }

    if (onClick) {
      onClick(event);
    }
  };

  return (
    <Link
      href={ctaButtonUrl}
      onClick={handleClick}
      trackingId={ctaButtonTrackingId}
      optimizelyFullStackClickEvents={ctaButtonOptimizelyFullStackClickEvents}
      trackingContentEntry={trackingContentEntry}
    >
      <StyledButton variant="primary" {...rest}>
        {ctaButtonLabel}
      </StyledButton>
    </Link>
  );
}

CtaButton.propTypes = {
  ctaButtonUrl: PropTypes.string,
  ctaButtonLabel: PropTypes.string,
  ctaButtonTrackingId: PropTypes.string,
  ctaButtonOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,

  /*
   * TEMPORARY EXPERIMENTAL SOLO STYLES EXTENSIONS!
   * added via https://sumupteam.atlassian.net/browse/SA-32606
   * TBD: will be removed or converted to proper features
   */
  soloDesign: PropTypes.object,
};

/**
 * @component
 */
export default CtaButton;
