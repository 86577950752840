import isServer from '~/shared/util/is-server';
import * as logger from '~/shared/services/logger';

export const QUERY_PARAMS_STORAGE_KEY = '__request_query_params_storage__';
export const QUERY_PARAMS_STORAGE_TTL = 1000 * 60 * 60; // 1 hour in ms

interface RequestQueryParams {
  [key: string]: any;
}

interface StoredRequestQueryParams {
  [key: string]: {
    value: any;
    expiry: number;
  };
}

export function save(queryParams: RequestQueryParams = {}): void {
  if (isServer) {
    return;
  }

  const existingQueryParamsList = getAllNonExpiredWithExpirationDates();
  const newQueryParamsList = Object.keys(queryParams).reduce((obj, key) => {
    const value = queryParams[key];
    const expiry = Date.now() + QUERY_PARAMS_STORAGE_TTL;

    return {
      ...obj,
      [key]: {
        value,
        expiry,
      },
    };
  }, {});
  const queryParamsWithExpirationDates = {
    ...existingQueryParamsList,
    ...newQueryParamsList,
  };

  try {
    const stringifiedData = JSON.stringify(queryParamsWithExpirationDates);

    window.localStorage.setItem(QUERY_PARAMS_STORAGE_KEY, stringifiedData);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
}

export function getAll(): RequestQueryParams {
  const queryParamsWithExpirationDates = getAllNonExpiredWithExpirationDates();
  const queryParamsObject = Object.keys(queryParamsWithExpirationDates).reduce(
    (obj, key) => {
      const { value } = queryParamsWithExpirationDates[key];

      return {
        ...obj,
        [key]: value,
      };
    },
    {},
  );

  return queryParamsObject;
}

export function getAllNonExpiredWithExpirationDates(): StoredRequestQueryParams {
  if (isServer) {
    return {};
  }

  const stringifiedData = window.localStorage.getItem(QUERY_PARAMS_STORAGE_KEY);

  if (!stringifiedData) {
    return {};
  }

  try {
    const allQueryParams = JSON.parse(stringifiedData);
    const actualQueryParams = Object.keys(allQueryParams).reduce((obj, key) => {
      const param = allQueryParams[key];
      const isExpired = Date.now() > param.expiry;

      if (!isExpired) {
        return {
          ...obj,
          [key]: { ...param },
        };
      }

      return obj;
    }, {});

    return actualQueryParams;
  } catch (err) {
    logger.error(err);

    return {};
  }
}
