import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import BackgroundImage from '../BackgroundImage';
import formatContentfulUrl from '../../../../util/format-contentful-url';

const wrapperBaseStyles = () => css`
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  overflow: hidden;
`;

const mobileStyles = () => css`
  @media (max-width: 800px) {
    display: none;
  }
`;

const videoStyles = () => css`
  height: 100%;
  width: 100%;
  object-fit: cover;
`;

const Wrapper = styled('div')(wrapperBaseStyles, mobileStyles);
const FallbackImage = styled(BackgroundImage)(mobileStyles);
const Video = styled('video')(videoStyles);

/**
 * A fullbleed background video that supports mp4 and webM formats.
 */
class BackgroundVideo extends Component {
  static propTypes = {
    /**
     * The mp4 video source URL.
     */
    mp4: PropTypes.string.isRequired,
    /**
     * * The webM video source URL.
     */
    webm: PropTypes.string.isRequired,
    /**
     * A fallback image that's shown while the video is loading.
     * Ideally it should match the first frame of the video.
     */
    placeholder: PropTypes.string,
    /**
     * An alternative description of the video. Crucial for accessibility.
     */
    alt: PropTypes.string.isRequired,
  };

  state = {
    renderVideo: false,
  };

  componentDidMount() {
    this.setState({ renderVideo: true });
  }

  render() {
    const { mp4, webm, placeholder, alt, ...rest } = this.props;
    if (!mp4 && !webm) {
      return null;
    }

    if (!this.state.renderVideo && placeholder) {
      return (
        <FallbackImage
          data-selector="FALLBACK_IMAGE"
          desktop={placeholder}
          tablet={placeholder}
          alt={alt}
        />
      );
    }

    const placeholderSrc = formatContentfulUrl(placeholder, {
      fm: 'jpg',
      w: 1440,
      h: 720,
    });

    return (
      <Wrapper {...rest}>
        <Video
          preload="metadata"
          autoPlay
          muted
          playsInline
          loop
          data-selector="hero-background-video"
        >
          {webm && <source src={webm} type="video/webm" />}
          {mp4 && <source src={mp4} type="video/mp4" />}
          {placeholder && <img src={placeholderSrc} alt={alt} />}
        </Video>
      </Wrapper>
    );
  }
}

/**
 * @component
 */
export default BackgroundVideo;
