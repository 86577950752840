import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import MobileMenu from './components/MobileMenu';
import DesktopMenu from './components/DeskopMenu';

import dataSelector from '~/shared/util/data-selector';
import { linkPropType } from '~/shared/util/shared-prop-types';

export const DATA_SELECTOR = 'main_nav_menu';

const navBaseStyles = ({ theme, actionAreaHeight }) => css`
  visibility: hidden;
  opacity: 0;
  overflow-y: scroll;
  pointer-events: none;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  // Needs to be that way to support ie11
  top: calc(${theme.spacings.tera} * 2);
  background-color: var(--cui-bg-normal);
  transition:
    opacity 0.15s ease-in-out,
    visibility 0.15s ease-in-out;
  border-top: 1px solid var(--cui-border-normal);
  height: calc(100vh - ${actionAreaHeight});

  ${theme.mq.nav} {
    top: 0;
    display: flex;
    justify-content: center;
    width: auto;
    visibility: visible;
    overflow-y: visible;
    opacity: 1;
    pointer-events: auto;
    height: auto;
    border: none;
  }
`;

const navOpenStyles = ({ theme, isMenuOpen }) =>
  isMenuOpen &&
  css`
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    z-index: ${theme.zIndex.navigation};
  `;

const Nav = styled('nav')(navBaseStyles, navOpenStyles);

/**
 * Menu links
 */
const Menu = forwardRef(
  (
    {
      links,
      closeMenu,
      isMenuOpen = false,
      isActionAreaVisible = false,
      navigationTrackingData = {},
      useNewMobileNavigation = false,
      linksGroupLabel,
      backButtonLabel,
      canBeHidden,
      willNotFit,
    },
    ref,
  ) => {
    const actionAreaHeight = isActionAreaVisible ? '150px' : '0px';

    return (
      <Nav
        ref={ref}
        isMenuOpen={isMenuOpen}
        role="navigation"
        data-selector={dataSelector('section', DATA_SELECTOR)}
        actionAreaHeight={actionAreaHeight}
      >
        {useNewMobileNavigation && willNotFit && isMenuOpen ? (
          <MobileMenu
            links={links}
            actionAreaHeight={actionAreaHeight}
            closeMenu={closeMenu}
            defaultLinksGroupLabel={linksGroupLabel}
            backButtonLabel={backButtonLabel}
            navigationTrackingData={navigationTrackingData}
          />
        ) : (
          <DesktopMenu
            links={links}
            actionAreaHeight={actionAreaHeight}
            closeMenu={closeMenu}
            canBeHidden={canBeHidden}
            navigationTrackingData={navigationTrackingData}
            isMenuOpen={isMenuOpen}
          />
        )}
      </Nav>
    );
  },
);

Menu.displayName = 'Menu';

Menu.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      ...linkPropType,
      links: PropTypes.arrayOf(PropTypes.shape(linkPropType)),
    }),
  ),
  isMenuOpen: PropTypes.bool,
  closeMenu: PropTypes.func,
  isActionAreaVisible: PropTypes.bool,
  navigationTrackingData: PropTypes.object,
  useNewMobileNavigation: PropTypes.bool,
  linksGroupLabel: PropTypes.string,
  backButtonLabel: PropTypes.string,
  canBeHidden: PropTypes.bool,
  willNotFit: PropTypes.bool,
};

/**
 * @component
 */
export default React.memo(Menu);
