import get from 'lodash/fp/get';

/**
 * Receive the correct status code from either error
 * or response Object
 * @param {Object} res
 * @param {Object} err
 */
export function getStatusCodeFromError(err, res = {}, fallback = 500) {
  return Number(get('statusCode', err) || get('statusCode', res) || fallback);
}
