import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Hamburger } from '@sumup/circuit-ui';

import LoginButton from './components/LoginButton/LoginButton';
import CtaButton from './components/CtaButton/CtaButton';
import LanguageSwitcher from './components/LanguageSwitcher';

import SiteContext from '~/shared/providers/SiteContext';
import {
  countryPropType,
  websitePropType,
  trackingContentEntryPropType,
} from '~/shared/util/shared-prop-types';

const wrapperStyles = ({ theme }) => css`
  position: relative;
  z-index: ${theme.zIndex.navigation};
`;

const Wrapper = styled('div')(wrapperStyles);

const contentWrapperBaseStyles = ({ theme, isLoginRowVisible }) => css`
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  padding: ${theme.spacings.giga};
  padding-top: ${isLoginRowVisible ? 0 : null};
  background: var(--cui-bg-normal);
  transition:
    opacity 0.15s ease-in-out,
    visibility 0.15s ease-in-out;

  ${theme.mq.nav} {
    position: static;
    display: block;
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    padding: 0;
  }
`;

const contentWrapperOpenStyles = ({ theme, isMenuOpen }) =>
  isMenuOpen &&
  css`
    visibility: visible;
    opacity: 1;
    pointer-events: auto;
    box-shadow: 0px 1px 0px 0px var(--cui-border-normal) inset;

    &::before {
      position: absolute;
      bottom: 100%;
      left: 0;
      right: 0;
      display: block;
      content: '';
      width: 100%;
      height: ${theme.spacings.peta};
      background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0),
        var(--cui-bg-normal)
      );
      pointer-events: none;
    }
  `;

const ContentWrapper = styled('div')(
  contentWrapperBaseStyles,
  contentWrapperOpenStyles,
);

const flexWrapperBaseStyles = () => css`
  display: flex;
  justify-content: center;
`;

const flexWrapperSpaceBetweenStyles = ({ theme, spaceBetween }) =>
  spaceBetween &&
  css`
    justify-content: space-between;

    ${theme.mq.nav} {
      > a {
        padding-right: ${theme.spacings.mega};
        border-right: 1px solid var(--cui-border-normal);
      }
    }
  `;

const FlexWrapper = styled('div')(
  flexWrapperBaseStyles,
  flexWrapperSpaceBetweenStyles,
);

const pinnedWrapperBaseStyles = ({ theme, showPinnedButton }) => css`
  position: fixed;
  top: 0;
  right: ${theme.spacings.zetta};
  left: ${theme.spacings.zetta};
  height: ${theme.spacings.nav.mobile};
  /* temporary change for A/B test [SA-13824]  */
  display: ${showPinnedButton ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;

  > a {
    width: auto;
    margin: 0;
  }

  ${theme.mq.nav} {
    display: none;
  }
`;

const PinnedWrapper = styled('div')(pinnedWrapperBaseStyles);

const hamburgerStyles = ({ theme }) => css`
  // Override styles from circuit-ui to comply with
  // current marketing website design
  border-width: 0px;

  & > span {
    height: 2px;
    width: 16px;
    pointer-events: none;

    &:before {
      height: 2px;
      width: 16px;
    }

    &:after {
      height: 2px;
      width: 16px;
    }
  }

  ${theme.mq.nav} {
    display: none;
  }
`;

const StyledHamburger = styled(Hamburger)(hamburgerStyles);

/**
 * This area bundles the login button, the language switcher, a CTA button, and
 * the Hamburger for the mobile menu.
 */
function ActionArea({
  loginLabel,
  loginUrl,
  loginTrackingId,
  loginOptimizelyFullStackClickEvents,
  ctaButtonUrl,
  ctaButtonLabel,
  ctaButtonTrackingId,
  ctaButtonOptimizelyFullStackClickEvents,
  hasLinks,
  isPinned,
  isMenuOpen,
  closeMenu,
  toggleMenu,
  localizedUrls = {},
  websites = [],
  country = {},
  locale,
  trackingContentEntry = {},
  willNotFit,
  scrolledPastThreshold,
  soloDesign = {},
}) {
  const { accessibilityLabels = {} } = useContext(SiteContext);
  const hasLoginButton = loginLabel && loginUrl;
  const hasCtaButton = ctaButtonLabel && ctaButtonUrl;
  const hasLanguageSwitcher = country.multilingual;

  if (!hasLoginButton && !hasCtaButton && !hasLanguageSwitcher && !hasLinks) {
    return null;
  }

  const buttonCount =
    (hasLoginButton ? 1 : 0) +
    (hasLanguageSwitcher ? 1 : 0) +
    (hasCtaButton ? 1 : 0);

  const showHamburguer = (hasLinks || buttonCount >= 2) && willNotFit;
  const showLanguageSwitcher = !isPinned || isMenuOpen;
  const showLoginButton = !isPinned || isMenuOpen;
  const showCtaButton = isPinned || isMenuOpen;
  const showPinnedButton = isPinned && !isMenuOpen && scrolledPastThreshold;
  const isLoginRowVisible = !!(hasLoginButton || hasLanguageSwitcher);
  const isBottomPanelVisible = !!(isLoginRowVisible || hasCtaButton);

  return (
    <Wrapper>
      {isBottomPanelVisible ? (
        <ContentWrapper
          isMenuOpen={isMenuOpen}
          isLoginRowVisible={isLoginRowVisible}
        >
          <FlexWrapper spaceBetween={hasLoginButton && hasLanguageSwitcher}>
            {showLoginButton && (
              <LoginButton
                loginLabel={loginLabel}
                loginUrl={loginUrl}
                loginTrackingId={loginTrackingId}
                loginOptimizelyFullStackClickEvents={
                  loginOptimizelyFullStackClickEvents
                }
                closeMenu={closeMenu}
                data-selector="login@action-area"
                trackingContentEntry={trackingContentEntry}
              />
            )}

            {showLanguageSwitcher && (
              <LanguageSwitcher
                country={country}
                websites={websites}
                locale={locale}
                localizedUrls={localizedUrls}
                data-selector="language-switcher@action-area"
                trackingContentEntry={trackingContentEntry}
              />
            )}
          </FlexWrapper>

          {showCtaButton && (
            <CtaButton
              ctaButtonUrl={ctaButtonUrl}
              ctaButtonLabel={ctaButtonLabel}
              ctaButtonTrackingId={ctaButtonTrackingId}
              ctaButtonOptimizelyFullStackClickEvents={
                ctaButtonOptimizelyFullStackClickEvents
              }
              onClick={closeMenu}
              data-selector="cta@action-area"
              trackingContentEntry={trackingContentEntry}
              soloDesign={soloDesign}
            />
          )}
        </ContentWrapper>
      ) : null}

      {/* temporary change for A/B test [SA-13824] {showPinnedButton && ( */}
      <PinnedWrapper
        data-selector="container@action-area"
        showPinnedButton={showPinnedButton}
      >
        <CtaButton
          ctaButtonUrl={ctaButtonUrl}
          ctaButtonLabel={ctaButtonLabel}
          ctaButtonTrackingId={ctaButtonTrackingId}
          ctaButtonOptimizelyFullStackClickEvents={
            ctaButtonOptimizelyFullStackClickEvents
          }
          onClick={closeMenu}
          data-selector="cta@action-area"
          size={'kilo'}
          trackingContentEntry={trackingContentEntry}
          soloDesign={soloDesign}
        />
      </PinnedWrapper>
      {/* temporary change for A/B test [SA-13824]  )} */}

      {showHamburguer && (
        <StyledHamburger
          isActive={isMenuOpen}
          onClick={toggleMenu}
          aria-expanded={isMenuOpen}
          activeLabel={accessibilityLabels.closeHamburgerMenu}
          inactiveLabel={accessibilityLabels.openHamburgerMenu}
          data-selector="hamburger@action-area"
        />
      )}
    </Wrapper>
  );
}

ActionArea.propTypes = {
  loginUrl: PropTypes.string,
  loginLabel: PropTypes.string,
  loginTrackingId: PropTypes.string,
  loginOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  ctaButtonUrl: PropTypes.string,
  ctaButtonLabel: PropTypes.string,
  ctaButtonTrackingId: PropTypes.string,
  ctaButtonOptimizelyFullStackClickEvents: PropTypes.arrayOf(PropTypes.string),
  localizedUrls: PropTypes.object,
  isPinned: PropTypes.bool,
  hasLinks: PropTypes.bool,
  isMenuOpen: PropTypes.bool,
  toggleMenu: PropTypes.func,
  closeMenu: PropTypes.func,
  websites: PropTypes.arrayOf(PropTypes.shape(websitePropType)),
  country: PropTypes.shape(countryPropType),
  locale: PropTypes.string,
  willNotFit: PropTypes.bool,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
  scrolledPastThreshold: PropTypes.bool,

  /*
   * TEMPORARY EXPERIMENTAL SOLO STYLES EXTENSIONS!
   * added via https://sumupteam.atlassian.net/browse/SA-32606
   * TBD: will be removed or converted to proper features
   */
  soloDesign: PropTypes.object,
};

/**
 * @component
 */
export default React.memo(ActionArea);
