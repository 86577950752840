import React, { ReactNode, createContext, Context } from 'react';
import merge from 'lodash/fp/merge';

import * as ActionTypes from './actionTypes';

import { PageType } from '~/shared/types/shared';
import { SectionBase } from '~/shared/api-controllers/shared/transformers/sections';

type PageProviderProps = {
  children: ReactNode;
  page?: PageType;
};

type State = PageType & {
  previousRoute: object;
  previousSections: SectionBase[];
};

type Action = {
  type: typeof ActionTypes.SET_PAGE;
  payload: PageType;
};

/**
 * Passes down page data of the current page
 */
const PageContext: Context<PageType> = createContext({});

const initialState = {
  previousRoute: {},
  previousSections: [],
};

export function getSectionsIds(sections: SectionBase[] = []) {
  return sections.map(({ id, contentType }) => ({ id, contentType }));
}

export function reducer(store: State, action: Action) {
  switch (action.type) {
    case ActionTypes.SET_PAGE:
      return {
        previousRoute: { ...store.route },
        previousSections: getSectionsIds(store.sections),
        ...action.payload,
      };
    default:
      return store;
  }
}

function PageProvider({ children, page }: PageProviderProps) {
  const [store, dispatch] = React.useReducer(
    reducer,
    merge(initialState, page),
  );

  React.useEffect(() => {
    if (page) {
      dispatch({ type: ActionTypes.SET_PAGE, payload: page });
    }
  }, [dispatch, page]);

  return <PageContext.Provider value={store}>{children}</PageContext.Provider>;
}

export { PageContext, PageProvider };
