import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import NavAnchor from '../../../NavAnchor';

import dataSelector from '~/shared/util/data-selector';
import Link from '~/shared/components/Link';
import {
  linkPropType,
  trackingContentEntryProps,
} from '~/shared/util/shared-prop-types';
import { NAVIGATION_EVENTS } from '~/shared/services/optimizely/navigation-revamp-experiment/constants';
import * as OptimizelyFullStack from '~/shared/services/optimizely/optimizely-browser-client';

const DATA_SELECTOR = 'tertiary_menu';

const listItemStyles = ({ theme }) => css`
  margin: 0 ${theme.spacings.giga};
  width: calc(100% - 2 * ${theme.spacings.giga});
  border-bottom: 1px solid var(--cui-border-normal);
  position: relative;

  ${theme.mq.nav} {
    display: inline-flex;
    text-align: center;
    margin: 0 ${theme.spacings.bit};
    width: auto;
    border: none;
    position: static;
  }

  ${theme.mq.mega} {
    margin: 0 ${theme.spacings.byte};
  }
`;

const verticalListItemStyles = ({ theme, isVertical }) =>
  isVertical &&
  css`
    ${theme.mq.nav} {
      width: 100%;
      margin: 0;
    }
  `;

const collapsibleItemStyles = ({ theme, applyCollapsibleStyles }) =>
  applyCollapsibleStyles &&
  css`
    ${theme.mq.mainNavCollapsibleMenu} {
      display: none;
    }
  `;

const ListItem = styled('li')(
  listItemStyles,
  verticalListItemStyles,
  collapsibleItemStyles,
);

const navAnchorStyles = ({ theme, isVertical = false }) => css`
  position: relative;
  display: inline-block;
  z-index: 3;
  cursor: pointer;

  ${theme.mq.nav} {
    white-space: nowrap;
    ${!isVertical &&
    `
      margin: 0;
      padding-top: calc((${theme.spacings.tertiaryNav.desktop} - 1em) / 2);
      padding-bottom: calc((${theme.spacings.tertiaryNav.desktop} - 1em) / 2);
      `}
  }
`;

const StyledNavAnchor = styled(NavAnchor)(navAnchorStyles);

/**
 * A NavItem component used in the tertiary menu. Inspired by MenuSection.js
 */
const NavItem = ({
  label = '',
  url = '',
  openLinkInNewWindow,
  contentType,
  name,
  id,
  isVertical = false,
  applyCollapsibleStyles = false,
  ...rest
}) => {
  const onClick = () => {
    OptimizelyFullStack.trackEvent({
      eventName: NAVIGATION_EVENTS.THIRD_LEVEL,
      eventTags: { label },
    });
  };

  return (
    <ListItem
      isVertical={isVertical}
      applyCollapsibleStyles={applyCollapsibleStyles}
      data-selector={dataSelector('list_item', DATA_SELECTOR)}
    >
      <Link
        href={url}
        target={openLinkInNewWindow ? '_blank' : undefined}
        trackingContentEntry={{
          contentType,
          contentEntryName: name,
          contentEntryId: id,
        }}
        onClick={onClick}
        {...rest}
      >
        <StyledNavAnchor
          isVertical={isVertical}
          isTertiaryAnchor={true}
          data-selector={dataSelector('link', DATA_SELECTOR)}
        >
          {label}
        </StyledNavAnchor>
      </Link>
    </ListItem>
  );
};

NavItem.propTypes = {
  ...linkPropType,
  ...trackingContentEntryProps,
};

/**
 * @component
 */
export default NavItem;
